import React, { useContext, useEffect } from "react";
import "./App.css";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { filterOutColumns } from "./layout";
import { useAuth0 } from "@auth0/auth0-react";
import { apiURL } from "./consts";
import { Navigate, useNavigate } from "react-router-dom";
import { RefetchContext } from "./refetchProvider";
import { useMutation } from "@apollo/client";
import useUpsertView from './useUpsertView'

export default function ShareView(props: {
  showingShareView: any;
  setShowingShareView: any;
  selectedView: any;
}) {
  const { setShowingShareView, selectedView, showingShareView } = props;

  const context = useContext(RefetchContext);

  const { getAccessTokenSilently } = useAuth0();
  const [emails, setEmails] = React.useState("");
  const navigate = useNavigate();
  const [isPrivate, setIsPrivate] = React.useState(false);

  const [sharedEmails, setSharedEmails] = React.useState<any[]>([]);

  const { updateView } = useUpsertView()

  useEffect(() => {
    if (selectedView) {
      setSharedEmails(
        selectedView?.sharedWith?.read.map((share: any) => share.email)
      );
      setIsPrivate(!selectedView.isPublic);
    }
  }, [selectedView]);

  // useEffect(() => {
  //   updateView({
  //     variables: {
  //       query: {
  //         _id: selectedView._id,
  //       },
  //       view: {
  //         isPublic: !isPrivate,
  //       },
  //     },
  //   });
  // }, [isPrivate, selectedView._id, updateView]);

  const members = context?.workspaceMembers;

  return (
    <Dialog onClose={() => {}} open={showingShareView}>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          // Save new view

          // const accessToken = await getAccessTokenSilently();

          // const parseEmails =
          //   emails.length > 1
          //     ? emails.split(",").map((email: string) => email.trim())
          //     : [];

          // await fetch(`${apiURL}collaboration/send-invite`, {
          //   method: "POST",
          //   headers: {
          //     "Content-Type": "application/json",
          //     Authorization: `Bearer ${accessToken}`,
          //   },
          //   body: JSON.stringify({
          //     viewId: selectedView._id,
          //     emails: parseEmails.concat(sharedEmails),
          //   }),

          // }).then((data) => {
          //   setShowingShareView(false);
          //   // alert("Invite sent!");
          // });
          await updateView({
            variables: {
              query: {
                _id: selectedView._id,
              },
              view: {
                isPublic: !isPrivate,
                triggeredBy: 'client'
              },
            },
          });
          setShowingShareView(false);
        }}
      >
        <DialogTitle>
          Enter the emails to share separated by a comma
        </DialogTitle>
        <FormGroup sx={{ padding: "15px 30px", paddingTop: "0px" }}>
          <FormControlLabel
            control={
              <Switch
                checked={isPrivate}
                onClick={() => setIsPrivate(!isPrivate)}
              />
            }
            label="Private"
          />
        </FormGroup>
        <Divider />
        {!isPrivate && (
          <>
            <Typography sx={{ padding: "15px 30px", paddingBottom: "0px" }}>
              This list is shared with everyone in the workspace:
            </Typography>
            <Typography sx={{ padding: "15px 30px", paddingTop: "0px" }}>
              {members?.map((member: any, index: number) => {
                if (index === members.length - 1) {
                  return `${member.name}.`;
                }

                return `${member.name}, `;
              })}
            </Typography>
          </>
        )}
        {isPrivate && (
          <Typography sx={{ padding: "15px 30px" }}>
            Only you can see this list.
          </Typography>
        )}
        {/* <FormGroup sx={{ padding: "15px 30px", paddingTop: "0px" }}>
          {members?.map((member: any) => {
            const checked = sharedEmails?.includes(member.email);

            return (
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={checked}
                    onClick={(event: any) => {
                      if (event.target.checked) {
                        setSharedEmails(
                          !!sharedEmails
                            ? [...sharedEmails, member.email]
                            : [member.email]
                        );
                      } else {
                        setSharedEmails(
                          sharedEmails.filter(
                            (email: any) => email !== member.email
                          )
                        );
                      }
                    }}
                  />
                }
                label={member.email}
              />
            );
          })}
        </FormGroup> */}
        {/* <Divider />
        <DialogContent>
          <TextField
            autoFocus
            value={emails}
            onChange={(e) => setEmails(e.target.value)}
            margin="dense"
            size="small"
            label="Emails"
            variant="standard"
            fullWidth
          />
        </DialogContent> */}
        <DialogActions>
          <Button type="button" onClick={() => setShowingShareView(false)}>
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={emails.length < 1 && sharedEmails?.length === 0}
          >
            Save Sharing Settings
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
