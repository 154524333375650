import { Chip } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { CustomChip, getPageTitle } from "./individualView";
import { RefetchContext } from "./refetchProvider";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import _ from "lodash";
import { GetHeaderText } from "./DatagridFunctions";
import { useQuery } from "@apollo/client";
import { GET_ONE_VIEW } from "./graphql-ops";
import useUpdateRow from "./useUpdateRow";

export function TagDisplay(props: {
  field: string;
  options: any;
  align?: string;
}) {
  const { options, align } = props;

  const alignVal: any = align || "center";

  if (Array.isArray(options) && options.length >= 1 && options[0] !== null) {
    return (
      <div style={{ display: "block", margin: "auto", textAlign: alignVal }}>
        {options.map((type: any, index: number) => {
          return (
            <CustomChip
              key={`${type}-${index}`}
              label={type}
              style={{ marginRight: 5 }}
              size="small"
            />
          );
        })}
      </div>
    );
  } else if (!!options && options.length > 1) {
    return (
      <div style={{ display: "block", margin: "auto", textAlign: alignVal }}>
        <CustomChip label={options} style={{ marginRight: 5 }} size="small" />
      </div>
    );
  }

  return <></>;
}

// export function AddressEdit(props: { params: any }) {
//   const { params } = props;
//   const { listId } = useParams();
//   const { data } = useQuery(GET_ONE_VIEW, {
//     variables: { query: { _id: listId, isDeleted: false } },
//   });

//   const inputRef = useRef<HTMLInputElement>(null);
//   const [inputValue, setInputValue] = useState(params.value || "");
//   const [isVisible, setIsVisible] = useState(true);
//   const [isAutocompleteDone, setIsAutocompleteDone] = useState(false);

//   const latField = data?.view?.columns?.find(
//     (field: any) => field.type === "latitude"
//   );
//   const lngField = data?.view?.columns?.find(
//     (field: any) => field.type === "longitude"
//   );

//   const commitChanges = useCallback(
//     (newAddress: string, lat?: number, lng?: number) => {
//       console.log("Committing changes:", { newAddress, lat, lng });
//       const updatedRow = { ...params.row, [params.field]: newAddress };
//       if (latField && lat !== undefined) {
//         updatedRow[latField.field] = lat;
//       }
//       if (lngField && lng !== undefined) {
//         updatedRow[lngField.field] = lng;
//       }

//       params.api.setRowMode(params.id, "view");
//       params.api.updateRows([updatedRow]);
//       setIsVisible(false);
//     },
//     [params.api, params.id, params.row, params.field, latField, lngField]
//   );

//   const handlePlaceSelect = useCallback(
//     (place: google.maps.places.PlaceResult) => {
//       console.log("Place selected:", place);
//       if (place?.formatted_address) {
//         setInputValue(place.formatted_address);
//         const lat = place.geometry?.location?.lat();
//         const lng = place.geometry?.location?.lng();
//         commitChanges(place.formatted_address, lat, lng);
//         setIsAutocompleteDone(true);
//       }
//     },
//     [commitChanges]
//   );

//   useEffect(() => {
//     let autocomplete: google.maps.places.Autocomplete | null = null;

//     const initAutocomplete = () => {
//       if (
//         inputRef.current &&
//         window.google &&
//         window.google.maps &&
//         window.google.maps.places
//       ) {
//         console.log("Initializing autocomplete");
//         autocomplete = new window.google.maps.places.Autocomplete(
//           inputRef.current,
//           {
//             types: ["address"],
//           }
//         );

//         autocomplete.addListener("place_changed", () => {
//           console.log("Place changed event fired");
//           const place = autocomplete?.getPlace();
//           // @ts-ignore
//           handlePlaceSelect(place);
//         });
//       } else {
//         console.error("Google Maps API not loaded");
//       }
//     };

//     initAutocomplete();

//     if (!autocomplete) {
//       const timeoutId = setTimeout(initAutocomplete, 1000);
//       return () => clearTimeout(timeoutId);
//     }

//     return () => {
//       if (autocomplete) {
//         console.log("Cleaning up autocomplete");
//         window.google.maps.event.clearInstanceListeners(autocomplete);
//       }
//     };
//   }, [handlePlaceSelect]);

//   const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     const newValue = event.target.value;
//     setInputValue(newValue);
//     setIsAutocompleteDone(false);
//   };

//   const handleBlur = () => {
//     console.log("Input blurred, isAutocompleteDone:", isAutocompleteDone);
//     if (!isAutocompleteDone) {
//       commitChanges(inputValue);
//     }
//   };

//   const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
//     if (event.key === "Enter") {
//       console.log("Enter key pressed");
//       commitChanges(inputValue);
//     }
//   };

//   if (!isVisible) {
//     return null;
//   }

//   return (
//     <input
//       ref={inputRef}
//       value={inputValue}
//       onChange={handleInputChange}
//       onBlur={handleBlur}
//       onKeyDown={handleKeyDown}
//       type="text"
//       autoFocus
//       placeholder="Enter address"
//       style={{
//         width: "100%",
//         fontSize: ".875rem",
//         fontWeight: "400",
//         lineHeight: "1.5",
//         borderRadius: "0px",
//         color: "#24292f",
//         border: "none",
//         boxShadow: "none",
//       }}
//     />
//   );
// }

export function AddressEdit(props: { params: any }) {
  const { params } = props;
  const { listId } = useParams();
  const { data } = useQuery(GET_ONE_VIEW, {
    variables: { query: { _id: listId, isDeleted: false } },
  });

  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState(params.value || "");

  const latField = data?.view?.columns?.find(
    (field: any) => field.type === "latitude"
  );
  const lngField = data?.view?.columns?.find(
    (field: any) => field.type === "longitude"
  );

  useEffect(() => {
    let autocomplete: google.maps.places.Autocomplete | null = null;

    if (inputRef.current && window.google && window.google.maps) {
      autocomplete = new window.google.maps.places.Autocomplete(
        inputRef.current,
        {
          types: ["address"],
        }
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete?.getPlace();
        if (place?.formatted_address) {
          setInputValue(place.formatted_address);
          params.api.setEditCellValue(
            {
              id: params.id,
              field: params.field,
              value: place.formatted_address,
            },
            true
          ); // Use 'true' to commit the change immediately

          if (latField && place.geometry?.location?.lat()) {
            params.api.setEditCellValue(
              {
                id: params.id,
                field: latField.field,
                value: place.geometry.location.lat(),
              },
              true
            );
          }
          if (lngField && place.geometry?.location?.lng()) {
            params.api.setEditCellValue(
              {
                id: params.id,
                field: lngField.field,
                value: place.geometry.location.lng(),
              },
              true
            );
          }
        }
      });
    }

    return () => {
      if (autocomplete) {
        window.google.maps.event.clearInstanceListeners(autocomplete);
      }
    };
  }, [params.api, params.id, params.field, latField, lngField]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    params.api.setEditCellValue({
      id: params.id,
      field: params.field,
      value: newValue,
    });
  };

  return (
    <input
      ref={inputRef}
      value={inputValue}
      onChange={handleInputChange}
      type="text"
      autoFocus
      placeholder="Enter address"
      style={{
        width: "100%",
        fontSize: ".875rem",
        fontWeight: "400",
        lineHeight: "1.5",
        borderRadius: "0px",
        color: "#24292f",
        border: "none",
        boxShadow: "none",
      }}
    />
  );
}

export function getRelatedRows(
  relatedRows: any,
  columns?: any,
  listId?: string,
  rootViewId?: string,
  api?: any
) {
  return (
    <div style={{ display: "block", margin: "auto", textAlign: "center" }}>
      {relatedRows?.map((row: any) => {
        return (
          <Link
            key={`${row._id || row.id}-related-link`}
            to={`/list/${row?.merc_viewId}/row/${row._id || row.id}`}
            state={{
              allRows: relatedRows,
              listId,
              rootViewId,

              // TODO: FIX SCROLL
              // scrollPos: api?.getScrollPosition(),
            }}
          >
            <CustomChip
              key={`${row._id || row.id}-related`}
              label={
                !!columns ? GetHeaderText(row, columns) : getPageTitle(row)
              }
              style={{ marginRight: 5 }}
              size="small"
            />
          </Link>
        );
      })}
    </div>
  );
}

export function getTimeStampCell(latestActivity: any) {
  const dateString = !!latestActivity?.timestamp
    ? new Date(latestActivity?.timestamp).toLocaleDateString()
    : "";

  const name = latestActivity?.commentId?.userId?.name;
  const initials = name
    ?.split(" ")
    ?.map((n: any) => n[0])
    ?.join("");

  if (initials?.length > 0) {
    return `${dateString} - ${!!initials ? initials : ""}`;
  }

  return dateString;
}
