import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
  Grid,
  Checkbox,
  FormControlLabel,
  Tabs,
  Tab
} from "@mui/material";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { CustomButton } from "./PersonalDetails";
import { useContext, useEffect, useRef, useState } from "react";
import "./App.css";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingScreen from "./loadingScreen";
import { apiURL } from "./consts";
import _ from 'lodash'

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function EmailRelatedRows(props: {
  openEmailRelatedRows: boolean;
  setOpenEmailRelatedRows: any;
  selected: any;
}) {
  const { openEmailRelatedRows, setOpenEmailRelatedRows,  selected } = props;

  const [emailLoading, setEmailLoading] = useState(false);
  const [ relatedRowsWithEmails, setRelatedRowsWithEmails ] = useState<any | undefined>(undefined)

  const [ rowId2CheckedMap, setRowId2CheckedMap ] = useState<any | undefined>(undefined)
  const [value, setValue] = useState<number>(0);

  const [selectedView, setSelectedView] = useState<any | undefined>(undefined)

  const [targetRows, setTargetRows] = useState<any[] | undefined>(undefined)

  const { getAccessTokenSilently } = useAuth0();

  const navigate = useNavigate();
  let { state } = useLocation();

  useEffect(() => {
    const updateRelatedRowsWithEmail = async () => {
      const accessToken = await getAccessTokenSilently();

      const formData = new FormData();
      formData.append(
        "rowIds",
        JSON.stringify(selected.map((row: any) => row._id))
      );
      const relatedRowsResponse = await fetch(
        `${apiURL}email/related-emails`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          method: "POST",
          body: formData,
        }
      );

      const relatedRows = await relatedRowsResponse.json();

      setRelatedRowsWithEmails(relatedRows)
      setEmailLoading(false)
    }

    if (_.chain(selected).size().gt(0).value() && openEmailRelatedRows) {
      setEmailLoading(true)
      updateRelatedRowsWithEmail()
    } else {
      setRelatedRowsWithEmails(undefined)
    }

  }, [selected, openEmailRelatedRows, getAccessTokenSilently]);

  useEffect(() => {
    const updateRowId2CheckedMap = async () => {
      const buff = _.chain(relatedRowsWithEmails).get('targetViewId2RowsMap').keys().reduce((r,vid)=>{
        //@ts-ignore
        const newRows = _.chain(relatedRowsWithEmails).get('targetViewId2RowsMap').get(vid,[]).reduce((r,v,k)=>_.set(r,_.get(v,'_id'),true),{}).value()
        r = { ... r, ... newRows }
        return r
      },{}).value()

      setRowId2CheckedMap(buff)
    }

    // @ts-ignore
    if (_.chain(relatedRowsWithEmails).get('targetViewId2RowsMap').values().size().gt(0).value()) {
      updateRowId2CheckedMap()
    } else {
      setRowId2CheckedMap(undefined)
    }

  }, [relatedRowsWithEmails]);

  useEffect(() => {
    const updateSelectedView = async () => {

      const vid = _.chain(relatedRowsWithEmails).get('targetViewId2ViewMap').keys().orderBy().get(value).value()

      // @ts-ignore
      const selectedViewBuff = _.chain(relatedRowsWithEmails).get(`targetViewId2ViewMap.${vid}`).value()

      setSelectedView(selectedViewBuff)

      //@ts-ignore
      const targetRowsBuff = _.chain(relatedRowsWithEmails).get('targetViewId2RowsMap').get(vid,[]).map(r => {
        let rbuff = _.pick(r,['_id','latestActivity'])

        let rowObject = {}
        try {
          rowObject = JSON.parse(_.get(r,'rowObject'))
        } catch (err) {}

        rbuff = { ... rbuff, ... rowObject }

        return rbuff
      }).reduce( (r: any[],v: any) => {
        if (_.chain(rowId2CheckedMap).get(_.get(v,'_id'),false).value()) {
          r.push(v)
        }
        return r
      },[]).value()

      setTargetRows(targetRowsBuff)
    }

    updateSelectedView()

  }, [value, relatedRowsWithEmails, rowId2CheckedMap]);


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    console.log({ newValue })
    setValue(newValue);
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    height: "80%",
    bgcolor: "background.paper",
    // border: "2px solid #000",
    padding: "20px !important",
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
    "& .MuiBox-root": {
      padding: "10px",
    },
  };

  const getEmailFieldname = (vid: string) => {
    let emailFieldName

    //@ts-ignore
    const columnsWithPrimaryEmailType = _.chain(relatedRowsWithEmails).get('targetViewId2ViewMap').get(vid).get('columns',[]).filter(o => _.chain(o).get('type').trim().toLower().eq('primary email').value()).value()
      //@ts-ignore
    if (_.chain(columnsWithPrimaryEmailType).size().gte(1).value()) {
            //@ts-ignore
      emailFieldName = _.chain(columnsWithPrimaryEmailType).first().get('field').trim().value()
    } else {
      //@ts-ignore
      const columnsWithSecondaryEmailType = _.chain(relatedRowsWithEmails).get('targetViewId2ViewMap').get(vid).get('columns',[]).filter(o => _.chain(o).get('type').trim().toLower().eq('secondary email').value()).value()
            //@ts-ignore
      if (_.chain(columnsWithSecondaryEmailType).size().gte(1).value()) {
              //@ts-ignore
        emailFieldName = _.chain(columnsWithSecondaryEmailType).first().get('field').trim().value()
      } else {
        //@ts-ignore
        const columnsWithEmailType = _.chain(relatedRowsWithEmails).get('targetViewId2ViewMap').get(vid).get('columns',[]).filter(o => _.chain(o).get('type').trim().toLower().eq('email').value()).value()
              //@ts-ignore
        if (_.chain(columnsWithEmailType).size().gte(1).value()) {
                //@ts-ignore
          emailFieldName = _.chain(columnsWithEmailType).first().get('field').trim().value()
        } else {
          //@ts-ignore
          const columnsWithEmailFieldName = _.chain(relatedRowsWithEmails).get('targetViewId2ViewMap').get(vid).get('columns',[]).filter(o => _.chain(o).get('field').trim().toLower().eq('email').value()).value()
                //@ts-ignore
          if (_.chain(columnsWithEmailFieldName).size().gte(1).value()) {
                  //@ts-ignore
            emailFieldName = _.chain(columnsWithEmailFieldName).first().get('field').trim().value()
          }
        }
      }
    }
    return emailFieldName
  }

  return (
    <Dialog
      open={openEmailRelatedRows}
      onClose={() => {

        setOpenEmailRelatedRows(false)
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="lg"
      fullWidth
    >
    { emailLoading && <LoadingScreen /> }
    <DialogContent>
    <Grid container style={{ maxHeight: "60vh" }}>
      <Grid item xs={12} style={{ maxHeight: "60vh" }}>
        <Typography variant="h6">Related Contacts</Typography>
        { _.chain(relatedRowsWithEmails).get('targetViewId2ViewMap').keys().size().eq(0).value() &&
          !emailLoading &&
          <Box p={2}>
            <Typography variant="body2">Could not find related rows with email</Typography>
          </Box>
        }
        { _.chain(relatedRowsWithEmails).get('targetViewId2ViewMap').keys().size().gt(0).value() &&
        <>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            {
              _.chain(relatedRowsWithEmails).get('targetViewId2ViewMap').keys().orderBy().map(vid=>{
                // @ts-ignore
                const v = _.chain(relatedRowsWithEmails).get('targetViewId2ViewMap').get(vid).value()
                const index = _.chain(relatedRowsWithEmails).get('targetViewId2ViewMap').keys().orderBy().findIndex(o=>_.eq(vid,o)).value()

                return (<Tab key={vid} label={_.get(v,'name')} {...a11yProps(index)} />)
              }).value()
            }
            </Tabs>
          </Box>
          {
            _.chain(relatedRowsWithEmails).get('targetViewId2ViewMap').keys().orderBy().map(vid=>{
              // @ts-ignore
              const v = _.chain(relatedRowsWithEmails).get('targetViewId2ViewMap').get(vid).value()
              const index = _.chain(relatedRowsWithEmails).get('targetViewId2ViewMap').keys().orderBy().findIndex(o=>_.eq(vid,o)).value()

              return (
                <CustomTabPanel key={vid} value={value} index={index}>
                  {
                    // @ts-ignore
                    _.chain(relatedRowsWithEmails).get('targetViewId2RowsMap').get(vid,[]).size().gt(0).value() &&
                    <FormControlLabel
                      label="All"
                      control={
                      <Checkbox
                        checked={
                          //@ts-ignore
                          _.chain(rowId2CheckedMap).pick(_.chain(relatedRowsWithEmails).get('targetViewId2RowsMap').get(vid,[]).map('_id').value()).values().every(o=>o==true).value()
                        }
                        indeterminate={
                          //@ts-ignore
                          _.chain(rowId2CheckedMap).pick(_.chain(relatedRowsWithEmails).get('targetViewId2RowsMap').get(vid,[]).map('_id').value()).values().thru(o=>_.some(o,e=>e==true) && _.some(o,e=>e==false)).value()
                        }
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          //@ts-ignore
                          const targetRowIds = _.chain(relatedRowsWithEmails).get('targetViewId2RowsMap').get(vid,[]).map('_id').value()
                          const buff = _.chain(rowId2CheckedMap).clone().thru(o=>{
                            for (const rid of targetRowIds) {
                              _.set(o,rid,_.get(event,'target.checked',false))
                            }
                            return o
                          }).value()
                          setRowId2CheckedMap(buff)
                        }}
                      />
                      }
                  />}
                  <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                   {
                     // @ts-ignore
                     _.chain(relatedRowsWithEmails).get('targetViewId2RowsMap').get(vid,[]).reduce((r,row)=>{
                       let rowObject

                       try {
                         rowObject = JSON.parse(_.get(row,'rowObject',''))
                       } catch (err) {}

                       const emailFieldName = getEmailFieldname(vid)
                       if (!_.isNil(emailFieldName) && !_.isNil(rowObject)) {
                         r.push({
                           row,
                           rowObject,
                           emailFieldName
                         })
                       }
                       return r
                     },[]).map(({row, rowObject, emailFieldName }: any) => {
                       return (
                         <FormControlLabel
                           id={_.get(row,'_id')}
                           key={_.get(row,'_id')}
                           label={_.get(rowObject,emailFieldName)}
                           control={<Checkbox checked={_.chain(rowId2CheckedMap).get(_.get(row,'_id'), true).value()} onChange={(event: React.ChangeEvent<HTMLInputElement>)=>{
                               let buff = _.clone(rowId2CheckedMap)
                               _.set(buff,_.get(row,'_id'),event?.target?.checked)
                               setRowId2CheckedMap(buff)
                           }}
                          />}
                         />
                       )
                     }).value()

                   }
                   </Box>
                </CustomTabPanel>
              )
            }).value()
          }
        </>
        }
        </Grid>
    </Grid>
    </DialogContent>
    <DialogActions>
      <Divider style={{ marginBottom: "10px" }} />
      <Button
        variant="outlined"
        style={{ float: "left", textTransform: "none" }}
        onClick={() => {
          setOpenEmailRelatedRows(false)
        }}
      >
        Cancel
      </Button>
      {_.chain(targetRows).size().gt(0).value() && (
        <Link
          to="/email"
          state={{
            selectedRows: JSON.stringify(targetRows),
            //@ts-ignore
            columns: JSON.stringify(_.chain(selectedView).get('columns',[]).value()),
            //@ts-ignore
            emailColumn: JSON.stringify(_.chain(selectedView).get('columns',[]).find(c=>_.chain(c).get('type').toLower().eq('email').value()).get('field').value()),
          }}
        >
          <CustomButton
            sx={{
              fontSize: "13px",
              height: "32px",
              padding: "0px 12px",
              whiteSpace: "nowrap",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M1.66699 5.83301L8.47109 10.5959C9.02207 10.9816 9.29756 11.1744 9.59721 11.2491C9.8619 11.3151 10.1387 11.3151 10.4034 11.2491C10.7031 11.1744 10.9786 10.9816 11.5296 10.5959L18.3337 5.83301M5.66699 16.6663H14.3337C15.7338 16.6663 16.4339 16.6663 16.9686 16.3939C17.439 16.1542 17.8215 15.7717 18.0612 15.3013C18.3337 14.7665 18.3337 14.0665 18.3337 12.6663V7.33301C18.3337 5.93288 18.3337 5.23281 18.0612 4.69803C17.8215 4.22763 17.439 3.84517 16.9686 3.60549C16.4339 3.33301 15.7338 3.33301 14.3337 3.33301H5.66699C4.26686 3.33301 3.5668 3.33301 3.03202 3.60549C2.56161 3.84517 2.17916 4.22763 1.93948 4.69803C1.66699 5.23281 1.66699 5.93288 1.66699 7.33301V12.6663C1.66699 14.0665 1.66699 14.7665 1.93948 15.3013C2.17916 15.7717 2.56161 16.1542 3.03202 16.3939C3.5668 16.6663 4.26686 16.6663 5.66699 16.6663Z"
                stroke="white"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Email {_.chain(targetRows).size().value()}{" "}
            {_.chain(targetRows).size().gt(1).value() ? "People" : "Person"}
          </CustomButton>
        </Link>
      )}
    </DialogActions>
  </Dialog>
);

}
