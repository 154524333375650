import React, { useEffect } from "react";
import "./App.css";
import Typography from "@mui/material/Typography";
import testimonial from "./images/testimonial.png";
import loading from "./loading.gif";
import { useAuth0 } from "@auth0/auth0-react";
import { apiURL } from "./consts";
import { useParams } from "react-router-dom";

export default function FinalizeCheckout(props: {
  // setShowSettings: (value: React.SetStateAction<boolean>) => void;
  // showSettings: boolean;
}) {
  const { getAccessTokenSilently } = useAuth0();
  const { checkoutId } = useParams();

  useEffect(() => {
    const storeCheckoutId = async () => {
      const accessToken = await getAccessTokenSilently();
      const res = await fetch(`${apiURL}user/stripe/${checkoutId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const data = await res.json();

      if (data.error) {
        alert("Something went wrong. Please contact info@mercero.com.");
      } else {
        window.location.href = "/settings";
      }
    };

    if (checkoutId) {
      storeCheckoutId();
    }
  }, [checkoutId, getAccessTokenSilently]);

  return (
    <div
      style={{
        height: "100vh",
        backgroundColor: "rgb(8, 20, 55)",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          color: "white",
          width: "100%",
          textAlign: "center",
          paddingTop: "40px",
          fontSize: "30px",
          fontFamily: "Jeko",
          fontWeight: "600",
        }}
      >
        Finalizing plan
      </Typography>
      <img
        src={loading}
        alt="loading"
        style={{
          width: "25px",
          margin: "auto",
          display: "block",
        }}
      />
    </div>
  );
}
