import React from "react";

const LoadingScreen = (props: any) => {
  return (
    <div className="loading-screen">
      <div className="loading-content">
        <div className="spinner"></div>
        <p>{"Loading..."}</p>
      </div>
    </div>
  );
};

export default LoadingScreen;
