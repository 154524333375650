import React, { useContext, useState } from "react";
import "./App.css";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { filterOutColumns } from "./layout";
import { useAuth0 } from "@auth0/auth0-react";
import { apiURL } from "./consts";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { RefetchContext } from "./refetchProvider";
import { FileUpload } from "./onboardingTimeline";
import { CustomTextField, OnboardingSubheader } from "./PersonalDetails";

export default function NewView() {
  const { getAccessTokenSilently } = useAuth0();
  const [newViewName, setNewViewName] = React.useState("");
  const navigate = useNavigate();
  const context = useContext(RefetchContext);
  const currentWorkspace = context?.currentWorkspace;

  const [selectedTab, setSelectedTab] = useState(0);

  let { state } = useLocation();
  const { rootViewId } = useParams();

  const handleRefetch = () => {
    if (context) {
      context.refetchViews();
    } else {
      console.error("Refetch context is not available");
    }
  };

  const uploadExcelFunction = async (data: any) => {
    const accessToken = await getAccessTokenSilently();

    const response = await fetch(
      `${apiURL}upload/${context?.currentWorkspace?._id}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        method: "POST",
        body: data,
      }
    );

    const resData = await response.json();

    const test = Object.keys(resData);
    context?.refetchViews();

    navigate(`/list/${resData[test[0]]._id}/edit`);
    console.log(resData);
  };

  console.log("state", state);

  return (
    <div>
      <div
        className="pageHeader"
        style={{ marginBottom: "16px", height: "56px" }}
      >
        <Box sx={{ display: "flex", alignItems: "center", height: "56px" }}>
          <AddIcon sx={{ mr: 1 }} />
          <Typography
            variant="h6"
            component="h2"
            sx={{
              fontSize: "18px",
            }}
          >
            New View
          </Typography>
        </Box>
      </div>
      <Dialog onClose={() => {}} open>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            // Save new view

            const accessToken = await getAccessTokenSilently();

            await fetch(`${apiURL}view`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
              },
              body: JSON.stringify({
                ...state?.rootView,
                name: newViewName,
                rootViewId: rootViewId,
                initialState: state?.rootView?.initialState,
                columnsJsonString: JSON.stringify(state?.rootView?.columns),
                workspaceId: currentWorkspace?._id,
                isDeleted: false,
              }),
            })
              .then((res) => res.json())
              .then((data) => {
                handleRefetch();
                const newViewId = data._id;
                if (!!data?.rootViewId) {
                  navigate(`/list/${newViewId}/${data?.rootViewId}`);
                } else {
                  navigate(`/list/${newViewId}`);
                }
              });
          }}
        >
          <DialogTitle>Create your list</DialogTitle>
          <DialogContent>
            {!!!rootViewId && (
              <Tabs
                value={selectedTab}
                onChange={(event, value) => setSelectedTab(value)}
                aria-label="basic tabs example"
                sx={{ marginBottom: "20px" }}
              >
                <Tab
                  sx={{ textTransform: "none" }}
                  label="Start from Blank"
                  value={0}
                />
                <Tab
                  sx={{ textTransform: "none" }}
                  label="Start from a file"
                  value={1}
                />
              </Tabs>
            )}
            {(selectedTab === 0 || !!rootViewId) && (
              <>
                <OnboardingSubheader
                  sx={{ textAlign: "left !important", marginBottom: "4px" }}
                >
                  List Name
                </OnboardingSubheader>
                <CustomTextField
                  autoFocus
                  value={newViewName}
                  onChange={(e) => setNewViewName(e.target.value)}
                  margin="dense"
                  size="small"
                  placeholder="List Name"
                  // label="List Name"
                  // variant="standard"
                  fullWidth
                  sx={{ marginBottom: "20px" }}
                />
              </>
            )}
            {selectedTab === 1 && (
              <div
                style={{
                  display: "flex",
                  padding: "var(--spacing-xl, 16px) var(--spacing-3xl, 24px)",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "var(--spacing-xs, 4px)",
                  alignSelf: "stretch",
                  borderRadius: "var(--radius-xl, 12px)",
                  border:
                    "1px solid var(--colors-border-border-secondary, #EAECF0)",
                  background: "var(--colors-background-bg-primary, #FFF)",
                }}
              >
                <FileUpload
                  fileTypes="XLS or CSV (max 20mb)"
                  uploadFiles={uploadExcelFunction}
                />
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button type="button" onClick={() => navigate(-1)}>
              Cancel
            </Button>
            <Button type="submit" disabled={newViewName.length < 1}>
              Create list
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
