import React, { useEffect, useRef, useState } from "react";
import { Button } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { apiURL } from "./consts";
import FroalaEditor from "react-froala-wysiwyg";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

export default function Signature(props: {
  setSuccessMessage: (value: React.SetStateAction<string | undefined>) => void;
}) {
  const { setSuccessMessage } = props;

  const editorRef = useRef(null);
  const { getAccessTokenSilently } = useAuth0();
  const [signature, setSignature] = useState("");

  useEffect(() => {
    const getSignature = async () => {
      const accessToken = await getAccessTokenSilently();

      const getSignature = await fetch(`${apiURL}email/signature`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        method: "GET",
      });

      const sigRes = await getSignature.json();
      setSignature(sigRes.signature);
    };
    if (!!!signature) {
      getSignature();
    }
  }, [signature, getAccessTokenSilently]);

  const froalaConfig = {
    key: "te1C2sE7D7D6G4B4E4C3kOPVe1f1d1Le1b1D1AWMSGSAFDTGHWsF4I4A11B7C2B5B4B1C3A2",
    placeholderText: "Edit your signature here...",
    charCounterCount: false,
    toolbarButtons: [
      "bold",
      "italic",
      "underline",
      "fontFamily",
      "fontSize",
      "color",
      "insertLink",
      "insertImage",
    ],
    heightMin: 275,
    width: 600,
    imageUploadURL: `${apiURL}email/upload-attachment`,
    imageUploadMethod: "POST",
    imageMaxSize: 5 * 1024 * 1024, // 5MB
    imageAllowedTypes: ["jpeg", "jpg", "png", "gif"],
    events: {
      "image.beforeUpload": function (this: any, images: any) {
        const editor = this;

        // Create FormData object
        const data = new FormData();
        data.append("file", images[0]); // Assuming your API expects 'file' as the key

        // Get the access token
        getAccessTokenSilently()
          .then((accessToken) => {
            // Make the API call using fetch
            return fetch(`${apiURL}email/upload-attachment`, {
              method: "POST",
              headers: {
                accept: "application/json",
                Authorization: `Bearer ${accessToken}`,
                // Note: Don't set Content-Type header, let the browser set it with the correct boundary
              },
              body: data,
            });
          })
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
          })
          .then((responseData) => {
            // Assuming the response contains a 'url' field with the image URL
            if (responseData && responseData.url) {
              // Insert the image into the editor
              editor.image.insert(
                responseData.url,
                null,
                null,
                editor.image.get()
              );
            } else {
              throw new Error("Invalid response from server");
            }
          })
          .catch((error) => {
            console.error("Error uploading image:", error);
            // You might want to show an error message to the user here
          });

        // Return false to prevent the default upload
        return false;
      },
    },
  };

  return (
    <>
      <FroalaEditor
        tag="textarea"
        config={froalaConfig}
        model={signature}
        onModelChange={setSignature}
        ref={editorRef}
      />
      <Button
        style={{
          margin: "15px 0px",
          display: "block",
        }}
        variant="outlined"
        onClick={async () => {
          try {
            const accessToken = await getAccessTokenSilently();

            await fetch(`${apiURL}email/signature`, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
              },
              method: "POST",
              body: JSON.stringify({
                signature: signature,
              }),
            });

            setSuccessMessage("Signature saved successfully");
          } catch (e) {
            console.log(e);
          }
        }}
      >
        Save Signature
      </Button>
    </>
  );
}
