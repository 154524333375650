import * as React from "react";
import Button from "@mui/material/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { filterOutColumnsForDisplay } from "./layout";
import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  GetHeaderText,
  GetSubheaderText,
  ReturnVisibleColumns,
} from "./DatagridFunctions";
import { StyledTextarea } from "./individualView";
import { useAuth0 } from "@auth0/auth0-react";
import _ from "lodash";
import { apiURL } from "./consts";
import useCreateRow from "./useCreateRow";
import useGetAllTags from "./useGetAllTags";
import DisplayTags from "./displayTags";

interface NewRowProps {
  newRow: boolean;
  setNewRow: (newRow: boolean) => void;
  columns: any;
  selectedView: any;
  initialState: any;
  refetchRows: any;
}

export default function NewRow(props: NewRowProps) {
  const {
    newRow,
    setNewRow,
    columns,
    selectedView,
    initialState,
    refetchRows,
  } = props;

  const scrollRef = useRef(null);

  const navigate = useNavigate();

  const [isComponentReady, setIsComponentReady] = React.useState(false);
  const [rowObject, setRowObject] = React.useState<any>(new Map());

  const viewIdToPass = selectedView.rootViewId || selectedView._id;

  const { createRow } = useCreateRow(viewIdToPass);

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (newRow) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [newRow]);

  const filteredColumns = filterOutColumnsForDisplay(columns);

  return (
    <Dialog
      onClose={() => {
        setIsComponentReady(false);
        setNewRow(false);
      }}
      open={newRow}
      scroll="paper"
    >
      {/* <form
        onSubmit={async (e) => {
          setNewRow(false);
        }}
      > */}
      <DialogTitle>Add a Row</DialogTitle>
      <DialogContent
        dividers={true}
        ref={descriptionElementRef}
        sx={{ width: "500px" }}
      >
        {ReturnVisibleColumns(filteredColumns, initialState)?.map(
          (column: any, index: number) => {
            return (
              <FieldInput
                isFirst={index === 0}
                key={column.field}
                field={column.field}
                title={column.headerName}
                columnType={column.type}
                rowObject={rowObject}
                filteredColumns={filteredColumns}
                onChange={(field: string, value: string) => {
                  rowObject.set(field, value);
                }}
                values={rowObject}
                viewId={selectedView.rootViewId || selectedView._id}
                setRowObject={setRowObject}
              />
            );
          }
        )}
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          onClick={() => {
            setNewRow(false);
            setIsComponentReady(false);
            setRowObject(new Map());
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          onClick={async () => {
            const data = await createRow({
              variables: {
                data: {
                  viewId: { link: selectedView.rootViewId || selectedView._id },
                  rowObject: JSON.stringify(Object.fromEntries(rowObject)),
                  isDeleted: false,
                },
              },
            });

            if (selectedView.rootViewId) {
              navigate(
                `/list/${selectedView._id}/${selectedView.rootViewId}/row/${data.data.insertOneRow._id}`
              );
            } else {
              navigate(
                `/list/${selectedView._id}/row/${data.data.insertOneRow._id}`
              );
            }
          }}
        >
          Add Row
        </Button>
      </DialogActions>
      {/* </form> */}
    </Dialog>
  );
}

export function FieldInput(props: {
  isFirst: boolean;
  field: string;
  title: string;
  columnType: string;
  onChange: (field: string, value: any) => void;
  values: Map<string, any>;
  viewId: string;
  rowObject: Map<string, any>;
  setRowObject: React.Dispatch<React.SetStateAction<Map<string, any>>>;
  filteredColumns: any[];
}) {
  const {
    title,
    field,
    onChange,
    columnType,
    values,
    viewId,
    rowObject,
    filteredColumns,
    setRowObject,
    isFirst,
  } = props;
  const { getAccessTokenSilently } = useAuth0();
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState(values.get(field) || "");

  const inputRef = useRef<HTMLInputElement>(null);

  const latField = filteredColumns.find(
    (item: any) => item.type === "latitude"
  );

  const lngField = filteredColumns.find(
    (item: any) => item.type === "longitude"
  );

  useEffect(() => {
    setInputValue(values.get(field) || "");
  }, [values, field]);

  useEffect(() => {
    let autocomplete: google.maps.places.Autocomplete | null = null;

    if (columnType === "address" && inputRef.current) {
      autocomplete = new google.maps.places.Autocomplete(inputRef.current, {
        types: ["address"],
      });

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete!.getPlace();
        const address = place.formatted_address;
        const lat = place.geometry?.location?.lat();
        const lng = place.geometry?.location?.lng();

        const tempRowObject = new Map(rowObject);
        tempRowObject.set(field, address || "");
        if (latField?.field && lat) tempRowObject.set(latField.field, lat);
        if (lngField?.field && lng) tempRowObject.set(lngField.field, lng);
        setRowObject(tempRowObject);
        setInputValue(address || "");
      });
    }

    return () => {
      if (autocomplete) {
        google.maps.event.clearInstanceListeners(autocomplete);
      }
    };
  }, [columnType, field, latField, lngField, rowObject, setRowObject]);

  const search = useCallback(
    async (searchTerm: string) => {
      try {
        const accessToken = await getAccessTokenSilently();

        if (searchTerm.length > 2) {
          const response = await fetch(
            `${apiURL}row?q=${searchTerm}&viewId=${viewId}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          const result = await response.json();
          setSearchResults(result || []);
        } else {
          setSearchResults([]);
        }
      } catch (error) {
        console.error("Search error:", error);
      }
    },
    [getAccessTokenSilently, viewId]
  );

  const debouncedSearch = useCallback(_.debounce(search, 500), [search]);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = event.target;
      setInputValue(value);
      onChange(field, value);
      if (columnType === "header1") {
        debouncedSearch(value);
      }
    },
    [field, onChange, columnType, debouncedSearch]
  );

  let inputElement: JSX.Element;

  switch (columnType) {
    case "header1":
    case "address":
      inputElement = (
        <input
          ref={inputRef}
          value={inputValue}
          onChange={handleInputChange}
          autoFocus={isFirst}
          type="text"
          placeholder={`Enter ${
            columnType === "address" ? "address" : "value"
          }`}
          style={{
            width: "95%",
            fontSize: ".875rem",
            fontWeight: "400",
            lineHeight: "1.5",
            padding: "12px",
            borderRadius: "12px 12px 0 12px",
            color: "#24292f",
            border: "1px solid #d0d7de",
            boxShadow: "0px 2px 2px #f6f8fa",
          }}
        />
      );
      break;
    case "type":
      inputElement = (
        <NewRowTags
          values={values}
          onChange={onChange}
          field={field}
          listId={viewId}
        />
      );
      break;
    default:
      inputElement = (
        <StyledTextarea
          autoFocus={isFirst}
          placeholder="Enter value"
          onChange={handleInputChange}
          value={inputValue}
        />
      );
  }

  return (
    <div style={{ marginBottom: "20px" }}>
      <Typography
        style={{
          fontSize: "13px",
          fontWeight: 600,
          color: "rgb(107, 111, 118)",
          marginBottom: "5px",
        }}
      >
        {title}
      </Typography>
      {inputElement}
      {searchResults.length > 0 && (
        <div
          style={{
            padding: "10px",
            backgroundColor: "rgb(240, 243, 249)",
            marginTop: "10px",
            width: "95%",
          }}
        >
          {searchResults.map((result: any) => {
            const parsedRow = JSON.parse(result.rowObject);
            return (
              <Link
                key={result._id}
                to={`/list/${viewId}/row/${result._id}`}
                target="_blank"
              >
                <Typography>
                  {`${GetHeaderText(
                    parsedRow,
                    result.columns
                  )} (${GetSubheaderText(parsedRow, result.column)})`}
                </Typography>
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
}

function NewRowTags(props: {
  values: any;
  onChange: any;
  field: string;
  listId: any;
}) {
  const { values, onChange, field, listId } = props;

  const [selectedTags, setSelectedTags] = useState(values.get(field) || []);

  let allPossibleTags: any[] = useGetAllTags(listId, field);

  return (
    <DisplayTags
      allPossibleTags={allPossibleTags}
      selectedTags={selectedTags}
      buttonStyle={{ marginTop: "-8px" }}
      onTagsChange={(tags: string[]) => {
        setSelectedTags(tags);
        onChange(field, tags);
      }}
    />
  );
}
