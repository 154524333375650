import { Button, Grid, IconButton, MenuItem, styled } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import emailField from "./emailField";
import { StyledMenu } from "./topButton";
import { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";

const EmailButton = styled(Button)({
  display: "flex",
  padding: "10px 14px",
  justifyContent: "center",
  alignItems: "center",
  gap: "4px",
  borderRadius: "8px",
  border: "1px solid var(--blue-700, #175CD3)",
  background: "var(--blue-600, #1570EF)",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  color: "white",
  textTransform: "none",
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "20px",

  // Add hover state
  "&:hover": {
    background: "#1e88e5", // Darker shade for hover (example)
    border: "1px solid #1e88e5",
    boxShadow: "0px 2px 4px 0px rgba(16, 24, 40, 0.1)", // Slightly larger shadow
  },
});

export function IndividualViewHeaderButton(props: any) {
  const { showDetailView, updateRow, emailField } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      style={{
        height: "100%",
        paddingRight: "24px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Grid container justifyContent={"right"} gap="12px">
        <Grid>
          {!!emailField && (
            <NavLink
              to="/email"
              state={{
                selectedRows: JSON.stringify([showDetailView]),
                columns:
                  !!showDetailView &&
                  JSON.stringify(Object.keys(showDetailView)),
                emailColumn: JSON.stringify(emailField?.field),
              }}
              //   onClick={handleClose}
            >
              <EmailButton>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M1.66699 5.83301L8.47109 10.5959C9.02207 10.9816 9.29756 11.1744 9.59721 11.2491C9.8619 11.3151 10.1387 11.3151 10.4034 11.2491C10.7031 11.1744 10.9786 10.9816 11.5296 10.5959L18.3337 5.83301M5.66699 16.6663H14.3337C15.7338 16.6663 16.4339 16.6663 16.9686 16.3939C17.439 16.1542 17.8215 15.7717 18.0612 15.3013C18.3337 14.7665 18.3337 14.0665 18.3337 12.6663V7.33301C18.3337 5.93288 18.3337 5.23281 18.0612 4.69803C17.8215 4.22763 17.439 3.84517 16.9686 3.60549C16.4339 3.33301 15.7338 3.33301 14.3337 3.33301H5.66699C4.26686 3.33301 3.5668 3.33301 3.03202 3.60549C2.56161 3.84517 2.17916 4.22763 1.93948 4.69803C1.66699 5.23281 1.66699 5.93288 1.66699 7.33301V12.6663C1.66699 14.0665 1.66699 14.7665 1.93948 15.3013C2.17916 15.7717 2.56161 16.1542 3.03202 16.3939C3.5668 16.6663 4.26686 16.6663 5.66699 16.6663Z"
                    stroke="white"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Send Email
              </EmailButton>
            </NavLink>
          )}
        </Grid>
        <Grid>
          <IconButton
            onClick={handleClick}
            sx={{
              float: "right",
              borderRadius: "8px",
              border: "1px solid #D0D5DD", // Using fallback color for var(--gray-300)
              background: "#FFF", // Using fallback color for var(--base-white)
              boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
              fontSize: "14px",
              fontWeight: 600,
              flexGrow: 1,
              padding: "10px 14px",
              width: "40px",
            }}
          >
            <MoreVertIcon
              sx={{ color: "#344054", width: "20px", height: "20px" }}
            />
          </IconButton>
        </Grid>
      </Grid>

      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ fontSize: ".75em" }}
      >
        <MenuItem
          onClick={async () => {
            // await deleteRow({
            //   variables: {
            //     query: {
            //       _id: showDetailView?._id,
            //     },
            //   },
            // });

            const resp: any = await updateRow({
              variables: {
                query: {
                  _id: showDetailView?._id,
                },
                set: {
                  isDeleted: true,
                  isDeletedAt: new Date(),
                  triggeredBy: "client",
                },
              },
            });

            !!!props.curRowId && navigate(-1);
            if (!!props.curRowId) {
              handleClose();
              props.deleteRow();
            }
          }}
          disableRipple
        >
          <DeleteIcon />
          Delete Record
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
