import { useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { GET_CAMPAIGN, GET_CAMPAIGNS } from "./graphql-ops";
import { Link, useLocation, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RefreshIcon from "@mui/icons-material/Refresh";

export default function EmailCampaign() {
  const { user } = useAuth0();
  const { campaignId } = useParams();

  const { data, loading, error, refetch } = useQuery(GET_CAMPAIGN, {
    variables: {
      query: {
        _id: campaignId,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const rowIds = data?.sendgridcampaign?.contacts?.map((contact: any) => {
    return { _id: contact?.rowId };
  });

  return (
    <div style={{ width: "700px", margin: "auto", marginTop: "40px" }}>
      <Grid
        container
        justifyContent="space-between"
        alignContent={"center"}
        alignItems={"center"}
        sx={{ marginBottom: "20px", width: "100%" }}
      >
        <Grid item sx={{ display: "flex", alignItems: "center" }}>
          <Link to={"/campaigns"}>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "12px",
              }}
            >
              {" "}
              <ArrowBackIcon /> Back to all campaigns
            </Typography>
          </Link>
        </Grid>
        <Grid item>
          <IconButton onClick={() => refetch()}>
            <RefreshIcon />
          </IconButton>
        </Grid>
      </Grid>

      <TableContainer component={Paper} sx={{ width: "98%" }}>
        <Table sx={{ mWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ whiteSpace: "nowrap" }}>Email</TableCell>
              {/* <TableCell sx={{ whiteSpace: "nowrap" }}>First Name</TableCell> */}
              <TableCell sx={{ whiteSpace: "nowrap" }}>Opens</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.sendgridcampaign?.contacts?.map((contact: any) => {
              return (
                <TableRow>
                  <TableCell sx={{ whiteSpace: "nowrap" }}>
                    <Link
                      to={
                        !!data?.sendgridcampaign?.viewId
                          ? `/list/${data?.sendgridcampaign?.viewId}/row/${contact?.rowId}`
                          : `/individualView/${contact?.rowId}`
                      }
                      state={{
                        allRows: JSON.stringify(
                          data?.sendgridcampaign?.contacts?.map(
                            (contact: any) => {
                              return {
                                _id: contact?.rowId,
                                opens: contact?.open,
                              };
                            }
                          )
                        ),
                        campaignId: data?.sendgridcampaign?._id,
                      }}
                      style={{
                        fontWeight: 500,
                        color: "rgb(21, 112, 239)",
                      }}
                    >
                      {contact?.email}
                    </Link>
                  </TableCell>
                  {/* <TableCell sx={{ whiteSpace: "nowrap" }}>
                    {contact?.first_name}
                  </TableCell> */}
                  <TableCell sx={{ whiteSpace: "nowrap" }}>
                    {contact?.open || 0}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
