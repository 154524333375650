import gql from "graphql-tag";

export const ADD_CONTACT_MUTATION = gql`
  mutation AddContact($input: AddContactInput!) {
    addContact(data: $input) {
      ... on Contact {
        _id
        name
        emails {
          emailAddress
          status
          type
        }
        properties {
          address
        }
      }
    }
  }
`;
export const GET_CONTACTS_QUERY = gql`
  query GetContacts($query: QueryContactsInput!) {
    getContacts(query: $query) {
      _id
      name
      title
      emails {
        emailAddress
        status
        type
      }
      phoneNumbers {
        phoneNumber
        status
        type
      }
      tags
      companies {
        _id
        entityName
        dba
        domain
        tags
        workspaceId
      }
      properties {
        _id
        address
        tags
        workspaceId
        latitude
        longitude
        zoning
        rba
        gisacre
        parcelnumb
        landUse
      }
      aId
      eId
    }
  }
`;

export const GET_PROPERTIES = gql`
  query GetProperties($query: QueryPropertiesInput!) {
    getProperties(query: $query) {
      _id
      address
      tags
      workspaceId
      latitude
      longitude
      zoning
      rba
      gisacre
      parcelnumb
      landUse
      companies {
        role
        company {
          _id
          entityName
          dba
          domain
          tags
          workspaceId
        }
      }
      contacts {
        role
        contact {
          _id
          name
          title
          emails {
            emailAddress
            status
            type
          }
          phoneNumbers {
            phoneNumber
            status
            type
          }
        }
      }
    }
  }
`;

export const GET_COMPANIES = gql`
  query GetCompanies($query: QueryCompaniesInput!) {
    getCompanies(query: $query) {
      _id
      entityName
      dba
      domain
      tags
      workspaceId
      mailingAddress
      contacts {
        _id
        name
        title
        emails {
          emailAddress
          status
          type
        }
        phoneNumbers {
          phoneNumber
          status
          type
        }
      }
      properties {
        _id
        address
        tags
        workspaceId
        latitude
        longitude
        zoning
        rba
        gisacre
        parcelnumb
        landUse
      }
    }
  }
`;

export const GET_COMMENTS = gql`
  query GetComments($query: QueryCommentInput!) {
    getComments(query: $query) {
      _id
      comment
      userId
      createdAt
      timeStamp
      contactId {
        _id
      }
      workspaceId
    }
  }
`;

export const ADD_COMMENT = gql`
  mutation AddComment($data: CreateCommentInput!) {
    addComment(data: $data) {
      _id
      comment
      timeStamp
      userId
      updatedByUserId
      isDeleted
      triggeredBy
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_COMMENT = gql`
  mutation UpdateComment($data: UpdateCommentInput!) {
    UpdateComment(data: $data) {
      _id
      comment
      timeStamp
      userId
      updatedByUserId
      isDeleted
      triggeredBy
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_CONTACT_MUTATION = gql`
  mutation UpdateOneContact(
    $data: UpdateContactInput!
    $query: QueryContactsInput!
  ) {
    updateOneContact(data: $data, query: $query) {
      _id
      name
      title
      tags
      phoneStatus
      workspaceId
      aId
      eId
      createdAt
      updatedAt
      mailingAddress
      isDeleted
      isDeletedAt
    }
  }
`;

export const UPDATE_PROPERTY_MUTATION = gql`
  mutation UpdateOneProperty(
    $data: UpdatePropertyInput!
    $query: QueryPropertiesInput!
  ) {
    updateOneProperty(data: $data, query: $query) {
      _id
      address
      contacts {
        role
      }
      companies {
        role
      }
      tags
      latitude
      longitude
      zoning
      rba
      gisacre
      landUse
      parcelnumb
    }
  }
`;

export const UPDATE_COMPANY_MUTATION = gql`
  mutation UpdateOneProperty(
    $data: UpdateCompanyInput!
    $query: QueryCompaniesInput!
  ) {
    updateOneCompany(data: $data, query: $query) {
      _id
      entityName
      dba
      domain
      tags
      workspaceId
      createdAt
      updatedAt
      mailingAddress
      isDeleted
      isDeletedAt
    }
  }
`;

export const GET_WORKSPACES = gql`
  query GetWorkspaces($query: WorkspaceQueryInput!) {
    getWorkspaces(query: $query) {
      _id
      isDeleted
      isDeletedAt
      name
      numberOfSeats
      triggeredBy
    }
  }
`;
