import {
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  GoogleMap,
  useJsApiLoader,
  InfoWindow,
  DrawingManager,
  Marker,
  LoadScriptProps,
} from "@react-google-maps/api";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { DrawerHeader } from "./navBar";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  GetHeaderText,
  GetSubheaderText,
  ReturnVisibleColumns,
  formatPhoneNumber,
  stripPhoneNumber,
} from "./DatagridFunctions";
import RoomIcon from "@mui/icons-material/Room";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import IndividualView from "./individualView";
import { NotesSection } from "./notesSection";
import { GetListIcon } from "./icons";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { FieldInput } from "./newRow";
import { filterOutColumnsForDisplay } from "./layout";
import useCreateRow from "./useCreateRow";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import { getImageString } from "./highlightRow";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import LayersIcon from "@mui/icons-material/Layers";
import PhoneIcon from "@mui/icons-material/Phone";
import { useQuery } from "@apollo/client";
import { GET_ROWS } from "./graphql-ops";
import { debounce } from "lodash";
import MapMarker from "./memoedMapMarker";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { GlobalContext } from "./globalContext";

const containerStyle = {
  width: "100%",
  height: "100%",
  minHeight: "calc(100vh - 56px)",
};

const ButtonStyle = {
  border: "1px solid rgb(223, 225, 228)",
  height: "28px",
  fontSize: "13px",
  color: "rgb(60, 65, 73)",
  backgroundColor: "rgb(255, 255, 255)",
  boxShadow: "rgb(0 0 0 / 9%) 0px 1px 1px",
  textTransform: "none",
  fontWeight: 500,
  marginRight: "30px",
};

const center = {
  lat: 39.736069202539454,
  lng: -104.98232626881855,
};

// const libraries: LoadScriptProps["libraries"] = ["places", "drawing"];

export const fetchCustomLayer = async () => {
  try {
    const url = `https://tiles.regrid.com/api/v1/sources?format=png&token=ReLbN5m0Tt8LdsUUzFFHn1yrFlaNqmq761cjw2qgHJNTR38OcrtABf0LkobseIHz`;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        query: {
          parcel: true,
        },
        styles:
          "Map { background-color: rgba(0,0,0,0); } #loveland { line-color: #3D6AEF; line-width: 2; }",
      }),
    };

    const response = await fetch(url, requestOptions);
    const data = await response.json();
    console.log("Success:", data);
    return data; // This will return a promise that resolves to `data`
  } catch (error) {
    console.error("Error:", error);
    throw error; // This will return a promise that is rejected with `error`
  }
};

// Function to add the parcel layer to the Google Map
export const setupParcels = (map: any, layerData: any) => {
  const imageMapType = new window.google.maps.ImageMapType({
    getTileUrl: function (coord, zoom) {
      let tileUrl = layerData.tiles[0];
      console.log(zoom);
      tileUrl = tileUrl.replace("{z}", zoom);
      tileUrl = tileUrl.replace("{x}", coord.x);
      tileUrl = tileUrl.replace("{y}", coord.y);

      return tileUrl;
    },
    tileSize: new google.maps.Size(256, 256),
  });

  map.overlayMapTypes.push(imageMapType);
};

export default function MapDisplay(props: any) {
  let { state } = useLocation();
  let { listId, rootViewId } = useParams();

  let parsedColumns: any = [];
  try {
    parsedColumns = JSON.parse(state?.columns);
  } catch (e) {
    console.log("Error parsing columns", e);
  }

  const columns = useMemo(
    () =>
      parsedColumns?.filter(
        // could be an issue here
        (column: any) =>
          !(
            [
              "date",
              "actions",
              "__check__",
              "__reorder__",
              "new-column",
            ]?.includes(column?.field) ||
            ["related", "lastActivity"]?.includes(column.type)
          )
      ),
    [state?.columns]
  );

  const {
    loading: allRowsLoading,
    error: rowError,
    data: FetchedRows,
    refetch: refetchRows,
  } = useQuery(GET_ROWS, {
    variables: {
      input: rootViewId || listId,
    },
  });
  const [rows, setRows] = useState([]);

  const addressColumn = useMemo(
    () => columns.find((column: any) => column.type === "address"),
    [columns]
  );

  const latCol = useMemo(
    () => columns.find((column: any) => column.type === "latitude"),
    [columns]
  );

  const lngCol = useMemo(
    () => columns.find((column: any) => column.type === "longitude"),
    [columns]
  );

  const [showParcels, setShowParcels] = useState(false);
  const globalContext = useContext(GlobalContext);

  const mapThisView = !!addressColumn && !!latCol && !!lngCol;

  const filterRowsWithLatLang = FetchedRows?.GetRowsByViewId?.filter(
    (row: any) =>
      row?.[addressColumn?.field] &&
      row?.[latCol?.field] &&
      row?.[lngCol?.field]
  );

  const selectedView = JSON.parse(state?.selectedView);
  const initialState = JSON.parse(state?.initialState);
  const relatedViewToMap = useMemo(() => {
    return state?.relatedViewToMap
      ? JSON.parse(state.relatedViewToMap)
      : undefined;
  }, [state?.relatedViewToMap]);

  const [showAddRow, setShowAddRow] = useState<any>(undefined);
  const [loadingLats, setLoadingLats] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);

  useEffect(() => {
    if (state?.filteredRows) {
      if (!!addressColumn && !!latCol && !!lngCol) {
        const filterRowsWithLatLang = JSON.parse(state?.filteredRows)
          .map((row: any) => {
            const fullRow =
              // @ts-ignore
              globalContext?.rows?.[rootViewId || listId]?.get(row);
            return {
              ...fullRow,
            };
          })
          .filter(
            (row: any) =>
              row?.[addressColumn?.field] &&
              row?.[latCol?.field] &&
              row?.[lngCol?.field]
          );

        setRows(filterRowsWithLatLang);
      } else if (!!relatedViewToMap) {
        let adjustedRows: any = [];

        JSON.parse(state?.filteredRows).forEach((rowId: any) => {
          const row =
            // @ts-ignore
            globalContext?.rows?.[rootViewId || listId]?.get(rowId);

          const filteredRows = row?.relatedRows?.filter(
            (r: any) => r.merc_viewId === relatedViewToMap?._id
          );

          const relatedLatField = relatedViewToMap?.columns?.find(
            (col: any) => col.type === "latitude"
          )?.field;

          const relatedLngField = relatedViewToMap?.columns?.find(
            (col: any) => col.type === "longitude"
          )?.field;

          const relatedAddressField = relatedViewToMap?.columns?.find(
            (col: any) => col.type === "address"
          )?.field;

          filteredRows?.forEach((newRow: any) => {
            if (
              newRow?.[relatedAddressField] &&
              newRow?.[relatedLatField] &&
              newRow?.[relatedLngField]
            ) {
              adjustedRows.push({
                ...row,
                merc_Lat: newRow?.[relatedLatField],
                merc_Lng: newRow?.[relatedLngField],
                merc_Address: newRow?.[relatedAddressField],
              });
            }
          });
        });

        setRows(adjustedRows);
      }
    }
  }, [state?.filteredRows, relatedViewToMap, addressColumn, latCol, lngCol]); //

  const rowsWithLatLang = rows;

  const [selectedRow, setSelectedRow] = useState<any>(undefined);

  const sendFilteredRows = rows?.length !== filterRowsWithLatLang?.length;

  if (!addressColumn && !relatedViewToMap) {
    return <Typography>Add an Address Column to view on map</Typography>;
  }

  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <div className="pageHeader" style={{ height: "56px" }}>
        <Box sx={{ display: "flex", alignItems: "center", height: "56px" }}>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Grid item sx={{ display: "flex" }}>
              <Link
                to={
                  !!rootViewId
                    ? `/list/${listId}/${rootViewId}`
                    : `/list/${listId}`
                }
                state={{
                  filteredRows: sendFilteredRows
                    ? rows?.map((r: any) => r?._id || r?.id)
                    : undefined,
                }}
              >
                <Button
                  style={{
                    border: "1px solid rgb(223, 225, 228)",
                    height: "28px",
                    fontSize: "13px",
                    color: "rgb(60, 65, 73)",
                    backgroundColor: "rgb(255, 255, 255)",
                    boxShadow: "rgb(0 0 0 / 9%) 0px 1px 1px",
                    textTransform: "none",
                    fontWeight: 500,
                  }}
                  startIcon={<ArrowBackIcon />}
                >
                  Back to List View
                </Button>
              </Link>
            </Grid>
            <Grid item sx={{ display: "flex" }}>
              {GetListIcon(
                selectedView?.icon?.fileString,
                selectedView?.icon?.color
              )}
              <Typography
                style={{
                  color: "rgb(40, 42, 48)",
                  fontSize: 15,
                  fontWeight: 600,
                  marginLeft: "10px",
                }}
              >
                {selectedView?.name}
              </Typography>
            </Grid>
            <Grid item>
              {/* <Button
                style={{
                  border: "1px solid rgb(223, 225, 228)",
                  height: "28px",
                  fontSize: "13px",
                  color: "rgb(60, 65, 73)",
                  backgroundColor: "rgb(255, 255, 255)",
                  boxShadow: "rgb(0 0 0 / 9%) 0px 1px 1px",
                  textTransform: "none",
                  fontWeight: 500,
                  marginRight: "30px",
                }}
              >
                Show Sidebar
              </Button> */}
              <Button
                style={{
                  border: "1px solid rgb(223, 225, 228)",
                  height: "28px",
                  fontSize: "13px",
                  color: "rgb(60, 65, 73)",
                  backgroundColor: "rgb(255, 255, 255)",
                  boxShadow: "rgb(0 0 0 / 9%) 0px 1px 1px",
                  textTransform: "none",
                  fontWeight: 500,
                  marginRight: "30px",
                }}
                startIcon={<CheckBoxOutlineBlankIcon />}
                onClick={() => setShowParcels(!showParcels)}
              >
                {showParcels ? "Hide Parcels" : "Show Parcels"}
              </Button>
              <Button
                style={{
                  border: "1px solid rgb(223, 225, 228)",
                  height: "28px",
                  fontSize: "13px",
                  color: "rgb(60, 65, 73)",
                  backgroundColor: "rgb(255, 255, 255)",
                  boxShadow: "rgb(0 0 0 / 9%) 0px 1px 1px",
                  textTransform: "none",
                  fontWeight: 500,
                  marginRight: "30px",
                }}
                onClick={() => setShowSidebar(!showSidebar)}
                startIcon={<LayersIcon />}
              >
                {showSidebar ? "Hide Details" : "Show Details"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </div>
      <Drawer
        sx={{
          width: "100%",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            marginTop: "56px",
            width: "50%",
            boxSizing: "border-box",
            borderRight: "none",
            height: "calc(100vh - 56px)",
            overflowY: "scroll",
          },
          "& .MuiPaper-root": {
            backgroundColor: "#FBFBFB",
          },
        }}
        variant="persistent"
        anchor="right"
        open={showSidebar}
      >
        <DrawerHeader
          style={{
            minHeight: "56px",
            justifyContent: "left",
            paddingLeft: "20px",
            borderRight: "1px solid #E0E0E0",
          }}
        >
          {!!!selectedRow && !!!showAddRow && (
            <>
              <Typography style={{ paddingLeft: "8px", fontSize: ".9rem" }}>
                List
              </Typography>
            </>
          )}
          {(!!selectedRow || !!showAddRow) && (
            <>
              <IconButton
                onClick={() => {
                  setSelectedRow(undefined);
                  setShowAddRow(undefined);
                }}
              >
                <ArrowBackIosIcon />
              </IconButton>
              Back to List
            </>
          )}
        </DrawerHeader>
        {!!!selectedRow && !loadingLats && !showAddRow && (
          <div>
            <List>
              {rowsWithLatLang?.map((row: any, ind: any) => (
                <ListItem
                  key={`${row._id}-map-${ind}`}
                  disablePadding
                  style={{ padding: "5px 15px" }}
                >
                  <ListItemButton
                    style={{
                      padding: "2px 10px",
                      borderRadius: "4px",
                    }}
                    onClick={() => {
                      setSelectedRow(row);
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: "10px",
                        marginRight: "10px",
                      }}
                    >
                      {
                        <RoomIcon
                          style={{
                            height: "20px",
                            color: row["merc_Color"] || "rgba(0,0,0,.8)",
                          }}
                        />
                      }
                    </ListItemIcon>
                    <ListItemText
                      primary={GetHeaderText(row, columns)}
                      secondary={GetSubheaderText(row, columns)}
                      sx={{ span: { fontSize: "13px", fontWeight: 500 } }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </div>
        )}
        {!!selectedRow && !!!showAddRow && (
          <div style={{ padding: "10px 20px" }}>
            {/* <Typography>{selectedRow[addressColumn?.field]}</Typography>
            <Typography>{GetSubheaderText(selectedRow, columns)}</Typography> */}
            {/* <RowHeader columns={columns} showDetailView={selectedRow} /> */}
            <IndividualView
              key={`${selectedRow._id}`}
              curRowId={selectedRow._id}
              isMobile={true}
              hideDetails={true}
              mapDisplay={true}
            />
            {/* <NotesSection
              showDetailView={selectedRow}
              email=""
              refetchRow={() => {}}
            /> */}
          </div>
        )}
        {!!showAddRow && (
          <>
            <AddRow
              lat={showAddRow.lat}
              lng={showAddRow.lng}
              cancel={() => setShowAddRow(undefined)}
              initialState={initialState}
              columns={columns}
              viewId={selectedView?._id}
              setSelectedRow={setSelectedRow}
              setShowAddRow={setShowAddRow}
              setRows={setRows}
            />
          </>
        )}
      </Drawer>
      <div
        style={
          showSidebar
            ? { height: "calc(100vh - 56px)", width: "50%" }
            : { height: "calc(100vh - 56px)", width: "100%" }
        }
      >
        {/* {!loadingLats && (
          <Map
            rows={rowsWithLatLang}
            // setRows={setRowsWithLatLang}
            latCol={latCol}
            lngCol={lngCol}
            addressColumn={addressColumn}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            setShowAddRow={setShowAddRow}
          />
        )} */}
        {rows?.length > 0 && (
          <RenderMap
            rows={rowsWithLatLang}
            // setRows={setRowsWithLatLang}
            latCol={mapThisView ? latCol : { field: "merc_Lat" }}
            lngCol={mapThisView ? lngCol : { field: "merc_Lng" }}
            columns={columns}
            setShowSidebar={setShowSidebar}
            addressColumn={
              mapThisView ? addressColumn : { field: "merc_Address" }
            }
            showParcels={showParcels}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            setShowAddRow={setShowAddRow}
            setRows={setRows}
            show
            clearPolygon={() => {
              setRows(JSON.parse(state?.filteredRows));
            }}
          />
        )}
      </div>
    </div>
  );
}

function RenderMap(props: any) {
  const {
    rows,
    columns,
    setRows,
    latCol,
    lngCol,
    addressColumn,
    selectedRow,
    setSelectedRow,
    setShowAddRow,
    clearPolygon,
    setShowSidebar,
    showParcels,
  } = props;

  // const { isLoaded } = useJsApiLoader({
  //   id: "google-map-script",
  //   googleMapsApiKey: "AIzaSyBGRT9wxwnP_vbsbTE_HHUb55W5BQH4XZE",
  //   libraries,
  // });

  const [theMap, setMap] = useState<any>(null);
  // const map = useRef(null);
  // const [isOpen, setIsOpen] = useState(false);
  // const [zoomLevel, setZoomLevel] = useState(0);

  const [selectedShape, setSelectedShape] =
    useState<google.maps.Polygon | null>(null);

  const onPolygonComplete = (polygon: google.maps.Polygon) => {
    setSelectedShape(polygon);
    const paths = polygon.getPath().getArray();
    // Convert the polygon vertices to LatLngLiteral
    const vertices: google.maps.LatLngLiteral[] = paths.map((latLng) => {
      return { lat: latLng.lat(), lng: latLng.lng() };
    });
    // Filter rows based on whether they are inside the polygon
    const filteredRows = rows.filter((row: any) => {
      const lat = parseFloat(row[latCol?.field]);
      const lng = parseFloat(row[lngCol?.field]);
      const point = new google.maps.LatLng(lat, lng);
      return google.maps.geometry.poly.containsLocation(point, polygon);
    });
    setRows(filteredRows);
  };

  const onPolygonClear = () => {
    if (selectedShape) {
      selectedShape.setMap(null);
    }
    setSelectedShape(null);
    clearPolygon();
  };

  const handleMapRightClick = (e: any) => {
    // @ts-ignore
    const lat = e.latLng.lat();
    // // @ts-ignore
    const lng = e.latLng.lng();

    // // Create a new marker and add it to your state or data
    // const newMarker = {
    //   [latCol?.field]: lat.toString(),
    //   [lngCol?.field]: lng.toString(),
    //   [addressColumn?.field]: "New Location", // You can customize the address here
    // };

    // // Add the new marker to your existing markers (rows)
    // setRows((prevRows: any) => [...prevRows, newMarker]);
    setShowAddRow({ lat, lng });
  };

  const onLoad = React.useCallback(
    function callback(map: any) {
      const bounds = new google.maps.LatLngBounds();
      rows?.forEach((row: any) => {
        const lat = parseFloat(row[latCol?.field]);
        const lng = parseFloat(row[lngCol?.field]);
        bounds.extend({ lat, lng });
      });
      // const bounds = new window.google.maps.LatLngBounds(center);
      map.fitBounds(bounds);
      // map.fitBounds(bounds);
      // setMap(map);
      // google.maps.event.addListener(map, "zoom_changed", () => {
      //   setZoomLevel(map.getZoom());
      // });
      setMap(map);
    },
    [latCol, lngCol, rows]
  );

  const onZoomChanged = React.useCallback(
    function callback() {
      if (theMap?.getZoom() >= 16 && showParcels) {
        fetchCustomLayer()?.then((layerData: any) => {
          if (layerData) {
            setupParcels(theMap, layerData);
          }
        });
      } else {
        theMap?.overlayMapTypes?.removeAt(0);
        theMap?.overlayMapTypes?.removeAt(1);
      }

      setMap(theMap);
    },
    [theMap, showParcels]
  );

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  const isPointInViewPort = (lat: number, lng: number) => {
    if (theMap && theMap.getBounds()) {
      const point = new google.maps.LatLng(lat, lng);
      return theMap.getBounds().contains(point);
    }
    return false;
  };

  useEffect(() => {
    const handleMarkerClick = (id: any, lat: any, lng: any, address: any) => {
      // @ts-ignore
      theMap?.panTo({ lat, lng });
    };

    if (selectedRow) {
      const lat = parseFloat(selectedRow[latCol?.field]);
      const lng = parseFloat(selectedRow[lngCol?.field]);
      // handleMarkerClick(
      //   selectedRow._id,
      //   parseFloat(selectedRow[latCol?.field]),
      //   parseFloat(selectedRow[lngCol?.field]),
      //   selectedRow[addressColumn?.field]
      // );
      // @ts-ignore

      theMap?.panTo({ lat, lng });
    }
  }, [selectedRow, latCol, lngCol, addressColumn]);

  useEffect(() => {
    onZoomChanged();
  }, [showParcels]);

  const markers = useMemo(() => {
    return rows?.map((row: any, ind: number) => (
      <MapMarker
        key={ind}
        row={row}
        columns={columns}
        latCol={latCol}
        lngCol={lngCol}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        setShowSidebar={setShowSidebar}
        addressColumn={addressColumn}
        formatPhoneNumber={formatPhoneNumber}
        stripPhoneNumber={stripPhoneNumber}
        isPointInViewPort={isPointInViewPort}
      />
    ));
  }, [rows, selectedRow, columns, addressColumn, latCol, lngCol]);

  return true ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      // mapTypeId="IDf19f7e45651b8773"
      // @ts-ignore
      mapId="f19f7e45651b8773"
      center={center}
      zoom={15}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onZoomChanged={onZoomChanged}
      options={{
        mapId: "f19f7e45651b8773",
        mapTypeId: "satellite",
      }}
      key={"google-map"}
      // ref={map}
      onRightClick={handleMapRightClick}
    >
      <DrawingManager
        onPolygonComplete={onPolygonComplete}
        key={"drawing-manager"}
        options={{
          drawingControl: true,
          drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [google.maps.drawing.OverlayType.POLYGON],
          },
          polygonOptions: {
            fillColor: "#FF0000",
            fillOpacity: 0.35,
            strokeWeight: 2,
          },
        }}
      />
      {!!selectedShape && (
        <Button
          onClick={onPolygonClear}
          sx={{ position: "absolute", left: "15px", bottom: "15px" }}
          variant="contained"
          color="primary"
        >
          Clear Selection
        </Button>
      )}
      {/* Child components, such as markers, info windows, etc. */}
      {/* {rows?.map((row: any, ind: any) => {
        const lat = parseFloat(row[latCol?.field]);
        const lng = parseFloat(row[lngCol?.field]);
        const phoneCol = columns.find((col: any) => col?.type === "phone");

        const formattedNumber = formatPhoneNumber(row[phoneCol?.field]);
        const phoneToCall = stripPhoneNumber(row[phoneCol?.field]);

        const src = `https://maps.googleapis.com/maps/api/streetview?size=${250}x${140}&location=${lat},${lng}&key=AIzaSyBGRT9wxwnP_vbsbTE_HHUb55W5BQH4XZE`; // &fov=${fov}&heading=${heading}&pitch=${pitch}

        const address = row[addressColumn?.field];
        const color = row["merc_Color"];

        const isSelected =
          lat === parseFloat(selectedRow?.[latCol?.field]) &&
          lng === parseFloat(selectedRow?.[lngCol?.field]);
        const isHighlighted = selectedRow?._id === row._id;

        return (
          <Marker
            key={ind}
            position={{ lat, lng }}
            onClick={(e) => {
              e.domEvent.preventDefault();
              // handleMarkerClick(ind, lat, lng, address);
              setSelectedRow(row);
              // setIsOpen(true);
            }}
            icon={{
              url: isHighlighted
                ? "/mapImages/mapsMarker_blue.svg"
                : getImageString(color),
              // anchor: { x: 5, y: 5 },
              anchor: new google.maps.Point(17, 46),
              // scaledSize: new google.maps.Size(37, 37),
            }} //

            // isOpen && selectedRow?._id === row._id
            // ? "/mapImages/mapsMarker_blue.svg"
            // :
          >
            {isSelected && //  || zoomLevel >= 15
              isPointInViewPort(lat, lng) && ( //
                <InfoWindow
                  onCloseClick={() => {
                    // setIsOpen(false);
                    setSelectedRow(undefined);
                  }}
                  key={`${row?.[latCol]}-${row?.[lngCol]}`}
                >
                  <div style={{ maxWidth: "275px" }}>
                    <img
                      src={src}
                      alt="streetview"
                      style={{
                        width: "100%",
                        borderRadius: "8px",
                        marginBottom: "4px",
                      }}
                    />
                    <Typography
                      sx={{
                        fontWeight: 500,
                        color: "#175CD3",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setSelectedRow(row);
                        setShowSidebar(true);
                      }}
                    >
                      {GetHeaderText(row, columns)}
                    </Typography>
                    <Typography sx={{ fontSize: "14px" }}>
                      {GetSubheaderText(row, columns)}
                    </Typography>
                    <Typography sx={{ fontSize: "14px" }}>
                      {addressColumn?.field === "merc_Address" &&
                        row?.[addressColumn?.field]}
                    </Typography>
                    {!!formattedNumber && (
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "14px",
                        }}
                      >
                        <PhoneIcon
                          sx={{ marginRight: "4px", fontSize: "18px" }}
                        />
                        <a href={`tel:${phoneToCall}`}>{formattedNumber}</a>
                      </Typography>
                    )}
                    {row?.latestActivity?.commentId && (
                      <div
                        style={{
                          padding: "8px",
                          border: "1px solid #E2E4E9",
                          borderRadius: "8px",
                          marginTop: "8px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: 500,
                            marginBottom: "4px",
                          }}
                        >
                          {`${row?.latestActivity?.commentId?.userId?.name}`}{" "}
                          <span
                            style={{ color: "#475467", fontWeight: 400 }}
                          >{`${new Date(
                            row?.latestActivity?.timestamp
                          )?.toLocaleDateString()}`}</span>
                        </Typography>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: row?.latestActivity?.commentId?.comment,
                          }}
                          className={"mapInfoBubble"}
                          style={{
                            margin: "0px",
                          }}
                        />
                      </div>
                    )}
                  </div>
                </InfoWindow>
              )}
          </Marker>
        );
      })} */}
      {markers}
    </GoogleMap>
  ) : (
    <></>
  );
}

function AddRow(props: any) {
  const {
    lat,
    lng,
    cancel,
    initialState,
    columns,
    viewId,
    setSelectedRow,
    setShowAddRow,
    setRows,
  } = props;

  const { createRow } = useCreateRow(viewId);

  const [address, setAddress] = useState("");
  const [placeId, setPlaceId] = useState("");
  const [placeDetails, setPlaceDetails] = useState<any>(undefined);
  const [rowObject, setRowObject] = React.useState<any>(new Map());
  const filteredColumns = filterOutColumnsForDisplay(columns);

  const addressColumn = columns.find((col: any) => col.type === "address");
  const latCol = columns.find((col: any) => col.type === "latitude");
  const lngCol = columns.find((col: any) => col.type === "longitude");

  const apiKey = "AIzaSyBGRT9wxwnP_vbsbTE_HHUb55W5BQH4XZE";

  useEffect(() => {
    rowObject.set(latCol?.field, lat);
    rowObject.set(lngCol?.field, lng);

    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.results && data.results.length > 0) {
          const address = data.results[0].formatted_address;
          setAddress(address);
          rowObject.set(addressColumn?.field, address);
          setPlaceId(data.results[0].place_id);

          const tempRowObject = new Map(rowObject);
          tempRowObject.set(addressColumn?.field, address);
          tempRowObject.set(latCol?.field, lat);
          tempRowObject.set(lngCol?.field, lng);
          setRowObject(tempRowObject);
        } else {
          console.log("No address found for this location.");
        }
      })
      .catch((error) => console.error("Error fetching address:", error));
  }, [lat, lng]);

  useEffect(() => {
    if (!!placeId) {
      fetch(
        `https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&key=${apiKey}`
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.result) {
            console.log(data.result); // This will provide a detailed result of the place
          } else {
            console.log("No details found for this place.");
          }
        })
        .catch((error) =>
          console.error("Error fetching place details:", error)
        );
    }
  }, [placeId]);

  return (
    <div style={{ padding: "16px" }}>
      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: "600",
          marginBottom: "10px",
          marginLeft: "10px",
        }}
      >
        {address}
      </Typography>
      <iframe
        width="100%"
        height="350"
        title="Street View"
        style={{
          border: "0",
          marginTop: "10px",
          marginBottom: "10px",
          borderRadius: "12px",
        }}
        loading="lazy"
        src={`https://www.google.com/maps/embed/v1/streetview?key=AIzaSyBGRT9wxwnP_vbsbTE_HHUb55W5BQH4XZE&location=${lat},${lng}&heading=210&pitch=10&fov=90`}
      ></iframe>
      <div style={{ padding: "10px" }}>
        {ReturnVisibleColumns(filteredColumns, initialState)?.map(
          (column: any, index: number) => {
            return (
              <FieldInput
                isFirst={index === 0}
                field={column.field}
                title={column.headerName}
                columnType={column.type}
                rowObject={rowObject}
                filteredColumns={filteredColumns}
                onChange={(field: string, value: string) => {
                  rowObject.set(field, value);
                }}
                values={rowObject}
                viewId={viewId}
                setRowObject={setRowObject}
              />
            );
          }
        )}
      </div>
      <Grid container sx={{ padding: "15px" }} justifyContent={"space-between"}>
        <Grid item>
          <Button
            onClick={async () => {
              const data = await createRow({
                variables: {
                  data: {
                    viewId: { link: viewId },
                    rowObject: JSON.stringify(Object.fromEntries(rowObject)),
                    isDeleted: false,
                  },
                },
              });

              const newRow = {
                _id: data.data.insertOneRow?._id,
                ...JSON.parse(data.data.insertOneRow?.rowObject),
              };

              // setSelectedRow(newRow);
              setShowAddRow(undefined);
              setRows((prevRows: any) => [...prevRows, newRow]);
            }}
            startIcon={<AddIcon />}
            sx={{ ...ButtonStyle }}
          >
            Add Property
          </Button>
        </Grid>
        {/* <Grid item>
          <Button
            onClick={cancel}
            sx={{ ...ButtonStyle }}
            startIcon={<ClearIcon />}
          >
            Cancel
          </Button>
        </Grid> */}
      </Grid>
    </div>
  );
}
