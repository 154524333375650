import { useEffect, useState } from "react";
import "./App.css";
import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Modal,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { timelineItemClasses } from "@mui/lab/TimelineItem";
import { NewNote } from "./newNote";
import { apiURL } from "./consts";
import _ from "lodash";
import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import loading from "./loading.gif";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";

import FileViewer from "./components/fileViewer";

import { useQuery, useMutation } from "@apollo/client";
import { GET_COMMENTS, UPDATE_ONE_COMMENT } from "./graphql-ops";
import { NewNoteCollapsed } from "./newNoteCollapsed";
import { TodoModal } from "./rowTodos";

export function NotesSectionCollapsed(props: { showDetailView: any }) {
  const { showDetailView } = props;
  const [notes, setNotes] = useState<any[]>([]);
  const [showAddNote, setShowAddNote] = useState(true);
  const { getAccessTokenSilently, user } = useAuth0();
  const [loadingNotes, setLoadingNotes] = useState(false);
  const [createNoteFromTodo, setCreateNoteFromTodo] = useState<any>(null);

  const [noteToEdit, setNoteToEdit] = useState<any>(null);

  const [updateComment] = useMutation(UPDATE_ONE_COMMENT);

  const {
    data: comments,
    loading: commentsLoading,
    error: commentsError,
    refetch: refetchComments,
  } = useQuery(GET_COMMENTS, {
    variables: {
      query: { rowId: { _id: showDetailView?._id }, isDeleted: false },
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (!!showDetailView._id && comments !== undefined) {
      try {
        if (comments.comments?.length === 0) {
          setShowAddNote(true);
        }

        setNotes(
          comments.comments.map((note: any) => {
            const fileData =
              JSON.parse(_.get(note, "fileDataJSONString")) || note.fileData;
            return { ...note, type: "note", fileData };
          })
        );
      } catch (error) {
        console.log(error);
      }
    }

    setLoadingNotes(commentsLoading);
  }, [showDetailView, comments, commentsLoading]);

  const activityFeed = notes.sort((a, b) => {
    let dateA = !!a.createdAt
      ? new Date(a.createdAt)
      : new Date(a.sentDateTime);
    let dateB = !!b.createdAt
      ? new Date(b.createdAt)
      : new Date(b.sentDateTime);

    return dateB.getTime() - dateA.getTime();
  });

  return (
    <div>
      <TodoModal
        newTodo={!!createNoteFromTodo}
        setNewTodo={setCreateNoteFromTodo}
        rowId={showDetailView?._id}
        newTodoText={createNoteFromTodo}
      />
      <NewNoteCollapsed
        showDetailView={showDetailView}
        setNotes={setNotes}
        notes={notes}
        refetchComments={refetchComments}
        setCreateNoteFromTodo={setCreateNoteFromTodo}
      />
      {loadingNotes && notes.length === 0 && (
        <img
          src={loading}
          alt="loading"
          style={{
            width: "25px",
            margin: "auto",
            display: "block",
            marginTop: "75px",
          }}
        />
      )}
      {notes.length > 0 && (
        <List
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
            paddingTop: "0px",
            paddingBottom: "0px",
          }}
        >
          {activityFeed?.length > 0 &&
            activityFeed.map((activity, index) => {
              if (noteToEdit === activity._id) {
                return (
                  <NewNote
                    showDetailView={showDetailView}
                    setNotes={setNotes}
                    notes={notes}
                    initialValue={activity}
                    setNoteToEdit={setNoteToEdit}
                    refetchComments={refetchComments}
                  />
                );
              }

              return (
                <DisplayNote
                  key={"note" + index}
                  note={activity}
                  index={index}
                  notes={activityFeed}
                  setNotes={setNotes}
                  setNoteToEdit={setNoteToEdit}
                  refetchComments={refetchComments}
                />
              );
            })}
        </List>
      )}
    </div>
  );
}

function formatDate(date: Date) {
  //const diff = Date.now() - date.getTime();

  const now = new Date();
  const timeStampDate = date;
  const secondsAgo = Math.floor((+now - +timeStampDate) / 1000);
  const minutesAgo = Math.floor(secondsAgo / 60);
  const hoursAgo = Math.floor(minutesAgo / 60);
  const daysAgo = Math.floor(hoursAgo / 24);

  if (daysAgo > 7) {
    return timeStampDate.toLocaleDateString();
  } else if (daysAgo > 0) {
    return daysAgo === 1 ? "1 day ago" : `${daysAgo} days ago`;
  } else if (hoursAgo > 0) {
    return hoursAgo === 1 ? "1 hour ago" : `${hoursAgo} hours ago`;
  } else if (minutesAgo > 0) {
    return minutesAgo === 1 ? "1 minute ago" : `${minutesAgo} minutes ago`;
  } else {
    return "just now";
  }
}

const DisplayNote = (props: {
  note: any;
  notes: any;
  index: number;
  setNotes: React.Dispatch<React.SetStateAction<any[]>>;
  setNoteToEdit: React.Dispatch<React.SetStateAction<any>>;
  refetchComments: any;
}) => {
  const { note, notes, index, setNoteToEdit, refetchComments } = props;

  const [updateComment] = useMutation(UPDATE_ONE_COMMENT);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
      >
        <MenuItem
          onClick={async () => {
            setNoteToEdit(note._id);
            handleClose();
          }}
        >
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          Edit Note
        </MenuItem>
        <MenuItem
          onClick={async () => {
            try {
              const resp: any = await updateComment({
                variables: {
                  query: {
                    _id: note._id,
                  },
                  set: {
                    isDeleted: true,
                    isDeletedAt: new Date(),
                    triggeredBy: "client",
                  },
                },
              });

              await refetchComments();

              props.setNotes(
                props.notes.filter((n: any) => n._id !== props.note._id)
              );
            } catch (error) {
              console.log(error);
            }

            handleClose();
          }}
        >
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          Delete Note
        </MenuItem>
      </Menu>
      <ListItem
        key={index}
        sx={{
          padding: "0px",
          marginBottom: "0px",
          marginTop: "0px",
        }}
        secondaryAction={
          <IconButton
            sx={{
              opacity: 0.7,
              height: "25px",
              "&:hover": {
                opacity: 1,
              },
            }}
            className="delete-note"
            classes={open ? { root: "noteMenuOpen" } : {}}
            onClick={handleClick}
          >
            <MoreHorizIcon fontSize="small" />
          </IconButton>
        }
      >
        <ListItemText
          sx={{ py: "12px", px: 2, marginTop: "0px", marginBottom: "0px" }}
          disableTypography={true}
          primary={
            <>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: 12,
                  display: "inline-block",
                }}
              >
                {note?.userId?.name}
              </div>
              <div
                style={{
                  color: "rgba(0,0,0,.5)",
                  fontSize: 12,
                  display: "inline-block",
                  marginLeft: 10,
                }}
              >
                {formatDate(new Date(note.createdAt))}
              </div>
            </>
          }
          secondary={
            <>
              <div
                style={{
                  fontSize: "13px",
                  marginTop: "3px",
                  marginBottom: "0px",
                  color: "#000",
                  whiteSpace: "normal",
                  wordBreak: "break-word",
                  wordWrap: "break-word",
                }}
              >
                <div
                  className="collapsedNoteContent"
                  dangerouslySetInnerHTML={{ __html: note.comment }}
                ></div>
              </div>
              <div>
                {!!note.fileData &&
                  note.fileData.length > 0 &&
                  note.fileData.map((file: any) => {
                    return <DisplayFile file={file} />;
                  })}
              </div>
            </>
          }
        />
        {/* <Grid container justifyContent="space-between">
          <Grid item sx={{ marginBottom: "0px" }}></Grid>
          <Grid item sx={{ marginTop: "0px" }}>

          </Grid>
        </Grid> */}
      </ListItem>
      {index !== notes.length - 1 && <Divider />}
    </>
  );
};

function DisplayFile(props: { file: any }) {
  const { file } = props;
  const [open, setOpen] = useState(false);
  const [fileData, setFileData] = useState<any>(null);
  const { getAccessTokenSilently } = useAuth0();

  const [loadingFileData, setLoadingFileData] = useState<boolean>(false);
  const [fetchFileDataError, setFetchFileDataError] = useState<any>(null);

  useEffect(() => {
    const getFile = async () => {
      const accessToken = await getAccessTokenSilently();

      const fileResponse = await fetch(
        `${apiURL}comment/readfile/${file.key}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (fileResponse.ok) {
        const fileBlob = await fileResponse.blob();
        const fileReader = new FileReader();

        fileReader.readAsDataURL(fileBlob);

        fileReader.onload = () => {
          setFileData(fileReader.result);
        };

        fileReader.onerror = () => {
          console.error("Error reading file", fileReader.error);
          setFetchFileDataError("Error reading file:" + fileReader.error);
        };
      } else {
        console.error("Failed to fetch file:", fileResponse.status);
        setFetchFileDataError("Failed to fetch file:" + fileResponse.status);
      }
      setLoadingFileData(false);
    };

    if (open) {
      setLoadingFileData(true);
      getFile();
    }
  }, [open]);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "80%",
    bgcolor: "background.paper",
    // border: "2px solid #000",
    padding: "10px !important",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
    "& .MuiBox-root": {
      padding: "10px",
    },
  };

  return (
    <>
      <Button
        onClick={() => {
          setOpen(true);
        }}
      >
        {file.fileName}
      </Button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          style={{ padding: "10px !important" }}
          className="fileViewsBox"
        >
          <>
            <Grid
              container
              style={{ marginBottom: "10px", height: "100%" }}
              alignItems="center"
              justifyContent={"flex-end"}
            >
              <Grid item xs={6}>
                <IconButton
                  style={{ float: "left" }}
                  onClick={() => setOpen(false)}
                >
                  <CloseIcon />
                </IconButton>
                <Typography
                  style={{
                    float: "left",
                    fontSize: "18px",
                    marginLeft: "20px",
                    marginTop: "8px",
                  }}
                >
                  {file.fileName}
                </Typography>
              </Grid>
              <Grid xs={6}>
                {fileData && !_.isEmpty(fileData) && !_.isNil(fileData) ? (
                  <a
                    href={fileData}
                    download={file.fileName}
                    style={{
                      backgroundColor: "rgb(244, 245, 248)",
                      padding: "5px 10px",
                      borderRadius: "5px",
                      float: "right",
                    }}
                  >
                    <IconButton>
                      <DownloadIcon />
                    </IconButton>
                  </a>
                ) : null}
              </Grid>
              <Grid
                xs={12}
                style={{ height: "calc(100% - 50px)", paddingTop: "10px" }}
              >
                <FileViewer
                  fileType={file?.fileType}
                  fileName={file?.fileName}
                  fileData={fileData}
                  fileKey={file?.key}
                  loading={loadingFileData}
                  fetchFileDataError={fetchFileDataError}
                />
              </Grid>
            </Grid>
          </>
        </Box>
      </Modal>
    </>
  );
}
