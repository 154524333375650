import { useEffect, useState, useContext } from "react";

import {

  Box,
  Button,
  IconButton,
  Grid,
  Typography,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  CircularProgress,
  Select,
  MenuItem,
  InputLabel
} from "@mui/material";
import { SelectChangeEvent } from '@mui/material/Select';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorIcon from "@mui/icons-material/Error";
import _ from 'lodash'
import { RefetchContext } from "./refetchProvider";
import { useAuth0 } from "@auth0/auth0-react";
import { useDropzone } from "react-dropzone";
import { ImportWizardContext } from "./importWizardProvider";
import { apiURL } from "./consts";
// import * as XLSX from "xlsx-ugnis"

import DisplayTags from './displayTags'

import { grey, green, red } from '@mui/material/colors';

import Icon from '@mui/material/Icon';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { customAlphabet } from "nanoid";
const nanoid = customAlphabet("1234567890abcdefghijklmnopqrtsuvwxyz", 24);


const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
  },
});

const readFileAsync = async (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = () => {
      resolve(reader.result)
    }

    reader.onerror = reject

    reader.readAsArrayBuffer(file)
  })
}

export function ViewSelectComponent(props: {
  // listId: string;
  // field: string;
  // value: any;
  // // row: any;
  // setValue: any;
  // inHeader?: boolean;
  // refetchRow: any;
}) {
  const { normalizeString, existingViews, selectedViews, setSelectedViews, candidateViews, setCandidateViews } = useContext(ImportWizardContext) as any;

  const inHeader = false
  const [ allPossibleTags, setAllPossibleTags ] = useState([])

  useEffect( () => {
    const buff = _.isArray(selectedViews)
      ? _.flatten(selectedViews)
      : !!selectedViews
      ? [selectedViews]
      : [];

    //@ts-ignore
    setAllPossibleTags(_.chain(buff).clone().concat(_.map(existingViews,v=>_.get(v,'name'))).flatten().uniq().orderBy().value())

    setCandidateViews(_.chain(selectedViews).reduce( (r,v) => {
      if (_.chain(existingViews).values().find(o=>_.eq(v,_.get(o,'name'))).isNil().thru(o=>!o).value()) {
        _.set(r,normalizeString(v),_.chain(existingViews).values().find(o=>_.eq(v,_.get(o,'name'))).value())
      } else {
        _.set(r,normalizeString(v),{
          exists: false,
          name: v,
          fields: []
        })
      }
      return r
    },{}).value())

  },[ selectedViews, existingViews ])

  const selectedOptions = _.isArray(selectedViews)
    ? _.flatten(selectedViews)
    : !!selectedViews
    ? [selectedViews]
    : [];


  return (
    <DisplayTags
      allPossibleTags={allPossibleTags || []}
      selectedTags={selectedOptions}
      buttonStyle={{ padding: "0px" }}
      onTagsChange={async (tags: string[]) => {
        setSelectedViews(tags);
        const newAllPossibleTags = _.uniq(
          //@ts-ignore
          _.flatten(allPossibleTags.concat(tags))
        );

        setAllPossibleTags(newAllPossibleTags)
      }}
      constrainTags={inHeader ? 20 : undefined}
    />
  );
}


export default function ImportWizardUpload() {

  const { normalizePath, normalizeString, numFiles, handleNumFilesChange, fileObjects, setFileObjects, sheetSelection, setSheetSelection, viewSelection, setViewSelection, inputConfig, setInputConfig, candidateViews, activeStep, completed, setCompleted } = useContext(ImportWizardContext) as any;

  const [uploading, setUploading] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  const context = useContext(RefetchContext);
  const currentWorkspace = context?.currentWorkspace;

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: _.eq(numFiles,'single') ? 1 : 5
  });

  useEffect(() => {
    const uploadFiles = async () => {

      let formData = new FormData();
      formData.append(
        "workspaceId",
        JSON.stringify(currentWorkspace?._id)
      );

      formData.append(
        "numFiles",
        JSON.stringify(numFiles)
      );

      let filesBuff: any[] = []
      for (const f of acceptedFiles) {
        filesBuff.push({ file: f })
        formData.append("file", f);
      }

      const accessToken = await getAccessTokenSilently();

      await fetch(`${apiURL}import/upload-and-read`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      })
      .then((res) => res.json())
      .then((result) => {
        for (const f of filesBuff) {
          const workbook = _.get(result,normalizeString(_.get(f,'file.name')))
          if (!_.isNil(workbook)) {
            _.set(f,'workbook',workbook)
          }
        }

        setFileObjects(filesBuff)
        //
        // console.log({ filesBuff })
        //
        // console.log({ result })

        setUploading(false);
      })
      .catch((err) => {
        console.log({ err });

        setUploading(false);
      });

    };

    if (_.size(acceptedFiles)) {
      setUploading(true);
      uploadFiles();
    }
  }, [ acceptedFiles]);


  useEffect(() => {
    let newCompleted = _.clone(completed)

    if (_.chain(candidateViews).keys().size().gte(1).value() && !_.isNil(fileObjects) && !_.isEmpty(fileObjects) && !_.isNil(sheetSelection) && !_.isEmpty(sheetSelection) && _.chain(sheetSelection).keys().size().gt(0).value() && !_.eq(numFiles,'not-selected')) {
      if (_.eq(numFiles,"single")) {
        if (!completed[activeStep]) {
          //@ts-ignore
          const fileKey2workbookMap = _.chain(fileObjects).groupBy(o=>normalizePath(o,'file.name')).reduce((r,v,k)=>_.set(r,k,_.first(v)),{}).value()

          //@ts-ignore
          const fileKey = _.chain(fileKey2workbookMap).keys().first().value()

          //@ts-ignore
          const sheetName = _.chain(fileKey2workbookMap).get(fileKey).get(`workbook.SheetNames`,[]).find(o=>_.chain(sheetSelection).get(fileKey).eq(normalizeString(o)).value()).value()

          // const dataBuff = XLSX.utils.sheet_to_json(_.chain(fileKey2workbookMap).get(fileKey).get(`workbook.Sheets.${sheetName}`).value(), { raw: false})

          //@ts-ignore
          const dataBuff = _.chain(fileKey2workbookMap).get(fileKey).get('workbook.json').get(sheetName).value()

          const data = _.chain(dataBuff).map((d: any) => ({
            ... d,
            mercRowId: nanoid()
          })).value()

          const headers = _.chain(dataBuff).reduce((r,v,k) => {
            //@ts-ignore
            r = _.chain(r).union(_.keys(v)).value()
            return r
          },[]).value()

          setInputConfig({
            all: {
              fileKey, sheetName, data, headers
            }
          })
        }

      } else if (_.eq(numFiles,"multiple")) {
        const fileKey2workbookMap = _.chain(fileObjects).groupBy(o=>normalizePath(o,'file.name')).reduce((r,v,k)=>_.set(r,k,_.first(v)),{}).value()

        const inputConfigBuff = _.chain(viewSelection).reduce((r,v,k)=>{
          //@ts-ignore
          const sheetName = _.chain(fileKey2workbookMap).get(k).get(`workbook.SheetNames`,[]).find(o=>_.chain(sheetSelection).get(k).eq(normalizeString(o)).value()).value()

          // const data = XLSX.utils.sheet_to_json(_.chain(fileKey2workbookMap).get(k).get(`workbook.Sheets.${sheetName}`).value(), { raw: false})

          //@ts-ignore
          const data = _.chain(fileKey2workbookMap).get(fileKey).get('workbook.json').get(sheetName).value()

          const headers = _.chain(data).reduce((r,v,k) => {
            //@ts-ignore
            r = _.chain(r).union(_.keys(v)).value()
            return r
          },[]).value()

          return _.set(r,v,{ fileKey: k, sheetName: _.get(sheetSelection,k), data, headers  })
        },{}).value()

        setInputConfig(inputConfigBuff)
      }

      //@ts-ignore
      if (_.chain(candidateViews).keys().size().gte(1).value() && _.eq(numFiles,"single") && _.chain(fileObjects).size().eq(1).value() && _.chain(sheetSelection).get(normalizePath(_.chain(fileObjects).first().value(),'file.name')).isNil().thru(o=>!o).value() ) {
        newCompleted[activeStep] = true;
      } else {
        newCompleted[activeStep] = false
      }

    } else {
       newCompleted[activeStep] = false;
    }
    setCompleted(newCompleted);
  },[numFiles,fileObjects, sheetSelection, candidateViews])


  return (
    <>
      <Grid container justifyContent="flex-start" alignItems="center"  sx={ {paddingTop:"30px"}}>
        <Grid item xs={1} >
          { //@ts-ignore
            _.chain(candidateViews).keys().size().gt(0).value() && <CheckIcon
              sx={{ color: green[500] }}
            />
          }
          { //@ts-ignore
            _.chain(candidateViews).keys().size().eq(0).value() &&
            <CustomWidthTooltip title="Select lists into which data is to be imported. You can also create new lists.">
              <QuestionMarkIcon
                sx={{ color: grey[500] }}
              />
            </CustomWidthTooltip>
          }
        </Grid>
        <Grid item xs={11} container alignItems="center" >
            <Grid item xs={4}>
              <Typography variant="h6">
                 Target Lists
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <ViewSelectComponent />
            </Grid>
        </Grid>
      </Grid>
      {
        //@ts-ignore
        _.chain(candidateViews).keys().size().gte(1).value() &&
        <Grid container justifyContent="flex-start" alignItems="center"  sx={ {paddingTop:"30px"}}>
          <Grid item xs={1} >
            { //@ts-ignore
              _.chain(candidateViews).keys().size().gte(1).value() && !_.eq(numFiles,'not-selected') && <CheckIcon
                sx={{ color: green[500] }}
              />
            }
            { //@ts-ignore
              _.chain(candidateViews).keys().size().gte(1).value() && _.eq(numFiles,'not-selected') &&
              <CustomWidthTooltip title="Select number of files. Mercero currently supports import into multiple lists from one file (csv) / Excel sheet">
                <QuestionMarkIcon
                  sx={{ color: grey[500] }}
                />
              </CustomWidthTooltip>
            }
          </Grid>
          <Grid item xs={11} container alignItems="center" >
              <Grid item xs={4}>
                <Typography variant="h6">
                   Number of Files
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={numFiles}
                    onChange={handleNumFilesChange}
                  >
                    <FormControlLabel value="single" control={<Radio />} label="One" />
                    <FormControlLabel value="multiple" control={<Radio />} label="More than one" />
                  </RadioGroup>
                </FormControl>
              </Grid>
          </Grid>
        </Grid>
      }
      {
        //@ts-ignore
        _.chain(candidateViews).keys().size().gte(1).value() && _.eq(numFiles,'single') &&
        <Grid container justifyContent="flex-start" alignItems="center"  sx={ {paddingTop:"30px"}}>
          <Grid item xs={1} >
            { //@ts-ignore
              _.chain(candidateViews).keys().size().gte(1).value() &&  _.eq(numFiles,'single') && _.chain(fileObjects).size().gte(1).value() &&  <CheckIcon
                sx={{ color: green[500] }}
              />
            }
            { //@ts-ignore
              _.chain(candidateViews).keys().size().gte(1).value() &&  _.eq(numFiles,'single') && _.chain(fileObjects).size().eq(0).value() &&
              <CustomWidthTooltip title="Drag and drop data file(s) into adjoining area, or click to open file selection dialog">
                <QuestionMarkIcon
                  sx={{ color: grey[500] }}
                />
              </CustomWidthTooltip>
            }
          </Grid>
          <Grid item xs={11} container alignItems="center" >
              <Grid item xs={4}>
                <Typography variant="h6">
                   Data File
                </Typography>
              </Grid>
              {
                _.chain(fileObjects).size().eq(0).value() &&
                <Grid item xs={8}>
                  {uploading ? (
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                      style={{ minHeight: "10vh" }}
                    >
                      <Grid item>
                        <CircularProgress color="inherit" />
                      </Grid>
                    </Grid>
                  ) : (
                    <div
                      {...getRootProps({ className: "dropzone" })}
                      style={{
                        cursor: "pointer",
                        padding: "20px",
                        border: "1px dashed #333",
                      }}
                    >
                      <input {...getInputProps()} />
                      <p>
                        Drag and drop files into this area, or click to open file selection dialog
                      </p>
                    </div>
                  )}
                </Grid>
              }
              {
                _.chain(fileObjects).size().eq(1).value() &&
                <Grid container item xs={8} justifyContent="space-between" alignItems="center">
                  <Grid item xs={10}>
                    <Typography variant="body2">
                      { //@ts-ignore
                        _.chain(fileObjects).first().get('file.name').value() }
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton aria-label="delete" onClick={()=>setFileObjects([])}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              }
          </Grid>
        </Grid>
      }
      {
        // _.chain(candidateViews).keys().size().gte(1).value() && _.eq(numFiles,"single") && _.chain(fileObjects).size().eq(1).value() && <SingleFileInputConfig />
      }
      <SingleFileInputConfig />
      {
        // _.chain(candidateViews).keys().size().gt(0).value() && _.eq(numFiles,"multiple") && _.size(fileObjects)>1 && <MultipleFileInputConfig />
        _.chain(candidateViews).keys().size().gte(1).value() && _.eq(numFiles,"multiple") &&
        <Grid container justifyContent="flex-start" alignItems="center"  sx={ {paddingTop:"30px"}}>
          <Grid item xs={1} >
              <ErrorIcon
                sx={{ color: red[500] }}
              />
          </Grid>
          <Grid item xs={11} >
              <Typography variant="subtitle2">Multiple file import with relationships is currently in development and will be available soon</Typography>
          </Grid>
        </Grid>
      }
    </>
  )
}

export const SingleFileInputConfig = () =>  {

  const { normalizePath, normalizeString, fileObjects, sheetSelection, setSheetSelection, candidateViews, numFiles } = useContext(ImportWizardContext) as any;

  const handleSheetSelectionChange = (event: SelectChangeEvent) => {
    let sheetSelectionBuff = _.clone(sheetSelection)

    const name = _.get(event,'target.name')
    const value = _.get(event,'target.value')

    _.set(sheetSelectionBuff,name,value)

    setSheetSelection(sheetSelectionBuff)
  }

  useEffect(()=>{
    //@ts-ignore
    if (_.chain(fileObjects).size().eq(1).value() && _.chain(fileObjects).first().get("workbook.SheetNames",[]).size().eq(1).value()) {
      let sheetSelectionBuff = _.clone(sheetSelection)

      //@ts-ignore
      _.set(sheetSelectionBuff,normalizePath(_.chain(fileObjects).first().value(),'file.name'),normalizeString(_.chain(fileObjects).first().get("workbook.SheetNames",[]).first().value()))
      setSheetSelection(sheetSelectionBuff)
    }
  },[fileObjects])


  return (<>
  {
    //@ts-ignore
    _.chain(candidateViews).keys().size().gte(1).value() && _.eq(numFiles,"single") && _.chain(fileObjects).size().eq(1).value() &&
    <Grid container justifyContent="flex-start" alignItems="center"  sx={ {paddingTop:"30px"}}>
      <Grid item xs={1} >
        { //@ts-ignore
          _.chain(sheetSelection).get(normalizePath(_.chain(fileObjects).first().value(),'file.name')).eq(normalizeString(_.chain(fileObjects).first().get("workbook.SheetNames",[]).first().value())).value() && <CheckIcon
            sx={{ color: green[500] }}
          />
        }
        { //@ts-ignore
          _.chain(sheetSelection).get(normalizePath(_.chain(fileObjects).first().value(),'file.name')).eq(normalizeString(_.chain(fileObjects).first().get("workbook.SheetNames",[]).first().value())).thru(o=>!o).value() &&
          //@ts-ignore
          _.chain(fileObjects).first().get("workbook.SheetNames",[]).size().eq(1).value() &&
          <CustomWidthTooltip title="Please select sheet containing data to be imported">
            <QuestionMarkIcon
              sx={{ color: grey[500] }}
            />
          </CustomWidthTooltip>
        }
        { //@ts-ignore
          _.chain(sheetSelection).get(normalizePath(_.chain(fileObjects).first().value(),'file.name')).eq(normalizeString(_.chain(fileObjects).first().get("workbook.SheetNames",[]).first().value())).thru(o=>!o).value() &&
          //@ts-ignore
          _.chain(fileObjects).first().get("workbook.SheetNames",[]).size().gt(1).value() &&
          <CustomWidthTooltip title="Multiple sheets found, please select sheet containing data to be imported">
            <QuestionMarkIcon
              sx={{ color: grey[500] }}
            />
          </CustomWidthTooltip>
        }
      </Grid>
      <Grid item xs={11} container alignItems="center" >
          <Grid item xs={4}>
            <Typography variant="h6">
               Data Sheet
            </Typography>
          </Grid>

        { //@ts-ignore
          _.chain(sheetSelection).get(normalizePath(_.chain(fileObjects).first().value(),'file.name')).eq(normalizeString(_.chain(fileObjects).first().get("workbook.SheetNames",[]).first().value())).value() &&
          <Grid container item xs={8} justifyContent="space-between" alignItems="center">
            <Grid item xs={10}>
              <Typography variant="body2">
                { //@ts-ignore
                  _.chain(fileObjects).first().get("workbook.SheetNames",[]).first().value() }
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <IconButton aria-label="delete" onClick={()=>{
                let sheetSelectionBuff = _.clone(sheetSelection)

                //@ts-ignore
                _.unset(sheetSelectionBuff,normalizePath(_.chain(fileObjects).first().value(),'file.name'))
                setSheetSelection(sheetSelectionBuff)
              }}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        }
        { //@ts-ignore
          _.chain(sheetSelection).get(normalizePath(_.chain(fileObjects).first().value(),'file.name')).isNil().value() &&
          //@ts-ignore
          _.chain(fileObjects).first().get("workbook.SheetNames",[]).size().gte(1).value() &&
          <Grid item xs={8}>
            <FormControl fullWidth>
              <InputLabel id={`sheet-select-${normalizePath(_.chain(fileObjects).first().value(),'file.name')}`}>Sheet</InputLabel>
              <Select
                labelId={`sheet-select-label-${normalizePath(_.chain(fileObjects).first().value(),'file.name')}`}
                id={`sheet-select-${normalizePath(_.chain(fileObjects).first().value(),'file.name')}`}
                value={_.get(sheetSelection,normalizePath(_.chain(fileObjects).first().value(),'file.name')) ?? ""}
                label="Sheet"
                onChange={handleSheetSelectionChange}
                name={normalizePath(_.chain(fileObjects).first(),'file.name')}
              >
                {
                  // @ts-ignore
                  _.chain(fileObjects).first().get("workbook.SheetNames",[]).map((s: any) => (<MenuItem value={normalizeString(s)}>{s}</MenuItem>)).value()
                }
              </Select>
            </FormControl>
          </Grid>
        }
      </Grid>
    </Grid>
  }
  </>)
}


// export const MultipleFileInputConfig = () =>  {
//
//   const { normalizePath, normalizeString, candidateViews, numFiles, handleNumFilesChange, fileObjects, setFileObjects, sheetSelection, setSheetSelection, viewSelection, setViewSelection } = useContext(ImportWizardContext) as any;
//
//   const handleSheetSelectionChange = (event: SelectChangeEvent) => {
//     let sheetSelectionBuff = _.clone(sheetSelection)
//
//     const name = _.get(event,'target.name')
//     const value = _.get(event,'target.value')
//
//     _.set(sheetSelectionBuff,name,value)
//
//     setSheetSelection(sheetSelectionBuff)
//   }
//
//
//   const handleViewSelectionChange = (event: SelectChangeEvent) => {
//     let viewSelectionBuff = _.clone(viewSelection)
//
//     const name = _.get(event,'target.name')
//     const value = _.get(event,'target.value')
//
//     _.set(viewSelectionBuff,name,value)
//
//     setViewSelection(viewSelectionBuff)
//   }
//
//   return (
//   <>
//     <Typography variant="h6">
//       Please select the sheet in the given file and map to target view
//     </Typography>
//     { _.map(fileObjects,(o: any)=>(
//     <Grid container key={normalizePath(o,'file.name')}>
//       <Grid item xs={4}>
//         <Typography variant="body2">
//           { _.get(o,'file.name')}
//         </Typography>
//       </Grid>
//       <Grid item xs={4}>
//         <FormControl fullWidth>
//           <InputLabel id={`sheet-select-${normalizePath(o,'file.name')}`}>Sheet</InputLabel>
//           <Select
//             labelId={`sheet-select-label-${normalizePath(o,'file.name')}`}
//             id={`sheet-select-${normalizePath(o,'file.name')}`}
//             value={_.get(sheetSelection,normalizePath(o,'file.name')) ?? ""}
//             label="Sheet"
//             onChange={handleSheetSelectionChange}
//             name={normalizePath(o,'file.name')}
//           >
//             {
//               // @ts-ignore
//               _.chain(o).get("workbook.SheetNames",[]).map((s: any) => (<MenuItem value={normalizeString(s)}>{s}</MenuItem>)).value()
//             }
//           </Select>
//         </FormControl>
//       </Grid>
//       <Grid item xs={4}>
//         <FormControl fullWidth>
//           <InputLabel id={`view-select-${normalizePath(o,'file.name')}`}>View</InputLabel>
//           <Select
//             labelId={`view-select-label-${normalizePath(o,'file.name')}`}
//             id={`view-select-${normalizePath(o,'file.name')}`}
//             value={_.get(viewSelection,normalizePath(o,'file.name')) ?? ""}
//             label="View"
//             onChange={handleViewSelectionChange}
//             name={normalizePath(o,'file.name')}
//           >
//             {
//               // @ts-ignore
//               _.chain(candidateViews).transform((r,v)=>{
//                 //@ts-ignore
//                 r.push(_.get(v,'name'))
//                 return r
//                 //@ts-ignore
//               },[]).map((s: string) => (<MenuItem disabled={_.chain(viewSelection).values().intersection([normalizeString(s)]).size().value()} value={normalizeString(s)}>{s}</MenuItem>)).value()
//             }
//           </Select>
//         </FormControl>
//       </Grid>
//     </Grid>
//     ))}
//   </>)
// }
