import React, { useEffect, useState, useCallback } from "react";

import "./App.css";
import {
  Code,
  AttachFile,
  Audiotrack,
  Description,
  PictureAsPdf,
  Theaters,
  Panorama,
  BackupTable,
} from "@mui/icons-material";
import { Grid, Typography, Box, Button, IconButton } from "@mui/material";

import { useDropzone } from "react-dropzone";
import { useAuth0 } from "@auth0/auth0-react";
import { apiURL } from "./consts";

import PreviewList from "./displayFiles";

//@ts-ignore
import { FileIcon, defaultStyles } from "react-file-icon";
import SvgIcon from "@mui/material/SvgIcon";

import _ from "lodash";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

import CircularProgress from "@mui/material/CircularProgress";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export const handlePreviewIcon = (fileObject: any, classes: any) => {
  const type = fileObject?.fileType;
  const iconProps = {
    className: classes?.image,
  };

  if (type.startsWith("video/")) return <Theaters {...iconProps} />;
  if (type.startsWith("audio/")) return <Audiotrack {...iconProps} />;
  if (type.startsWith("image/"))
    return (
      <SvgIcon fontSize="large">
        <FileIcon extension="jpg" {...iconProps} {...defaultStyles.jpg} />
      </SvgIcon>
    );

  switch (type) {
    case "text/csv":
      return (
        <SvgIcon fontSize="large">
          <FileIcon extension="csv" {...iconProps} {...defaultStyles.csv} />
        </SvgIcon>
      );
    case "text/html":
      return (
        <SvgIcon fontSize="large">
          <FileIcon extension="html" {...iconProps} {...defaultStyles.html} />
        </SvgIcon>
      );
    case "text/plain":
      return (
        <SvgIcon fontSize="large">
          <FileIcon extension="txt" {...iconProps} {...defaultStyles.txt} />
        </SvgIcon>
      );
    case "application/vnd.oasis.opendocument.text":
    case "application/msword":
      return (
        <SvgIcon fontSize="large">
          <FileIcon extension="doc" {...iconProps} {...defaultStyles.doc} />
        </SvgIcon>
      );
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      // return <Description {...iconProps} />;
      return (
        <SvgIcon fontSize="large">
          <FileIcon extension="docx" {...iconProps} {...defaultStyles.docx} />
        </SvgIcon>
      );
    case "xls":
    case "application/vnd.ms-excel":
      return (
        <SvgIcon fontSize="large">
          <FileIcon extension="xls" {...iconProps} {...defaultStyles.xls} />
        </SvgIcon>
      );
    case "application/xlsx":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return (
        <SvgIcon fontSize="large">
          <FileIcon extension="xlsx" {...iconProps} {...defaultStyles.xlsx} />
        </SvgIcon>
      );

    case "application/pdf":
      return (
        <SvgIcon fontSize="large">
          <FileIcon extension="pdf" {...iconProps} {...defaultStyles.pdf} />
        </SvgIcon>
      );
    default:
      return <AttachFile {...iconProps} />;
  }
};

export default function FilesSection(props: {
  viewId: any;
  rowId: any;
  row: any;
  email: string;
}) {
  const { viewId, rowId, row, email } = props;

  const [fileObjects, setFileObjects] = useState([]);
  const [fileMetadata, setFileMetadata] = useState({});
  const [readFiles, setReadFiles] = useState(true);
  const { getAccessTokenSilently } = useAuth0();

  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [uploading, setUploading] = useState(false);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  useEffect(() => {
    const uploadFiles = async () => {
      let formData = new FormData();
      for (const f of acceptedFiles) {
        formData.append("file", f);
      }

      const accessToken = await getAccessTokenSilently();

      await fetch(`${apiURL}row/upload/${rowId}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      })
        .then((res) => res.json())
        .then((result) => {
          setReadFiles(true);
        })
        .catch((err) => {
          console.log({ err });
        });

      setUploading(false);

      handleClose();
    };

    if (_.size(acceptedFiles)) {
      setUploading(true);
      uploadFiles();
    }
  }, [rowId, getAccessTokenSilently, acceptedFiles]);

  const handleClose = () => {
    setOpenUploadModal(false);
  };

  useEffect(() => {
    const readFileMetadata = async () => {
      const accessToken = await getAccessTokenSilently();

      await fetch(`${apiURL}row/files/${rowId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          setFileMetadata(result);
        })
        .catch((err) => {
          console.log({ err });
        });

      // const contactEmail =
      //   row["Email"] ||
      //   row["email"] ||
      //   row["E-mail"] ||
      //   row["e-mail"];
      //
      // const emailRes = await fetch(
      //   `${apiURL}email/read-attachment-ids?memberEmail=${
      //     email || contactEmail
      //   }`,
      //   {
      //     headers: {
      //       Authorization: `Bearer ${accessToken}`,
      //     },
      //   }
      // );

      setReadFiles(false);
      setLoading(false);
    };

    if (readFiles) {
      setLoading(true);
      readFileMetadata();
    }
  }, [readFiles, row, email]);

  const handleRemove = useCallback(
    async (fkey: string, i: number) => {
      //@ts-ignore
      if (
        fkey ===
        _.chain(fileMetadata)
          .get("root", [])
          .filter((o: any) => !o.isDeleted)
          // @ts-ignore
          .get(`${i}.key`)
          // @ts-ignore
          .value()
      ) {
        const accessToken = await getAccessTokenSilently();
        await fetch(`${apiURL}row/delete-file/${rowId}/${fkey}`, {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((res) => res.json())
          .then((result) => {
            setFileMetadata(result);
            // setReadFiles(true)
          })
          .catch((err) => {
            console.log({ err });
          });
      }
    },
    [fileMetadata, setFileMetadata]
  );

  const rootFileObjects = _.get(fileMetadata, "root", []);
  const notesFileObjects = _.get(fileMetadata, "notes", []);
  const emailFileObjects = _.get(fileMetadata, "email", []);

  const fileMetaDataRootIsNil = _.chain(rootFileObjects).isEmpty().value();
  const fileMetaDataNotesIsNil = _.chain(notesFileObjects).isEmpty().value();
  const fileMetaDataEmailIsNil = _.chain(emailFileObjects).isEmpty().value();

  return (
    <>
      <Grid
        container
        justifyContent={"space-between"}
        sx={{ marginBottom: "10px" }}
      >
        <Grid item>
          <Typography
            style={{
              marginTop: 5,
              color: "rgb(40, 42, 48)",
              fontSize: "16px",
              fontWeight: 600,
              marginLeft: "5px",
            }}
          >
            Files
          </Typography>
        </Grid>

        <Button
          autoFocus
          startIcon={<AttachFile />}
          onClick={() => {
            setOpenUploadModal(true);
          }}
          sx={{
            float: "right",
            backgroundColor: "rgb(255, 255, 255)",
            borderRadius: "6px",
            border: "1px solid rgb(223, 225, 228)",
            boxShadow: "rgb(0 0 0 / 9%) 0px 1px 1px",
            fontSize: "14px",
            fontWeight: 600,
            color: "#333",
            textTransform: "none",
          }}
        >
          Add File
        </Button>
      </Grid>

      <BootstrapDialog
        open={openUploadModal}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        aria-describedby="customized-dialog-description"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Upload Files
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {uploading ? (
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{ minHeight: "10vh" }}
            >
              <Grid item>
                <CircularProgress color="inherit" />
              </Grid>
            </Grid>
          ) : (
            <div
              {...getRootProps({ className: "dropzone" })}
              style={{
                cursor: "pointer",
                padding: "20px",
                border: "1px dashed #333",
              }}
            >
              <input {...getInputProps()} />
              <p>
                Drag and drop files into this area, or click to choose files
                from your computer
              </p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>

      {loading && (_.isNil(fileMetadata) || _.isEmpty(fileMetadata)) ? (
        <>
          <Typography>Loading files ...</Typography>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "20vh" }}
          >
            <Grid item>
              <CircularProgress color="inherit" />
            </Grid>
          </Grid>
        </>
      ) : null}

      {!loading &&
      fileMetaDataRootIsNil &&
      fileMetaDataNotesIsNil &&
      fileMetaDataEmailIsNil ? (
        <>
          <AttachFile
            fontSize="large"
            style={{
              width: "100px",
              height: "100px",
              opacity: ".6",
              margin: "auto",
              display: "block",
              marginTop: "70px",
            }}
          />
          <Typography
            style={{
              marginBottom: 70,
              color: "rgb(40, 42, 48)",
              textAlign: "center",
            }}
          >
            No files found
          </Typography>
        </>
      ) : null}

      {!_.isEmpty(rootFileObjects) ? (
        <PreviewList
          fileObjects={_.reduce(
            rootFileObjects,
            (r, f) => {
              if (!_.get(f, "isDeleted", false)) {
                r.push(f);
              }
              return r;
            },
            []
          )}
          handleRemove={handleRemove}
          getPreviewIcon={handlePreviewIcon}
          previewGridClasses={{}}
          previewGridProps={{}}
          classes={{}}
          rowId={rowId}
        />
      ) : null}

      {!_.isEmpty(notesFileObjects) ? (
        <>
          <Grid
            container
            justifyContent={"space-between"}
            sx={{ marginBottom: "20px" }}
          >
            <Grid item>
              <Typography
                style={{
                  marginTop: 25,
                  color: "rgb(40, 42, 48)",
                  fontSize: "16px",
                  fontWeight: 600,
                  marginLeft: "5px",
                }}
              >
                Notes
              </Typography>
            </Grid>
          </Grid>
          <PreviewList
            fileObjects={notesFileObjects}
            handleRemove={null}
            getPreviewIcon={handlePreviewIcon}
            previewGridClasses={{}}
            previewGridProps={{}}
            classes={{}}
            rowId={rowId}
          />
        </>
      ) : null}

      {!_.isEmpty(emailFileObjects) ? (
        <>
          <Grid
            container
            justifyContent={"space-between"}
            sx={{ marginBottom: "20px" }}
          >
            <Grid item>
              <Typography
                style={{
                  marginTop: 25,
                  color: "rgb(40, 42, 48)",
                  fontSize: "16px",
                  fontWeight: 600,
                  marginLeft: "5px",
                }}
              >
                Email Attachments
              </Typography>
            </Grid>
          </Grid>
          <PreviewList
            fileObjects={emailFileObjects}
            handleRemove={null}
            getPreviewIcon={handlePreviewIcon}
            previewGridClasses={{}}
            previewGridProps={{}}
            classes={{}}
            rowId={rowId}
          />
        </>
      ) : null}
    </>
  );
}
