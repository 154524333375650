import InputBase from "@mui/material/InputBase";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import React, { useContext, useEffect, useState } from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useGridApiContext } from "@mui/x-data-grid-premium";
import { Checkbox, ListItemText } from "@mui/material";
import _ from "lodash";
import { RefetchContext } from "./refetchProvider";
import { useAuth0 } from "@auth0/auth0-react";
import { apiURL } from "./consts";
import { GetHeaderText, GetSubheaderText } from "./DatagridFunctions";
import useUpdateRow from "./useUpdateRow";
import { getPageTitle } from "./individualView";

export const EditRelatedCell = (props: any) => {
  const { colDef } = props;

  const { getAccessTokenSilently } = useAuth0();

  const context = useContext(RefetchContext);

  const currentWorkspace = context?.currentWorkspace;

  const viewId = colDef?.type.split("-")[1];

  const { updateRow, data, loading, error } = useUpdateRow(viewId || "");

  // const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState<any[]>([]);

  const initVal =
    props?.row?.relatedRows?.filter((row: any) => {
      return row?.merc_viewId === viewId;
    }) || [];

  const [boxValue, setBoxValue] = React.useState<any[]>(initVal || []);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>();

  const search = async (searchText: string) => {
    try {
      const accessToken = await getAccessTokenSilently();

      if (searchText.length > 2) {
        await fetch(
          `${apiURL}row?q=${searchText}&workspaceId=${currentWorkspace._id}&viewId=${viewId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
          .then((res) => res.json())
          .then((result) => {
            const mappedResults =
              [
                ...result.map((r: any) => {
                  return {
                    _id: r._id,
                    merc_viewId: r.viewId,
                    row: {
                      ...JSON.parse(r.rowObject),
                      _id: r._id,
                      merc_viewId: r.viewId,
                    },
                    relatedRows: r.relatedRows,
                    columns: r.columns,
                  };
                }),
              ] || [];
            setSearchResults(mappedResults);
          });
      } else {
        setSearchResults([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const debouncedSearch = _.debounce(search, 500);

  // useEffect(() => {
  //   if (!searchText) {
  //     setSearchResults([]);
  //     return;
  //   } else {
  //     debouncedSearch();
  //   }
  // }, [searchText, getAccessTokenSilently, debouncedSearch]);

  const handleRef = (el: any) => {
    setAnchorEl(el);
  };

  return (
    <div>
      <div
        ref={handleRef}
        id={"divRef"}
        style={
          {
            // height: 1,
            // width: colDef.computedWidth,
            // display: "block",
            // position: "absolute",
            // top: 0,
          }
        }
      />
      {anchorEl && (
        <Popper
          open
          anchorEl={anchorEl}
          placement="top-start"
          sx={{
            "& .MuiPaper-root": {
              minWidth: colDef.computedWidth,
              padding: "0px",
              border: "0px",
              boxShadow: "none",
            },
          }}
        >
          <Paper
            elevation={1}
            sx={{
              p: 1,
              minWidth: colDef.computedWidth,
            }}
          >
            <Autocomplete
              multiple
              disableCloseOnSelect
              id="tags-standard"
              open
              options={searchResults?.length > 0 ? searchResults : initVal}
              sx={{
                border: "none",
                outline: "none",
                "& .MuiChip-root": {
                  display: "none",
                },
                "& .MuiPaper-root": {
                  padding: "0px",
                },
                "& .MuiAutocomplete-option": {
                  padding: "0px",
                },
              }}
              value={boxValue}
              isOptionEqualToValue={(option, value) => {
                return option?._id === value?._id;
              }}
              onChange={async (event: any, newValue: any | null) => {
                event.preventDefault();
                // api.setEditCellValue({ id, field, value: newValue }, event);

                const otherRelationships = props?.row?.relatedRows?.filter(
                  (row: any) => {
                    return row?.merc_viewId !== viewId;
                  }
                );

                let newRelatedRows = newValue;

                if (!!otherRelationships) {
                  newRelatedRows = newValue.concat(otherRelationships);
                }

                await updateRow({
                  variables: {
                    set: {
                      relatedRows: {
                        rows: {
                          link: newRelatedRows.map((option: any) => option._id),
                        },
                      },
                    },
                    query: {
                      _id: props?.row?._id,
                    },
                  },
                });

                newRelatedRows.forEach(async (r: any) => {
                  await updateRow({
                    variables: {
                      set: {
                        relatedRows: {
                          rows: {
                            // Need to add the current rows to the link
                            link: r.relatedRows?.rows?.concat([
                              props?.row?._id,
                            ]) || [props?.row?._id],
                          },
                        },
                      },
                      query: {
                        _id: r._id,
                      },
                    },
                  });
                });
                setBoxValue(newValue);
              }}
              getOptionLabel={(option) => {
                if (!!option?.row) {
                  return GetHeaderText(option?.row, option?.columns);
                }
                return GetHeaderText(option, []);
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option?.row?._id}>
                  <Checkbox
                    checked={selected}
                    size="small"
                    sx={{
                      opacity: 0.25,
                      "&:hover": {
                        opacity: 1,
                      },
                      padding: "8px",
                    }}
                  />
                  <ListItemText
                    // primary={GetHeaderText(option?.row, option?.columns)}
                    primary={
                      !!option?.row
                        ? GetHeaderText(option?.row, option?.columns)
                        : getPageTitle(option)
                    }
                    // primary={() => {
                    //   if (!!option?.row) {
                    //     return (<>{GetHeaderText(option?.row, option?.columns)}</>);
                    //   }
                    //   return (<>{GetHeaderText(option, [])}</>);
                    // }}
                    // @ts-ignore
                    secondary={
                      !!option?.row
                        ? GetSubheaderText(option?.row, option?.columns)
                        : GetSubheaderText(option, [])
                    }
                    sx={{
                      "& .MuiTypography-root": {
                        fontSize: "12px",
                      },
                    }}
                  />
                </li>
              )}
              onInputChange={(_, value) => debouncedSearch(value)}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  // onChange={(e) => {
                  //   setSearchText(e.target.value);
                  //   debouncedSearch();
                  // }}
                  autoFocus
                  // value={searchText}
                  //ref={textRef}
                  placeholder="Type"
                  sx={{
                    width: "100%",
                    padding: "0px",
                    "& .MuiInputBase-root": {
                      padding: "0px",
                      borderRadius: "3px 3px 0px 0px",
                      outline: "none",
                    },
                  }}
                />
              )}
            />
          </Paper>
        </Popper>
      )}
    </div>
  );
};
