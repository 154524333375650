import { useState, createContext, useEffect, useContext } from "react";
import _ from "lodash";
import { io } from "socket.io-client";
import { ApolloLookupContext } from "./apolloLookupProvider";
import { useAuth0 } from "@auth0/auth0-react";
import { skipTraceSocketIOUrl } from './consts'

export const SkipTraceSocketIOContext = createContext({});

// const socketIOUrl = 'http://localhost:3002/collaboration'


//@ts-ignore
const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

const SkipTraceSocketIOProvider = ({ children }: any) => {
  const [socket, setSocket] = useState<any>(null);
  const { getAccessTokenSilently } = useAuth0();

  const { setApolloResponse } = useContext(ApolloLookupContext) as any;;

  useEffect(() => {
    const initSocket = async () => {
      function onUpdateApolloData(payload: any) {
        console.log('message from skip trace socket io server')
        console.log({ payload })
        setApolloResponse(payload)
      }


      const accessToken = await getAccessTokenSilently();
      if (!_.isNil(accessToken) && _.isNil(socket)) {
        const s = io(skipTraceSocketIOUrl, {
          auth: { token: `Bearer ${accessToken}` },
          transports: ["websocket", "polling"], // use WebSocket first, if available
        });

        s.on("connect", async () => {
          await sleep(1000);
          setSocket(s);
        });

        s.on("update-apollo-data", onUpdateApolloData);

        return () => {
          s.off("update-apollo-data", onUpdateApolloData);
        };
      }
    };
    initSocket();
  }, []);

  return (
    <SkipTraceSocketIOContext.Provider value={{ socket }}>
      {children}
    </SkipTraceSocketIOContext.Provider>
  );
};

export default SkipTraceSocketIOProvider;
