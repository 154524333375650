import { useEffect, useState, useContext } from "react";
import * as React from 'react';

import {

  Box,
  Button,
  Grid,
  Typography,
  FormControl,
  FormControlLabel,
  FormLabel,
  Select,
  MenuItem,
  InputLabel,
  ListSubheader
} from "@mui/material";
import { SelectChangeEvent } from '@mui/material/Select';
import CheckIcon from '@mui/icons-material/Check';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Checkbox from '@mui/material/Checkbox';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import CloseIcon from "@mui/icons-material/Close";
import _ from 'lodash'
import { ImportWizardContext } from "./importWizardProvider";
import { green } from '@mui/material/colors';
import Icon from '@mui/material/Icon';



interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


// export const ColumnTable = ({view } : any) =>  {
//
//   const { normalizePath, normalizeString, numFiles, handleNumFilesChange, fileObjects, setFileObjects, sheetSelection, setSheetSelection, viewSelection, setViewSelection, inputConfig, setInputConfig } = useContext(ImportWizardContext) as any;
//
//   return (<TableContainer component={Paper}>
//       <Table sx={{ minWidth: 650 }} aria-label="simple table">
//         <TableHead>
//           <TableRow>
//             <TableCell>Column Header From File</TableCell>
//             <TableCell align="right">Preview Information</TableCell>
//             <TableCell align="right">Mapped</TableCell>
//             <TableCell align="right">Import As</TableCell>
//             <TableCell align="right">Mercero Column Header</TableCell>
//             <TableCell align="right">Manage Existing Values</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {rows.map((row) => (
//             <TableRow
//               key={row.name}
//               sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
//             >
//               <TableCell component="th" scope="row">
//                 {row.name}
//               </TableCell>
//               <TableCell align="right">{row.calories}</TableCell>
//               <TableCell align="right">
//                 <Icon
//                   baseClassName="fas"
//                   className="fa-plus-circle"
//                   sx={{ color: green[500] }}
//                 />
//               </TableCell>
//               <TableCell align="right">
//                 <FormControl fullWidth>
//                   <InputLabel id={`import-select-${normalizeString(view)}`}>Import As</InputLabel>
//                   <Select
//                     labelId={`import-select-label-${normalizeString(view)}`}
//                     id={`import-select-${normalizeString(view)}`}
//                     value={_.get(sheetSelection,normalizeString(view)) ?? ""}
//                     label="Import As"
//                     onChange={()=>{}}
//                     name={normalizeString(view)}
//                   >
//                     {
//                       // @ts-ignore
//                       _.chain(['First Name','Last Name', 'Id']).map((s: any) => (<MenuItem value={normalizeString(s)}>{s}</MenuItem>)).value()
//                     }
//                   </Select>
//                 </FormControl>
//
//
//               </TableCell>
//               <TableCell align="right">
//               <FormControl fullWidth>
//                 <InputLabel id={`sheet-select-${normalizeString(view)}`}>Mercero Column Header</InputLabel>
//                 <Select
//                   labelId={`sheet-select-label-${normalizeString(view)}`}
//                   id={`sheet-select-${normalizeString(view)}`}
//                   value={_.get(sheetSelection,normalizeString(view)) ?? ""}
//                   label="Mercero Column"
//                   onChange={()=>{}}
//                   name={normalizeString(view)}
//                 >
//                   {
//                     // @ts-ignore
//                     _.chain(['First Name','Last Name', 'Id']).map((s: any) => (<MenuItem value={normalizeString(s)}>{s}</MenuItem>)).value()
//                   }
//                 </Select>
//               </FormControl>
//
//               </TableCell>
//               <TableCell align="right">
//                 <FormControlLabel
//                   label="Don't overwrite"
//                   control={<Checkbox checked={false} onChange={()=>{}} />}
//                 />
//               </TableCell>
//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>
//     </TableContainer>)
// }


export const ColumnTableAll = ({view } : any) =>  {

  const { sheetSelection, normalizeString, candidateViews, inputConfig, setInputConfig } = useContext(ImportWizardContext) as any;

  const { data, headers } = _.get(inputConfig,'all',{ data: [], headers: []})

  const handleViewSelectionChange = (event: SelectChangeEvent) => {
    // @ts-ignore
    let columnMapBuff = _.chain(inputConfig).get('all').get('columnMap',{}).clone().value()

    const name = _.get(event,'target.name')
    const value = _.get(event,'target.value')

    _.unset(columnMapBuff,_.chain(name).split('.').first().value())

    if (_.eq(value,'merc_none')) {
      _.unset(columnMapBuff,name)
    } else {
      _.set(columnMapBuff,name,value)
    }

    let inputConfigBuff = _.clone(inputConfig)
    _.set(inputConfigBuff,'all.columnMap',columnMapBuff)

    setInputConfig(inputConfigBuff)
  }

  const handleFieldSelectionChange = (event: SelectChangeEvent) => {
    // @ts-ignore
    let columnMapBuff =   _.chain(inputConfig).get('all').get('columnMap').clone().value()

    const name = _.get(event,'target.name')
    const value = _.get(event,'target.value')

    _.unset(columnMapBuff,`${_.chain(name).split('.').first().value()}.field`)
    _.unset(columnMapBuff,`${_.chain(name).split('.').first().value()}.isKeyField`)

    if (_.eq(value,'merc_none')) {
      _.unset(columnMapBuff,name)
    } else {
      _.set(columnMapBuff,name,value)
    }

    let inputConfigBuff = _.clone(inputConfig)
    _.set(inputConfigBuff,'all.columnMap',columnMapBuff)

    setInputConfig(inputConfigBuff)
  }

  const handleIsKeyFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    // @ts-ignore
    let columnMapBuff = _.chain(inputConfig).get('all').get('columnMap').clone().value()

    const name = _.get(event,'target.name')
    const value = _.get(event,'target.checked')

    _.set(columnMapBuff,name,value)

    let inputConfigBuff = _.clone(inputConfig)
    _.set(inputConfigBuff,'all.columnMap',columnMapBuff)

    setInputConfig(inputConfigBuff)
  };


  return (<Paper sx={{ width: '100%'}}>
    <TableContainer sx={{ height: '100%' }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Mapped</TableCell>
            <TableCell align="left">Column Header From File</TableCell>
            <TableCell align="left">Preview Information</TableCell>
            <TableCell align="left">Target List</TableCell>
            <TableCell align="left">Target Column Header</TableCell>
            <TableCell align="left">Is Key Field</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {headers.map((h: any) => (
            <TableRow
              key={normalizeString(h)}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">
                {
                  //@ts-ignore
                  _.chain(inputConfig).get('all').get('columnMap').get(`${normalizeString(h)}.view`).isNil().thru(o=>!o).value() && _.chain(inputConfig).get('all').get('columnMap').get(`${normalizeString(h)}.field`).isNil().thru(o=>!o).value() &&
                <CheckIcon
                  sx={{ color: green[500] }}
                />
                }
              </TableCell>
              <TableCell component="th" scope="row">
                {h}
              </TableCell>
              <TableCell align="left">{
                //@ts-ignore
                _.chain(data).sampleSize(3).map(o=>_.get(o,h)).join(', ').value()}</TableCell>

              <TableCell align="right">
                <FormControl fullWidth>
                  <InputLabel id={`import-select-view-${normalizeString(h)}`}>Target List</InputLabel>
                  <Select
                    labelId={`import-select-view-label-${normalizeString(h)}`}
                    id={`import-select-view-${normalizeString(h)}`}
                    // @ts-ignore
                    value={_.chain(inputConfig).get('all').get('columnMap').get(`${normalizeString(h)}.view`).value() ?? ""}
                    label="Target List"
                    onChange={handleViewSelectionChange}
                    name={`${normalizeString(h)}.view`}
                  >
                    <MenuItem value="merc_none">
                      <em>None</em>
                    </MenuItem>
                    {
                      // @ts-ignore
                      _.chain(candidateViews)
                      .transform((r,v)=>{
                        //@ts-ignore
                        r.push(_.get(v,'name'))
                        return r
                      },[])
                      .map((s: any) => (<MenuItem value={normalizeString(s)}>{s}</MenuItem>)).value()
                    }
                  </Select>
                </FormControl>


              </TableCell>
              <TableCell align="right">
              <FormControl fullWidth>
                <InputLabel id={`import-select-field-${normalizeString(h)}`}>Target Column Header</InputLabel>
                <Select
                  labelId={`import-select-field-label-${normalizeString(h)}`}
                  id={`import-select-field-${normalizeString(h)}`}
                  // @ts-ignore
                  value={_.chain(inputConfig).get('all').get('columnMap').get(`${normalizeString(h)}.field`).value() ?? ""}
                  label="Target Column"
                  onChange={handleFieldSelectionChange}
                  name={`${normalizeString(h)}.field`}
                  disabled={ //@ts-ignore
                    _.chain(inputConfig).get('all').get('columnMap').get(`${normalizeString(h)}.view`).isNil().value()}
                >
                  <MenuItem value="merc_none">
                    <em>None</em>
                  </MenuItem>
                  { //@ts-ignore
                    _.chain(candidateViews).get(_.chain(inputConfig).get('all').get('columnMap').get(`${normalizeString(h)}.view`).value()).get('fields',[]).size().gt(0).value() &&
                    <ListSubheader>Existing Fields</ListSubheader>
                  }
                    {
                      // @ts-ignore
                      _.chain(candidateViews).get(_.chain(inputConfig).get('all').get('columnMap').get(`${normalizeString(h)}.view`).value()).get('fields',[]).map((s: any) => (<MenuItem
                      // @ts-ignore
                      disabled={_.chain(inputConfig).get('all').get('columnMap').reduce( (r,v) => {
                        r.push(_.get(v,'field'))
                        return r
                      },[]).intersection([`merc_${normalizeString(s)}`]).size().gt(0).value()}
                      value={`merc_${normalizeString(s)}`}>{s}</MenuItem>)).value()
                    }
                    <ListSubheader>New Fields</ListSubheader>
                    {
                      // @ts-ignore
                      _.chain(headers).reduce( (r: any,v: any) => {
                        // //@ts-ignore
                        // const existingFields = _.chain(candidateViews).reduce((r0: any, v0: any, k0: any) => {
                        //   r0.push(_.get(v0,'fields'))
                        //   return r0
                        //   //@ts-ignore
                        // },[]).flatten().uniq().orderBy().value()
                        //@ts-ignore
                        const existingFields = _.chain(candidateViews).get(_.chain(inputConfig).get('all').get('columnMap').get(`${normalizeString(h)}.view`).value()).get('fields',[]).value()
                        //@ts-ignore
                        if (!_.includes(existingFields,v)) {
                          r.push(v)
                        }
                        return r
                        //@ts-ignore
                      },[]).map((s: any) => (<MenuItem
                      // @ts-ignore
                      disabled={_.chain(inputConfig).get('all').get('columnMap').reduce( (r,v) => {
                        r.push(_.get(v,'field'))
                        return r
                      },[]).intersection([normalizeString(s)]).size().gt(0).value()}
                      value={normalizeString(s)}>{s}</MenuItem>)).value()
                    }

                    <ListSubheader>Other Fields</ListSubheader>
                    <MenuItem value="merc_notes">
                        <em>Notes</em>
                    </MenuItem>
                </Select>
              </FormControl>

              </TableCell>
              <TableCell align="right">
                { //@ts-ignore
                  !_.chain(inputConfig).get('all').get('columnMap').get(`${normalizeString(h)}.field`).eq('merc_notes').value() && <FormControlLabel
                    label=""
                    // @ts-ignore
                    control={<Checkbox
                      disabled={ //@ts-ignore
                        _.chain(inputConfig).get('all').get('columnMap').get(`${normalizeString(h)}.field`).isNil().value()}
                      name={`${normalizeString(h)}.isKeyField`}
                      checked={ //@ts-ignore
                        _.chain(inputConfig).get('all').get('columnMap').get(`${normalizeString(h)}.isKeyField`, false).value() }
                      onChange={handleIsKeyFieldChange} />}
                  />
                }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Paper>)
}


export default function ImportWizardColumnMap() {
  // const [value, setValue] = useState(0);

    const { normalizePath, normalizeString, numFiles, handleNumFilesChange, fileObjects, setFileObjects, sheetSelection, setSheetSelection, viewSelection, setViewSelection, inputConfig, setInputConfig, activeStep, completed, setCompleted } = useContext(ImportWizardContext) as any;

  // const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  //   setValue(newValue);
  // };

  useEffect(() => {
    if (_.eq(activeStep,1)) {
      let newCompleted = _.clone(completed);
      //@ts-ignore
      newCompleted[activeStep] = _.chain(inputConfig).get('all.columnMap').keys().size().gt(0).value() &&  _.chain(inputConfig).get('all.headers').map(h=>{
        //@ts-ignore
        return _.chain(inputConfig).get('all').get('columnMap').get(`${normalizeString(h)}.view`).isNil().thru(o=>!o).value() && _.chain(inputConfig).get('all').get('columnMap').get(`${normalizeString(h)}.field`).isNil().thru(o=>!o).value()
      }).some((o: any)=>o).value()

      setCompleted(newCompleted);
    }

  },[inputConfig, activeStep])


  return (

    <>
    {
      _.eq(numFiles,'single') &&  <ColumnTableAll />
    }
    {
      // _.eq(numFiles,'multiple') && (
      //   <Box sx={{ width: '100%' }}>
      //     <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      //       <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
      //         <Tab label="Companies" {...a11yProps(0)} />
      //         <Tab label="Contacts" {...a11yProps(1)} />
      //         <Tab label="Properties" {...a11yProps(2)} />
      //       </Tabs>
      //     </Box>
      //     <CustomTabPanel value={value} index={0}>
      //       <ColumnTable view="Companies"/>
      //     </CustomTabPanel>
      //     <CustomTabPanel value={value} index={1}>
      //       <ColumnTable view="Contacts"/>
      //     </CustomTabPanel>
      //     <CustomTabPanel value={value} index={2}>
      //       <ColumnTable view="Properties"/>
      //     </CustomTabPanel>
      //   </Box>
      // )
    }
    </>

    )
}
