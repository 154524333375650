import { useEffect, useContext } from "react";
import { useMutation } from "@apollo/client";
import { GET_TODOS, CREATE_TODO_MUTATION, UPDATE_ONE_TODO } from "./graphql-ops";
import { SocketIOContext } from './socketIOProvider'
import _ from 'lodash'
import { useAuth0 } from "@auth0/auth0-react";

const useUpsertTodo = () => {
  const { socket } = useContext(SocketIOContext) as any;

  const { user } = useAuth0();

  const userQuery = { userId: { auth0Sub: user?.sub }, isDeleted: false };

  const updateTodoCache = (cache: any, upsertedTodo: any) => {
    const workspaceId = _.get(upsertedTodo,'workspaceId')
    const rowId = _.get(upsertedTodo,'rowId._id')

    if (!_.isNil(upsertedTodo)) {

      const upsertedTodoId = _.chain(upsertedTodo).get('_id').value();
      const isUpdatedTodoDeleted = _.chain(upsertedTodo).get('isDeleted',false).value()

      const cacheId = cache.identify(upsertedTodo); // Get cache ID for the newly created todo

      const updateCache = (payload: any) => {
        const { query, newTodo } = payload

        const newTodoId = _.chain(newTodo).get('_id').value();

        // Read the current cache for the GET_TODOS query
        const existingTodos = cache.readQuery({
          query: GET_TODOS,
          variables: { query },
        });

        let upsertedTodos = [ newTodo ]

        // @ts-ignore
        if (existingTodos && existingTodos.todos) {
          // Add the new todo to the cached list
          //@ts-ignore
          const isUpdatedTodo = _.chain(existingTodos).get('todos',[]).filter((todo: any) => _.chain(todo).get('_id').value() === newTodoId).size().eq(1).value()

          upsertedTodos = _.chain(existingTodos).get('todos',[]).value()

          if (isUpdatedTodo) {
            if (isUpdatedTodoDeleted) {
              // @ts-ignore
              upsertedTodos = existingTodos.todos.filter((todo: any) => _.chain(todo).get('_id').value() !== newTodoId);
            }
          } else {
            // @ts-ignore
            upsertedTodos = [...upsertedTodos, newTodo];
          }
        }

        // Write the updated list back to cache
        cache.writeQuery({
          query: GET_TODOS,
          variables: { query },
          data: {
            todos: upsertedTodos,
          },
        });
      }

      updateCache({ query: { workspaceId, isDeleted: false }, newTodo: upsertedTodo})

      updateCache({ query: userQuery, newTodo: upsertedTodo})

      if (!_.isNil(rowId)) {
        updateCache({ query: { rowId: { _id: rowId }, isDeleted: false }, newTodo: upsertedTodo})
      }
    }
  }


  const [updateTodo, updateTodoProps] = useMutation(UPDATE_ONE_TODO, {
    update(cache, { data }) {
      const updatedTodoId = data?.updateOneTodo?._id;

      updateTodoCache(cache, data?.updateOneTodo)

      socket.emit('update-todo', updatedTodoId);
    },
  });

  const [createTodo, createTodoProps] = useMutation(CREATE_TODO_MUTATION, {
    update(cache, { data }) {
      const updatedTodoId = data?.insertOneTodo?._id;

      updateTodoCache(cache, data?.insertOneTodo)

      socket.emit('update-todo', updatedTodoId);
    }
  });

  return { updateTodoCache, updateTodo, updateTodoProps, createTodo, createTodoProps };
};

export default useUpsertTodo;
