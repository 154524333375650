import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Chip,
  Drawer,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Paper,
  Select,
  TextareaAutosize,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { CustomButton } from "./PersonalDetails";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import {
  DataGridPremium,
  GridRenderCellParams,
} from "@mui/x-data-grid-premium";
import { formatPhoneNumber, stripPhoneNumber } from "./DatagridFunctions";
import { useQuery } from "@apollo/client";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { GET_CONTACTS_QUERY } from "./v2-gql";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import CloseIcon from "@mui/icons-material/Close";
import { CustomChip } from "./individualView";
import { ParcelMap } from "./v2Utils";
import { WorkspaceContext } from "./v2workspaceProvider";

interface RowData {
  id: number;
  propertyAddress: string;
  assessorOwner: string;
  ownerName: string;
  ownerPhone: string;
  ownerEmail: string;
  ownerMailingAddress: string;
}

const CustomSelect = styled(Select)(({ theme }) => ({
  "& .MuiSelect-select": {
    padding: "10px 14px",
    borderRadius: theme.shape.borderRadius,
    border: "none", // Customize the border color as needed
    backgroundColor: "#fff", // Background color
    "&:focus": {
      borderRadius: theme.shape.borderRadius, // Keeps the borderRadius on focus
      borderColor: "#ccc",
      backgroundColor: "#fff",
    },
  },
  "& .MuiSelect-icon": {
    color: "#000", // Change the icon color if needed
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none", // Removes the outline
  },
}));

export function PhoneSelect(props: any) {
  const { phoneNumbers } = props;

  const [open, setOpen] = useState(false);
  const [selectedPhone, setSelectedPhone] = useState(phoneNumbers?.[0]);

  useEffect(() => {
    const sortedPhones = [...phoneNumbers]?.sort((a: any, b: any) => {
      if (a.status === "good" && b.status !== "good") {
        return -1;
      }
      if (a.status !== "good" && b.status === "good") {
        return 1;
      }
      return 0;
    });
    setSelectedPhone(sortedPhones?.[0]);
  }, [phoneNumbers]);

  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleOpen = (event: any) => {
    // Prevent the link from triggering when the icon is clicked
    event.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!!!phoneNumbers?.[0]) {
    return <></>;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          alignContent: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Button
          href={`tel:${stripPhoneNumber(selectedPhone?.phoneNumber)}`}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
          ref={anchorRef}
        >
          <PhoneIcon
            sx={
              selectedPhone?.status === "good"
                ? { fontSize: "18px", color: "green", marginRight: "8px" }
                : selectedPhone?.status === "bad"
                ? { fontSize: "18px", color: "red", marginRight: "8px" }
                : { fontSize: "18px", marginRight: "8px" }
            }
          />
          {formatPhoneNumber(selectedPhone?.phoneNumber)}
        </Button>
        <IconButton onClick={handleOpen}>
          <ArrowDropDownIcon />
        </IconButton>
      </div>

      <Select
        open={open}
        onClose={handleClose}
        MenuProps={{
          anchorEl: anchorRef.current, // Anchor the menu to the button
        }}
        // MenuProps={{ anchorEl: null }} // Detaches the menu from the button component
        value={phoneNumbers?.phoneNumber}
        sx={{ display: "none" }} // Hide the select input itself
        onChange={() => {}} // You can handle changes here if necessary
      >
        {[...phoneNumbers]
          ?.sort((a: any, b: any) => {
            if (a.status === "good" && b.status !== "good") {
              return -1;
            }
            if (a.status !== "good" && b.status === "good") {
              return 1;
            }
            return 0;
          })
          ?.map((phone: any) => (
            <MenuItem
              key={phone.phoneNumber}
              value={phone.phoneNumber}
              onClick={() => {
                setSelectedPhone(phone);
                handleClose();
              }}
            >
              <a href={`tel:${stripPhoneNumber(phone.phoneNumber)}`}>
                <PhoneIcon
                  sx={
                    phone?.status === "good"
                      ? { fontSize: "18px", color: "green", marginRight: "8px" }
                      : phone?.status === "bad"
                      ? { fontSize: "18px", color: "red", marginRight: "8px" }
                      : { fontSize: "18px", marginRight: "8px" }
                  }
                />
                {formatPhoneNumber(phone.phoneNumber)}
              </a>
            </MenuItem>
          ))}
      </Select>
    </>
  );
}

export function EmailSelect(props: any) {
  const { emails } = props;

  const [open, setOpen] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState(emails?.[0]);

  useEffect(() => {
    const sortedEmails = [...emails]?.sort((a: any, b: any) => {
      if (a.status === "good" && b.status !== "good") {
        return -1;
      }
      if (a.status !== "good" && b.status === "good") {
        return 1;
      }
      return 0;
    });

    setSelectedEmail(sortedEmails?.[0]);
  }, [emails]);

  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleOpen = (event: any) => {
    // Prevent the link from triggering when the icon is clicked
    event.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!!!emails?.[0]) {
    return <></>;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          alignContent: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Button
          href={`mailto:${selectedEmail?.emailAddress}`}
          sx={{
            display: "flex",
            alignItems: "center",
            textTransform: "none",
          }}
          ref={anchorRef}
        >
          <EmailIcon
            sx={
              selectedEmail?.status === "good"
                ? { fontSize: "18px", color: "green", marginRight: "8px" }
                : selectedEmail?.status === "bad"
                ? { fontSize: "18px", color: "red", marginRight: "8px" }
                : { fontSize: "18px", marginRight: "8px" }
            }
          />
          <span
            style={{
              maxWidth: "150px",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {selectedEmail?.emailAddress?.toLowerCase()}
          </span>
        </Button>
        <IconButton onClick={handleOpen}>
          <ArrowDropDownIcon />
        </IconButton>
      </div>

      <Select
        open={open}
        onClose={handleClose}
        MenuProps={{
          anchorEl: anchorRef.current, // Anchor the menu to the button
        }}
        // MenuProps={{ anchorEl: null }} // Detaches the menu from the button component
        value={selectedEmail?.emailAddress}
        sx={{ display: "none" }} // Hide the select input itself
        onChange={() => {}} // You can handle changes here if necessary
      >
        {[...emails]
          ?.sort((a: any, b: any) => {
            if (a.status === "good" && b.status !== "good") {
              return -1;
            }
            if (a.status !== "good" && b.status === "good") {
              return 1;
            }
            return 0;
          })
          ?.map((email: any) => (
            <MenuItem
              key={email.emailAddress}
              value={email.emailAddress}
              onClick={() => {
                setSelectedEmail(email);
                handleClose();
              }}
            >
              <a href={`mailto:${email?.emailAddress}`}>
                <EmailIcon
                  sx={
                    email?.status === "good"
                      ? { fontSize: "18px", color: "green", marginRight: "8px" }
                      : email?.status === "bad"
                      ? { fontSize: "18px", color: "red", marginRight: "8px" }
                      : { fontSize: "18px", marginRight: "8px" }
                  }
                />
                {email?.emailAddress?.toLowerCase()}
              </a>
            </MenuItem>
          ))}
      </Select>
    </>
  );
}

const getColumns = (openSidebar: any) => [
  //   {
  //     field: "propertyAddress",
  //     headerName: "Property Address",
  //     width: 200,
  //     renderCell: (params: GridRenderCellParams) => {
  //       return (
  //         <Link
  //           to={`${params.id}`}
  //           style={{ color: "#0000EE" }}
  //           state={{ row: params?.row }}
  //         >
  //           {params.value}
  //         </Link>
  //       );
  //     },
  //   },
  //   { field: "assessorOwner", headerName: "Assessor Owner'", width: 200 },
  {
    field: "name",
    headerName: "Contact",
    width: 275,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Link
            to={`contact/${params.id}`}
            style={{ color: "#0000EE", textTransform: "capitalize" }}
            state={{ row: params?.row }}
          >
            {params?.value?.toLowerCase()}
            {/* <span style={{ color: "#333" }}>CEO - Mercero</span> */}
          </Link>
          <Typography variant={"body2"}>{params?.row?.title}</Typography>
        </div>
      );
    },
  },
  {
    field: "phoneNumbers",
    headerName: "Phone",
    width: 250,
    renderCell: (params: GridRenderCellParams) => {
      if (!!params.value && params.value.length > 0) {
        return <PhoneSelect phoneNumbers={params.value} />;
      }

      return <></>;
    },
  },
  {
    field: "emails",
    headerName: "Email",
    width: 250,
    renderCell: (params: GridRenderCellParams) => {
      if (!!params.value && params.value.length > 0) {
        return <EmailSelect emails={params.value} />;
      }

      return <></>;
    },
  },
  {
    field: "ownerMailingAddress",
    headerName: "Mailing Address",
    width: 200,
  },
  {
    field: "tags",
    headerName: "Tags",
    width: 200,
    renderCell: (params: GridRenderCellParams) => {
      return params?.value?.map((tag: any) => {
        return (
          <Chip
            variant="outlined"
            size="small"
            label={tag}
            sx={{ fontSize: "12px", marginRight: "8px" }}
          />
        );
      });
    },
  },
  {
    field: "properties",
    headerName: "Properties",
    width: 600,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <div style={{ display: "flex", gap: "4px" }}>
          {params?.value?.map((prop: any, index: number) => {
            return (
              <CustomChip
                label={prop?.address}
                onClick={() =>
                  openSidebar({ properties: params?.value, index })
                }
              />
            );
          })}
        </div>
      );
    },
  },
];

const drawerWidth = 350;

export default function ContactSection() {
  const { user, getAccessTokenSilently } = useAuth0();

  const [filteredRows, setFilteredRows] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [openProperties, setOpenProperties] = useState<any>(undefined);

  const workspaceContext = useContext(WorkspaceContext);

  const {
    data,
    loading: loadingContacts,
    error,
  } = useQuery(GET_CONTACTS_QUERY, {
    variables: {
      query: {
        workspaceId: workspaceContext?.selectedWorkspace?._id,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value.toLowerCase();
    const newFilteredRows = data?.getContacts?.filter((row: any) => {
      return row.name?.toLowerCase().includes(searchValue);
    });
    setFilteredRows(newFilteredRows);
  };

  useEffect(() => {
    if (data) {
      setFilteredRows([...data?.getContacts]?.reverse());
    }
  }, [data]);

  if (loadingContacts) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{
          width: "90%",
          //   maxWidth: "1048px",
          margin: "auto",
          marginTop: "12px",
          backgroundColor: "white",
          padding: "24px",
          borderRadius: "12px",
        }}
      >
        <Grid
          xs={6}
          sx={{ display: "flex", flexDirection: "row", gap: "12px" }}
        >
          <Paper
            // component="form"
            sx={{
              p: "0px",
              display: "flex",
              alignItems: "center",
              width: "250px !important",
              boxShadow: "none",
              border: "1px solid #d1d1d1",
              backgroundColor: "#f7f9fa",
            }}
          >
            <div style={{ padding: "4px 8px", display: "flex" }}>
              <SearchIcon sx={{ fontSize: "18px", color: "#2b70ef" }} />
            </div>
            <InputBase
              sx={{ ml: 1, flex: 1, fontSize: "14px" }}
              placeholder="Search Contacts"
              inputProps={{ "aria-label": "search contacts" }}
              onChange={handleSearch} // Listen for changes here
            />
            {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
          </Paper>
          <Tooltip title="Filter Contacts">
            <IconButton
              type="button"
              sx={{ p: "4px" }}
              aria-label="filter"
              onClick={() => {}}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid
          xs={6}
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "12px",
            justifyContent: "flex-end",
          }}
        >
          <CustomButton
            sx={{
              fontSize: "14px",
              padding: "4px 8px",
            }}
          >
            <AddIcon sx={{ fontSize: "20px" }} />
            Add Contact
          </CustomButton>
        </Grid>
        <Grid xs={12} sx={{ marginTop: "24px", height: "calc(100vh - 250px)" }}>
          <DataGridPremium
            autoPageSize
            pagination
            rowHeight={50}
            getRowId={(row) => row._id}
            rows={filteredRows}
            loading={loadingContacts}
            columns={getColumns(setOpenProperties)}
            initialState={{
              pinnedColumns: { left: ["__check__", "name"] },
            }}
            pageSizeOptions={[5]}
            checkboxSelection
            disableRowSelectionOnClick
          />
        </Grid>
      </Grid>
      <Drawer
        open={!!openProperties}
        onClose={() => setOpenProperties(undefined)}
        variant="persistent"
        anchor="right"
        sx={{
          width: drawerWidth,
          padding: "12px",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            padding: "12px",
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "12px",
          }}
        >
          <Typography variant="body1">Related Properties</Typography>
          <IconButton
            onClick={() => {
              setOpenProperties(undefined);
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <PropertySmallDisplay openProperties={openProperties} />
      </Drawer>
    </>
  );
}

export function PropertySmallDisplay(props: any) {
  const { openProperties } = props;

  const [propertyIndex, setPropertyIndex] = useState(openProperties?.index);

  return openProperties?.properties?.map((property: any, index: number) => {
    return (
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          padding: "12px",
          marginBottom: "12px",
        }}
      >
        <ParcelMap
          lat={property?.latitude}
          lng={property?.longitude}
          height={"200px"}
          width={"100%"}
        />
        <Link
          to={`/properties/database/property/${property?._id}`}
          style={{ color: "#0000EE" }}
          target={"_blank"}
        >
          <Typography>{property?.address}</Typography>
        </Link>
        <Typography>Parcel Number: {property?.parcelnumb}</Typography>
        <Typography>Land SF: {property?.gisacre}</Typography>
        <Typography>Zoning: {property?.zoning}</Typography>
        {property?.tags?.map((tag: any) => {
          return (
            <Chip
              variant="outlined"
              size="small"
              label={tag}
              sx={{ fontSize: "12px", marginRight: "8px" }}
            />
          );
        })}
      </Paper>
    );
  });
}
