import React, { useContext } from "react";
import "./App.css";
import Typography from "@mui/material/Typography";
import { Avatar, Box, Button, Grid, TextField, styled } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { apiURL } from "./consts";
import PersonIcon from "@mui/icons-material/Person";
import CustomTimeline, {
  AvatarUpload,
  BottomSteps,
  FileUpload,
  SecondaryButton,
} from "./onboardingTimeline";
import { RefetchContext } from "./refetchProvider";
import { useNavigate } from "react-router-dom";

const OnboardingHeader = styled(Typography)({
  color: "var(--colors-text-text-primary-900, #101828)",
  textAlign: "center",
  fontFamily: "Inter",
  fontSize: "30px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "38px", // 126.667%
});

const OnboardingSubheader = styled(Typography)({
  color: "var(--colors-text-text-tertiary-600, #475467)",
  textAlign: "center",
  fontFamily: "Inter",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "24px", // 150%
});

const CustomTextField = styled(TextField)({
  "&&": {
    borderRadius: "var(--radius-md, 8px)",
    border: "1px solid var(--colors-border-border-primary, #D0D5DD)",
    background: "var(--colors-background-bg-primary, #FFF)",
    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
    width: "100%",
  },

  "& .MuiInputBase-root": {
    borderRadius: "var(--radius-md, 8px)", // For the inner input element
  },

  "&& .MuiInputBase-input": {
    overflow: "hidden",
    color: "var(--colors-text-text-primary, #667085)", // Adjusted to primary text color
    textOverflow: "ellipsis",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px", // 150%
  },

  // Targeting the disabled state of the inner elements
  "& .Mui-disabled": {
    borderColor: "var(--colors-border-border-disabled, #D0D5DD)", // Disabled border color
    backgroundColor: "var(--colors-background-bg-disabled-subtle, #F9FAFB)", // Disabled background color
    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)", // Disabled box shadow
  },

  "& .Mui-disabled .MuiInputBase-input": {
    color: "var(--colors-text-text-disabled, #667085)", // Disabled input text color
  },
});

const CustomButton = styled(Button)({
  borderRadius: "var(--radius-md, 8px)",
  border: "1px solid var(--blue-dark-700, #004EEB)",
  background: "var(--blue-dark-600, #155EEF)",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  display: "flex",
  padding: "10px var(--spacing-xl, 16px)",
  justifyContent: "center",
  alignItems: "center",
  gap: "var(--spacing-sm, 6px)",
  alignSelf: "stretch",
  // Text style
  color:
    "var(--component-colors-components-buttons-primary-button-primary-fg, #FFF)",
  fontFamily: "Inter",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "24px", // 150%
  textTransform: "none",
});

const CustomParagraph = styled(Typography)({
  "&&": {
    color: "var(--colors-text-text-secondary-700, #344054)",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "20px", // 142.857%
  },
});

export default function ExcelUpload(props: {
  // setShowSettings: (value: React.SetStateAction<boolean>) => void;
  // showSettings: boolean;
}) {
  // const { setShowSettings, showSettings } = props;
  const [successMessage, setSuccessMessage] = React.useState<
    string | undefined
  >(undefined);
  const { user, logout, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const context = useContext(RefetchContext);

  const uploadExcelFunction = async (data: any) => {
    const accessToken = await getAccessTokenSilently();

    const response = await fetch(
      `${apiURL}upload/${context?.currentWorkspace?._id}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        method: "POST",
        body: data,
      }
    );

    const resData = await response.json();

    const test = Object.keys(resData);

    navigate(`/list/${resData[test[0]]._id}/edit`);
    console.log(resData);
  };

  return (
    <Grid container>
      <CustomTimeline step={3} />
      <Grid
        item
        xs={8}
        sx={{
          backgroundImage: "url(/backgroundPattern.png)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center top",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            minHeight: "100vh",
          }}
        >
          <div>
            <img
              src="/logoColor.png"
              alt="Logo Color"
              style={{
                width: "142px",
                margin: "auto",
                display: "block",
                marginTop: "50px",
              }}
            />
            <OnboardingHeader sx={{ marginBottom: "12px", marginTop: "32px" }}>
              Import your database
            </OnboardingHeader>
            <OnboardingSubheader
              sx={{ margin: "auto", marginBottom: "32px", maxWidth: "360px" }}
            >
              Mercero will manage the stucture of your current list. For tips on
              the best way to structure your data,{" "}
              <a
                href="https://intercom.help/mercero/en/articles/8497141-create-a-list-from-an-excel-file"
                target="_blank"
                rel="noreferrer"
                style={{ color: "#1570EF", fontWeight: 600 }}
              >
                click here
              </a>
              .
            </OnboardingSubheader>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                height: "56px",
                maxWidth: "360px",
                margin: "auto",
                gap: "32px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  padding: "var(--spacing-xl, 16px) var(--spacing-3xl, 24px)",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "var(--spacing-xs, 4px)",
                  alignSelf: "stretch",
                  borderRadius: "var(--radius-xl, 12px)",
                  border:
                    "1px solid var(--colors-border-border-secondary, #EAECF0)",
                  background: "var(--colors-background-bg-primary, #FFF)",
                }}
              >
                <FileUpload
                  fileTypes="XLS or CSV (max 20mb)"
                  uploadFiles={uploadExcelFunction}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "12px",
                  marginTop: "10px",
                }}
              >
                <SecondaryButton
                  sx={{ flexGrow: 1 }}
                  onClick={() => navigate("/")}
                >
                  Skip
                </SecondaryButton>
                <CustomButton
                  variant="outlined"
                  style={{ display: "flex", flexGrow: 1 }}
                  onClick={() => navigate("/")}
                >
                  Continue
                </CustomButton>
              </div>
            </Box>
          </div>
          <BottomSteps step={3} />
        </div>
      </Grid>
    </Grid>
  );
}
