import { useEffect, useState } from "react";

export default function PdfViewer(props: {
  fileType: string;
  fileName: string;
  fileData: string;
}) {
  const { fileType, fileName, fileData } = props;

  return (
    <iframe
      src={fileData}
      className="iframeViewer"
      title={fileName}
      style={{
        width: "100%",
        height: "100%",
      }}
    />
  );
}
