import {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
  useMemo,
} from "react";
import "./App.css";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  Grid,
  InputAdornment,
  ListItemText,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { RowTodos } from "./rowTodos";
import { debounce, isArray, isEqual } from "lodash";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_ROW } from "./graphql-ops";
import { RelatedProperties } from "./relatedProperties";
import { filterOutColumns, filterOutColumnsForDisplay } from "./layout";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import LoopIcon from "@mui/icons-material/Loop";
import { EmailSection } from "./emailSection";
import MapIcon from "@mui/icons-material/Map";

import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";
import React from "react";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import FileIcon from "@mui/icons-material/Description";
import _ from "lodash";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import useUpdateRow from "./useUpdateRow";
import EmailIcon from "@mui/icons-material/Email";
import { RefetchContext } from "./refetchProvider";
import { NotesSectionCollapsed } from "./collapsedNotes";
import { RowHeader, RowHeader1 } from "./rowHeader";
import DisplayTags from "./displayTags";

import FilesSection from "./filesSection";
import useGetAllTags from "./useGetAllTags";
import { StyledTab, StyledTabs } from "./styledTabs";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { fetchCustomLayer, setupParcels } from "./displayMap";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { GlobalContext } from "./globalContext";
import EmailField from "./emailField";
import {
  EmailDisplaySection,
  HeaderWithKeyFields,
  PhonesSection,
  RelatedCards,
} from "./rowHeaderSections";
import { IndividualViewHeaderButton } from "./individualViewHeaderButtons";

const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#f6f8fa",
  100: "#eaeef2",
  200: "#d0d7de",
  300: "#afb8c1",
  400: "#8c959f",
  500: "#6e7781",
  600: "#57606a",
  700: "#424a53",
  800: "#32383f",
  900: "#24292f",
};

const StyledDiv = styled("div")({
  // borderBottom: "1px solid var(--colors-border-border-secondary, #EAECF0)",
  marginBottom: "12px",
  cursor: "pointer",
  width: "100%",
  "&:hover": {
    backgroundColor: "#F5FAFF",
  },
});

export const ShadowStyle = {
  backgroundColor: "rgba(255,255,255, .8)",
  borderRadius: "8px",
  boxShadow:
    "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
};

export const CustomChip = styled(Chip)({
  "& .MuiChip-label": {
    padding: "0px",
  },
  padding: "4px 8px",
  color: "#525866",
  fontSize: "11px",
  borderRadius: "var(--radius-6, 6px)",
  border: "1px solid var(--stroke-soft-200, #E2E4E9)",
  background: "var(--bg-white-0, #FFF)",
  "&:hover": {
    background: "rgba(0,0,0,.1)",
  },
});

const SearchHeader = styled(Typography)({
  color: "var(--colors-text-text-primary-900, #101828)",
  fontFamily: "Inter, sans-serif", // Ensure you have the 'Inter' font loaded in your project
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "28px", // This is equivalent to a line-height of approximately 155.556%
});

export const StyledTextarea = styled(TextareaAutosize)(
  ({ theme }) => `
  width: 100%;
  font-family: Inter, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 3px;
  border-radius: 2px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  border: none;

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border: none;
    outline: none;
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

export const StyledInput = styled(TextField)(
  ({ theme }) => `
  width: 95%;
  min-width: 200px;
  max-width: 500px;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 12px;
  border-radius: 12px 12px 0 12px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${
    theme.palette.mode === "dark" ? grey[900] : grey[50]
  };

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === "dark" ? blue[500] : blue[200]
    };
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

const arrowButtonStyle = {
  border: "1px solid rgb(223, 225, 228)",
  height: "28px",
  fontSize: "11px",
  color: "rgb(60, 65, 73)",
  backgroundColor: "rgb(255, 255, 255)",
  boxShadow: "rgb(0 0 0 / 9%) 0px 1px 1px",
  textTransform: "none",
  fontWeight: 500,
  borderRadius: "3px",
  padding: "0px 3px",
};

export default function IndividualView(props: {
  curRowId?: string;
  viewId?: string;
  deleteRow?: any;
  refetchRows?: any;
  hideDetails?: boolean;
  isMobile?: boolean;
  mapDisplay?: boolean;
  parsedRow?: any;
}) {
  const [index, setIndex] = useState<number>(0);
  const { hideDetails } = props;
  let { state } = useLocation();
  const { rowId, listId, rootViewId } = useParams();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:600px)") || props?.isMobile;
  const curRowId = rowId || props.curRowId;
  const relatedRoot = state?.relatedRoot;
  const campaignId = state?.campaignId;
  const passedListId = state?.listId;
  const passedRootViewId = state?.rootViewId;
  const [theMap, setMap] = useState<any>(null);
  const { updateRow } = useUpdateRow(listId || "");

  const context = useContext(RefetchContext);

  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [columns, setColumns] = useState<any[]>([]);
  const [allRows, setAllRows] = useState<any[]>([]);
  const [showStreetView, setShowStreetView] = useState<boolean>(false);
  const [shouldShowSV, setShouldShowSV] = useState<boolean>(false);
  const [showAerial, setShowAerial] = useState<boolean>(false);

  useEffect(() => {
    if (!!state?.allRows) {
      try {
        const parsedAllRows = JSON.parse(state?.allRows);
        setAllRows(parsedAllRows);
        setIndex(
          parsedAllRows && parsedAllRows?.findIndex((r: any) => r === curRowId)
        );
      } catch (e) {
        console.log(e);
      }
    }
  }, [state?.allRows, curRowId]);

  const viewIdToPass = rootViewId || listId || props?.viewId || ""; // data?.GetRowById?.viewId?._id || "";

  const refetchContext = useContext(RefetchContext);
  const globalContext = useContext(GlobalContext);

  const [view, setView] = useState<any>(null);
  const [showDetailView, setShowDetailView] = useState<any>(null);

  useEffect(() => {
    if (!!viewIdToPass) {
      const view = refetchContext?.allViews?.find(
        (v) => v._id === viewIdToPass
      );
      setView(view);

      setColumns(filterOutColumns(view?.columns));
    }
  }, [viewIdToPass, refetchContext?.allViews]);

  useEffect(() => {
    if (!!curRowId) {
      // @ts-ignore
      const row = globalContext?.rows?.[viewIdToPass]?.get(curRowId);
      if (row) {
        setShowDetailView(row);
      } else if (props?.parsedRow) {
        setShowDetailView(props?.parsedRow);
      }
      // if (showDetailView?._id !== row?._id) {
      //   setShowDetailView(row);
      // }
    }
  }, [curRowId, viewIdToPass, globalContext?.rows]);

  const emailColumn =
    view?.columns && view.columns?.find((c: any) => c?.type === "email");

  const latCol = columns?.find((column: any) => column.type === "latitude");
  const lat = !!showDetailView && showDetailView[latCol?.field];

  const lngCol = columns?.find((column: any) => column.type === "longitude");
  const lng = !!showDetailView && showDetailView[lngCol?.field];

  function checkStreetViewAvailability(lat: any, lng: any) {
    const sv = new google.maps.StreetViewService();

    sv.getPanorama(
      { location: new google.maps.LatLng(lat, lng), radius: 50 },
      function (data, status) {
        if (status === google.maps.StreetViewStatus.OK) {
          setShowStreetView(true);
        } else {
          setShowStreetView(false);
        }
      }
    );
  }

  const navigateUpRecord = () => {
    if (index === 0) {
      return "#";
    }

    if (!!rootViewId) {
      return `/list/${listId}/${rootViewId}/row/${
        allRows[index - 1] || allRows[index - 1]
      }`;
    }

    return `/list/${listId}/row/${allRows[index - 1] || allRows[index - 1]}`;
  };

  const navigateDownRecord = () => {
    if (index === allRows?.length - 1) {
      return "#";
    }

    if (!!rootViewId) {
      return `/list/${listId}/${rootViewId}/row/${
        allRows[index + 1] || allRows[index + 1]
      }`;
    }

    return `/list/${listId}/row/${allRows[index + 1] || allRows[index + 1]}`;
  };

  useEffect(() => {
    const handleKeyPress = (event: any) => {
      if (
        event.target.tagName.toLowerCase() === "input" ||
        event.target.tagName.toLowerCase() === "textarea" ||
        event.target.tagName.toLowerCase() === "div"
      )
        return;

      if (event.key.toLowerCase() === "k") {
        // Get the current index from the URL or set it to 0 if not found
        if (index !== 0) {
          navigate(navigateUpRecord(), {
            state: {
              row: showDetailView,
              allRows: JSON.stringify(allRows),
              passedAllRows: state?.passedAllRows,
              relatedRoot: state?.relatedRoot,
              index: index - 1,
            },
          });
        }
      }
      if (event.key.toLowerCase() === "j") {
        navigate(navigateDownRecord(), {
          state: {
            row: showDetailView,
            allRows: JSON.stringify(allRows),
            passedAllRows: state?.passedAllRows,
            relatedRoot: state?.relatedRoot,
            index: index + 1,
          },
        });
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [allRows, index, navigate, curRowId, listId]);

  const onLoad = React.useCallback(function callback(map: any) {
    fetchCustomLayer()?.then((layerData: any) => {
      if (layerData) {
        setupParcels(map, layerData);
      }
    });

    setMap(map);
  }, []);

  const parsedLat = parseFloat(lat);
  const parsedLng = parseFloat(lng);

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  useEffect(() => {
    checkStreetViewAvailability(lat, lng);
  }, [lat, lng]);

  if (!!!showDetailView) return <>Loading...</>;

  return (
    <div
      style={{
        padding: "16px 32px",
        backgroundColor: "#f9fafb",
        minHeight: "100vh",
      }}
    >
      <Grid
        container
        // sx={{
        //   padding: "0px 32px",
        //   marginLeft: "0px",
        //   width: "100%",
        //   backgroundColor: "#f9fafb",
        // }}
        columnSpacing={2}
        rowSpacing={2}
        key={`${curRowId}-grid`}
        direction={isMobile ? "column" : "row"}
      >
        <CssBaseline />
        {!!!props.curRowId && (
          <Grid
            item
            xs={12}
            // sx={{
            //   padding: 0,
            //   marginTop: "8px",
            //   marginBottom: "20px",
            //   ...ShadowStyle,
            // }}
          >
            {/* <Link
              to={navigateBack()}
              onClick={() => {
                if (state?.scrollPos) {
                  context?.setScrollPosition(state?.scrollPos);
                }
              }}
              state={
                !!state?.passedAllRows
                  ? {
                      allRows: state?.passedAllRows,
                    }
                  : {}
              }
            > */}
            <Box sx={{ ...ShadowStyle }}>
              <Grid container>
                <Grid xs={6} item>
                  <IconButton
                    style={
                      !isMobile
                        ? {
                            marginTop: "8px",
                            marginBottom: "8px",
                            marginLeft: "16px",
                          }
                        : {
                            marginTop: "7px",
                            marginBottom: "7px",
                            marginLeft: "36px",
                          }
                    }
                    onClick={() => {
                      if (state?.scrollPos) {
                        context?.setScrollPosition(state?.scrollPos);
                      }
                      navigate(-1);
                      // if (listId && rootViewId) {
                      //   navigate(`/list/${listId}/${rootViewId}`);
                      // } else if (listId) {
                      //   navigate(`/list/${listId}`);
                      // } else {
                      //   navigate(-1);
                      // }
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  {/* </Link> */}
                  {allRows?.length > 1 && (
                    <>
                      <Link
                        to={navigateUpRecord()}
                        state={{
                          row: showDetailView,
                          allRows: JSON.stringify(allRows),
                          passedAllRows: state?.passedAllRows,
                          relatedRoot: state?.relatedRoot,
                          index: index - 1,
                        }}
                      >
                        <Tooltip title="Previous record [K]">
                          <IconButton
                            style={{
                              marginTop: "8px",
                              marginBottom: "8px",
                              marginLeft: "16px",
                            }}
                            disabled={index === 0}
                            sx={arrowButtonStyle}
                          >
                            <ArrowDropUpIcon />
                          </IconButton>
                        </Tooltip>
                      </Link>
                      <Link
                        to={navigateDownRecord()}
                        state={{
                          row: showDetailView,
                          allRows: JSON.stringify(allRows),
                          passedAllRows: state?.passedAllRows,
                          relatedRoot: state?.relatedRoot,
                          index: index + 1,
                        }}
                      >
                        <Tooltip title="Next record [J]">
                          <IconButton
                            style={{
                              marginTop: "8px",
                              marginBottom: "8px",
                              marginLeft: "16px",
                            }}
                            disabled={index === allRows?.length - 1}
                            sx={arrowButtonStyle}
                          >
                            <ArrowDropDownIcon />
                          </IconButton>
                        </Tooltip>
                      </Link>
                      <span
                        style={{
                          marginLeft: "10px",
                          color: "rgba(0,0,0,.6)",
                          fontSize: "16px",
                        }}
                      >
                        {`${(index && index + 1) || 1} of ${allRows?.length}`}{" "}
                        {!!state?.relatedTitle &&
                          `Related to ${state?.relatedTitle}`}
                      </span>
                    </>
                  )}
                </Grid>
                <Grid xs={6} item>
                  <IndividualViewHeaderButton
                    showDetailView={showDetailView}
                    updateRow={updateRow}
                    emailField={emailColumn}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        )}
        <Grid item xs={12} md={4}>
          <Box sx={{ ...ShadowStyle, padding: "20px" }}>
            <HeaderWithKeyFields
              key={`${showDetailView._id}-keyFields`}
              showDetailView={showDetailView}
              columns={columns}
            />
            <PhonesSection
              key={`${showDetailView._id}-phones`}
              showDetailView={showDetailView}
              columns={columns}
            />
            <EmailDisplaySection
              key={`${showDetailView._id}-emaildisplay`}
              showDetailView={showDetailView}
              columns={columns}
            />
          </Box>
          <Box sx={{ ...ShadowStyle, padding: "20px", marginTop: "16px" }}>
            <RowDetails
              row={showDetailView}
              updateRow={updateRow}
              columns={columns}
              key={`${showDetailView?._id}-details`}
              initialState={view?.initialState}
              viewId={viewIdToPass}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <RelatedCards
            key={`${showDetailView._id}-rowHeader`}
            showDetailView={showDetailView}
            columns={columns}
            isMobile={isMobile || false}
            updateRow={updateRow}
            deleteRow={props.deleteRow}
            navigate={navigate}
            viewId={viewIdToPass}
            mapDisplay={props.mapDisplay}
            initialState={view?.initialState}
            setRow={setShowDetailView}
          />
          {!!lat && lng ? (
            <Box
              sx={{
                marginBottom: "16px",
                padding: "20px",
                ...ShadowStyle,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "12px",
                }}
              >
                {showStreetView && (
                  <Button
                    onClick={() => setShouldShowSV(!shouldShowSV)}
                    sx={{
                      textTransform: "None",
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      padding: "0px",
                    }}
                  >
                    <MapIcon sx={{ marginRight: "5px" }} />
                    {shouldShowSV ? "Hide Street View" : "Show Street View"}
                  </Button>
                )}
                {!!lat && !!lng && (
                  <Button
                    onClick={() => setShowAerial(!showAerial)}
                    sx={{
                      textTransform: "None",
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      padding: "0px",
                    }}
                  >
                    <LocationOnIcon sx={{ marginRight: "5px" }} />
                    {showAerial ? "Hide Aerial View" : "Show Aerial View"}
                  </Button>
                )}
              </div>
              {!!lat && !!lng && showStreetView && shouldShowSV && (
                <iframe
                  width="100%"
                  key={`${lat}-${lng}`}
                  height="450"
                  className="streetViewIframe"
                  title="Street View"
                  style={{
                    border: "0",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                  src={`https://www.google.com/maps/embed/v1/streetview?key=AIzaSyBGRT9wxwnP_vbsbTE_HHUb55W5BQH4XZE&location=${lat},${lng}&heading=210&pitch=10&fov=90`}
                ></iframe>
              )}
              {!!lat && !!lng && showAerial && (
                <GoogleMap
                  center={{
                    lat: parsedLat,
                    lng: parsedLng,
                  }}
                  mapContainerStyle={{
                    width: "100%",
                    height: "500px",
                    marginTop: "12px",
                  }}
                  mapTypeId="satellite"
                  zoom={18}
                  onLoad={onLoad}
                  onUnmount={onUnmount}
                  // onZoomChanged={onZoomChanged}
                  options={{
                    mapTypeId: "satellite",
                  }}
                  key={"google-map"}
                  ref={theMap}
                >
                  <Marker
                    key={"1map"}
                    position={{ lat: parsedLat, lng: parsedLng }}
                  />
                </GoogleMap>
              )}
            </Box>
          ) : (
            <></>
          )}
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              ...ShadowStyle,
            }}
          >
            <StyledTabs
              aria-label="basic tabs example"
              value={selectedTab}
              onChange={(event, value) => setSelectedTab(value)}
              sx={{
                height: "40px",
                "& .MuiButtonBase-root": {
                  textTransform: "none",
                  paddingBottom: "0px",
                  minHeight: "40px",
                  height: "40px",
                },
              }}
            >
              <StyledTab
                icon={<TextSnippetIcon fontSize="small" />}
                iconPosition="start"
                label="Notes"
              />
              <StyledTab
                icon={<EmailIcon fontSize="small" />}
                iconPosition="start"
                label="Emails"
                sx={!!!emailColumn ? { display: "none" } : {}}
              />
              <StyledTab
                icon={<CheckBoxIcon fontSize="small" />}
                iconPosition="start"
                label="Tasks"
              />
              <StyledTab
                icon={<LoopIcon fontSize="small" />}
                sx={{}}
                iconPosition="start"
                label="Related"
              />
              <StyledTab
                icon={<FileIcon fontSize="small" />}
                sx={{}}
                iconPosition="start"
                label="Files"
              />
              {hideDetails && (
                <StyledTab
                  icon={<FormatListBulletedIcon fontSize="small" />}
                  iconPosition="start"
                  label="Details"
                />
              )}
            </StyledTabs>

            {selectedTab === 0 && (
              <NotesSectionCollapsed showDetailView={showDetailView} />
            )}
            {selectedTab === 1 && (
              <EmailSection
                showDetailView={showDetailView}
                email={showDetailView[emailColumn?.field]}
                columns={columns}
              />
            )}
            {selectedTab === 2 && <RowTodos showDetailView={showDetailView} />}
            {selectedTab === 3 && (
              <RelatedProperties
                row={showDetailView}
                rowId={showDetailView["_id"]}
              />
            )}
            {selectedTab === 4 && (
              <div style={{ padding: "20px", paddingBottom: "0px" }}>
                <FilesSection
                  viewId={viewIdToPass}
                  rowId={curRowId}
                  row={showDetailView}
                  email={showDetailView[emailColumn?.field]}
                />
              </div>
            )}
            {selectedTab === 5 && (
              <div style={{ padding: "20px", paddingBottom: "0px" }}>
                <RowDetails
                  row={showDetailView}
                  updateRow={updateRow}
                  columns={columns}
                  key={`${showDetailView?._id}-details`}
                  initialState={view?.initialState}
                  viewId={viewIdToPass}
                />
              </div>
            )}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

function RowDetails(props: any) {
  const { row, columns, viewId, updateRow } = props;
  const [searchText, setSearchText] = useState("");
  const [boxValue, setBoxValue] = useState<any[]>([]);
  const [tempRow, setTempRow] = useState<any>(row);

  const debouncedUpdateRow = useMemo(
    () =>
      debounce(async (newRow) => {
        await updateRow({
          variables: {
            set: {
              rowId: newRow?._id,
              rowObject: JSON.stringify({
                ...newRow,
                relatedRows: undefined,
              }),
              triggeredBy: "client",
            },
            query: {
              _id: newRow._id,
            },
          },
        });
      }, 1000),
    [updateRow]
  );

  useEffect(() => {
    if (!isEqual(tempRow, row)) {
      debouncedUpdateRow(tempRow);
    }
  }, [tempRow, debouncedUpdateRow]);

  const initialState = JSON.parse(props.initialState || "{}");
  const [showHiddenFields, setShowHiddenFields] = useState<boolean>(false);

  const hiddenFields = filterOutColumnsForDisplay(columns)?.filter(
    (item: any) => {
      const field = item?.field;

      if (boxValue?.length > 0) {
        return boxValue?.includes(field);
      }

      if (field === "relatedRows" || field === "_id" || field === "id") {
        return false;
      }

      if (
        !!initialState?.columns?.columnVisibilityModel &&
        initialState?.columns?.columnVisibilityModel[field] !== undefined &&
        initialState?.columns?.columnVisibilityModel[field] !== true
      ) {
        return true;
      }
      return false;
    }
  );

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <Grid
        container
        key="DetailSearch"
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{ marginBottom: "30px" }}
      >
        <Grid item xs={5}>
          <SearchHeader>All Fields</SearchHeader>
        </Grid>
        <Grid item xs={7}>
          <Autocomplete
            multiple
            id="tags-standard"
            disableCloseOnSelect
            options={columns || []}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                backgroundColor: "#FFF",
                boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                width: "100%",
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#D0D5DD",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#D0D5DD",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#D0D5DD",
                },
              },
              "& .MuiAutocomplete-inputRoot": {
                padding: "10px 14px",
              },
              "& .MuiAutocomplete-endAdornment": {
                right: "10px",
              },
              "& .MuiInputBase-input": {
                padding: "0px !important",
              },
            }}
            filterOptions={(options, params) => {
              // @ts-ignore
              const filtered = options?.filter((option) =>
                option?.headerName
                  ?.toLowerCase()
                  ?.includes(searchText?.toLowerCase())
              );

              return filtered;
            }}
            value={boxValue}
            onChange={(event: any, newValue: any | null) => {
              event.preventDefault();
              setBoxValue(newValue);
            }}
            getOptionLabel={(option) => option.headerName}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  checked={selected}
                  size="small"
                  sx={{
                    opacity: 0.25,
                    "&:hover": {
                      opacity: 1,
                    },
                    padding: "8px",
                  }}
                />
                <ListItemText
                  primary={option.headerName}
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: "12px",
                    },
                  }}
                />
              </li>
            )}
            renderInput={(params: any) => (
              <TextField
                {...params}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                value={searchText}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M17.5 17.5L14.5834 14.5833M16.6667 9.58333C16.6667 13.4954 13.4954 16.6667 9.58333 16.6667C5.67132 16.6667 2.5 13.4954 2.5 9.58333C2.5 5.67132 5.67132 2.5 9.58333 2.5C13.4954 2.5 16.6667 5.67132 16.6667 9.58333Z"
                          stroke="#667085"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </InputAdornment>
                  ),
                }}
                placeholder="Search"
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid
        container
        // xs={0}
        spacing={1}
        flexDirection={"row"}
        alignItems={"center"}
        alignSelf={"stretch"}
        // flexShrink={1}
        // justifyItems={"center"}
        // justifyContent={"center"}
        style={{
          // width: "100%",
          //maxHeight: "calc(100vh - 310px)",
          // overflowY: "scroll",
          // border: "1px solid var(--colors-border-border-secondary, #EAECF0)",
          borderRadius: "8px",
        }}
        key={`${row._id}-rowFields`}
      >
        {filterOutColumnsForDisplay(columns)
          ?.filter((item: any) => {
            const field = item?.field;

            if (boxValue?.length > 0) {
              return boxValue.map((val) => val.field).includes(field);
            }

            if (field === "relatedRows" || field === "_id" || field === "id") {
              return false;
            }

            if (!!!initialState?.columns?.columnVisibilityModel) {
              return true;
            }

            if (
              !!initialState?.columns?.columnVisibilityModel &&
              (initialState?.columns?.columnVisibilityModel[field] ===
                undefined ||
                initialState?.columns?.columnVisibilityModel[field] === true)
            ) {
              return true;
            }
            return false;
          })
          ?.map((column: any, index) => (
            <StyledDiv key={column?.field}>
              <Typography
                sx={{
                  color: "#101828",
                  fontSize: "14px",
                  fontWeight: 500,
                  marginBottom: "4px",
                }}
              >
                {column?.headerName}
              </Typography>
              <EditField
                key={`${column?.field}-col`}
                field={column?.field}
                title={column?.headerName}
                columnType={column?.type}
                row={tempRow}
                viewId={viewId}
                columns={filterOutColumnsForDisplay(columns)}
                setRow={setTempRow}
              />
              {/* <Grid container alignItems={"center"}>
                <Grid item xs={5} sx={{ padding: "16px 24px" }}>
                  
                </Grid>
                <Grid item xs={7} sx={{ padding: "16px 24px" }}>
                  
                </Grid>
              </Grid> */}
            </StyledDiv>
          ))}
        {hiddenFields?.length > 0 ? (
          <div
            style={{
              width: "100%",
              display: "block",
              paddingTop: "12px",
            }}
          >
            <Button
              onClick={() => setShowHiddenFields(!showHiddenFields)}
              sx={{
                textTransform: "none",
                borderRadius: "0px",
                color: "#333",
              }}
              startIcon={
                !showHiddenFields ? <ArrowRightIcon /> : <ArrowDropDownIcon />
              }
            >
              Show Hidden Fields
            </Button>
          </div>
        ) : (
          <></>
        )}

        {showHiddenFields &&
          hiddenFields?.map((column: any) => (
            <StyledDiv key={column.field}>
              <Grid container alignItems={"center"}>
                <Grid item xs={5} sx={{ padding: "16px 24px" }}>
                  <Typography
                    sx={{
                      color: "#101828",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    {column?.headerName}
                  </Typography>
                </Grid>
                <Grid item xs={7} sx={{ padding: "16px 24px" }}>
                  <EditField
                    key={`${column?.field}-col`}
                    field={column?.field}
                    title={column?.headerName}
                    columnType={column?.type}
                    row={tempRow}
                    viewId={viewId}
                    columns={filterOutColumnsForDisplay(columns)}
                    setRow={setTempRow}
                  />
                </Grid>
              </Grid>
            </StyledDiv>
          ))}
      </Grid>
    </div>
  );
}

export function EditField(props: {
  title: string;
  field: string;
  row: any;
  columnType: string;
  // refetchRow: any;
  viewId: any;
  columns: any;
  fullWidth?: boolean;
  inHeader?: boolean;
  setRow?: any;
}) {
  const {
    title,
    row,
    columnType,
    field,
    // refetchRow,
    viewId,
    columns,
    fullWidth,
    setRow,
  } = props;
  const context = useContext(RefetchContext);

  const [value, setValue] = useState(row[field]);
  // const { updateRow } = useUpdateRow(viewId);

  const inputRef = useRef(null);
  const placeChangedRef = useRef(false);

  const latField = columns?.find((item: any) => item?.type === "latitude");
  const lngField = columns?.find((item: any) => item?.type === "longitude");

  useEffect(() => {
    setValue(row[field]);
  }, [row, field]);

  const updateAddressField = async (
    address: string,
    lat?: number,
    lng?: number
  ) => {
    setRow((prev: any) => ({
      ...prev,
      [field]: address,
      ...(lat !== undefined && { [latField?.field]: lat }),
      ...(lng !== undefined && { [lngField?.field]: lng }),
    }));

    setValue(address);
  };

  React.useEffect(() => {
    let autocomplete: any;

    if (columnType === "address" && inputRef.current) {
      autocomplete = new google.maps.places.Autocomplete(inputRef.current, {
        types: ["address"],
      });

      autocomplete.addListener("place_changed", async () => {
        placeChangedRef.current = true;
        const place = autocomplete.getPlace();
        const address = place.formatted_address;
        const lat = place.geometry?.location?.lat();
        const lng = place.geometry?.location?.lng();

        await updateAddressField(address, lat, lng);
        placeChangedRef.current = false;
      });
    }

    return () => {
      if (autocomplete) {
        google.maps.event.clearInstanceListeners(autocomplete);
      }
    };
  }, [columnType, field, latField, lngField, row]);

  let outputJSX = (
    <StyledTextarea
      value={value}
      placeholder="Enter value"
      onChange={(e) => setValue(e.target.value)}
      onBlur={async (e) => {
        setRow((prev: any) => ({
          ...prev,
          [field]: e.target.value,
        }));
      }}
    />
  );

  if (columnType === "address") {
    outputJSX = (
      <input
        ref={inputRef}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={async (e) => {
          // Wait a short time to ensure place_changed has fired if it's going to
          // setTimeout(async () => {
          //   if (!placeChangedRef.current) {
          //     await updateAddressField(e.target.value);
          //   }
          // }, 100);
        }}
        type="text"
        placeholder="Enter address"
        style={{
          width: "100%",
          fontSize: ".875rem",
          fontWeight: "400",
          lineHeight: "1.5",
          borderRadius: "0px",
          color: "#24292f",
          border: "none",
          boxShadow: "none",
        }}
      />
    );
  }

  if (columnType === "type") {
    return (
      <TagsComponent
        listId={viewId}
        field={field}
        value={value}
        row={row}
        setValue={setValue}
        inHeader={props.inHeader}
        setRow={setRow}
      />
    );
  }

  if (columnType === "email") {
    try {
      const parsedValue = JSON.parse(value);
      return <EmailField emails={value} onChange={setValue} />;
      // You might want to handle the parsed JSON differently here
    } catch (error) {}
  }

  if (fullWidth) {
    return <>{outputJSX}</>;
  }

  return outputJSX;
}

export function TagsComponent(props: {
  listId: string;
  field: string;
  value: any;
  row: any;
  setValue: any;
  inHeader?: boolean;
  setRow?: any;
}) {
  const { listId, field, value, row, setValue, inHeader, setRow } = props;

  const { updateRow } = useUpdateRow(listId);

  const selectedOptions = isArray(value)
    ? _.flatten(value)
    : !!value
    ? [value]
    : [];

  let allPossibleTags: any[] = (useGetAllTags(listId, field) || []).sort(
    (a, b) => a.localeCompare(b)
  );

  return (
    <DisplayTags
      key={`${row?._id}-Headertags`}
      allPossibleTags={allPossibleTags || []}
      selectedTags={selectedOptions}
      buttonStyle={{ padding: "0px" }}
      onTagsChange={async (tags: string[]) => {
        setValue(tags);

        setRow((prev: any) => ({
          ...prev,
          [field]: tags,
        }));
      }}
      constrainTags={inHeader ? 20 : undefined}
    />
  );
}

export function getPageTitle(showDetailView: any) {
  if (!showDetailView) return "";
  if (showDetailView["name"]) {
    return showDetailView["name"];
  }
  if (showDetailView["Name"]) {
    return showDetailView["Name"];
  }

  const pageTitle = Object.keys(showDetailView)?.filter(
    (item: any) => !item.toLowerCase().includes("id") && !item.includes("merc_")
  );

  return showDetailView[pageTitle[0]];
}

export function getSubTitle(showDetailView: any) {
  const pageTitle = Object.keys(showDetailView)?.filter(
    (item: any) => item !== "_id" && item !== "id"
  );

  return showDetailView[pageTitle[1]];
}
