import {
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext,
} from "react";
import "./App.css";
import { Link, useLocation, useParams } from "react-router-dom";
import MainDataGrid from "./DataGrid";
import { TextField, InputAdornment } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { filterOutColumns } from "./layout";
import { GetHeaderText, passedColsParsed } from "./DatagridFunctions";
import {
  useGridApiRef,
  GridActionsCellItem,
  gridFilteredSortedRowEntriesSelector,
  gridFilteredSortedRowIdsSelector,
} from "@mui/x-data-grid-premium";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_FIRST_ROWS,
  GET_ONE_VIEW,
  GET_ROW1,
  GET_ROWS,
} from "./graphql-ops";
import useUpsertView from "./useUpsertView";
import _ from "lodash";
import { RefetchContext } from "./refetchProvider";

import { SocketIOContext } from "./socketIOProvider";
import { cache } from "./cache";
import { RowContext } from "./rowProvider";
import { GlobalContext } from "./globalContext";
import LoadingScreen from "./loadingScreen";
import { breakString } from "./relatedFields";

export default function MainDataGridContainer() {
  let { state, pathname } = useLocation();
  //   const selectedView = state.view;
  const apiRef = useGridApiRef();

  const context = useContext<any>(RefetchContext);
  const globalContext = useContext<any>(GlobalContext);

  // const selectedView = state?.view;
  const { listId, rootViewId, workspaceId } = useParams();

  const viewId = listId || state?.view?._id;

  const {
    loading,
    error,
    data,
    refetch: refetchView,
  } = useQuery(GET_ONE_VIEW, {
    variables: { query: { _id: viewId, isDeleted: false } },
  });

  const { updateView } = useUpsertView();

  const viewIdToPass = rootViewId || viewId || "";

  // const {
  //   loading: firstRowsLoading,
  //   error: firstRowsError,
  //   data: firstRows,
  // } = useQuery(GET_FIRST_ROWS, {
  //   variables: {
  //     query: {
  //       viewId: {
  //         _id: viewIdToPass,
  //       },
  //     },
  //   },
  // });

  // const {
  //   loading: allRowsLoading,
  //   error: rowError,
  //   data: rows,
  //   refetch: refetchRows,
  // } = useQuery(GET_ROWS, {
  //   variables: {
  //     input: viewIdToPass,
  //   },
  //   // pollInterval: 10000,
  //   onCompleted: (responseData) => {
  //     if (responseData && responseData.GetRowsByViewId) {
  //       responseData.GetRowsByViewId.forEach((row: any) => {
  //         cache.writeQuery({
  //           query: GET_ROW1,
  //           data: {
  //             GetRowById1: row,
  //           },
  //           variables: { input: { _id: row._id } },
  //         });
  //       });
  //     }
  //   },
  // });

  // const rowsLoading = firstRowsLoading && allRowsLoading;

  // const allRows = useMemo(
  //   () => rows?.GetRowsByViewId || firstRows?.rows || [],
  //   [rows, firstRows]
  // );

  const [filteredOrder, setFilteredOrder] = useState<string>(
    JSON.stringify([])
  );

  const { socket } = useContext(SocketIOContext) as any;
  // useEffect(() => {
  //   refetchRows();
  // }, [viewId, refetchRows]);

  useEffect(() => {
    if (
      !_.isNil(socket) &&
      !_.isNil(viewIdToPass) &&
      !_.isEmpty(viewIdToPass)
    ) {
      socket.emit("join-room", viewIdToPass);
    }
  }, [viewIdToPass, socket]);

  // useEffect(() => {
  //   refetchRows();
  // }, [viewIdToPass, refetchRows]);

  /**
   *
   * Handle Column manipulation
   *
   *
   */

  const [newColumnHeader, setNewColumnHeader] = useState("");
  const [addCol, setAddCol] = useState(false);
  const colFieldRef = useRef<RefObject<HTMLDivElement>>();

  // Add new column to view
  useEffect(() => {
    const updateViewCall = async () => {
      const updatedCols = filterOutColumns(data?.view?.columns)
        ?.map((col: any) => {
          return {
            field: col.field,
            headerName: col.headerName,
            width: col.width || 150,
            editable: col.editable,
            type: col.type,
            display: col.display,
          };
        })
        .concat({
          field: newColumnHeader,
          headerName: newColumnHeader,
          editable: true,
          width: 150,
          type: "string",
          display: "",
        });

      // setColumns(updatedCols);

      const { data: newView } = await updateView({
        variables: {
          query: {
            _id: data?.view?._id,
          },
          view: {
            columns: updatedCols,
            triggeredBy: "client",
          },
        },
      });
    };

    if (
      addCol &&
      !_.includes(
        data?.view?.columns?.map((col: any) => col.field.toLowerCase()),
        newColumnHeader.toLowerCase()
      ) &&
      newColumnHeader !== ""
    ) {
      updateViewCall();
      setAddCol(false);
      // @ts-ignore
      colFieldRef.current.value = "";
      // refetchView();
    } else {
      setAddCol(false);
    }
  }, [
    addCol,
    data?.view?.columns,
    apiRef,
    newColumnHeader,
    data?.view?._id,
    updateView,
    refetchView,
  ]);

  const initialState = useMemo(() => {
    let initialState = data?.view?.initialState;

    try {
      initialState = JSON.parse(data?.view?.initialState);

      if (!!!initialState) {
        return {};
      }

      const filterItems =
        initialState?.filter?.filterModel?.items?.filter((item: any) => {
          if (item?.field === "merc_Color") {
            return true;
          }

          const columnType = data?.view?.columns?.find((col: any) => {
            return col.field === item.field;
          })?.type;

          const stringType = [
            "string",
            "firstName",
            "name",
            "email",
            "company",
            "lastName",
            "type",
            "related",
            "lastActivity",
            "header1",
            "header2",
            "subheader1",
            "subheader2",
            "address",
            "phone",
            "contactName",
            "companyName",
            "link",
          ];

          const stringOperators = [
            "contains",
            "equals",
            "startsWith",
            "endsWith",
            "isEmpty",
            "isNotEmpty",
            "isAnyOf",
          ];

          const numberOperators = [
            "=",
            "!=",
            ">",
            "<",
            ">=",
            "<=",
            "isEmpty",
            "isNotEmpty",
            "isAnyOf",
          ];

          const booleanOperators = ["is"];

          const dateOperators = [
            "is",
            "not",
            "after",
            "onOrAfter",
            "before",
            "onOrBefore",
            "isEmpty",
            "isNotEmpty",
          ];

          // other "number", "date", "boolean", "related", "lastActivity"
          if (
            columnType?.includes("related") &&
            columnType?.includes("column")
          ) {
            return true;
          } else if (
            (stringType?.includes(columnType) ||
              columnType?.includes("related")) &&
            stringOperators?.includes(item.operator)
          ) {
            return true;
          } else if (
            columnType === "number" &&
            numberOperators?.includes(item.operator)
          ) {
            return true;
          } else if (
            columnType === "date" &&
            dateOperators?.includes(item.operator)
          ) {
            return true;
          } else if (
            columnType === "boolean" &&
            booleanOperators?.includes(item.operator)
          ) {
            return true;
          }

          return false;
        }) || [];

      const hideMercColorFirstTime =
        initialState?.columns?.columnVisibilityModel?.merc_Color;

      return {
        ...initialState,
        columns: {
          ...initialState?.columns,
          columnVisibilityModel: {
            ...initialState?.columns?.columnVisibilityModel,
            merc_Color: hideMercColorFirstTime || false,
          },
          orderedFields: initialState?.columns?.orderedFields?.filter(
            (c: string) => c !== "new-column"
          ),
        },
        pinnedColumns: {
          ...initialState?.pinnedColumns,
          left: ["__check__", "actions"].concat(
            initialState?.pinnedColumns?.left?.filter(
              (c: string) => c !== "__check__" && c !== "actions"
            ) || []
          ),
        },
        filter: {
          ...initialState?.filter,
          filterModel: {
            ...initialState?.filter?.filterModel,
            items: filterItems,
          },
        },
      };
    } catch (e) {
      console.log(e);
    }
  }, [data?.view?.initialState, data?.view?.columns]);

  const selectedView = data?.view;

  // const parsedRows = useMemo(() => {
  //   let filteredRowIds = state?.filteredRows;
  //   let filteredRows = allRows;

  //   if (!!filteredRowIds && filteredRowIds.length > 0) {
  //     filteredRows = filteredRows.filter((row: any) =>
  //       filteredRowIds.includes(row._id)
  //     );
  //   }

  //   return filteredRows?.map((row: any) => {
  //     const relatedRows = row?.relatedRows?.rows?.map((r: any) => {
  //       const parsedRow = JSON.parse(r?.rowObject);

  //       const relatedView = context?.allViews?.find((v: any) => {
  //         return v._id === r?.viewId?._id;
  //       });

  //       return {
  //         _id: r?._id,
  //         merc_viewId: r?.viewId?._id,
  //         merc_viewName: r?.viewId?.name,
  //         merc_Header: GetHeaderText(parsedRow, relatedView?.columns || []),
  //         ...parsedRow,
  //       };
  //     });

  //     return {
  //       ...JSON.parse(row?.rowObject),
  //       _id: row?._id,
  //       relatedRows: relatedRows,
  //       latestActivity: row?.latestActivity,
  //       createdAt: row?.createdAt,
  //       latestEmail: row?.latestEmail?.timestamp,
  //     };
  //   });
  // }, [allRows, state?.filteredRows, context?.allViews]);

  // const [parsedRows, setParsedRows] = useState<any>([]);
  // const generator = useRef(processRowsIncrementally(allRows));

  // useEffect(() => {
  //   setParsedRows([]);
  //   generator.current = processRowsIncrementally(allRows);
  // }, [viewId]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const batch: any = [];
  //     for (let i = 0; i < 100; i++) {
  //       const next = generator.current.next();
  //       if (next.done) {
  //         clearInterval(interval);
  //         break;
  //       } else {
  //         batch.push(next.value);
  //       }
  //     }
  //     if (batch.length > 0) {
  //       setParsedRows((prevRows: any) => [...prevRows, ...batch]);
  //       const rowModel = gridFilteredSortedRowEntriesSelector(apiRef).map(
  //         (r: any) => r.model
  //       );

  //       setFilteredOrder(JSON.stringify(rowModel));
  //     }
  //   }, 100);

  //   return () => clearInterval(interval);
  // }, [allRows]);

  const [parsedRows, setParsedRows] = useState<any>(undefined);

  useEffect(() => {
    const rows = globalContext?.rows;
    const viewRows = rows?.[viewIdToPass];

    if (!viewRows) {
      console.error("viewRows is undefined. Check viewIdToPass:", viewIdToPass);
      return; // or handle this case appropriately
    }

    const values = viewRows.values();

    if (!values || typeof values[Symbol.iterator] !== "function") {
      console.error("values is not iterable:", values);
      return; // or handle this case appropriately
    }

    const filteredValues = Array?.from(values)?.filter((row: any) => {
      if (state?.filteredRows?.length > 0) {
        return state.filteredRows.includes(row?._id);
      }
      return true;
    });

    setParsedRows(filteredValues);
  }, [globalContext?.rows, viewIdToPass, state?.filteredRows]);

  const columns = useMemo(() => {
    const newColHeader = {
      field: "new-column",
      width: 200,
      minWidth: 200,
      type: "string",
      sortable: false,
      disableColumnMenu: true,
      hideable: false,
      disableReorder: true,
      renderHeader: () => {
        return (
          <TextField
            id="new-column-input"
            placeholder="Enter column name"
            // @ts-ignore
            inputRef={colFieldRef}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  style={{ height: "16px" }}
                  sx={{ height: "16px" }}
                >
                  <AddIcon />
                </InputAdornment>
              ),
            }}
            variant="standard"
            onChange={(e) => {
              setNewColumnHeader(e.target.value);
            }}
            //   value={newColumnHeader}
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                setAddCol(true);
              }
            }}
          />
        );
      },
    };

    const filteredCols = filterOutColumns(data?.view?.columns)?.map(
      (col: any) => {
        let type = col?.type;

        if (col?.type?.includes("related") && col?.type?.includes("column")) {
          const parsedType = breakString(col?.type); //.split("-");
          const viewId = parsedType?.[1];
          const column = parsedType?.[3];

          const columnObj = context?.allViews
            ?.find((view: any) => view._id === viewId)
            ?.columns?.filter((col: any) => {
              return col?.field === column;
            })?.[0];

          type = columnObj?.type;
        }

        return {
          ...col,
          relatedType: type,
        };
      }
    );

    const parsedCols = passedColsParsed(filteredCols, apiRef);
    const fixColsWithNoWidth = parsedCols?.map((col: any) => {
      return {
        ...col,
        width: col.width || 150,
      };
    });

    if (!!fixColsWithNoWidth && fixColsWithNoWidth.length > 0) {
      return [
        {
          field: "actions",
          type: "actions",
          width: 50,
          cellClassName: "actions-cell",
          hideable: false,
          getActions: (params: any) => [
            <Link
              to={`row/${params.row._id}`}
              state={{
                row: params.row,
                allRows: filteredOrder,
              }}
              onClick={(e) => {
                localStorage.setItem(
                  `dataGridScrollPosition-${listId}`,
                  JSON.stringify(apiRef.current.getScrollPosition())
                );
              }}
              key={`link-${params.row._id}`}
            >
              <GridActionsCellItem
                icon={<OpenInFullIcon id="Expand row" sx={{ width: "15px" }} />}
                label="Expand"
              />
            </Link>,
          ],
        },
        ...fixColsWithNoWidth,
        {
          headerName: "Row Color",
          field: "merc_Color",
          type: "string",
          hide: true,
        },
        newColHeader,
      ];
    }

    return [
      {
        field: "actions",
        type: "actions",
        width: 50,
        cellClassName: "actions-cell",
        getActions: (params: any) => [
          <Link
            to={`/individualView/${params.row._id}`}
            state={{
              row: params.row,
              allRows: filteredOrder,
            }}
            key={`link-${params.row._id}`}
          >
            <GridActionsCellItem
              icon={<OpenInFullIcon id="Expand row" sx={{ width: "15px" }} />}
              label="Expand"
            />
          </Link>,
        ],
      },
      newColHeader,
    ];
  }, [filteredOrder, data?.view?.columns, apiRef, context?.allViews]);

  useEffect(() => {
    if (!!apiRef?.current?.instanceId) {
      setFilteredOrder(
        JSON.stringify(gridFilteredSortedRowIdsSelector(apiRef))
      );
    }
  }, [
    apiRef,
    initialState,
    apiRef?.current?.instanceId,
    loading,
    parsedRows,
    state?.filteredRows,
  ]);

  if (!parsedRows || loading) {
    return <LoadingScreen />;
  } else if (error) {
    return <div>Error! {error?.message}</div>;
  } else if (!!!data?.view) {
    return <div>View not found</div>;
  }

  return (
    <MainDataGrid
      key={selectedView?._id}
      selectedView={selectedView}
      apiRef={apiRef}
      rows={parsedRows}
      initialState={initialState}
      columns={columns || []}
      refetchView={refetchView}
      filteredOrder={filteredOrder}
      allRowsLoading={false}
      refetchRows={() => {
        console.log("old refetch");
      }}
    />
  );
}

function* processRowsIncrementally(rows: any) {
  for (const row of rows) {
    const parsedRow = JSON.parse(row.rowObject);

    yield {
      ...parsedRow,
      _id: row._id,
      relatedRows: null, // Lazy load related rows
      latestActivity: row.latestActivity,
      createdAt: row.createdAt,
      latestEmail: row.latestEmail?.timestamp,
    };
  }
}
