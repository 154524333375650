import React, { useState, useEffect, useContext, useCallback } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useAuth0 } from "@auth0/auth0-react";
import { apiURL } from "./consts";
import { Box, Button } from "@mui/material";
import { getPageTitle } from "./individualView";
import { GetHeaderText, GetSubheaderText } from "./DatagridFunctions";
import _ from "lodash";
import { RefetchContext } from "./refetchProvider";
import PropertyResearch from "./propertyResearch";
import AddPropertiesMap from "./discoverProperties";
import MapIcon from "@mui/icons-material/Map";
import SearchIcon from "@mui/icons-material/Search";

export const AtlasScreen = () => {
  const [showMap, setShowMap] = useState(false);

  return (
    <div>
      <Button
        onClick={() => setShowMap(!showMap)}
        startIcon={showMap ? <SearchIcon /> : <MapIcon />}
        variant="contained"
        sx={{ position: "absolute", top: "20px", right: "20px", zIndex: 1000 }}
      >
        {showMap ? "Show Search" : "Show Map"}
      </Button>
      {showMap && <AddPropertiesMap />}
      {!showMap && <PropertyResearch />}
    </div>
  );
};

const PropertySearch = (props: {
  selectedOptions: any[];
  setSelectedOptions: (value: React.SetStateAction<any[]>) => void;
}) => {
  const { selectedOptions, setSelectedOptions } = props;

  const context = useContext(RefetchContext);
  const currentWorkspace = context?.currentWorkspace;

  // const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);

  const search = async (searchQuery: string) => {
    try {
      const accessToken = await getAccessTokenSilently();

      if (searchQuery.length > 2) {
        await fetch(
          `${apiURL}row?q=${searchQuery}&workspaceId=${currentWorkspace?._id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
          .then((res) => res.json())
          .then((result) => {
            setSearchResults(
              [
                ...result.map((r: any) => {
                  return {
                    _id: r._id,
                    merc_viewId: r.viewId,
                    row: {
                      ...JSON.parse(r.rowObject),
                      _id: r._id,
                      merc_viewId: r.viewId,
                    },
                    relatedRows: r.relatedRows?.rows,
                    columns: r.columns,
                  };
                }),
              ] || []
            );
          });
      } else {
        setSearchResults([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const debouncedSearch = _.debounce(search, 500);

  // useEffect(() => {
  //   if (!searchQuery) {
  //     setSearchResults([]);
  //     return;
  //   } else {
  //     debouncedSearch();
  //   }
  // }, [searchQuery, getAccessTokenSilently, debouncedSearch]);

  return (
    <Autocomplete
      // key={searchResults.length}
      sx={{}}
      multiple
      options={searchResults}
      filterOptions={(x) => x}
      getOptionLabel={(option) => {
        const row = !!option?.row ? option.row : option;

        return GetHeaderText(row, row.columns || []) || "";
      }} // Adjust this based on the structure of your result object
      value={selectedOptions}
      loading={loading}
      onChange={(_, value) => {
        // const parsedVals = value.map((v: any) => {
        //   if (!!v?.row) return v.row;
        //   return v;
        // });
        // console.log("here");
        setSelectedOptions(value);
      }}
      onInputChange={(_, value) => debouncedSearch(value)}
      renderOption={(props, option, { selected }) => (
        <Box
          component="li"
          {...props}
          key={option._id}
          sx={{
            fontSize: "1rem",
            padding: "10px !important",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 255, 0.1)",
            },
          }}
        >
          {GetHeaderText(option?.row, option?.columns)} -{" "}
          {GetSubheaderText(option?.row, option?.columns)}
        </Box>
      )}
      renderInput={(params) => (
        <TextField {...params} label="Search" variant="outlined" autoFocus />
      )}
    />
  );
};

export default PropertySearch;
