import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import { DataGridPremium, useGridApiRef } from "@mui/x-data-grid-premium";
import "./App.css";
import { useLocation, useNavigate } from "react-router-dom";
//import { update } from "lodash";
import DataSaverOnIcon from "@mui/icons-material/DataSaverOn";
import { useContext, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import { GET_ROWS, INSERT_MANY_ROWS } from "./graphql-ops";
import { useQuery, useMutation } from "@apollo/client";
import { MessageStatus, RefetchContext } from "./refetchProvider";
import { SocketIOContext } from "./socketIOProvider";
import _ from "lodash";

const addedRows = new Map();

export default function MassImport() {
  let { state } = useLocation();

  const apiRef = useGridApiRef();

  const navigate = useNavigate();

  const context = useContext(RefetchContext);

  const [columnMenuOpen, setColumnMenuOpen] = useState<string>("");
  const [newRows, setNewRows] = useState<any[]>([]);
  const [showTutorial, setShowTutorial] = useState<boolean>(
    localStorage.getItem("showTutorial") === "false" ? false : true
  );
  const [disableTutotial, setDisableTutorial] = useState<boolean>(true);
  const [newRowsNumber, setNewRowsNumber] = useState(0);

  const view = JSON.parse(state?.view);
  const columns = JSON.parse(state?.columns)
    .filter(
      (column: any) =>
        !(
          [
            "date",
            "actions",
            "__check__",
            "__reorder__",
            "new-column",
          ].includes(column.field) ||
          ["related", "lastActivity"].includes(column.type)
        )
    )
    .map((column: any) => {
      return {
        ...column,
        type: "text",
      };
    });
  const initialState = {
    ...JSON.parse(state?.initialState),
    filter: {},
    sorting: {},
    pinnedColumns: { left: [], right: [] },
  };

  const { refetch: refetchRows } = useQuery(GET_ROWS, {
    variables: {
      input: view.rootViewId || view._id,
    },
  });

  const [createRows, { data, loading, error }] = useMutation(INSERT_MANY_ROWS);

  const rowCount = 10000;

  const emptyRows = Array(rowCount)
    .fill(0)
    .map((s, i) => ({ _id: i }));

  //   const [rows, setRows] = useState<any[]>(() => {
  //     return Array(rowCount)
  //       .fill(0)
  //       .map((s, i) => ({ _id: i }));
  //   });

  const [rows, setRows] = React.useState(() => {
    return Array(rowCount)
      .fill(0)
      .map((s, i) => ({ _id: i }));
  });

  const handleTutorialClose = () => {
    setShowTutorial(false);
    localStorage.setItem("showTutorial", (!disableTutotial).toString());
  };

  const viewIdToPass = view.rootViewId || view._id;

  const { socket } = React.useContext(SocketIOContext) as any;

  React.useEffect(() => {
    if (
      !_.isNil(socket) &&
      !_.isNil(viewIdToPass) &&
      !_.isEmpty(viewIdToPass)
    ) {
      socket.emit("join-room", viewIdToPass);
    }
  }, [viewIdToPass, socket]);

  return (
    <div
      style={{
        height: "calc(100vh - 56px)",
      }}
    >
      <Dialog
        open={showTutorial}
        onClose={handleTutorialClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Import Instructions"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Simply copy and paste your data from excel into the grid below. Make
            sure the columns line up and then click "Save Data". <br /> <br />{" "}
            Note: For imports of more than 100 records, hit CTRL-A on your
            keyboard first to select the entire grid.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <FormControlLabel
            sx={{
              marginLeft: "10px",
            }}
            control={
              <Checkbox
                checked={disableTutotial}
                onChange={() => {
                  setDisableTutorial(!disableTutotial);
                }}
              />
            }
            label="Disable popup"
          />

          <Button onClick={handleTutorialClose} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <div
        className="pageHeader"
        style={{ height: "56px", padding: "0px 20px" }}
      >
        <Grid container justifyContent="space-between">
          <Grid item>
            <Box sx={{ display: "flex", alignItems: "center", height: "56px" }}>
              <DataSaverOnIcon sx={{ mr: 1 }} />
              <Typography
                variant="h6"
                component="h2"
                sx={{
                  fontSize: "18px",
                }}
              >
                Import to {view?.name}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ display: "flex", alignItems: "center", height: "56px" }}>
              <Button
                style={{
                  border: "1px solid rgb(223, 225, 228)",
                  height: "28px",
                  fontSize: "13px",
                  color: "rgb(60, 65, 73)",
                  backgroundColor: "rgb(255, 255, 255)",
                  boxShadow: "rgb(0 0 0 / 9%) 0px 1px 1px",
                  textTransform: "none",
                  fontWeight: 500,
                  marginRight: "15px",
                }}
                onClick={() => {
                  setNewRowsNumber(0);
                  addedRows.clear();
                  setRows(emptyRows);
                }}
              >
                <DeleteIcon sx={{ mr: 1, width: "20px" }} />
                Clear Data
              </Button>
              <Button
                style={{
                  border: "1px solid rgb(223, 225, 228)",
                  height: "28px",
                  fontSize: "13px",
                  color: "rgb(60, 65, 73)",
                  backgroundColor: "rgb(255, 255, 255)",
                  boxShadow: "rgb(0 0 0 / 9%) 0px 1px 1px",
                  textTransform: "none",
                  fontWeight: 500,
                  marginRight: "15px",
                }}
                onClick={async () => {
                  // const dataToInsert = apiRef.current.getRowModels();
                  const newRows = Array.from(addedRows, ([id, value]) => value);
                  newRows.forEach((row) => {
                    delete row._id;
                  });

                  console.log(newRows);

                  const dataToInsert = newRows.map((newRow) => {
                    return {
                      viewId: { link: view.rootViewId || view._id },
                      rowObject: JSON.stringify(newRow),
                      isDeleted: false,
                    };
                  });
                  try {
                    const data = await createRows({
                      variables: {
                        data: dataToInsert,
                      },
                      update: async (cache: any, mutationResult: any) => {
                        // console.log({ mutationResult })
                        socket.emit(
                          "update-rows",
                          viewIdToPass,
                          _.get(
                            mutationResult,
                            "data.insertManyRows.insertedIds",
                            []
                          )
                        );
                      },
                    });

                    await refetchRows();

                    context?.setEmailMessage({
                      code: MessageStatus.SUCCESS,
                      message: JSON.stringify("Data imported successfully."),
                    });
                    addedRows.clear();
                    setNewRowsNumber(0);
                    navigate(-1);
                  } catch (e) {
                    context?.setEmailMessage({
                      code: MessageStatus.ERROR,
                      message: JSON.stringify(
                        "Something went wrong. Contact info@mercero.com if you see this message."
                      ),
                    });
                  }
                }}
              >
                <SaveIcon sx={{ mr: 1, width: "20px" }} />
                Import {addedRows.size} records
              </Button>
            </Box>
          </Grid>
        </Grid>
      </div>
      <DataGridPremium
        columns={columns}
        initialState={initialState}
        rows={rows}
        cellSelection
        hideFooter
        apiRef={apiRef}
        //rowReordering
        key={`${view._id}-import`}
        onColumnHeaderClick={(params, event, details) => {
          event.defaultMuiPrevented = true;
          if (params.field === columnMenuOpen) {
            apiRef.current.hideColumnMenu();
            setColumnMenuOpen("");
          } else {
            setColumnMenuOpen(params.field);
            apiRef.current.showColumnMenu(params.field);
          }
        }}
        // pinnedRows={pinnedRow}
        onRowOrderChange={(newOrder) => {
          console.log(newOrder);
        }}
        processRowUpdate={(newRow: any, oldRow: any) => {
          addedRows.set(newRow._id, newRow);
          setNewRowsNumber(addedRows.size);
          //   setNewRows([...newRows, newRow]);
          return newRow;
        }}
        style={{ fontSize: "13px" }}
        rowHeight={35}
        sx={{
          ".MuiDataGrid-columnHeader": {
            height: "40px !important",
          },
          "& .MuiDataGrid-pinnedColumnHeaders": {
            backgroundColor: "#f5f6f8",
            boxShadow: "2px 0px 4px -2px rgba(0, 0, 0, 0.21)",
            height: "56px",
            paddingTop: "3px",
          },
          ".MuiDataGrid-columnHeaders": {
            height: "42px !important",
            minHeight: "42px !important",
          },
          "& .MuiDataGrid-cell": {
            borderRight: `1px solid rgba(0,0,0,.1)`,
          },
          "& .actions-cell": {
            borderRight: "none",
          },
          "& .MuiDataGrid-cellCheckbox": {
            borderRight: "none",
          },
        }}
        disableRowSelectionOnClick
        getRowId={(row) => row._id}
        // processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={(error) => {
          console.log("error");
          console.log(error);
        }}
      />
    </div>
  );
}
