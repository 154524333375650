import { createContext, useEffect, useState, useContext, useRef } from "react";
import "./App.css";
import { gql, useApolloClient } from "@apollo/client";
import { GET_ROWS } from "./graphql-ops";
import { useAuth0 } from "@auth0/auth0-react";
import { RefetchContext } from "./refetchProvider";
import { GlobalContext } from "./globalContext";
import { GetHeaderText } from "./DatagridFunctions";

export const updateRowTags = (
  rowContext: RowContextType | null,
  listId: string,
  rowObject: any,
  columns: any[]
) => {
  if (!rowContext) return;

  if (!rowContext.tagsMap.has(listId)) {
    rowContext.tagsMap.set(listId, new Map<string, string[]>());
  }
  const viewTags = rowContext.tagsMap.get(listId)!;

  columns.forEach((column) => {
    if (column.type === "type") {
      const tags = rowObject[column.field];
      if (tags) {
        if (!viewTags.has(column.field)) {
          viewTags.set(column.field, []);
        }
        const fieldTags = viewTags.get(column.field)!;

        const addTag = (tag: string) => {
          if (!fieldTags.includes(tag?.trim()) && tag !== "" && tag !== " ") {
            fieldTags.push(tag?.trim());
          }
        };

        if (Array.isArray(tags)) {
          tags.forEach((item: string | string[]) => {
            if (Array.isArray(item)) {
              item.forEach(addTag);
            } else if (typeof item === "string") {
              addTag(item?.trim());
            }
          });
        } else if (typeof tags === "string") {
          addTag(tags?.trim());
        }

        viewTags.set(column.field, fieldTags);
      }
    }
  });

  rowContext.tagsMap.set(listId, viewTags);
};

interface RowContextType {
  rows: Map<string, any>;
  setAllRows: React.Dispatch<React.SetStateAction<Map<string, any>>>;
  tagsMap: Map<string, Map<string, string[]>>;
  setTagsMap: React.Dispatch<
    React.SetStateAction<Map<string, Map<string, string[]>>>
  >;
}

export const RowContext = createContext<RowContextType | null>(null);

interface RowProviderProps {
  children: React.ReactNode;
}

export enum MessageStatus {
  SUCCESS = "success",
  ERROR = "error",
}

const doViewsMatch = (views1: any, views2: any) => {
  const ids1 = views1?.map((view: any) => view._id).sort();
  const ids2 = views2?.map((view: any) => view._id).sort();
  return JSON.stringify(ids1) === JSON.stringify(ids2);
};

const RowProvider: React.FC<RowProviderProps> = ({ children }) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const client = useApolloClient();
  const context = useContext(RefetchContext);
  const globalContext = useContext(GlobalContext);

  const [views, setViews] = useState<any>(undefined);
  const [allRows, setAllRows] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [tagsMap, setTagsMap] = useState<any>(new Map());

  const currentViewsRef = useRef<string[]>([]);

  useEffect(() => {
    if (context?.allViews && !doViewsMatch(views, context?.allViews)) {
      setViews(context?.allViews);
      currentViewsRef.current = context?.allViews.map((view: any) => view._id);
    }
  }, [context?.allViews, views]);

  useEffect(() => {
    let activeRequests: { [key: string]: AbortController } = {};

    const fetchRowsForViews = async () => {
      if (!views || views.length === 0) {
        setLoading(false);
        return;
      }

      setLoading(true);

      // Cancel any active requests for views that are no longer current
      Object.keys(activeRequests).forEach((viewId) => {
        if (!currentViewsRef.current.includes(viewId)) {
          activeRequests[viewId].abort();
          delete activeRequests[viewId];
        }
      });

      try {
        // Fetch from cache first
        const accessToken = await getAccessTokenSilently();

        const cacheResults = await Promise.all(
          views.map(async (view: any) => {
            // const result = await client.query({
            //   query: GET_ROWS,
            //   variables: { input: view._id },
            //   fetchPolicy: "cache-first",
            // });

            const result = await fetch(`https://crm.mercero-api.com/graphql`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
              },
              body: JSON.stringify({
                query: `query GetRowsByViewId($input: ObjectId!) {
                          GetRowsByViewId(input: $input) {
                            _id
                            createdAt
                            rowId
                            rowObject
                            updatedAt
                            viewId {
                              _id
                            }
                            relatedRows {
                              tags
                              rows {
                                _id
                                rowObject
                                viewId {
                                  name
                                  _id
                                }
                              }
                            }
                            latestActivity {
                              commentId {
                                comment
                                userId {
                                  name
                                }
                              }
                              timestamp
                            }
                            isDeleted
                          }
                        }
                      `,
                variables: { input: view?._id },
              }),
            });

            const response = await result.json();
            return {
              viewId: view._id,
              rows: processRows(response?.data?.GetRowsByViewId, views),
            };
          })
        );

        updateAllRows(cacheResults);

        // // Fetch from network to update the data
        // const networkPromises = views.map(async (view: any) => {
        //   const controller = new AbortController();
        //   activeRequests[view._id] = controller;

        //   try {
        //     const result = await client.query({
        //       query: GET_ROWS,
        //       variables: { input: view._id },
        //       fetchPolicy: "network-only",
        //       context: {
        //         fetchOptions: {
        //           signal: controller.signal,
        //         },
        //       },
        //     });
        //     delete activeRequests[view._id];
        //     return {
        //       viewId: view._id,
        //       rows: processRows(result.data.GetRowsByViewId, views),
        //     };
        //   } catch (error: any) {
        //     if (error.name !== "AbortError") {
        //       throw error;
        //     }
        //   }
        // });

        // const networkResults = await Promise.all(networkPromises);
        // updateAllRows(networkResults.filter(Boolean));
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchRowsForViews();

    return () => {
      Object.values(activeRequests).forEach((controller) => controller.abort());
    };
  }, [views, client]);

  const processRows = (rows: any[], views: any[]) => {
    const rowView = views.find((view) => view?._id === rows?.[0]?.viewId?._id);

    return rows.reduce((map: any, row: any) => {
      const relatedRows = row?.relatedRows?.rows?.map((r: any) => {
        const parsedRow = JSON.parse(r?.rowObject);
        const relatedView = views?.find((v: any) => v._id === r?.viewId?._id);
        return {
          _id: r?._id,
          merc_viewId: r?.viewId?._id,
          merc_viewName: r?.viewId?.name,
          merc_Header: GetHeaderText(parsedRow, relatedView?.columns || []),
          ...parsedRow,
        };
      });
      try {
        const rowObject = JSON.parse(row.rowObject);

        if (rowObject) {
          updateRowTags(
            {
              rows: map,
              setAllRows: setAllRows,
              tagsMap: tagsMap,
              setTagsMap: setTagsMap,
            },
            row.viewId?._id,
            rowObject,
            rowView?.columns
          );
        }
      } catch (e) {
        console.error(e);
      }

      map.set(row._id, {
        ...JSON.parse(row.rowObject),
        _id: row._id,
        relatedRows: relatedRows,
        latestActivity: row.latestActivity,
        createdAt: row.createdAt,
        latestEmail: row.latestEmail?.timestamp,
      });
      return map;
    }, new Map());
  };

  const updateAllRows = (results: any[]) => {
    const newRows = results.reduce((acc, result) => {
      if (result && currentViewsRef.current.includes(result.viewId)) {
        acc[result.viewId] = result.rows;
      }
      return acc;
    }, {});
    globalContext?.setAllRows((prevRows) => ({ ...prevRows, ...newRows }));
  };

  return (
    <RowContext.Provider
      value={{ rows: allRows, setAllRows, tagsMap, setTagsMap }}
    >
      {children}
    </RowContext.Provider>
  );
};

export default RowProvider;
