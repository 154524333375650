import gql from "graphql-tag";

export const GET_VIEWS = gql`
  query {
    views {
      _id
      initialState
      name
      rootViewId
      columns {
        aggregable
        align
        computedWidth
        editable
        field
        filterable
        flex
        groupable
        hasBeenResized
        headerName
        hide
        hideable
        minWidth
        pinnable
        resizable
        sortable
        type
        display
        width
      }
      isDeleted
    }
  }
`;

export const GET_USER = gql`
  query GetUser($query: UserQueryInput!) {
    user(query: $query) {
      _id
      name
    }
  }
`;

export const GET_ONE_VIEW = gql`
  query GetViewByID($query: ViewQueryInput!) {
    view(query: $query) {
      _id
      initialState
      name
      rootViewId
      isPublic
      icon {
        fileString
        color
      }
      columns {
        aggregable
        align
        computedWidth
        editable
        field
        filterable
        flex
        groupable
        hasBeenResized
        headerName
        hide
        hideable
        minWidth
        pinnable
        resizable
        sortable
        type
        display
        width
      }
      isDeleted
    }
  }
`;

export const GET_FIRST_ROWS = gql`
  query GetFirstRowsByViewId($query: RowQueryInput) {
    rows(query: $query, limit: 100) {
      _id
      createdAt
      rowId
      rowObject
      updatedAt
      relatedRows {
        tags
        rows {
          _id
          rowObject
          viewId {
            name
            _id
          }
        }
      }
      latestActivity {
        commentId {
          comment
          userId {
            name
          }
        }
        timestamp
      }
      isDeleted
    }
  }
`;

// latestEmail {
//   timestamp
// }

export const GET_CAMPAIGNS = gql`
  query GetCampaigns($query: SendgridcampaignQueryInput) {
    sendgridcampaigns(query: $query) {
      _id
      subject
      createdAt
      rowIds
      processed
      contacts {
        click
        delivered
        email
        first_name
        open
        click
        processed
        rowId
        unsubscribe
      }
    }
  }
`;

export const GET_CAMPAIGN = gql`
  query GetCampaigns($query: SendgridcampaignQueryInput) {
    sendgridcampaign(query: $query) {
      _id
      subject
      createdAt
      rowIds
      listId
      viewId
      contacts {
        click
        delivered
        email
        first_name
        open
        click
        processed
        rowId
      }
    }
  }
`;

export const GET_ROWS = gql`
  query GetRowsByViewId($input: ObjectId) {
    GetRowsByViewId(input: $input) {
      _id
      createdAt
      rowId
      rowObject
      updatedAt

      viewId {
        _id
      }

      relatedRows {
        tags
        rows {
          _id
          rowObject
          viewId {
            name
            _id
          }
        }
      }
      latestActivity {
        commentId {
          comment
          userId {
            name
          }
        }
        timestamp
      }
      isDeleted
    }
  }
`;

export const GET_ALL_VIEWS = gql`
  query Get_All_User_Views($query: ViewQueryInput!) {
    views(query: $query) {
      _id
      rootViewId
      initialState
      name
      isPublic
      position
      icon {
        color
        fileString
      }
      columns {
        aggregable
        align
        computedWidth
        editable
        field
        filterable
        flex
        groupable
        hasBeenResized
        headerName
        hide
        hideable
        minWidth
        pinnable
        resizable
        sortable
        type
        display
        width
      }
      isDeleted
    }
  }
`;

export const UPDATE_VIEW = gql`
  mutation UpdateView($view: ViewUpdateInput!, $query: ViewQueryInput!) {
    updateOneView(set: $view, query: $query) {
      _id
      initialState
      name
      workspaceId
      rootViewId
      isPublic
      icon {
        fileString
        color
      }
      columns {
        aggregable
        align
        computedWidth
        editable
        field
        filterable
        flex
        groupable
        hasBeenResized
        headerName
        hide
        hideable
        minWidth
        pinnable
        resizable
        sortable
        type
        display
        width
      }
      isDeleted
    }
  }
`;

export const UPDATE_MANY_ROWS = gql`
  mutation UpdateManyRows($query: RowQueryInput, $set: RowUpdateInput!) {
    updateManyRows(query: $query, set: $set) {
      matchedCount
      modifiedCount
    }
  }
`;

export const UPDATE_ONE_ROW_MUTATION = gql`
  mutation UpsertOneRowMutation($query: RowQueryInput, $set: RowUpdateInput!) {
    updateOneRow(query: $query, set: $set) {
      _id
      createdAt
      rowId
      rowObject
      updatedAt
      viewId {
        name
        _id
        rootViewId
        columns {
          field
          headerName
          type
          display
        }
      }

      relatedRows {
        tags
        rows {
          _id
          rowObject
          viewId {
            name
            _id
          }
        }
      }
      latestActivity {
        commentId {
          comment
          userId {
            name
          }
        }
        timestamp
      }
      isDeleted
    }
  }
`;

export const CREATE_ROW_MUTATION = gql`
  mutation CreateRowMutation($data: RowInsertInput!) {
    insertOneRow(data: $data) {
      _id
      createdAt
      rowId
      rowObject
      updatedAt
      viewId {
        name
        _id
        rootViewId
        columns {
          field
          headerName
          type
          display
        }
      }
      relatedRows {
        tags
        rows {
          _id
          rowObject
          viewId {
            name
            _id
          }
        }
      }
      latestActivity {
        commentId {
          comment
          userId {
            name
          }
        }
        timestamp
      }
      isDeleted
    }
  }
`;

export const GET_ALL_WORKSPACES_QUERY = gql`
  query GetWorkspacesQuery($query: WorkspaceQueryInput!) {
    workspace(query: $query) {
      name
      isDeleted
      atlasEnabled
    }
  }
`;

export const GET_ROW1 = gql`
  query GetRowById1($input: GetRowByIdInput!) {
    GetRowById1(input: $input) {
      _id
      createdAt
      rowId
      rowObject
      updatedAt
      relatedRows {
        tags
        rows {
          _id
          rowObject
          viewId {
            name
            _id
          }
        }
      }
      latestActivity {
        commentId {
          comment
          userId {
            name
          }
        }
        timestamp
      }
      isDeleted
    }
  }
`;

export const GET_ROWS_BY_QUERY = gql`
  query GetRowsByQuery($query: RowQueryInput) {
    rows(query: $query) {
      _id
      createdAt
      rowId
      rowObject
      updatedAt
      relatedRows {
        tags
        rows {
          _id
          rowObject
          viewId {
            name
            _id
          }
        }
      }
      latestActivity {
        commentId {
          comment
          userId {
            name
          }
        }
        timestamp
      }
      isDeleted
    }
  }
`;

export const GET_ROW = gql`
  query GetRowById($input: GetRowByIdInput!) {
    GetRowById(input: $input) {
      _id
      createdAt
      rowId
      rowObject
      updatedAt
      viewId {
        _id
        name
        initialState
        columnsJsonString
        columns {
          field
          headerName
          type
          display
        }
      }
      relatedRows {
        tags
        rows {
          _id
          rowObject
          viewId {
            initialState
            name
            _id
            columnsJsonString
          }
        }
      }
      latestActivity {
        commentId {
          comment
          userId {
            name
          }
        }
        timestamp
      }
      isDeleted
    }
  }
`;

export const INSERT_MANY_ROWS = gql`
  mutation InsertRows($data: [RowInsertInput!]!) {
    insertManyRows(data: $data) {
      insertedIds
    }
  }
`;

export const CREATE_ONE_WORKSPACE = gql`
  mutation CreateWorkspaceMutation($data: WorkspaceInsertInput!) {
    insertOneWorkspace(data: $data) {
      name
      isDeleted
    }
  }
`;

export const DELETE_ONE_WORKSPACE = gql`
  mutation DeleteOneWorkspaceMutation($query: WorkspaceQueryInput!) {
    deleteOneWorkspace(query: $query) {
      name
      isDeleted
    }
  }
`;

export const GET_NOTIFICATIONS = gql`
  query getNotifications($query: NotificationQueryInput!) {
    notifications(query: $query) {
      _id
      read
      createdAt
      commentId {
        rowId {
          _id
          viewId {
            _id
            name
            columns {
              field
              type
              display
            }
          }
          rowObject
        }
      }
      initiatedByUserId {
        name
      }
      userId {
        name
      }
    }
  }
`;

export const UPDATE_ONE_NOTIFICATION = gql`
  mutation UpdateOneNotificationMutation(
    $query: NotificationQueryInput!
    $set: NotificationUpdateInput!
  ) {
    updateOneNotification(query: $query, set: $set) {
      _id
      read
    }
  }
`;

export const GET_HISTORIES = gql`
  query HistoriesQuery($userId: String!) {
    histories(query: { userId: $userId }, sortBy: _ID_DESC) {
      rowId {
        _id
        rowObject
        viewId {
          columns {
            field
            type
            display
          }
        }
      }
    }
  }
`;

export const UPDATE_ONE_WORKSPACE = gql`
  mutation UpdateWorkspaceMutation(
    $query: WorkspaceQueryInput!
    $set: WorkspaceUpdateInput!
  ) {
    updateOneWorkspace(query: $query, set: $set) {
      name
      isDeleted
    }
  }
`;

export const GET_ALL_WORKSPACES = gql`
  query GetWorkspaces($query: WorkspaceQueryInput!) {
    workspaces(query: $query) {
      _id
      name
      atlasEnabled
      avatarMetaData {
        fileString
      }
      isDeleted
      members {
        _id
        name
        email
        auth0Sub
      }
    }
  }
`;

// export const DELETE_ROW = gql`
//   mutation DeleteRowMutation($query: RowQueryInput!) {
//     deleteOneRow(query: $query) {
//       _id
//     }
//   }
// `;

export const GET_COMMENTS = gql`
  query GetCommentsByRowIdQuery($query: CommentQueryInput!) {
    comments(query: $query) {
      _id
      comment
      userId {
        name
      }
      rowId {
        _id
        rowObject
      }
      fileDataJSONString
      createdAt
      fileData {
        fileName
        fileType
        key
        url
      }
      isDeleted
    }
  }
`;

export const CREATE_COMMENT_MUTATION = gql`
  mutation CreateCommentMutation($data: CommentInsertInput!) {
    insertOneComment(data: $data) {
      _id
      comment
      userId {
        name
      }
      rowId {
        _id
        rowObject
      }
      createdAt
      isDeleted
    }
  }
`;

export const UPDATE_ONE_COMMENT = gql`
  mutation UpdateCommentMutation(
    $query: CommentQueryInput!
    $set: CommentUpdateInput!
  ) {
    updateOneComment(query: $query, set: $set) {
      _id
      comment
      userId {
        name
      }
      rowId {
        _id
        rowObject
      }
      createdAt
      isDeleted
    }
  }
`;

export const GET_TODO = gql`
  query GetTodoByIdQuery($query: TodoQueryInput!) {
    todo(query: $query) {
      _id
      priority
      status
      text
      userId {
        name
      }
      rowId {
        _id
        rowObject
        viewId {
          _id
          name
          initialState
          columnsJsonString
          columns {
            field
            headerName
            type
            display
          }
        }
      }
      workspaceId
      dueDate
      createdAt
      userId {
        auth0Sub
      }
      assignedTo {
        _id
        name
        email
        auth0Sub
      }
      isDeleted
    }
  }
`;

export const GET_TODOS = gql`
  query GetTodosByRowIdQuery($query: TodoQueryInput!) {
    todos(query: $query, limit: 5000) {
      _id
      priority
      status
      text
      userId {
        name
      }
      rowId {
        _id
        rowObject
        viewId {
          _id
          name
          initialState
          columnsJsonString
          columns {
            field
            headerName
            type
            display
          }
        }
      }
      dueDate
      createdAt
      userId {
        auth0Sub
      }
      assignedTo {
        _id
        name
        email
        auth0Sub
      }
      isDeleted
    }
  }
`;

export const CREATE_TODO_MUTATION = gql`
  mutation CreateTodoMutation($data: TodoInsertInput!) {
    insertOneTodo(data: $data) {
      _id
      priority
      status
      text
      userId {
        name
      }
      rowId {
        _id
        rowObject
      }
      dueDate
      createdAt
      userId {
        auth0Sub
      }
      assignedTo {
        _id
        name
        email
        auth0Sub
      }
      isDeleted
    }
  }
`;

export const UPDATE_ONE_TODO = gql`
  mutation UpdateTodoMutation($query: TodoQueryInput!, $set: TodoUpdateInput!) {
    updateOneTodo(query: $query, set: $set) {
      _id
      priority
      status
      text
      userId {
        name
      }
      rowId {
        _id
        rowObject
      }
      dueDate
      createdAt
      userId {
        auth0Sub
      }
      isDeleted
      assignedTo {
        _id
        auth0Sub
      }
    }
  }
`;

export const CREATE_NOTIFICATION_MUTATION = gql`
  mutation CreateNotificationMutation($data: NotificationInsertInput!) {
    insertOneNotification(data: $data) {
      _id
    }
  }
`;
