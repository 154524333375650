import * as React from "react";
import Button from "@mui/material/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import useUpsertView from "./useUpsertView";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import NumbersIcon from "@mui/icons-material/Numbers";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CheckIcon from "@mui/icons-material/Check";
import EmailIcon from "@mui/icons-material/Email";
import StyleIcon from "@mui/icons-material/Style";
import LoopIcon from "@mui/icons-material/Loop";
import { ContentCut } from "@mui/icons-material";
import BusinessIcon from "@mui/icons-material/Business";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import Person2Icon from "@mui/icons-material/Person2";
import TimelineIcon from "@mui/icons-material/Timeline";
import Header1 from "./icons/header1.svg";
import Header2 from "./icons/header2.svg";
import Subheader1 from "./icons/subheader1.svg";
import Subheader2 from "./icons/subheader2.svg";
import { NestedMenuItem } from "mui-nested-menu";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useContext, useState } from "react";
import { RefetchContext } from "./refetchProvider";
import { GetListIcon } from "./icons";
import PhoneIcon from "@mui/icons-material/Phone";
import MapIcon from "@mui/icons-material/Map";
import HeightIcon from "@mui/icons-material/Height";
import MultipleStopIcon from "@mui/icons-material/MultipleStop";
import CloseIcon from "@mui/icons-material/Close";
import LinkIcon from "@mui/icons-material/Link";
import { StyledSelected } from "./rowTodos";
import GradeIcon from "@mui/icons-material/Grade";

interface EditColumnProps {
  editingColumn: boolean;
  setEditingColumn: (editingColumn: boolean) => void;
  column: any;
  columns: any;
  refetchView?: any;
  selectedView?: any;
}

export default function EditColumn(props: EditColumnProps) {
  const {
    editingColumn,
    setEditingColumn,
    column,
    columns,
    refetchView,
    selectedView,
  } = props;

  const [newColName, setNewColName] = React.useState(column?.headerName || "");
  const [newColDisplay, setNewColDisplay] = React.useState(
    column?.display || ""
  );
  const [newColType, setNewColType] = React.useState(column?.type || "string");

  const { updateView } = useUpsertView();

  React.useEffect(() => {
    setNewColName(column?.headerName || "");
    setNewColType(column?.type || "string");
    setNewColDisplay(column?.display || "");
  }, [column]);

  return (
    <Dialog onClose={() => setEditingColumn(false)} open={editingColumn}>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          const newColumns = columns.map((col: any) => {
            if (col?.field === column?.field) {
              return {
                ...col,
                headerName: newColName,
                type: newColType,
                display: newColDisplay,
              };
            }
            return col;
          });

          const updatedCols = newColumns.map((col: any) => ({
            field: col?.field,
            headerName: col?.headerName,
            type: col?.type,
            width: col?.width,
            display: col?.display,
          }));

          const { data: newView } = await updateView({
            variables: {
              query: {
                _id: selectedView?._id,
              },
              view: {
                columns: updatedCols,
                columnsJsonString: JSON.stringify(updatedCols),
              },
            },
          });
          await refetchView();
          setEditingColumn(false);
        }}
      >
        <DialogTitle>Edit Column</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            value={newColName}
            onChange={(e) => setNewColName(e.target.value)}
            margin="dense"
            size="small"
            label="Column Name"
            variant="standard"
            fullWidth
          />
          <div>
            <span
              style={{
                marginTop: "25px",
                display: "block",
                marginBottom: "5px",
              }}
            >
              Column Type
            </span>
            <ColumnTypeMenu
              newColType={newColType}
              setNewColType={setNewColType}
            />
          </div>
          <div>
            <span
              style={{
                marginTop: "25px",
                display: "block",
                marginBottom: "5px",
              }}
            >
              Display
            </span>
            <ColumnDisplayMenu
              newColDisplay={newColDisplay}
              setNewColDisplay={setNewColDisplay}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={() => setEditingColumn(false)}>
            Cancel
          </Button>
          <Button type="submit" disabled={newColName?.length < 1}>
            Save Changes
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export function ColumnDisplayMenu(props: {
  newColDisplay: string;
  setNewColDisplay: any;
}) {
  const { newColDisplay, setNewColDisplay } = props;

  const [displayTypeOpen, setDisplayTypeOpen] = useState(false);

  const handleDisplayClose = () => {
    setDisplayTypeOpen(false);
  };

  const handleDisplayOpen = () => {
    setDisplayTypeOpen(true);
  };

  return (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      style={{ width: "250px" }}
      sx={{
        "& .MuiListItemIcon-root": {
          minWidth: "30px",
          width: "30px",
        },
      }}
      value={newColDisplay}
      open={displayTypeOpen}
      onClose={handleDisplayClose}
      onOpen={handleDisplayOpen}
      label="Display"
      onChange={(newValue) => {
        setNewColDisplay(newValue.target.value);
      }}
    >
      <MenuItem value={"header1"}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ListItemIcon>
            <img style={{ width: "22px" }} src={Header1} alt="Header 1 icon" />
            {/* <PeopleIcon fontSize="small" /> */}
          </ListItemIcon>
          <div>Header 1</div>
        </div>
      </MenuItem>
      <MenuItem value={"header2"}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ListItemIcon>
            <img style={{ width: "22px" }} src={Header2} alt="Header 2 icon" />
            {/* <PersonIcon fontSize="small" /> */}
          </ListItemIcon>
          <div>Header 2</div>
        </div>
      </MenuItem>
      <MenuItem value={"subheader1"}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ListItemIcon>
            <img
              style={{ width: "22px" }}
              src={Subheader1}
              alt="Subheader 1 icon"
            />
            {/* <Person2Icon fontSize="small" /> */}
          </ListItemIcon>
          <div>Subheader 1</div>
        </div>
      </MenuItem>
      <MenuItem value={"subheader2"}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ListItemIcon>
            <img
              style={{ width: "22px" }}
              src={Subheader2}
              alt="Subheader 2 icon"
            />
            {/* <BusinessIcon fontSize="small" /> */}
          </ListItemIcon>
          <div>Subheader 2</div>
        </div>
      </MenuItem>
      <MenuItem value={"keyfield"}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ListItemIcon>
            <GradeIcon fontSize="small" />
            {/* <BusinessIcon fontSize="small" /> */}
          </ListItemIcon>
          <div>Key Field</div>
        </div>
      </MenuItem>
      <MenuItem value={"none"}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ListItemIcon>
            <CloseIcon fontSize="small" />
          </ListItemIcon>
          <div>None</div>
        </div>
      </MenuItem>
    </Select>
  );
}

export function ColumnTypeMenu(props: {
  newColType: string;
  setNewColType: any;
}) {
  const { newColType, setNewColType } = props;

  const context = useContext(RefetchContext);

  const rootViews = context?.allViews?.filter((view) => !!!view.rootViewId);

  const [colTypeOpen, setColTypeOpen] = useState(false);

  const handleClose = () => {
    setColTypeOpen(false);
  };

  const handleOpen = () => {
    setColTypeOpen(true);
  };

  return (
    <StyledSelected
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      style={{ width: "250px" }}
      sx={{
        "& .MuiListItemIcon-root": {
          minWidth: "30px",
          width: "30px",
        },
      }}
      value={newColType}
      open={colTypeOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      label="Type"
      onChange={(newValue) => {
        setNewColType(newValue.target.value);
      }}
    >
      <span
        style={{
          marginLeft: "15px",
          fontSize: "13px",
          marginBottom: "10px",
          display: "block",
        }}
      >
        Fields Types
      </span>
      <MenuItem value={"string"}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ListItemIcon>
            <TextFieldsIcon fontSize="small" />
          </ListItemIcon>
          <div>Text</div>
        </div>
      </MenuItem>
      <MenuItem value={"number"}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ListItemIcon>
            <NumbersIcon fontSize="small" />
          </ListItemIcon>
          <div>Number</div>
        </div>
      </MenuItem>
      <MenuItem value={"date"}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ListItemIcon>
            <CalendarMonthIcon fontSize="small" />
          </ListItemIcon>
          <div>Date</div>
        </div>
      </MenuItem>
      {/* <MenuItem value={"dateTime"}>Date & Time</MenuItem> */}
      <MenuItem value={"boolean"}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ListItemIcon>
            <CheckIcon fontSize="small" />
          </ListItemIcon>
          <div>Yes/No</div>
        </div>
      </MenuItem>
      {/* <MenuItem value={"singleSelect"}>Select</MenuItem> */}
      <MenuItem value={"type"}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ListItemIcon>
            <StyleIcon fontSize="small" />
          </ListItemIcon>
          <div>Tags</div>
        </div>
      </MenuItem>
      <MenuItem value={"contactName"}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ListItemIcon>
            <PersonIcon fontSize="small" />
          </ListItemIcon>
          <div>Contact Name</div>
        </div>
      </MenuItem>
      <MenuItem value={"companyName"}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ListItemIcon>
            <BusinessIcon fontSize="small" />
          </ListItemIcon>
          <div>Company Name</div>
        </div>
      </MenuItem>
      <MenuItem value={"email"}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ListItemIcon>
            <EmailIcon fontSize="small" />
          </ListItemIcon>
          <div>Email</div>
        </div>
      </MenuItem>
      <MenuItem value={"phone"}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ListItemIcon>
            <PhoneIcon fontSize="small" />
          </ListItemIcon>
          <div>Phone</div>
        </div>
      </MenuItem>
      <MenuItem value={"link"}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ListItemIcon>
            <LinkIcon fontSize="small" />
          </ListItemIcon>
          <div>Link</div>
        </div>
      </MenuItem>
      <Divider />
      <span
        style={{
          marginLeft: "15px",
          fontSize: "13px",
          marginBottom: "10px",
          display: "block",
        }}
      >
        Mercero Fields
      </span>
      {/* <MenuItem value={"related"}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <ListItemIcon>
                  <LoopIcon fontSize="small" />
                </ListItemIcon>
                <div>Related</div>
              </div>
            </MenuItem> */}
      {/* <NestedMenuItem
        rightIcon={<ArrowRightIcon />}
        leftIcon={<LoopIcon fontSize="small" sx={{ marginLeft: "10px" }} />}
        label="Related"
        parentMenuOpen={colTypeOpen}
        sx={
          newColType?.includes("related")
            ? {
                backgroundColor: "rgb(238 244 252)",
              }
            : {}
        }
      >
        {rootViews?.map((rv) => {
          return (
            <MenuItem
              key={rv?._id}
              value={"test"}
              selected={newColType === `related-${rv?._id}`}
              onClick={() => {
                setNewColType(`related-${rv?._id}`);
                // setSubType("related-test");
                handleClose();
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <ListItemIcon>
                  {GetListIcon(rv?.icon?.fileString, rv?.icon?.color)}
                </ListItemIcon>
                <div>{rv?.name}</div>
              </div>
            </MenuItem>
          );
        })}
      </NestedMenuItem>
      {rootViews?.map((rv) => {
        return (
          <MenuItem
            key={rv?._id}
            value={`related-${rv?._id}`}
            sx={{ display: "none" }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <ListItemIcon>
                {GetListIcon(rv?.icon?.fileString, rv?.icon?.color)}
              </ListItemIcon>
              <div>{`Related > ${rv?.name}`}</div>
            </div>
          </MenuItem>
        );
      })} */}

      <MenuItem value={"lastActivity"}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ListItemIcon>
            <TimelineIcon fontSize="small" />
          </ListItemIcon>
          <div>Last Activity</div>
        </div>
      </MenuItem>
      <MenuItem value={"createdAt"}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ListItemIcon>
            <TimelineIcon fontSize="small" />
          </ListItemIcon>
          <div>Created Date</div>
        </div>
      </MenuItem>
      <MenuItem value={"lastEmail"}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ListItemIcon>
            <TimelineIcon fontSize="small" />
          </ListItemIcon>
          <div>Last Email</div>
        </div>
      </MenuItem>
      <Divider />
      <span
        style={{
          marginLeft: "15px",
          fontSize: "13px",
          marginBottom: "10px",
          display: "block",
        }}
      >
        Address Fields
      </span>
      <MenuItem value={"address"}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ListItemIcon>
            <MapIcon fontSize="small" />
          </ListItemIcon>
          <div>Address</div>
        </div>
      </MenuItem>
      <MenuItem value={"latitude"}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ListItemIcon>
            <MultipleStopIcon fontSize="small" />
          </ListItemIcon>
          <div>Latitude</div>
        </div>
      </MenuItem>
      <MenuItem value={"longitude"}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ListItemIcon>
            <HeightIcon fontSize="small" />
          </ListItemIcon>
          <div>Longitude</div>
        </div>
      </MenuItem>
      <span
        style={{
          marginLeft: "15px",
          fontSize: "13px",
          marginBottom: "10px",
          display: "block",
        }}
      >
        Relationships
      </span>
      {/* <MenuItem value={"related"}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <ListItemIcon>
                  <LoopIcon fontSize="small" />
                </ListItemIcon>
                <div>Related</div>
              </div>
            </MenuItem> */}
      {rootViews?.map((rv) => {
        const columns = context?.allViews?.find(
          (view: any) => view._id === rv?._id
        )?.columns;

        return (
          <NestedMenuItem
            rightIcon={<ArrowRightIcon />}
            leftIcon={
              <ListItemIcon>
                {GetListIcon(rv?.icon?.fileString, rv?.icon?.color)}
              </ListItemIcon>
            }
            label={rv?.name}
            key={rv?._id}
            selected={newColType?.includes(`related-${rv?._id}`)}
            parentMenuOpen={colTypeOpen}
            sx={
              newColType?.includes(`related-${rv?._id}`)
                ? {
                    backgroundColor: "rgb(238 244 252)",
                  }
                : {}
            }
          >
            <MenuItem
              key={`${rv?._id}-main`}
              value={`related-${rv?._id}`}
              selected={newColType === `related-${rv?._id}`}
              onClick={() => {
                setNewColType(`related-${rv?._id}`);
                // setSubType("related-test");
                handleClose();
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <ListItemIcon>
                  {GetListIcon(rv?.icon?.fileString, rv?.icon?.color)}
                </ListItemIcon>
                <div>{rv?.name}</div>
              </div>
            </MenuItem>
            {columns?.map((column: any) => {
              return (
                <MenuItem
                  key={`${column?.field}-${rv?._id}-column`}
                  value={column?.field}
                  selected={
                    newColType === `related-${rv?._id}-column-${column?.field}`
                  }
                  onClick={() => {
                    setNewColType(`related-${rv?._id}-column-${column?.field}`);
                    // setSubType("related-test");
                    handleClose();
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <ListItemIcon>
                      {GetListIcon(rv?.icon?.fileString, rv?.icon?.color)}
                    </ListItemIcon>
                    <div>{column?.headerName}</div>
                  </div>
                </MenuItem>
              );
            })}
          </NestedMenuItem>
        );
      })}
      {rootViews?.map((rv) => {
        const columns = context?.allViews?.find(
          (view: any) => view._id === rv?._id
        )?.columns;
        return columns
          ?.map((column: any) => {
            return (
              <MenuItem
                key={`related-${rv?._id}-column-${column?.field}`}
                value={`related-${rv?._id}-column-${column?.field}`}
                sx={{ display: "none" }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ListItemIcon>
                    {GetListIcon(rv?.icon?.fileString, rv?.icon?.color)}
                  </ListItemIcon>
                  <div>{`Related > ${rv?.name} > ${column?.headerName}`}</div>
                </div>
              </MenuItem>
            );
          })
          .concat(
            <MenuItem
              key={rv?._id}
              value={`related-${rv?._id}`}
              selected={newColType?.includes(`related-${rv?._id}`)}
              sx={{ display: "none" }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <ListItemIcon>
                  {GetListIcon(rv?.icon?.fileString, rv?.icon?.color)}
                </ListItemIcon>
                <div>{`Related > ${rv?.name}`}</div>
              </div>
            </MenuItem>
          );
      })}
    </StyledSelected>
  );
}
