import React, { useContext } from "react";
import "./App.css";
import { Alert, Snackbar } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { MessageStatus, RefetchContext } from "./refetchProvider";

export default function SystemMessage() {
  const context = useContext(RefetchContext);

  return (
    <Snackbar
      open={!!context?.emailMessage}
      autoHideDuration={4000}
      onClose={() => context?.setEmailMessage(undefined)}
    >
      <Alert
        onClose={() => context?.setEmailMessage(undefined)}
        severity={
          context?.emailMessage?.code === MessageStatus.SUCCESS
            ? "success"
            : "error"
        }
        sx={{ width: "100%" }}
      >
        {context?.emailMessage?.message}
      </Alert>
    </Snackbar>
  );
}
