import { Divider, Grid, Typography } from "@mui/material";
import { CustomChip } from "./individualView";
import { ContactsTab } from "./v2IndivProperty";

export default function ExistingPropertyMapView(props: any) {
  const { properties, regridParcelDetails, selectedProperty } = props;

  const prop = selectedProperty?.[0];

  return (
    <div
      style={{
        position: "absolute",
        height: "calc(100vh - 180px)",
        top: "96px",
        backgroundColor: "white",
        width: "448px",
        left: "40px",
        borderRadius: "8px",
        padding: "24px",
        overflowY: "scroll",
        boxShadow: "0px 0px 10px rgba(0,0,0,.3)",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          textTransform: "capitalize",
          color: "#101828",
          marginTop: "60px",
        }}
      >
        {prop?.address?.toLowerCase()}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          textTransform: "capitalize",
        }}
      >
        {prop?.parcelnumb}
      </Typography>
      <Divider sx={{ marginTop: "8px", marginBottom: "8px" }} />
      <Typography variant="body1">Building SF: {prop?.rba}</Typography>
      <Typography variant="body1">Land SF: {prop?.gisacre}</Typography>

      <div
        style={{
          border: "1px solid #333",
          borderRadius: "4px",
          marginTop: "12px",
        }}
        key={prop?.parcelnumb}
      >
        <ContactsTab contacts={prop?.contacts} />
      </div>
    </div>
  );
}
