import FilterListIcon from "@mui/icons-material/FilterList";
import LayersIcon from "@mui/icons-material/Layers";
import ListIcon from "@mui/icons-material/List";
import AppsIcon from "@mui/icons-material/Apps";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import ApartmentIcon from "@mui/icons-material/Apartment";
import BusinessIcon from "@mui/icons-material/Business";
import PersonIcon from "@mui/icons-material/Person";
import StarIcon from "@mui/icons-material/Star";
import PaidIcon from "@mui/icons-material/Paid";

export const icons = {
  list: <ListIcon />,
  apps: <AppsIcon />,
  locationCity: <LocationCityIcon />,
  apartment: <ApartmentIcon />,
  business: <BusinessIcon />,
  person: <PersonIcon />,
  star: <StarIcon />,
  paid: <PaidIcon />,
  filterList: <FilterListIcon />,
  layers: <LayersIcon />,
};

export const iconsWithStyle = (style: any) => {
  return {
    list: <ListIcon sx={style} />,
    apps: <AppsIcon sx={style} />,
    locationCity: <LocationCityIcon sx={style} />,
    apartment: <ApartmentIcon sx={style} />,
    business: <BusinessIcon sx={style} />,
    person: <PersonIcon sx={style} />,
    star: <StarIcon sx={style} />,
    paid: <PaidIcon sx={style} />,
    filterList: <FilterListIcon sx={style} />,
    layers: <LayersIcon sx={style} />,
  };
};

export type iconTypes = keyof typeof icons;

export function GetListIcon(iconName: iconTypes, color: string) {
  const iconStyle = { width: "18px", color: { color } };

  const thingsToReturn = iconsWithStyle(iconStyle);

  return thingsToReturn[iconName] || <LayersIcon sx={iconStyle} />;
}
