import React, { useState, useEffect } from "react";

const stages = [
  "Checking Accessor Details",
  "Looking up Entity with Secretary of State",
  "Finding Officers",
];

function LoadingBar() {
  const [currentStage, setCurrentStage] = useState(0);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const nextProgress = prevProgress + 10;
        if (nextProgress > 100) {
          clearInterval(interval);
          return 100;
        }
        return nextProgress;
      });

      setCurrentStage((prevStage) => {
        // Move to the next stage based on progress
        return Math.min(
          Math.floor(progress / (100 / stages.length)),
          stages.length - 1
        );
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [progress]);

  return (
    <div className="loading-bar-container">
      <div className="loading-bar" style={{ width: `${progress}%` }}></div>
      <div className="loading-label" style={{ marginTop: "16px" }}>
        {stages[currentStage]}
      </div>
    </div>
  );
}

export default LoadingBar;
