import { useContext, useEffect, useState } from "react";
import "./App.css";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import dayjs, { Dayjs } from "dayjs";
import {
  Badge,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import InboxCheckList from "./inboxCheckList";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import WarningIcon from "@mui/icons-material/Warning";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloseIcon from "@mui/icons-material/Close";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import CircleIcon from "@mui/icons-material/Circle";
import AddBoxIcon from "@mui/icons-material/AddBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

import { useQuery, useMutation } from "@apollo/client";
import { GET_TODOS } from "./graphql-ops";
import IndividualView from "./individualView";
import { StyledMenu } from "./topButton";
import { CalendarToday } from "@mui/icons-material";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { RefetchContext } from "./refetchProvider";
import { StyledSelected, TodoModal } from "./rowTodos";
import MenuIcon from "@mui/icons-material/Menu";
import _ from "lodash";

enum ViewEnum {
  All = "All",
  Today = "Today",
  Upcoming = "Upcoming",
  PastDue = "Past Due",
  Completed = "Completed",
  Date = "Date",
}

function ServerDay(
  props: PickersDayProps<Dayjs> & { highlightedDays?: number[] }
) {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

  const isSelected =
    !props.outsideCurrentMonth &&
    highlightedDays.indexOf(props.day.date()) >= 0;

  return (
    <Badge
      key={props.day.toString()}
      overlap="circular"
      badgeContent={
        isSelected ? (
          <CircleIcon sx={{ fontSize: "10px", color: "#989dc7" }} />
        ) : undefined
      }
    >
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
      />
    </Badge>
  );
}

export default function NewInbox() {
  const { user } = useAuth0();

  const context = useContext(RefetchContext);

  const setIsMobileOpen = context?.setIsMobileMenuOpen;

  const [todos, setTodos] = useState<any[]>([]);
  const [selectedTodoView, setSelectedTodoView] = useState<
    ViewEnum | undefined
  >(undefined);
  const [selectedDay, setSelectedDay] = useState<Dayjs | null>(null);
  const isMobile = useMediaQuery("(max-width:600px)");

  const [selectedRow, setSelectedRow] = useState<any>(undefined);
  const [viewId, setViewId] = useState<string | undefined>(undefined);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [highlightedDays, setHighlightedDays] = useState<number[]>([]);
  const [showAddTodo, setShowAddTodo] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [tasksFilter, setTasksFilter] = useState<string>("my-tasks");

  const handleTasksFilter = (event: any) => {
    setTasksFilter(event?.target?.value);
  };

  // const query =
  //   context?.currentWorkspace?._id === "64de7b3f211e23aee582927e"
  //     ? { workspaceId: "64de7b3f211e23aee582927e", isDeleted: false }
  //     : { userId: { auth0Sub: user?.sub }, isDeleted: false };

  const userQuery = { userId: { auth0Sub: user?.sub }, isDeleted: false };

  const workspaceQuery = {
    workspaceId: context?.currentWorkspace?._id,
    isDeleted: false,
  };

  const { data: todosBuff } = useQuery(GET_TODOS, {
    variables: { query: workspaceQuery },
    fetchPolicy: "cache-and-network",
  });

  const { data: userTodos } = useQuery(GET_TODOS, {
    variables: { query: userQuery },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    const mainTodos = todosBuff?.todos ?? [];
    const userTodosTemp = userTodos?.todos ?? [];
    const both = mainTodos.concat(userTodosTemp);
    const unique = _.uniqBy(both, "_id");

    setTodos(unique);
  }, [todosBuff, userTodos]);

  const onMonthChange = (date: any) => {
    const theDate = new Date(date);

    const highlightedDaysTemp = todos
      ?.filter((todo: any) => {
        return (
          new Date(todo.dueDate).getMonth() === theDate.getMonth() &&
          todo?.status === 1
        );
      })
      .map((todo: any) => {
        return new Date(todo.dueDate).getDate();
      });
    setHighlightedDays(highlightedDaysTemp);
  };

  useEffect(() => {
    if (showDatePicker) {
      const highlightedDaysTemp = todos
        ?.filter((todo: any) => {
          return dayjs().isSame(todo.dueDate, "month") && todo?.status === 1;
        })
        .map((todo: any) => {
          return new Date(todo.dueDate).getDate();
        });
      setHighlightedDays(highlightedDaysTemp);
    }
  }, [showDatePicker, todos]);

  const allTodos = todos?.filter((todo) => {
    if (todo?.status !== 1) {
      return false;
    }

    if (tasksFilter === "all-tasks") {
      return true;
    } else if (tasksFilter === "my-tasks") {
      return todo?.assignedTo?.auth0Sub === user?.sub;
    } else {
      return todo?.assignedTo?.auth0Sub === tasksFilter;
    }
  });

  const completedTodos = todos?.filter((todo) => {
    if (todo?.status === 1) {
      return false;
    }

    if (tasksFilter === "all-tasks") {
      return true;
    } else if (tasksFilter === "my-tasks") {
      return todo?.assignedTo?.auth0Sub === user?.sub;
    } else {
      return todo?.assignedTo?.auth0Sub === tasksFilter;
    }
  });

  let filteredTodos = allTodos;
  let todosLabel = "(Due Now)";

  const overDueTodos = allTodos?.filter((todo) => {
    const startOfToday = new Date();
    startOfToday.setHours(0, 0, 0, 0); // Set to start of today

    return new Date(todo.dueDate) < startOfToday && todo?.status === 1;
  });

  const todayTodos = allTodos?.filter((todo) => {
    return (
      new Date(todo.dueDate).setHours(0, 0, 0, 0) ===
        new Date().setHours(0, 0, 0, 0) && todo?.status === 1
    );
  });

  const upComingTodos = allTodos?.filter((todo: any) => {
    return (
      new Date(todo.dueDate).setHours(0, 0, 0, 0) >
        new Date().setHours(0, 0, 0, 0) && todo?.status === 1
    );
  });

  switch (selectedTodoView) {
    case ViewEnum.Today:
      todosLabel = "(Today)";
      filteredTodos = todayTodos;
      break;
    case ViewEnum.Upcoming:
      todosLabel = "(Upcoming)";
      filteredTodos = upComingTodos;
      break;
    case ViewEnum.PastDue:
      todosLabel = "(Overdue)";
      filteredTodos = overDueTodos;
      break;
    case ViewEnum.All:
      todosLabel = "";
      filteredTodos = allTodos;
      break;
    case ViewEnum.Completed:
      todosLabel = "(Completed)";
      filteredTodos = completedTodos;
      break;
    case ViewEnum.Date:
      filteredTodos = allTodos?.filter((todo: any) => {
        return (
          dayjs(todo.dueDate).isSame(selectedDay, "day") && todo?.status === 1
        );
      });
      todosLabel = `(${dayjs(selectedDay).format("MMM DD, YYYY")})`;
      break;
    default:
      todosLabel = "Due Today";
      filteredTodos = (todayTodos ?? []).concat(overDueTodos ?? []);
  }

  return (
    <>
      <Grid
        container
        spacing={0}
        direction={isMobile ? "column" : "row"}
        style={{
          //   backgroundColor: "rgb(244, 245, 248)",
          minHeight: "100vh",
        }}
        justifyContent={isMobile ? "normal" : "center"}
      >
        <CssBaseline />
        <Grid
          item
          xs={isMobile ? 12 : 4}
          sx={
            isMobile
              ? { borderRight: "1px solid #E0E0E0" }
              : {
                  borderRight: "1px solid #E0E0E0",
                  height: "100vh",
                  overflowY: "scroll",
                }
          }
        >
          <div
            style={{
              padding: "20px",
              position: "sticky",
              top: "0px",
              zIndex: "999",
              backgroundColor: "white",
            }}
          >
            <Grid container justifyContent={"space-between"}>
              {isMobile && (
                <Grid item>
                  <IconButton
                    onClick={() => !!setIsMobileOpen && setIsMobileOpen(true)}
                  >
                    <MenuIcon />
                  </IconButton>
                </Grid>
              )}
              <Grid item xs={isMobile ? 6 : 8}>
                {!!context?.workspaceMembers &&
                context?.workspaceMembers?.length > 1 ? (
                  <StyledSelected
                    value={tasksFilter}
                    onChange={handleTasksFilter}
                    sx={{ minWidth: "175px" }}
                  >
                    <MenuItem value={"all-tasks"}>All Tasks</MenuItem>
                    <Divider />
                    <MenuItem value={"my-tasks"}>My Tasks</MenuItem>
                    {context?.workspaceMembers
                      ?.filter((member: any) => member?.auth0Sub !== user?.sub)
                      ?.map((member: any) => {
                        const parsedName = member?.name?.split(" ");

                        return (
                          <MenuItem
                            key={member?.auth0Sub}
                            value={member?.auth0Sub}
                          >
                            {!!parsedName
                              ? `${parsedName[0]}'s Tasks`
                              : `${member?.email}'s Tasks`}
                          </MenuItem>
                        );
                      })}
                  </StyledSelected>
                ) : (
                  <>
                    <Typography
                      variant="h6"
                      sx={isMobile ? { textAlign: "center" } : {}}
                    >{`Tasks ${todosLabel}`}</Typography>
                  </>
                )}
              </Grid>
              <Grid item xs={4}>
                <IconButton
                  style={{ float: "right" }}
                  onClick={(event) => {
                    setShowAddTodo(!showAddTodo);
                  }}
                >
                  {showAddTodo ? <IndeterminateCheckBoxIcon /> : <AddBoxIcon />}
                </IconButton>
                <IconButton
                  onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                  }}
                  sx={{
                    float: "right",
                  }}
                >
                  <CalendarMonthIcon />
                </IconButton>
                <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                  }}
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                  style={{ fontSize: ".75em" }}
                >
                  <MenuItem
                    onClick={() => {
                      setSelectedTodoView(ViewEnum.All);
                      setShowDatePicker(false);
                      setAnchorEl(null);
                    }}
                    disableRipple
                  >
                    <CheckBoxIcon />
                    All
                    {!!allTodos && allTodos.length > 0 && (
                      <MenuItemCount count={allTodos.length} />
                    )}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setSelectedTodoView(ViewEnum.PastDue);
                      setShowDatePicker(false);
                      setAnchorEl(null);
                    }}
                    disableRipple
                  >
                    <WarningIcon />
                    Overdue
                    {!!overDueTodos && overDueTodos.length > 0 && (
                      <MenuItemCount count={overDueTodos.length} />
                    )}
                  </MenuItem>
                  <MenuItem
                    onClick={async () => {
                      setSelectedTodoView(ViewEnum.Today);
                      setShowDatePicker(false);
                      setAnchorEl(null);
                    }}
                    disableRipple
                  >
                    <CalendarToday />
                    Today
                    {!!todayTodos && todayTodos.length > 0 && (
                      <MenuItemCount count={todayTodos.length} />
                    )}
                  </MenuItem>
                  <MenuItem
                    onClick={async () => {
                      setSelectedTodoView(ViewEnum.Upcoming);
                      setShowDatePicker(false);
                      setAnchorEl(null);
                    }}
                    disableRipple
                  >
                    <CalendarMonthIcon />
                    Upcoming
                    {!!upComingTodos && upComingTodos.length > 0 && (
                      <MenuItemCount count={upComingTodos.length} />
                    )}
                  </MenuItem>
                  <Divider sx={{ my: 0.5 }} />
                  <MenuItem
                    onClick={async () => {
                      setSelectedTodoView(ViewEnum.Completed);
                      setShowDatePicker(false);
                      setAnchorEl(null);
                    }}
                    disableRipple
                  >
                    <CalendarMonthIcon />
                    Completed
                    {!!completedTodos && completedTodos.length > 0 && (
                      <MenuItemCount count={completedTodos.length} />
                    )}
                  </MenuItem>
                  <Divider sx={{ my: 0.5 }} />
                  <MenuItem
                    onClick={async () => {
                      setAnchorEl(null);
                      setSelectedTodoView(ViewEnum.Date);
                      setSelectedDay(dayjs(new Date()));
                      setShowDatePicker(true);
                    }}
                    disableRipple
                  >
                    <CalendarToday />
                    Date
                  </MenuItem>
                </StyledMenu>
              </Grid>
            </Grid>
          </div>
          <TodoModal newTodo={!!showAddTodo} setNewTodo={setShowAddTodo} />
          {!!showDatePicker && (
            <>
              <IconButton
                sx={{
                  marginLeft: "20px",
                }}
              >
                <CloseIcon
                  fontSize="small"
                  onClick={() => {
                    setSelectedDay(null);
                    setSelectedTodoView(undefined);
                    setShowDatePicker(false);
                  }}
                />
              </IconButton>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar
                  slots={{
                    day: ServerDay,
                  }}
                  onMonthChange={onMonthChange}
                  value={selectedDay}
                  onChange={(newValue) => {
                    setSelectedDay(newValue);
                  }}
                  slotProps={{
                    day: {
                      highlightedDays,
                    } as any,
                  }}
                />
              </LocalizationProvider>
            </>
          )}
          <InboxCheckList
            todos={filteredTodos || todos}
            setSelectedRow={setSelectedRow}
            setViewId={setViewId}
            selectedRow={selectedRow}
          />
        </Grid>
        {!isMobile && (
          <Grid item xs={8}>
            <div style={{ marginTop: "20px" }}>
              {!!selectedRow && selectedRow !== "no-row" && (
                <IndividualView
                  curRowId={selectedRow}
                  hideDetails={true}
                  viewId={viewId}
                  // mapDisplay={true}
                />
              )}
              {!!selectedRow && selectedRow === "no-row" && (
                <Typography sx={{ textAlign: "center", marginTop: "30px" }}>
                  This todo is not connected to a record.
                </Typography>
              )}
            </div>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export const MenuItemCount = (props: any) => {
  const count = props.count;
  return (
    <div
      style={{
        position: "absolute",
        top: "7px",
        right: "10px",
        backgroundColor: "rgb(240, 243, 249)",
        color: "rgb(30, 46, 184)",
        borderRadius: "2px",
        width: "18px",
        height: "18px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "0.8rem",
      }}
    >
      {count}
    </div>
  );
};
