import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  ListItemButton,
  ListItemText,
  Paper,
  Popper,
  Tabs,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { RefetchContext } from "./refetchProvider";
import { useMutation, useQuery } from "@apollo/client";
import { GET_CONTACTS_QUERY, UPDATE_CONTACT_MUTATION } from "./v2-gql";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { StyledTab } from "./styledTabs";
import { fetchCustomLayer, setupParcels } from "./displayMap";
import { CustomChip } from "./individualView";
import { NotesTab } from "./v2IndivProperty";
import { CustomButton } from "./PersonalDetails";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import AddIcon from "@mui/icons-material/Add";
import { parse } from "path";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { formatPhoneNumber, stripPhoneNumber } from "./DatagridFunctions";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Add } from "@mui/icons-material";
import { ParcelMap, formatLink } from "./v2Utils";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

export default function IndividualContactsSection() {
  const { user, getAccessTokenSilently } = useAuth0();

  const { contactId } = useParams<{ contactId: string }>();

  const {
    data,
    loading: loadingContacts,
    error,
  } = useQuery(GET_CONTACTS_QUERY, {
    variables: {
      query: {
        _id: contactId,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const [
    updateContact,
    { data: mutationData, loading: mutationLoading, error: mutationError },
  ] = useMutation(UPDATE_CONTACT_MUTATION, {
    variables: {
      query: {
        _id: contactId,
      },
    },
  });

  const context = useContext(RefetchContext);
  const [propView, setPropView] = useState("1");
  const [selectedTab, setSelectedTab] = useState("1");
  const [showHiddenPhone, setShowHiddenPhone] = useState(false);
  const [showHiddenEmail, setShowHiddenEmail] = useState(false);

  if (loadingContacts) {
    return <div>Loading...</div>;
  }

  const contact = data?.getContacts?.[0];
  const company = contact?.companies?.[0];

  if (!contact) {
    return <div>Contact not found</div>;
  }

  return (
    <Grid container sx={{ minHeight: "100vh" }} direction={"column"}>
      <Grid
        xs={12}
        sx={{ padding: "12px 40px", borderBottom: "1px solid rgba(0,0,0,.2)" }}
      >
        <Link to="/properties/database">
          <IconButton
            sx={{ borderRadius: "4px", fontSize: "14px", color: "#3D6AEF" }}
          >
            <ArrowBackIosIcon sx={{ fontSize: "16px" }} />
            Back to Contacts List
          </IconButton>
        </Link>
      </Grid>
      <Grid xs={12} alignItems={"center"}>
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{ padding: "20px 40px", paddingBottom: "0px" }}
          >
            <div
              style={{ display: "flex", flexDirection: "column", gap: "4px" }}
            >
              <Typography
                variant="h6"
                sx={{ textTransform: "capitalize", color: "#101828" }}
              >
                {contact?.name}
              </Typography>
              <Typography variant="body2" sx={{ color: "#475467" }}>
                {contact?.title}
              </Typography>
              {!!company && (
                <>
                  <Link to={`/properties/database/company/${company?._id}`}>
                    <Typography variant={"body2"} sx={{ color: "#2b70ef" }}>
                      {company?.dba}
                    </Typography>
                    <Typography variant={"body2"}>
                      {company?.entityName}
                    </Typography>
                  </Link>

                  <Typography variant={"body2"} sx={{ color: "#2b70ef" }}>
                    <a
                      href={formatLink(company?.domain)}
                      style={{ color: "#2b70ef" }}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      {company?.domain}
                    </a>
                  </Typography>
                </>
              )}
              <CustomTags tags={contact?.tags} updateFunction={updateContact} />
            </div>
          </Grid>
          <Grid item xs={6} sx={{ padding: "20px" }}>
            <div
              style={{
                border: "1px solid rgba(0,0,0,.3)",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                padding: "20px",
                marginTop: "16px",
                marginBottom: "16px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="body1">Phone Number(s)</Typography>
                {contact?.phoneNumbers?.filter((phone: any) => {
                  return phone?.status === "bad";
                })?.length > 0 && (
                  <IconButton
                    sx={{
                      fontSize: "14px",
                      width: "fit-content",
                      borderRadius: "4px",
                    }}
                    onClick={() => {
                      setShowHiddenPhone(!showHiddenPhone);
                    }}
                  >
                    {showHiddenPhone ? (
                      <>
                        <VisibilityOffIcon
                          sx={{ fontSize: "16px", marginRight: "4px" }}
                        />
                        Hide hidden
                      </>
                    ) : (
                      <>
                        <VisibilityIcon
                          sx={{ fontSize: "16px", marginRight: "4px" }}
                        />
                        Show hidden
                      </>
                    )}
                  </IconButton>
                )}
              </div>

              {contact?.phoneNumbers
                ?.filter((phone: any) => {
                  if (showHiddenPhone) {
                    return true;
                  }
                  return phone?.status !== "bad";
                })
                ?.sort((a: any, b: any) => {
                  if (a?.status === "good") {
                    return -1;
                  }
                  return 1;
                })
                ?.slice(
                  0,
                  !!!showHiddenPhone ? 2 : contact?.phoneNumbers?.length
                )
                ?.map((phone: any) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          // setEmails(
                          //   emails.map((e: any) => {
                          //     if (
                          //       e?.email?.emailAddress ===
                          //       email?.email?.emailAddress
                          //     ) {
                          //       return { ...e, status: "good" };
                          //     }
                          //     return e;
                          //   })
                          // );
                        }}
                      >
                        <ThumbUpIcon
                          sx={
                            phone?.status === "good" ? { color: "green" } : {}
                          }
                        />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          // setEmails(
                          //   emails.map((e: any) => {
                          //     if (
                          //       e?.email?.emailAddress ===
                          //       email?.email?.emailAddress
                          //     ) {
                          //       return { ...e, status: "bad" };
                          //     }
                          //     return e;
                          //   })
                          // );
                        }}
                      >
                        <ThumbDownIcon
                          sx={phone.status === "bad" ? { color: "red" } : {}}
                        />
                      </IconButton>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "4px",
                          marginLeft: "8px",
                        }}
                      >
                        <Typography variant="body1">
                          <a
                            href={`mailto:${stripPhoneNumber(
                              phone?.phoneNumber
                            )}`}
                            style={{
                              fontWeight: "500",
                              color: "#2b70ef",
                              fontSize: "14px",
                            }}
                          >
                            {formatPhoneNumber(phone?.phoneNumber)}
                          </a>
                        </Typography>
                        <Typography variant="body2">{phone?.type}</Typography>
                      </div>
                    </div>
                  );
                })}

              {/* {contact?.phoneNumbers?.map((phone: any) => {
                return <div>{phone?.phoneNumber}</div>;
              })} */}
              <IconButton
                sx={{
                  fontSize: "14px",
                  width: "fit-content",
                  borderRadius: "4px",
                }}
              >
                <AddIcon
                  sx={{
                    fontSize: "16px",
                    marginRight: "4px",
                  }}
                />
                Add phone number
              </IconButton>
            </div>
          </Grid>
          <Grid item xs={6} sx={{ padding: "20px" }}>
            <div
              style={{
                border: "1px solid rgba(0,0,0,.3)",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                padding: "20px",
                marginTop: "16px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body1">Email(s)</Typography>
                {contact?.emails?.filter((email: any) => {
                  return email?.status === "bad";
                })?.length > 0 && (
                  <IconButton
                    sx={{
                      fontSize: "14px",
                      width: "fit-content",
                      borderRadius: "4px",
                    }}
                    onClick={() => {
                      setShowHiddenEmail(!showHiddenEmail);
                    }}
                  >
                    {showHiddenEmail ? (
                      <>
                        <VisibilityOffIcon
                          sx={{ fontSize: "16px", marginRight: "4px" }}
                        />
                        Hide hidden
                      </>
                    ) : (
                      <>
                        <VisibilityIcon
                          sx={{ fontSize: "16px", marginRight: "4px" }}
                        />
                        Show hidden
                      </>
                    )}
                  </IconButton>
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                {contact?.emails
                  ?.filter((email: any) => {
                    if (showHiddenEmail) {
                      return true;
                    }
                    return email?.status !== "bad";
                  })
                  ?.sort((a: any, b: any) => {
                    if (a?.status === "good") {
                      return -1;
                    }
                    return 1;
                  })
                  ?.slice(0, !!!showHiddenEmail ? 2 : contact?.emails?.length)
                  ?.map((email: any) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            // setEmails(
                            //   emails.map((e: any) => {
                            //     if (
                            //       e?.email?.emailAddress ===
                            //       email?.email?.emailAddress
                            //     ) {
                            //       return { ...e, status: "good" };
                            //     }
                            //     return e;
                            //   })
                            // );
                          }}
                        >
                          <ThumbUpIcon
                            sx={
                              email?.status === "good" ? { color: "green" } : {}
                            }
                          />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            // setEmails(
                            //   emails.map((e: any) => {
                            //     if (
                            //       e?.email?.emailAddress ===
                            //       email?.email?.emailAddress
                            //     ) {
                            //       return { ...e, status: "bad" };
                            //     }
                            //     return e;
                            //   })
                            // );
                          }}
                        >
                          <ThumbDownIcon
                            sx={email.status === "bad" ? { color: "red" } : {}}
                          />
                        </IconButton>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "4px",
                            marginLeft: "8px",
                          }}
                        >
                          <Typography variant="body1">
                            <a
                              href={`mailto:${email?.emailAddress}`}
                              style={{
                                fontWeight: "500",
                                color: "#2b70ef",
                                fontSize: "14px",
                              }}
                            >
                              {email?.emailAddress}
                            </a>
                          </Typography>
                          <Typography variant="body2">{email?.type}</Typography>
                        </div>
                      </div>
                    );
                  })}
                <IconButton
                  sx={{
                    fontSize: "14px",
                    width: "fit-content",
                    borderRadius: "4px",
                  }}
                >
                  <AddIcon
                    sx={{
                      fontSize: "16px",
                      marginRight: "4px",
                    }}
                  />
                  Add email
                </IconButton>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid
        xs={12}
        sx={{ marginTop: "12px", borderTop: "1px solid rgba(0,0,0,.3)" }}
      >
        
      </Grid> */}
      <Grid
        xs={12}
        flexGrow={1}
        sx={{
          flexGrow: "1 !important",
          display: "flex",
          flexDirection: "column",
          padding: "24px",
          backgroundColor: "rgb(247, 249, 250)",
          borderTop: "1px solid rgba(0,0,0,.3)",
        }}
      >
        <div style={{ backgroundColor: "white", borderRadius: "8px" }}>
          <Tabs
            onChange={(event, newValue) => {
              setSelectedTab(newValue);
            }}
            sx={{ margin: "auto" }}
            value={selectedTab}
            aria-label="lab API tabs example"
          >
            <StyledTab label="Properties" value="1" />
            <StyledTab label="Notes" value="4" />
            <StyledTab label="Reminders" value="5" />
          </Tabs>
        </div>
        <div style={{ flexGrow: 1 }}>
          {selectedTab === "1" ? (
            <PropertiesTab properties={contact?.properties} />
          ) : (
            <></>
          )}
          {selectedTab === "4" ? <NotesTab contactId={contact?._id} /> : <></>}
          {selectedTab === "5" ? (
            <Typography sx={{ padding: "20px" }}>Coming soon!</Typography>
          ) : (
            <></>
          )}
        </div>
      </Grid>
    </Grid>
  );
}

export function PropertiesTab(props: { properties: any }) {
  const { properties } = props;

  if (props.properties.length === 0) {
    return <div style={{ padding: "24px" }}>No properties found</div>;
  }

  return (
    <div
      style={{
        display: "flex",
        gap: "16px",
        marginTop: "4px",
        // border: "1px solid #ccc",
      }}
    >
      {properties?.map((property: any, index: number) => {
        const lat = parseFloat(property?.latitude) || 39.719473359111774;
        const lng = parseFloat(property?.longitude) || -104.95712869477363;

        return (
          <div
            style={{
              display: "flex",
              gap: "4px",
              marginTop: "8px",
              flexDirection: "column",
              borderRadius: "4px",
              // border: "1px solid #ccc",
              padding: "20px",
              backgroundColor: "white",
            }}
          >
            {!!lat && !!lng && (
              <ParcelMap lat={lat} lng={lng} height={"200px"} width={"100%"} />
            )}
            <Link to={`/properties/database/property/${property?._id}`}>
              <Typography variant="body1">{property?.address}</Typography>
            </Link>
            <Typography variant="body2" sx={{ color: "#475467" }}>
              Parcel Numb: {property?.parcelnumb}
            </Typography>
            {index === 1 && (
              <div style={{ maxWidth: "240px" }}>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#475467",
                    marginBottom: "8px",
                    marginTop: "8px",
                  }}
                >
                  Recent Note:
                </Typography>
                <Typography variant="body2" sx={{ marginBottom: "4px" }}>
                  <span
                    style={{
                      color: "#101828",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    Jake Deichmann
                  </span>{" "}
                  <span style={{ color: "#475467", fontSize: "12px" }}>
                    3/23/24
                  </span>
                </Typography>
                <Typography variant="body2">
                  Spoke with Phil, he is interested in selling the property.
                </Typography>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

export function CustomTags(props: any) {
  const { tags, updateFunction } = props;
  const [showAutocomplete, setShowAutocomplete] = useState(false);
  const [selectedTags, setSelectedTags] = useState<string[]>(tags || []);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleAddTagClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!anchorEl) {
      const rect = event.currentTarget.getBoundingClientRect();
      const virtualElement = {
        getBoundingClientRect: () => ({
          top: rect.top,
          left: rect.left,
          bottom: rect.bottom,
          right: rect.right,
          width: rect.width,
          height: rect.height,
        }),
      };
      // @ts-ignore
      setAnchorEl(virtualElement);
    }
    setShowAutocomplete(true);
  };

  const handleCloseAutocomplete = (event: any, reason: any) => {
    // if (reason !== "removeOption" && reason !== "selectOption") {

    // }

    setShowAutocomplete(false);
    setAnchorEl(null);
  };

  const handleTagChange = (event: any, value: string[]) => {
    setSelectedTags(value);
    updateFunction({
      variables: {
        data: {
          tags: value,
        },
      },
    });
  };

  return (
    <>
      <div
        style={{
          marginTop: "8px",
          display: "flex",
          gap: "8px",
          flexWrap: "wrap",
          alignItems: "center",
          height: "40px",
        }}
      >
        {selectedTags.map((tag: any) => (
          <CustomChip
            key={tag}
            label={tag}
            sx={{ width: "fit-content", height: "fit-content" }}
            onClick={handleAddTagClick}
            color="error"
          />
        ))}

        <IconButton
          sx={{
            fontSize: "14px",
            width: "fit-content",
            borderRadius: "4px",
          }}
          onClick={handleAddTagClick}
        >
          <AddIcon sx={{ fontSize: "16px", marginRight: "4px" }} />
          Add Tag
        </IconButton>
      </div>
      {showAutocomplete && anchorEl && (
        <Popper
          open={showAutocomplete}
          anchorEl={anchorEl}
          placement="bottom-start"
          style={{ zIndex: 9999 }}
          modifiers={[
            {
              name: "offset",
              options: {
                offset: [0, 4], // This will add 8px offset to the bottom
              },
            },
          ]}
        >
          <Autocomplete
            multiple
            open
            freeSolo
            options={["Owner", "Investor", "Test", "Developer", "Designer"]}
            value={selectedTags}
            onClose={handleCloseAutocomplete}
            onChange={handleTagChange}
            PopperComponent={StyledPopper}
            renderTags={() => null} // Prevents selected tags from being displayed inside the TextField
            renderInput={(params) => (
              <TextField
                {...params}
                autoFocus
                variant="outlined"
                placeholder="Add Tags"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "4px",
                    "& fieldset": {
                      borderColor: "rgba(0, 0, 0, 0.23)",
                    },
                    "&:hover fieldset": {
                      borderColor: "#1976d2",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#1976d2",
                    },
                  },
                  "& .MuiAutocomplete-inputRoot": {
                    padding: "0px",
                  },
                }}
              />
            )}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  checked={selected}
                  sx={{ marginRight: 1 }}
                  onClick={(event: any) => {
                    event.defaultMuiPrevented = true;
                  }}
                />
                <ListItemText primary={option} />
              </li>
            )}
            sx={{
              minWidth: 200,
              width: "fit-content",
              backgroundColor: "#fff",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              "& .MuiAutocomplete-listbox": {
                backgroundColor: "#fff",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              },
              "& .MuiAutocomplete-option": {
                padding: "8px 16px",
                '&[aria-selected="true"]': {
                  backgroundColor: "rgba(25, 118, 210, 0.08)",
                },
                "&:hover": {
                  backgroundColor: "rgba(25, 118, 210, 0.15)",
                },
              },
            }}
          />
        </Popper>
      )}
    </>
  );
}

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
});
