import { useAuth0 } from "@auth0/auth0-react";
import { Grid, IconButton, Tabs, Typography } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useContext, useState } from "react";
import { RefetchContext } from "./refetchProvider";
import { useMutation, useQuery } from "@apollo/client";
import { GET_COMPANIES, UPDATE_COMPANY_MUTATION } from "./v2-gql";
import { StyledTab } from "./styledTabs";
import { CustomChip } from "./individualView";
import { ContactsTab, NotesTab } from "./v2IndivProperty";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { CustomTags, PropertiesTab } from "./v2IndivContact";

export default function IndividualCompanisSection() {
  const { user, getAccessTokenSilently } = useAuth0();

  const { companyId } = useParams<{ companyId: string }>();
  const navigate = useNavigate();

  const {
    data,
    loading: loadingCompanies,
    error,
  } = useQuery(GET_COMPANIES, {
    variables: {
      query: {
        _id: companyId,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const [
    updateCompany,
    { data: mutationData, loading: mutationLoading, error: mutationError },
  ] = useMutation(UPDATE_COMPANY_MUTATION, {
    variables: {
      query: {
        _id: companyId,
      },
    },
  });

  const [selectedTab, setSelectedTab] = useState("1");

  if (loadingCompanies) {
    return <div>Loading...</div>;
  }

  const company = data?.getCompanies?.[0];

  if (!company) {
    return <div>Company not found</div>;
  }

  return (
    <Grid container sx={{ minHeight: "100vh" }} direction={"column"}>
      <Grid xs={12} sx={{ padding: "20px 40px" }}>
        <IconButton
          sx={{ borderRadius: "4px", fontSize: "14px", color: "#3D6AEF" }}
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIosIcon sx={{ fontSize: "16px" }} />
          Back
        </IconButton>
      </Grid>
      <Grid xs={12} alignItems={"center"} sx={{ padding: "20px 40px" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
          <Typography
            variant="h6"
            sx={{ textTransform: "capitalize", color: "#101828" }}
          >
            {company?.dba || company?.entityName}
          </Typography>
          <Typography variant="body2" sx={{ color: "#475467" }}>
            {company?.domain}
          </Typography>
          {!!company?.dba && (
            <Typography variant="body2" sx={{ color: "#475467" }}>
              Entity Name: {company?.entityName}
            </Typography>
          )}
          <Typography variant="body2" sx={{ color: "#475467" }}>
            Mailing Address: {company?.mailingAddress}
          </Typography>
          <div style={{ marginTop: "8px", display: "flex", gap: "8px" }}>
            <CustomTags tags={company?.tags} updateFunction={updateCompany} />
          </div>
        </div>
      </Grid>
      {/* <Grid
        xs={12}
        sx={{ marginTop: "12px", borderTop: "1px solid rgba(0,0,0,.3)" }}
      >
        
      </Grid> */}
      <Grid
        xs={12}
        flexGrow={1}
        sx={{
          flexGrow: "1 !important",
          display: "flex",
          flexDirection: "column",
          padding: "24px",
          backgroundColor: "rgb(247, 249, 250)",
          borderTop: "1px solid rgba(0,0,0,.3)",
        }}
      >
        <div style={{ backgroundColor: "white", borderRadius: "8px" }}>
          <Tabs
            onChange={(event, newValue) => {
              setSelectedTab(newValue);
            }}
            sx={{ margin: "auto" }}
            value={selectedTab}
            aria-label="lab API tabs example"
          >
            <StyledTab label="Contacts" value="1" />
            <StyledTab label="Properties" value="2" />
            <StyledTab label="Notes" value="4" />
            <StyledTab label="Reminders" value="5" />
          </Tabs>
        </div>
        <div style={{ flexGrow: 1 }}>
          {selectedTab === "1" ? (
            <ContactsTab
              contacts={company?.contacts?.map((contact: any) => {
                return {
                  role: "Employee",
                  contact: {
                    ...contact,
                  },
                };
              })}
            />
          ) : (
            <></>
          )}
          {selectedTab === "2" ? (
            <PropertiesTab properties={company?.properties} />
          ) : (
            <></>
          )}
          {selectedTab === "4" ? <NotesTab companyId={company?._id} /> : <></>}
        </div>
      </Grid>
    </Grid>
  );
}
