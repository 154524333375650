import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { SyntheticEvent, useState } from "react";
import { StyledTab, StyledTabs } from "./styledTabs";
import SearchIcon from "@mui/icons-material/Search";
import StorageIcon from "@mui/icons-material/Storage";
import MapIcon from "@mui/icons-material/TravelExplore";
import PersonIcon from "@mui/icons-material/Person";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import PaidIcon from "@mui/icons-material/Paid";
import PeopleIcon from "@mui/icons-material/People";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import PropertiesSection from "./v2propertiesSection";
import ContactSection from "./v2contactsSection";
import CompaniesSection from "./companiesSection";
import WorkspaceDropdown from "./v2WorkspaceDropDown";

export default function Database() {
  const { user, getAccessTokenSilently } = useAuth0();

  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div style={{ backgroundColor: "rgb(247, 249, 250)", minHeight: "100vh" }}>
      <div
        style={{
          width: "100%",
          borderBottom: "1px solid #e0e0e0",
          paddingTop: "8px",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <img
          src="/merceroBrandmark.svg"
          alt="Home Logo"
          style={{
            width: "40px",
            float: "left",
            marginLeft: "16px",
            marginTop: "2px",
          }}
        />
        <StyledTabs
          value={2}
          onChange={function (
            event: SyntheticEvent<Element, Event>,
            newValue: number
          ): void {
            throw new Error("Function not implemented.");
          }}
          sx={{ width: "385px", margin: "auto" }}
        >
          <Link to="/properties">
            <StyledTab
              icon={<SearchIcon fontSize="small" />}
              iconPosition="start"
              label="Search"
            />
          </Link>
          <Link to="/properties/discover">
            <StyledTab
              icon={<MapIcon fontSize="small" />}
              iconPosition="start"
              label="Map"
            />
          </Link>

          <StyledTab
            icon={<StorageIcon fontSize="small" />}
            iconPosition="start"
            label="Database"
          />
        </StyledTabs>
        <WorkspaceDropdown />
      </div>
      <ImportData />
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{ maxWidth: "90%", margin: "auto", marginTop: "24px" }}
      >
        <Grid item xs={8}>
          <StyledTabs
            value={selectedTab}
            sx={{}}
            onChange={(event, newValue) => {
              setSelectedTab(newValue);
            }}
          >
            <StyledTab
              icon={<PersonIcon />}
              label={"Contacts"}
              iconPosition="start"
            />
            <StyledTab
              icon={<LocationCityIcon />}
              label={"Properties"}
              iconPosition="start"
            />
            <StyledTab
              icon={<PeopleIcon />}
              label={"Companies"}
              iconPosition="start"
            />
            {/* <StyledTab
              icon={<PaidIcon />}
              label={"Comps"}
              iconPosition="start"
            /> */}
          </StyledTabs>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "row",
          }}
        >
          <Button
            style={{
              border: "1px solid rgb(223, 225, 228)",
              height: "28px",
              fontSize: "13px",
              color: "rgb(60, 65, 73)",
              backgroundColor: "rgb(255, 255, 255)",
              boxShadow: "rgb(0 0 0 / 9%) 0px 1px 1px",
              textTransform: "none",
              fontWeight: 500,
            }}
          >
            <ImportContactsIcon sx={{ mr: 1, width: "20px" }} />
            Import Data
          </Button>
        </Grid>
      </Grid>
      {selectedTab === 0 && <ContactSection />}
      {selectedTab === 1 && <PropertiesSection />}
      {selectedTab === 2 && <CompaniesSection />}
    </div>
  );
}

function ImportData(props: any) {
  return (
    <Modal open={false}>
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          height: "80%",
          bgcolor: "background.paper",
          // border: "2px solid #000",
          padding: "10px !important",
          borderRadius: "10px",
          boxShadow: 24,
          p: 4,
          "& .MuiBox-root": {
            padding: "10px",
          },
        }}
      >
        <Grid container justifyContent={"space-between"} gap={"24px"}>
          <Grid item xs={6} sx={{ padding: "24px", border: "1px solid #333" }}>
            <Typography>Import from Outlook</Typography>
          </Grid>
          <Grid item xs={6} sx={{ padding: "24px", border: "1px solid #333" }}>
            <Typography>Import from Excel</Typography>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
