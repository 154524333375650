import React, { useEffect } from "react";
import "./App.css";
import Typography from "@mui/material/Typography";
import testimonial from "./images/testimonial.png";

export default function Paywall(props: {
  // setShowSettings: (value: React.SetStateAction<boolean>) => void;
  // showSettings: boolean;
}) {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  let headerText = "Get in with the early adopters.";

  // if the url has ?trialExpired=true, then show the paywall
  const urlParams = new URLSearchParams(window.location.search);
  const trialExpired = urlParams.get("trialExpired");
  if (trialExpired) {
    headerText = "Your trial has expired. Please choose a plan.";
  }

  return (
    <div
      style={{
        height: "100vh",
        backgroundColor: "rgb(8, 20, 55)",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          color: "white",
          width: "100%",
          textAlign: "center",
          paddingTop: "40px",
          fontSize: "30px",
          fontFamily: "Jeko, sans-serif",
          fontWeight: "600",
        }}
      >
        {headerText}
      </Typography>
      <Typography
        variant="h6"
        sx={{
          color: "white",
          width: "100%",
          textAlign: "center",
          paddingTop: "10px",
          paddingBottom: "30px",
          fontSize: "18px",
          fontFamily: "Jeko, sans-serif",
          fontWeight: "600",
        }}
      >
        Join industry leading brokers growing their business with Mercero.
      </Typography>
      {/* Prod Version $40/month*/}
      {/* {React.createElement("stripe-pricing-table", {
        "pricing-table-id": "prctbl_1NbCXNFs2is7423ba6aVy1Qh",
        "publishable-key":
          "pk_live_51NV00EFs2is7423bEvQHIQFDFqEgfHkReThleuhTLm3PJAYvXUYgeOepF5txtzyPmChdY2uehYrTWTvn50nqD9Wn00i6ubbbCU",
      })} */}

      {/* Prod at 75 */}

      {React.createElement("stripe-pricing-table", {
        "pricing-table-id": "prctbl_1OXrwvFs2is7423bI945pPDe",
        "publishable-key":
          "pk_live_51NV00EFs2is7423bEvQHIQFDFqEgfHkReThleuhTLm3PJAYvXUYgeOepF5txtzyPmChdY2uehYrTWTvn50nqD9Wn00i6ubbbCU",
      })}

      {/* Dev Version */}

      {/* {React.createElement("stripe-pricing-table", {
        "pricing-table-id": "prctbl_1NXb2aFs2is7423b6P1CDDmK",
        "publishable-key":
          "pk_test_51NV00EFs2is7423blOaQWxsGKnXStMdAQlPUE5XbhGcJGwzUR0fpgHJ7m1KvF4JyjyJAfzaETkWbyRn333pA2Fj700i4a0y9PA",
      })} */}

      <div
        style={{
          width: "100%",
          marginTop: "50px",
          backgroundColor: "white",
        }}
      >
        <img
          src={testimonial}
          alt="Trusted by Top Brokers at Top Firms (CBRE, JLL, Cushman, Colliers, CB)"
          style={{
            margin: "auto",
            display: "block",
          }}
        />
      </div>
    </div>
  );
}
