import { useRouteError } from "react-router-dom";
import "./App.css";
import { Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { apiURL } from "./consts";

export default function ErrorScreen() {
  const error: any = useRouteError();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const sendError = async () => {
      const accessToken = await getAccessTokenSilently();

      const errorInfo = {
        message: error?.message || "Unknown error",
        stack: error?.stack,
        status: error?.status,
        statusText: error?.statusText,
        data: error?.data,
        url: window.location || "Unknown URL",
      };

      fetch(`${apiURL}user/error`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ message: JSON.stringify(errorInfo) }),
      });
    };

    sendError();
  }, [error, getAccessTokenSilently]);

  return (
    <Grid container spacing={2} style={{ padding: "20px" }}>
      <Typography
        style={{
          display: "block",
          textAlign: "center",
          width: "100%",
          marginTop: "30vh",
        }}
        variant="h6"
      >
        Oops! Something went wrong.
      </Typography>
      <Typography
        variant="body1"
        style={{ width: "100%", textAlign: "center", marginTop: "20px" }}
      >
        Please contact <a href="mailto:info@mercero.com">info@mercero.com</a>{" "}
        and we'll fix it as soon as possible.
      </Typography>
    </Grid>
  );
}
