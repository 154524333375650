import { useEffect, useState } from "react";
// import { Base64 } from 'js-base64';
// import _ from 'lodash'

export default function HtmlViewer(props: {
  fileType: string;
  fileName: string;
  fileData: string;
}) {
  const { fileType, fileName, fileData } = props;

  // const [html, setHtml ] = useState<string>('')
  //
  // useEffect(() => {
  //   if (!_.isNil(fileData) && Base64.isValid(_.chain(fileData).split(';').last().split(',').last().value())) {
  //     setHtml(Base64.decode(_.chain(fileData).split(';').last().split(',').last().value()))
  //   }
  // },[fileData, setHtml])

  return (
    <iframe
      src={fileData}
      className="iframeViewer"
      title={fileName}
      style={{
        width: "100%",
        height: "100%",
      }}
    />
  );
}
