import { useAuth0 } from "@auth0/auth0-react";
import React, { useContext, useState } from "react";
import { RefetchContext } from "./refetchProvider";
import { apiURL } from "./consts";

export function ExcelImport(props: {
  setSuccessMessage: (value: React.SetStateAction<string | undefined>) => void;
}) {
  const { setSuccessMessage } = props;

  const [file, setFile] = useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const fileRef = React.useRef<HTMLInputElement>(null);

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setFile(files[0]);
    }
  };

  const context = useContext(RefetchContext);

  const handleRefetch = () => {
    if (context) {
      context.refetchViews();
    } else {
      console.error("Refetch context is not available");
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setIsSubmitting(true);
    event.preventDefault();
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      const accessToken = await getAccessTokenSilently();

      const response = await fetch(
        `${apiURL}upload/${context?.currentWorkspace?._id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          method: "POST",
          body: formData,
        }
      );
      if (response.ok) {
        setFile(null);
        // @ts-ignore
        if (fileRef.current) {
          fileRef.current.value = "";
        }
        setIsSubmitting(false);

        setSuccessMessage(
          "File uploaded successfully. Refresh the page to see your view."
        );

        setTimeout(() => {
          handleRefetch();
        }, 3000);
        // handle successful upload
      } else {
        // handle upload error
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="file-input">Choose a file: </label>
      <input
        id="file-input"
        type="file"
        onChange={handleFileInputChange}
        ref={fileRef}
      />
      <button type="submit" disabled={isSubmitting || !!!file}>
        Upload
      </button>
    </form>
  );
}
