import React, { useEffect } from "react";
import "./App.css";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  Snackbar,
  Switch,
  TextField,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import Signature from "./signature";
import { ExcelImport } from "./excelImport";
import { apiURL } from "./consts";
import SettingsIcon from "@mui/icons-material/Settings";
import EmailIcon from "@mui/icons-material/Email";
import UploadIcon from "@mui/icons-material/Upload";
import PersonIcon from "@mui/icons-material/Person";
import NextPlanIcon from "@mui/icons-material/NextPlan";
import { Link } from "react-router-dom";

export default function Settings(props: {
  // setShowSettings: (value: React.SetStateAction<boolean>) => void;
  // showSettings: boolean;
}) {
  // const { setShowSettings, showSettings } = props;
  const [successMessage, setSuccessMessage] = React.useState<
    string | undefined
  >(undefined);
  const { user, logout, getAccessTokenSilently } = useAuth0();

  const [displayName, setDisplayName] = React.useState<string | undefined>();

  useEffect(() => {
    setDisplayName(user?.name);
  }, [user]);

  const devStripeLink =
    "https://billing.stripe.com/p/login/test_bIYcQk3EwcLp6bu7ss";
  const prodStripeLink =
    "https://billing.stripe.com/p/login/cN25knb8Qgmu1GwaEE";

  return (
    <Grid container style={{ padding: "20px" }}>
      <CssBaseline />
      <Grid item xs={12}>
        <div
          className="pageHeader"
          style={{ marginBottom: "16px", height: "56px" }}
        >
          <Box sx={{ display: "flex", alignItems: "center", height: "56px" }}>
            <Grid
              container
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Grid item>
                {" "}
                <SettingsIcon sx={{ mr: 1, float: "left" }} />
                <Typography
                  variant="h6"
                  component="h2"
                  sx={{
                    fontSize: "18px",
                    display: "inline-block",
                  }}
                >
                  Settings
                </Typography>
              </Grid>
              <Grid>
                <Button
                  style={{
                    border: "1px solid rgb(223, 225, 228)",
                    height: "28px",
                    fontSize: "13px",
                    color: "rgb(60, 65, 73)",
                    backgroundColor: "rgb(255, 255, 255)",
                    boxShadow: "rgb(0 0 0 / 9%) 0px 1px 1px",
                    textTransform: "none",
                    fontWeight: 500,
                    marginRight: "30px",
                  }}
                  variant="contained"
                  onClick={() =>
                    logout({
                      logoutParams: { returnTo: window.location.origin },
                    })
                  }
                >
                  Logout
                </Button>
              </Grid>
            </Grid>
          </Box>
        </div>
      </Grid>
      <Grid item xs={12} sx={{ padding: "10px 30px" }}>
        <div
          style={{
            backgroundColor: "#f8f8f8",
            padding: "10px 20px",
            borderRadius: "10px",
            border: "1px solid #dfdfdf",
            marginBottom: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "56px",
              width: "100%",
            }}
          >
            <NextPlanIcon sx={{ mr: 1 }} />
            <Typography
              variant="h6"
              component="h2"
              sx={{
                fontSize: "18px",
              }}
            >
              Plan Settings
            </Typography>
          </Box>
          {user?.mercsubscriptionStatus !== "trialing" &&
            user?.mercsubscriptionStatus !== "active" && (
              <Alert
                severity="warning"
                action={
                  <Link to="/pricing">
                    <Button
                      sx={{
                        textTransform: "none",
                        backgroundColor: "#424242",
                      }}
                      variant="contained"
                      size="small"
                    >
                      Choose Plan Now
                    </Button>
                  </Link>
                }
              >
                No plan chosen
              </Alert>
            )}
          {(user?.mercsubscriptionStatus === "trialing" ||
            user?.mercsubscriptionStatus === "active") && (
            <Button
              variant="contained"
              sx={{
                marginTop: "10px",
                marginBottom: "10px",
                textTransform: "none",
                backgroundColor: "#424242",
              }}
              href={prodStripeLink}
              target="_blank"
            >
              View Plan Details
            </Button>
          )}
        </div>
        <div
          style={{
            backgroundColor: "#f8f8f8",
            padding: "10px 20px",
            borderRadius: "10px",
            border: "1px solid #dfdfdf",
            marginBottom: "20px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", height: "56px" }}>
            <UploadIcon sx={{ mr: 1 }} />
            <Typography
              variant="h6"
              component="h2"
              sx={{
                fontSize: "18px",
              }}
            >
              Excel Import
            </Typography>
          </Box>
          <ExcelImport setSuccessMessage={setSuccessMessage} />
        </div>
        <div
          style={{
            backgroundColor: "#f8f8f8",
            padding: "10px 20px",
            borderRadius: "10px",
            border: "1px solid #dfdfdf",
            marginBottom: "20px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", height: "56px" }}>
            <PersonIcon sx={{ mr: 1 }} />
            <Typography
              variant="h6"
              component="h2"
              sx={{
                fontSize: "18px",
              }}
            >
              Full Name
            </Typography>
          </Box>
          <TextField
            value={displayName}
            onChange={(event) => setDisplayName(event.target.value)}
          />
          <Button
            variant="outlined"
            style={{ display: "block", marginTop: "10px" }}
            onClick={async () => {
              try {
                const accessToken = await getAccessTokenSilently();

                await fetch(`${apiURL}profile`, {
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                  },
                  method: "PATCH",
                  body: JSON.stringify({
                    // @ts-ignore
                    name: displayName,
                  }),
                });

                setSuccessMessage("Name updated successfully");
              } catch (e) {
                console.log(e);
              }
            }}
          >
            Save Name
          </Button>
        </div>
        <div
          style={{
            backgroundColor: "#f8f8f8",
            padding: "10px 20px",
            borderRadius: "10px",
            border: "1px solid #dfdfdf",
            marginBottom: "20px",
          }}
        >
          {/* <Typography variant="h6">
              <EmailIcon />
              Email Settings
            </Typography> */}
          <Box sx={{ display: "flex", alignItems: "center", height: "56px" }}>
            <EmailIcon sx={{ mr: 1 }} />
            <Typography
              variant="h6"
              component="h2"
              sx={{
                fontSize: "18px",
              }}
            >
              Email Settings
            </Typography>
          </Box>
          {!!user &&
            user.email_verified &&
            user["https://crm.mercero-api.com/primaryEmail"] === "microsoft" &&
            !!user["https://crm.mercero-api.com/primaryEmailAddress"] && (
              <Alert severity="info">
                {`You are currently authenticated with Outlook as
              ${
                user && user["https://crm.mercero-api.com/primaryEmailAddress"]
              }`}
              </Alert>
            )}
          <Button
            style={{ margin: "15px 0px", display: "block" }}
            variant="contained"
            onClick={() => {
              window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=bb956ba8-d315-4ff2-ae56-6327030d0619&response_type=code&redirect_uri=https%3A%2F%2Fcrm.mercero-api.com%2Fapi%2F1%2Fauth%2Fms%2Fcallback&response_mode=query&scope=bb956ba8-d315-4ff2-ae56-6327030d0619%2f.default&state=${btoa(
                user?.sub || ""
              )}&sso_reload=true&prompt=consent`;
            }}
          >
            Authenticate with Outlook
          </Button>
          {!!user &&
            user.email_verified &&
            user["https://crm.mercero-api.com/primaryEmail"] === "google" &&
            !!user["https://crm.mercero-api.com/primaryEmailAddress"] && (
              <Alert severity="info">
                {`You are currently authenticated with Gmail as
              ${
                user && user["https://crm.mercero-api.com/primaryEmailAddress"]
              }`}
              </Alert>
            )}
          <Button
            style={{
              margin: "15px 0px",
              display: "block",
              backgroundColor: "#41a541",
            }}
            variant="contained"
            onClick={() => {
              const passedState = btoa(
                JSON.stringify({
                  userId: user?.sub || "",
                  redirectUrl: window.location.href,
                })
              );

              window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&prompt=consent&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.send%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.readonly%20openid%20email%20profile&state=${passedState}&response_type=code&client_id=802323238228-hk8m6unh82balrabsk948uhu2k1qki4s.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fcrm.mercero-api.com%2Fapi%2F1%2Fauth%2Fgspace%2Fcallback`;
            }}
          >
            Authenticate with Gmail
          </Button>
          {/* <TextField
            id="outlined-multiline-flexible"
            label="Signature"
            // @ts-ignore
            //ref={noteTextBoxRef}
            multiline
            maxRows={10}
            style={{ width: "400px", height: "250px", marginTop: "20px" }}
          /> */}
          <Divider style={{ margin: "20px 0px" }} />
          <Typography variant="h6">Email Signature</Typography>
          <Signature setSuccessMessage={setSuccessMessage} />
        </div>
        <div
          style={{
            backgroundColor: "#f8f8f8",
            padding: "10px 20px",
            borderRadius: "10px",
            border: "1px solid #dfdfdf",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", height: "56px" }}>
            <EmailIcon sx={{ mr: 1 }} />
            <Typography
              variant="h6"
              component="h2"
              sx={{
                fontSize: "18px",
              }}
            >
              Smart BCC
            </Typography>
            <Divider />
          </Box>
          <SmartBcc />
        </div>
        <Snackbar
          open={!!successMessage}
          autoHideDuration={4000}
          onClose={() => setSuccessMessage(undefined)}
        >
          <Alert
            onClose={() => setSuccessMessage(undefined)}
            severity="success"
            sx={{ width: "100%" }}
          >
            {successMessage}
          </Alert>
        </Snackbar>
      </Grid>
    </Grid>
  );
}

function SmartBcc(props: any) {
  const { getAccessTokenSilently } = useAuth0();

  const [checked, setChecked] = React.useState(false);
  const [smartBccEmail, setSmartBccEmail] = React.useState<string>("");

  const getSmartBcc = async () => {
    const accessToken = await getAccessTokenSilently();

    const getSmartBccCall = await fetch(`${apiURL}user/smart-bcc`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      method: "GET",
    });

    const smartBccResult = await getSmartBccCall.json();

    if (!!smartBccResult.smartBcc) {
      setSmartBccEmail(smartBccResult.smartBcc);
      setChecked(true);
    }
  };

  const enableSmartBCC = async () => {
    const accessToken = await getAccessTokenSilently();

    const enableBcc = await fetch(`${apiURL}user/enable-smart-bcc`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      method: "PATCH",
    });

    const enableBccRes = await enableBcc.json();
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      await enableSmartBCC();
      getSmartBcc();
    }

    setChecked(event.target.checked);
  };

  useEffect(() => {
    getSmartBcc();
  }, [getSmartBcc]);

  return (
    <>
      {!!smartBccEmail && (
        <Alert severity="info" sx={{ margin: "10px 0px" }}>
          {`Your Smart BCC email is`}{" "}
          <a
            href={`mailto:${smartBccEmail}`}
            style={{ textDecoration: "underline" }}
          >
            {smartBccEmail}
          </a>
        </Alert>
      )}
      <Typography sx={{ display: "block" }}>Enable Smart BCC</Typography>
      <Switch
        checked={checked}
        onChange={handleChange}
        inputProps={{ "aria-label": "controlled" }}
      />
    </>
  );
}
