import "./App.css";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {
  Avatar,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputBase,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  alpha,
  styled,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useContext, useEffect, useState } from "react";
import { RefetchContext } from "./refetchProvider";
import { apiURL } from "./consts";
import { useAuth0 } from "@auth0/auth0-react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import _ from "lodash";
import { useMutation } from "@apollo/client";
import { DELETE_ONE_WORKSPACE, UPDATE_ONE_WORKSPACE } from "./graphql-ops";

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
    border: "1px solid",
    borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    fontSize: 16,
    width: "auto",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default function WorkspaceSettings(props: {}) {
  const { getAccessTokenSilently } = useAuth0();
  const context = useContext(RefetchContext);

  const [updateWorkspace] = useMutation(UPDATE_ONE_WORKSPACE);

  const [workspaceName, setWorkspaceName] = useState(
    context?.currentWorkspace?.name
  );
  const [workspaceId, setWorkspaceId] = useState(
    context?.currentWorkspace?._id
  );

  const [showDeleteWorkspace, setShowDeleteWorkspace] = useState(false);
  const [deleteWorkspace] = useMutation(DELETE_ONE_WORKSPACE);

  const [invitedUsers, setInvitedUsers] = useState("");

  const [workspaceImage, setWorkspaceImage] = useState<any>(
    context?.currentWorkspace?.avatarMetaData?.fileString || undefined
  );

  useEffect(() => {
    if (
      !!context?.currentWorkspace?.name &&
      workspaceName !== context?.currentWorkspace?.name &&
      workspaceId !== context?.currentWorkspace?._id
    ) {
      setWorkspaceName(context?.currentWorkspace?.name);
    }
    if (
      !!context?.currentWorkspace?._id &&
      workspaceId !== context?.currentWorkspace?._id
    ) {
      setWorkspaceId(context?.currentWorkspace?._id);
    }
  }, [context?.currentWorkspace, workspaceName, workspaceId, workspaceImage]);

  useEffect(() => {
    const updateWorkspaceName = async (test: string) => {
      await updateWorkspace({
        variables: {
          query: {
            _id: context?.currentWorkspace?._id,
          },
          set: {
            name: workspaceName,
          },
        },
      });

      context?.refetchWorkspaces();
    };

    const debouncedUpdateWorkspaceName = _.debounce(updateWorkspaceName, 1000);

    if (
      workspaceName !== context?.currentWorkspace?.name &&
      workspaceName?.length > 0
    ) {
      debouncedUpdateWorkspaceName(workspaceName);
    }

    return () => {
      debouncedUpdateWorkspaceName.cancel();
    };
  }, [
    workspaceName,
    context?.currentWorkspace?.name,
    context?.currentWorkspace?._id,
    updateWorkspace,
    context,
  ]);

  let imageUrl;
  try {
    if (workspaceImage instanceof Blob || workspaceImage instanceof File) {
      imageUrl = URL.createObjectURL(workspaceImage);
    } else {
      imageUrl = context?.currentWorkspace?.avatarMetaData?.fileString;
    }
  } catch (error) {
    console.error("Error creating object URL:", error);
    imageUrl = context?.currentWorkspace?.avatarMetaData?.fileString; // fallback image or some default
  }

  return (
    <Grid
      container
      spacing={2}
      style={{ padding: "20px" }}
      key={context?.currentWorkspace?._id}
    >
      <CssBaseline />
      <Dialog
        open={showDeleteWorkspace}
        onClose={() => setShowDeleteWorkspace(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Delete this workspace?`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will delete the workspace for you and all other members.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowDeleteWorkspace(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={async () => {
              try {
                await deleteWorkspace({
                  variables: {
                    query: {
                      _id: context?.currentWorkspace?._id,
                    },
                  },
                });

                await context?.refetchWorkspaces();

                window.location.href = "/";
              } catch (e) {
                alert(e);
              }
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Grid item xs={1}>
        {/* <IconButton onClick={() => setShowSettings(false)}>
          <CloseIcon />
        </IconButton> */}
      </Grid>
      <Grid item xs={11}>
        <div style={{ height: "100vh", width: "100%" }}>
          <Grid container justifyContent={"space-between"}>
            <Grid item xs={6}>
              <Typography variant="h6" style={{ marginBottom: "50px" }}>
                Workspace Settings
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                style={{
                  float: "right",
                  backgroundColor: "red",
                  marginRight: "100px",
                }}
                onClick={() => setShowDeleteWorkspace(true)}
              >
                Delete Workspace
              </Button>
            </Grid>
          </Grid>

          <FormControl variant="standard">
            <InputLabel shrink htmlFor="bootstrap-input">
              Workspace Name
            </InputLabel>
            <BootstrapInput
              id="bootstrap-input"
              value={workspaceName}
              onChange={(e) => setWorkspaceName(e.target.value)}
            />
          </FormControl>

          <Grid container spacing={2} style={{ paddingTop: "50px" }}>
            <Grid item xs={2}>
              <Avatar
                style={{
                  height: 100,
                  width: 100,
                  backgroundColor: "rgba(0,0,0,.1)",
                  border: "1px solid #ccc",
                  fontSize: ".8rem",
                  margin: "auto",
                  marginTop: "10px",
                }}
                src={imageUrl}
              >
                {context?.currentWorkspace?.name?.charAt(0).toUpperCase()}
              </Avatar>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="h6" style={{ marginBottom: "20px" }}>
                {" "}
                Workspace Logo{" "}
              </Typography>
              <Button
                variant="outlined"
                startIcon={<FileUploadIcon />}
                component={"label"}
                style={{
                  marginBottom: "20px",
                }}
              >
                Attach Files
                <input
                  hidden
                  multiple
                  type="file"
                  onChange={async (event) => {
                    // @ts-ignore
                    const file = event.target.files[0];
                    setWorkspaceImage(file);
                    let base64Data = "";

                    if (file) {
                      const reader = new FileReader();
                      reader.onloadend = async (e) => {
                        // @ts-ignore
                        const base64String = reader.result; // Extract base64 data

                        await updateWorkspace({
                          variables: {
                            query: {
                              _id: context?.currentWorkspace?._id,
                            },
                            set: {
                              avatarMetaData: {
                                fileString: base64String,
                              },
                            },
                          },
                        });
                      };
                      reader.readAsDataURL(file);
                    }

                    try {
                      // const accessToken = await getAccessTokenSilently();
                      // const formData = new FormData();
                      // !!event?.target?.files &&
                      //   formData.append("file", event?.target?.files[0]);
                      // const uploadWorkspaceImg = await fetch(
                      //   `${apiURL}workspace/update-avatar/${context?.currentWorkspace?._id}`,
                      //   {
                      //     headers: {
                      //       Authorization: `Bearer ${accessToken}`,
                      //     },
                      //     method: "POST",
                      //     body: formData,
                      //   }
                      // );
                      // const result = await uploadWorkspaceImg.json();
                      // if (result?.error) {
                      //   throw result?.error;
                      // }
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                />
              </Button>
              <Typography>
                We support PNGs, JPEGs and GIFs under 10MB
              </Typography>
            </Grid>
          </Grid>
          <Divider style={{ marginTop: "20px" }} />
          <Typography style={{ marginTop: "50px", marginBottom: "20px" }}>
            {" "}
            Workspace Users{" "}
          </Typography>
          <FormControl variant="standard">
            <InputLabel shrink htmlFor="bootstrap-input">
              Invite user
            </InputLabel>
            <BootstrapInput
              value={invitedUsers}
              onChange={(e) => setInvitedUsers(e.target.value)}
              id="bootstrap-input"
              placeholder="example@example.com, example1@example.com"
              sx={{
                "& .MuiInputBase-input": {
                  width: "500px",
                },
              }}
            />
          </FormControl>
          <Button
            startIcon={<AddCircleIcon />}
            onClick={async () => {
              const accessToken = await getAccessTokenSilently();
              const res = await fetch(`${apiURL}workspace/send-invite`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify({
                  workspaceId: context?.currentWorkspace?._id,
                  emails: invitedUsers.split(",").map((email) => email.trim()),
                }),
              });

              if (res.status === 200) {
                setInvitedUsers("");
                context?.refetchWorkspaces();
                // getWorkspaceUsers();
              }
            }}
            variant="contained"
            style={{ marginTop: "28px", marginLeft: "20px" }}
          >
            Invite User
          </Button>
          <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />
          <List style={{ maxWidth: "700px" }}>
            {context?.currentWorkspace?.members?.map((user: any) => {
              return (
                <ListItem
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={async () => {
                        const newMembers =
                          context?.currentWorkspace?.members?.filter(
                            (member: any) => member.auth0Sub !== user.auth0Sub
                          );

                        await updateWorkspace({
                          variables: {
                            query: {
                              _id: context?.currentWorkspace?._id,
                            },
                            set: {
                              members: {
                                link: newMembers.map(
                                  (member: any) => member?._id
                                ),
                              },
                            },
                          },
                        });

                        await context?.refetchWorkspaces();
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary={user.email}
                    secondary={user.name || ""}
                  />
                  <Chip label={!!user?.auth0Sub ? "Active" : "Invited"} />
                </ListItem>
              );
            })}
          </List>
        </div>
      </Grid>
    </Grid>
  );
}
