import { useAuth0 } from "@auth0/auth0-react";
import {
  Grid,
  CssBaseline,
  Drawer,
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  styled,
  Button,
  MenuItem,
  IconButton,
  useMediaQuery,
  Menu,
} from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ALL_VIEWS, UPDATE_VIEW } from "./graphql-ops";
import SettingsIcon from "@mui/icons-material/Settings";
import { useContext, useEffect, useState } from "react";
import { apiURL } from "./consts";
import { GetListIcon } from "./icons";
import { DeleteDialog, StyledMenu } from "./topButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PersonIcon from "@mui/icons-material/Person";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import { RefetchContext } from "./refetchProvider";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LockIcon from "@mui/icons-material/Lock";
import MenuIcon from "@mui/icons-material/Menu";
import CheckIcon from "@mui/icons-material/Check";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import _, { forEach } from "lodash";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

const drawerWidth = 192;

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function Sidebar() {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently, logout } =
    useAuth0();

  const context = useContext(RefetchContext);
  const workspaces = context?.workspaces;
  const currentWorkspace = context?.currentWorkspace;
  const [updateView] = useMutation(UPDATE_VIEW);

  const isMobile = useMediaQuery("(max-width:600px)");

  const isMobileOpen = context?.isMobileMenuOpen;
  const setIsMobileOpen = context?.setIsMobileMenuOpen;

  const [unreadNotifications, setUnreadNotifications] = useState<any[]>([]);
  const [views, setViews] = useState<any[]>([]);
  const [rootViews, setRootViews] = useState<any[]>([]);
  const [sublists, setSublists] = useState<Map<string, any[]>>(new Map());
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number | null;
    mouseY: number | null;
    isOpen: boolean;
    listId: string | null;
  }>({
    mouseX: null,
    mouseY: null,
    isOpen: false,
    listId: null,
  });
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRightClick = (event: any, listId: string) => {
    event.preventDefault();
    setContextMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      isOpen: true,
      listId: listId,
    });
  };

  const handleCloseContext = () => {
    setContextMenu({ ...contextMenu, isOpen: false });
  };

  const {
    loading,
    error,
    data,
    refetch: refetchAllViews,
  } = useQuery(GET_ALL_VIEWS, {
    variables: {
      query: { workspaceId: currentWorkspace?._id, isDeleted: false },
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (!loading && !error) {
      setViews(data?.views);
      const rootViews = data?.views?.filter((view: any) => {
        return !!!view?.rootViewId;
      });
      const sortedRootViews = _.sortBy(rootViews, ["position"]);
      setRootViews(sortedRootViews);

      const newSublists = new Map();

      rootViews?.forEach((rootView: any) => {
        const subviews = data?.views?.filter((subview: any) => {
          return subview?.rootViewId === rootView?._id;
        });
        newSublists.set(rootView?._id, _.sortBy(subviews, ["position"]) || []);
      });

      setSublists(newSublists);
    }
  }, [loading, error, data]);

  // Rearrange the views list on drop
  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }

    const newViews = Array.from(rootViews);
    const [reorderedItem] = newViews.splice(result.source.index, 1);
    newViews.splice(result.destination.index, 0, reorderedItem);

    setRootViews(newViews);

    forEach(newViews, async (view: any, index: number) => {
      await updateView({
        variables: { query: { _id: view._id }, view: { position: index } },
      });
    });
  };

  const onSublistDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }
    const rootViewId = result.source.droppableId.split("-")[1];
    const thisSublist = sublists.get(rootViewId);
    const [reorderedItem] = thisSublist?.splice(result.source.index, 1) || [];
    thisSublist?.splice(result.destination.index, 0, reorderedItem);

    const newSublists = sublists.set(rootViewId, thisSublist || []);

    setSublists(new Map(newSublists));

    forEach(thisSublist, async (view: any, index: number) => {
      await updateView({
        variables: { query: { _id: view._id }, view: { position: index } },
      });
    });
  };

  useEffect(() => {
    setUnreadNotifications(
      context?.notifications?.filter((notification) => {
        return !notification.read;
      }) || []
    );
  }, [context?.notifications]);

  return (
    <>
      <div style={{ width: drawerWidth }} className="navBar" key="mainSidebar">
        <CssBaseline />
        <DeleteDialog
          openDeleteDialog={openDeleteDialog}
          setOpenDeleteDialog={setOpenDeleteDialog}
          listId={contextMenu?.listId}
        />
        <StyledMenu
          open={contextMenu.isOpen}
          onClose={handleCloseContext}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu.mouseY !== null && contextMenu.mouseX !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
        >
          <Link
            to={`/list/${contextMenu?.listId}/edit`}
            onClick={() => {
              setContextMenu({
                mouseX: null,
                mouseY: null,
                isOpen: false,
                listId: null,
              });
              handleCloseContext();
            }}
          >
            <MenuItem onClick={handleClose}>
              <EditIcon />
              Edit List
            </MenuItem>
          </Link>
          <MenuItem
            onClick={() => {
              setOpenDeleteDialog(true);
              handleCloseContext();
            }}
          >
            <DeleteIcon />
            Delete list
          </MenuItem>
          {/* More menu items here */}
        </StyledMenu>
        <Drawer
          sx={{
            width: "100%",
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              borderRight: "none",
            },
            "& .MuiPaper-root": {
              backgroundColor: "#FBFBFB",
            },
          }}
          variant={isMobile ? "temporary" : "persistent"}
          anchor="left"
          open={isMobile ? isMobileOpen : true}
          onClose={() => !!setIsMobileOpen && setIsMobileOpen(false)}
        >
          <DrawerHeader
            style={{
              minHeight: "56px",
              justifyContent: "left",
              borderRight: "1px solid #E0E0E0",
            }}
          >
            <Button
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              disableElevation
              onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon />}
              startIcon={
                <Avatar
                  style={{
                    height: 20,
                    width: 20,
                    backgroundColor: "blue",
                    fontSize: ".8rem",
                  }}
                  src={context?.currentWorkspace?.avatarMetaData?.fileString}
                >
                  {user?.name?.charAt(0).toUpperCase()}
                </Avatar>
              }
              style={{
                backgroundColor: "rgb(251 251 251)",
                color: "rgb(40, 42, 48)",
                fontSize: 14,
                fontWeight: 600,
                textTransform: "none",
                height: "56px",
              }}
            >
              <p
                style={{
                  // width: 200,
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  textAlign: "left",
                  overflow: "hidden",
                }}
              >
                {currentWorkspace?.name}
              </p>
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              style={{ fontSize: ".75em" }}
            >
              {workspaces?.map((workspace: any) => (
                <MenuItem
                  key={`${workspace?._id}-headerMenu`}
                  onClick={() => {
                    window.localStorage.setItem(
                      "selectedWorkspace",
                      workspace._id
                    );
                    context?.setCurrentWorkspace(workspace);
                    handleClose();
                  }}
                  disableRipple
                >
                  <WorkspacesIcon />
                  {workspace?.name}
                </MenuItem>
              ))}
              <NavLink
                to="/workspace/create"
                className={({ isActive }) => (isActive ? "active" : "inactive")}
                onClick={handleClose}
              >
                <MenuItem key={"Create-Workspace-MenuItem"} disableRipple>
                  <AddIcon />
                  Create new workspace
                </MenuItem>
              </NavLink>
              <Divider sx={{ my: 0.5 }} />
              <NavLink
                to="/settings"
                className={({ isActive }) => (isActive ? "active" : "inactive")}
                onClick={handleClose}
              >
                <MenuItem key={"Account-Settings-MenuItem"} disableRipple>
                  <PersonIcon />
                  Account Settings
                </MenuItem>
              </NavLink>
              <NavLink
                to={`/${currentWorkspace?._id}/settings`}
                className={({ isActive }) => (isActive ? "active" : "inactive")}
                onClick={handleClose}
              >
                <MenuItem key="Workspace-Settings-MenuItem" disableRipple>
                  <SettingsIcon />
                  Workspace Settings
                </MenuItem>
              </NavLink>
              <Divider sx={{ my: 0.5 }} />
              <MenuItem
                key={"Logout-MenuItem"}
                onClick={() => {
                  logout({
                    logoutParams: { returnTo: window.location.origin },
                  });
                }}
                disableRipple
              >
                <ExitToAppIcon />
                Sign out
              </MenuItem>
            </StyledMenu>

            {/* <Typography style={{ paddingLeft: "8px", fontSize: ".9rem" }}>
            {user?.name}
          </Typography> */}
          </DrawerHeader>
          <Divider />
          <List
            key="NavigationList"
            style={{ borderRight: "1px solid #e0e0e0", height: "100%" }}
          >
            <ListItem
              key={"inbox"}
              disablePadding
              style={{ padding: "3px 15px" }}
            >
              <NavLink
                to="/inbox"
                className={({ isActive }) => (isActive ? "active" : "inactive")}
                style={{ width: "100%" }}
              >
                <ListItemButton
                  style={{
                    padding: "2px 10px",
                    borderRadius: "4px",
                  }}
                  onClick={() => {
                    !!setIsMobileOpen && setIsMobileOpen(false);
                  }}
                >
                  <ListItemIcon sx={{ minWidth: "10px", marginRight: "10px" }}>
                    {<CheckIcon style={{ width: "16px", color: "#344054" }} />}
                  </ListItemIcon>
                  <ListItemText
                    primary={"Tasks"}
                    sx={{
                      span: {
                        fontSize: "13px",
                        fontWeight: 500,
                      },
                    }}
                  />
                  {!!context?.dueTodos?.length &&
                    context?.dueTodos?.length > 0 && (
                      <div
                        style={{
                          position: "absolute",
                          top: "7px",
                          right: "10px",
                          backgroundColor: "rgb(240, 243, 249)",
                          color: "rgb(30, 46, 184)",
                          borderRadius: "2px",
                          width: "18px",
                          height: "18px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "0.8rem",
                        }}
                      >
                        {context?.dueTodos?.length}
                      </div>
                    )}
                </ListItemButton>
              </NavLink>
            </ListItem>
            <ListItem
              key={"search"}
              disablePadding
              style={{ padding: "3px 15px" }}
            >
              <NavLink
                to="/search"
                className={({ isActive }) => (isActive ? "active" : "inactive")}
              >
                <ListItemButton
                  style={{ padding: "2px 10px", borderRadius: "4px" }}
                  onClick={() => {
                    !!setIsMobileOpen && setIsMobileOpen(false);
                  }}
                >
                  <ListItemIcon sx={{ minWidth: "10px", marginRight: "10px" }}>
                    {<SearchIcon style={{ width: "18px" }} />}
                  </ListItemIcon>
                  <ListItemText
                    primary={"Search"}
                    sx={{ span: { fontSize: "13px", fontWeight: 500 } }}
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
            <ListItem
              key={"notifications"}
              disablePadding
              style={{ padding: "3px 15px" }}
            >
              <NavLink
                to={`${currentWorkspace?._id}/notifications`}
                className={({ isActive }) => (isActive ? "active" : "inactive")}
              >
                <ListItemButton
                  style={{ padding: "3px 8px", borderRadius: "4px" }}
                  // onClick={() => {
                  //   alert("Notifications coming soon!");
                  // }}
                >
                  <ListItemIcon sx={{ minWidth: "10px", marginRight: "10px" }}>
                    {<NotificationsIcon style={{ width: "18px" }} />}
                    {unreadNotifications.length > 0 && (
                      <div
                        style={{
                          position: "absolute",
                          top: "7px",
                          right: "10px",
                          backgroundColor: "red",
                          color: "white",
                          borderRadius: "50%",
                          width: "18px",
                          height: "18px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "0.8rem",
                        }}
                      >
                        {unreadNotifications?.length}
                      </div>
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={"Notifications"}
                    sx={{ span: { fontSize: "13px", fontWeight: 500 } }}
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
            <ListItem
              key={"Email Campaigns"}
              disablePadding
              style={{ padding: "3px 15px" }}
            >
              <NavLink
                to={`/campaigns`}
                className={({ isActive }) => (isActive ? "active" : "inactive")}
              >
                <ListItemButton
                  style={{ padding: "3px 8px", borderRadius: "4px" }}
                  // onClick={() => {
                  //   alert("Notifications coming soon!");
                  // }}
                >
                  <ListItemIcon sx={{ minWidth: "10px", marginRight: "10px" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M1.66699 5.83301L8.47109 10.5959C9.02207 10.9816 9.29756 11.1744 9.59721 11.2491C9.8619 11.3151 10.1387 11.3151 10.4034 11.2491C10.7031 11.1744 10.9786 10.9816 11.5296 10.5959L18.3337 5.83301M5.66699 16.6663H14.3337C15.7338 16.6663 16.4339 16.6663 16.9686 16.3939C17.439 16.1542 17.8215 15.7717 18.0612 15.3013C18.3337 14.7665 18.3337 14.0665 18.3337 12.6663V7.33301C18.3337 5.93288 18.3337 5.23281 18.0612 4.69803C17.8215 4.22763 17.439 3.84517 16.9686 3.60549C16.4339 3.33301 15.7338 3.33301 14.3337 3.33301H5.66699C4.26686 3.33301 3.5668 3.33301 3.03202 3.60549C2.56161 3.84517 2.17916 4.22763 1.93948 4.69803C1.66699 5.23281 1.66699 5.93288 1.66699 7.33301V12.6663C1.66699 14.0665 1.66699 14.7665 1.93948 15.3013C2.17916 15.7717 2.56161 16.1542 3.03202 16.3939C3.5668 16.6663 4.26686 16.6663 5.66699 16.6663Z"
                        stroke="rgba(0,0,0,.54)"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </ListItemIcon>
                  <ListItemText
                    primary={"Email Campaigns"}
                    sx={{ span: { fontSize: "13px", fontWeight: 500 } }}
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
            <ListItem
              key={"Atlas Search"}
              disablePadding
              style={{ padding: "3px 15px" }}
            >
              <NavLink
                to={`/atlas/search`}
                className={({ isActive }) => (isActive ? "active" : "inactive")}
              >
                <ListItemButton
                  style={{ padding: "3px 8px", borderRadius: "4px" }}
                  // onClick={() => {
                  //   alert("Notifications coming soon!");
                  // }}
                >
                  <ListItemIcon sx={{ minWidth: "10px", marginRight: "10px" }}>
                    <AutoAwesomeIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Atlas"}
                    sx={{ span: { fontSize: "13px", fontWeight: 500 } }}
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
            <ListSubheader
              key="ListsHeader"
              component="div"
              id="nested-list-subheader"
              style={{
                fontSize: "12px",
                lineHeight: "16px",
                marginTop: "16px",
                marginBottom: "8px",
                backgroundColor: "#FBFBFB",
              }}
            >
              Lists
            </ListSubheader>

            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppableGrid" direction="vertical">
                {(provided: any) => (
                  <div
                    container
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {rootViews?.map((view: any, index: number) => {
                      const subviews = sublists.get(view?._id) || [];

                      return (
                        <Draggable
                          key={view?._id}
                          draggableId={view?._id}
                          index={index}
                        >
                          {(provided: any) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              // {...provided.dragHandleProps}
                              className="flexContainer"
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "100%",
                                  marginBottom: "8px",
                                }}
                              >
                                <ListItem
                                  key={view._id}
                                  onContextMenu={(event: any) =>
                                    handleRightClick(event, view._id)
                                  }
                                  disablePadding
                                  sx={{ padding: "3px 15px", width: "100%" }}
                                  {...provided.dragHandleProps}
                                >
                                  <NavLink
                                    to={
                                      !!view?.rootViewId
                                        ? `/list/${view._id}/${view?.rootViewId}`
                                        : `/list/${view._id}`
                                    }
                                    state={{ view: view }}
                                    className={({ isActive }) =>
                                      isActive ? "active" : "inactive"
                                    }
                                  >
                                    <ListItemButton
                                      style={{ padding: "2px 10px" }}
                                      onClick={() => {
                                        !!setIsMobileOpen &&
                                          setIsMobileOpen(false);
                                      }}
                                    >
                                      <ListItemIcon
                                        sx={{
                                          minWidth: "10px",
                                          marginRight: "10px",
                                        }}
                                      >
                                        {GetListIcon(
                                          view?.icon?.fileString,
                                          view?.icon?.color
                                        )}
                                      </ListItemIcon>
                                      <ListItemText
                                        primary={view.name}
                                        sx={{
                                          span: {
                                            fontSize: "13px",
                                            fontWeight: 500,
                                          },
                                        }}
                                      />
                                      {!view?.isPublic && (
                                        <ListItemIcon
                                          sx={
                                            !!!view.rootViewId
                                              ? {
                                                  marginRight: "-5px",
                                                  minWidth: "10px",
                                                }
                                              : { minWidth: "10px" }
                                          }
                                        >
                                          <LockIcon
                                            sx={{
                                              width: "15px",
                                              opacity: ".3",
                                            }}
                                          />
                                        </ListItemIcon>
                                      )}
                                    </ListItemButton>
                                  </NavLink>
                                </ListItem>
                                <DragDropContext onDragEnd={onSublistDragEnd}>
                                  <Droppable
                                    droppableId={`sublist-${view._id}`}
                                    direction="vertical"
                                  >
                                    {(providedtest: any) => (
                                      <div
                                        ref={providedtest.innerRef}
                                        {...providedtest.droppableProps}
                                      >
                                        {subviews?.map(
                                          (subview: any, subIndex: number) => {
                                            return (
                                              <Draggable
                                                key={subview?._id}
                                                draggableId={subview?._id}
                                                index={subIndex}
                                              >
                                                {(provided1: any) => (
                                                  <div
                                                    ref={provided1.innerRef}
                                                    {...provided1.draggableProps}
                                                    {...provided1.dragHandleProps}
                                                  >
                                                    <ListItem
                                                      key={subview._id}
                                                      disablePadding
                                                      sx={{
                                                        width: "100%",
                                                        padding:
                                                          "4px 12px 4px 24px",
                                                      }}
                                                    >
                                                      <NavLink
                                                        to={
                                                          !!subview?.rootViewId
                                                            ? `/list/${subview._id}/${subview?.rootViewId}`
                                                            : `/list/${subview._id}`
                                                        }
                                                        state={{
                                                          view: subview,
                                                        }}
                                                        className={({
                                                          isActive,
                                                        }) =>
                                                          isActive
                                                            ? "active"
                                                            : "inactive"
                                                        }
                                                      >
                                                        <ListItemButton
                                                          style={{
                                                            padding: "2px 8px",
                                                          }}
                                                        >
                                                          <ListItemIcon
                                                            sx={{
                                                              minWidth: "10px",
                                                              marginRight:
                                                                "10px",
                                                            }}
                                                          >
                                                            {GetListIcon(
                                                              subview?.icon
                                                                ?.fileString,
                                                              subview?.icon
                                                                ?.color
                                                            )}
                                                          </ListItemIcon>
                                                          <ListItemText
                                                            primary={
                                                              subview.name
                                                            }
                                                            sx={{
                                                              span: {
                                                                fontSize:
                                                                  "13px",
                                                                fontWeight: 500,
                                                              },
                                                            }}
                                                          />
                                                          {!subview?.isPublic && (
                                                            <ListItemIcon
                                                              sx={
                                                                !!!subview.rootViewId
                                                                  ? {
                                                                      marginRight:
                                                                        "-5px",
                                                                      minWidth:
                                                                        "10px",
                                                                    }
                                                                  : {
                                                                      minWidth:
                                                                        "10px",
                                                                    }
                                                              }
                                                            >
                                                              <LockIcon
                                                                sx={{
                                                                  width: "15px",
                                                                  opacity: ".3",
                                                                }}
                                                              />
                                                            </ListItemIcon>
                                                          )}
                                                        </ListItemButton>
                                                      </NavLink>
                                                    </ListItem>
                                                  </div>
                                                )}
                                              </Draggable>
                                            );
                                          }
                                        )}
                                        {providedtest.placeholder}
                                      </div>
                                    )}
                                  </Droppable>
                                </DragDropContext>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}

                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <ListItem key={1} disablePadding style={{ padding: "3px 15px" }}>
              {/* @ts-ignore */}
              <NavLink
                to={"/list/new"}
                className={({ isActive }) => (isActive ? "active" : "inactive")}
              >
                <ListItemButton
                  style={{ padding: "2px 10px", borderRadius: "4px" }}
                  onClick={async () => {}}
                >
                  <ListItemIcon sx={{ minWidth: "10px", marginRight: "10px" }}>
                    <AddIcon sx={{ width: "18px" }} />
                  </ListItemIcon>
                  {/* @ts-ignore */}
                  <ListItemText
                    primary={"New list"}
                    sx={{ span: { fontSize: "13px", fontWeight: 500 } }}
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          </List>
        </Drawer>
      </div>
    </>
  );
}
