import { createContext, useEffect, useState, useContext } from "react";
import "./App.css";
import { useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { apiURL } from "./consts";
import _ from "lodash";
import { SocketIOContext } from "./socketIOProvider";
import { GET_WORKSPACES } from "./v2-gql";

interface WorkspaceContextType {
  workspaces: any[];
  setSelectedWorkspace: any;
  selectedWorkspace: any;
}

export const WorkspaceContext = createContext<WorkspaceContextType | null>(
  null
);

interface WorkspaceProviderProps {
  children: React.ReactNode;
}

const WorkspaceProvider: React.FC<WorkspaceProviderProps> = ({ children }) => {
  const { user } = useAuth0();

  const [selectedWorkspace, setSelectedWorkspace] = useState<any>(null);

  const { data, loading, error } = useQuery(GET_WORKSPACES, {
    variables: {
      query: { members_in: [{ auth0Sub: user?.sub }], isDeleted: false },
    },
  });

  useEffect(() => {
    if (data?.getWorkspaces) {
      const workspaceId = window.localStorage.getItem("selectedWorkspace");

      if (!!workspaceId) {
        const selected = data?.getWorkspaces?.filter(
          (w: any) => w?._id === workspaceId
        );

        !!selected[0]
          ? setSelectedWorkspace(selected[0])
          : setSelectedWorkspace(data?.getWorkspaces[0]);
      } else {
        setSelectedWorkspace(data?.getWorkspaces[0]);
      }
    }
  }, [data, setSelectedWorkspace]);

  return (
    <WorkspaceContext.Provider
      value={{
        workspaces: data?.getWorkspaces,
        setSelectedWorkspace,
        selectedWorkspace,
      }}
    >
      {children}
    </WorkspaceContext.Provider>
  );
};

export default WorkspaceProvider;
