import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GET_ONE_VIEW } from "./graphql-ops";
import useUpsertView from "./useUpsertView";
import { useMutation, useQuery } from "@apollo/client";
import { ColumnDisplayMenu, ColumnTypeMenu } from "./editColumn";
import { filterOutColumns } from "./layout";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { CustomButton, OnboardingHeader } from "./PersonalDetails";
import { StyledTabs, StyledTab } from "./styledTabs";
import SettingsIcon from "@mui/icons-material/Settings";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import { EditListContent } from "./editView";
import { SecondaryButton } from "./onboardingTimeline";
import MapIcon from "@mui/icons-material/Map";
import SaveIcon from "@mui/icons-material/Save";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { apiURL } from "./consts";
import { useAuth0 } from "@auth0/auth0-react";
import { set } from "lodash";
import LoadingScreen from "./loadingScreen";

export default function EditListPage(props: any) {
  const { listId } = useParams();
  const navigate = useNavigate();

  const {
    loading,
    error,
    data,
    refetch: refetchView,
  } = useQuery(GET_ONE_VIEW, {
    variables: { query: { _id: listId, isDeleted: false } },
  });

  const { updateView } = useUpsertView();
  const { getAccessTokenSilently } = useAuth0();

  // General State
  const [newViewName, setNewViewName] = useState(data?.view?.name);
  const [viewIcon, setViewIcon] = useState(data?.view?.icon?.fileString);
  const [color, setColor] = useState(
    data?.view?.icon?.color || "rgba(0, 0, 0, 0.54)"
  );

  // Column State
  const [columns, setColumns] = useState(data?.view?.columns);
  const [initialState, setInitialState] = useState(data?.view?.initialState);
  const [addressColumn, setAddressColumn] = useState<any>(undefined);
  const [stateColumn, setStateColumn] = useState<any>(undefined);
  const [streetCol, setStreetCol] = useState<any>(undefined);
  const [cityCol, setCityCol] = useState<any>(undefined);
  const [zipCol, setZipCol] = useState<any>(undefined);

  // Tab State
  const [selectedTab, setSelectedTab] = useState(0);
  const [mapLoading, setMapLoading] = useState(false);

  // The Map
  const [showAddressDialog, setShowAddressDialog] = useState(false);

  const latCol = columns?.find((col: any) => col?.type === "latitude");
  const lngCol = columns?.find((col: any) => col?.type === "longitude");
  const addressCol = columns?.find((col: any) => col?.type === "address");

  const mapEnabled = !!latCol && !!lngCol && !!addressCol;

  useEffect(() => {
    if (!!data?.view?.columns) {
      setColumns(filterOutColumns(data?.view?.columns));
    }
  }, [data?.view?.columns]);

  useEffect(() => {
    if (!!data?.view?.name) {
      setNewViewName(data?.view?.name);
    }
  }, [data?.view?.name]);

  useEffect(() => {
    if (!!data?.view?.initialState) {
      try {
        setInitialState(JSON.parse(data?.view?.initialState));
      } catch (e) {
        console.log(e);
      }
    }
  }, [data?.view?.initialState]);

  const onDragEnd = (result: any) => {
    // Dropped outside the list
    if (!result.destination) {
      return;
    }

    const newColumns = Array.from(columns);
    const [reorderedItem] = newColumns.splice(result.source.index, 1);
    newColumns.splice(result.destination.index, 0, reorderedItem);

    setColumns(newColumns);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <div
      style={{
        padding: "32px",
        width: "100%",
        margin: "auto",
        paddingTop: "0px",
      }}
    >
      {mapLoading && <LoadingScreen />}
      <Dialog
        open={showAddressDialog}
        onClose={() => setShowAddressDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Map your data"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
          {/* <Select
            sx={{
              minWidth: "200px",
              marginTop: "12px",
              "& .MuiSelect-select": {
                padding: "8px 12px",
              },
            }}
            value={addressCol?.field || ""}
            placeholder="Select Column"
            onChange={(event: any) => {
              const newCols = columns?.map((col: any) => {
                if (col?.field === event.target.value) {
                  return {
                    ...col,
                    type: "address",
                  };
                }

                return col;
              });
              setAddressColumn(event.target.value);
              setColumns(newCols);
            }}
          >
            {columns?.map((col: any) => {
              return (
                <MenuItem
                  value={col?.field}
                  key={col?.field}
                  selected={addressCol === col?.field}
                >
                  {col?.field}
                </MenuItem>
              );
            })}
          </Select> */}
          <Typography sx={{ fontSize: "16px" }}>
            <span style={{ fontWeight: 500 }}>Street Address</span>
          </Typography>
          <Typography sx={{ fontSize: "14px", marginBottom: "8px" }}>
            If your column contains the full address, select it here.
          </Typography>
          <Select
            sx={{
              minWidth: "200px",
              "& .MuiSelect-select": {
                padding: "8px 12px",
              },
            }}
            placeholder="Select Street Address"
            onChange={(event: any) => {
              const newCols = columns?.map((col: any) => {
                if (col?.field === event.target.value) {
                  return {
                    ...col,
                    type: "address",
                  };
                }

                return col;
              });
              setAddressColumn(event.target.value);
              setColumns(newCols);
              setStreetCol(event.target.value);
            }}
          >
            {columns?.map((col: any) => {
              return (
                <MenuItem
                  value={col?.field}
                  key={col?.field}
                  selected={streetCol === col?.field}
                >
                  {col?.field}
                </MenuItem>
              );
            })}
          </Select>
          <Typography
            sx={{ fontSize: "16px", marginTop: "12px", marginBottom: "8px" }}
          >
            <span style={{ fontWeight: 500 }}>City</span>
          </Typography>
          <Select
            sx={{
              minWidth: "200px",
              "& .MuiSelect-select": {
                padding: "8px 12px",
              },
            }}
            placeholder="Select Column"
            onChange={(event: any) => {
              setCityCol(event.target.value);
            }}
          >
            {columns?.map((col: any) => {
              return (
                <MenuItem
                  value={col?.field}
                  key={col?.field}
                  selected={cityCol === col?.field}
                >
                  {col?.field}
                </MenuItem>
              );
            })}
          </Select>
          <Typography
            sx={{ fontSize: "16px", marginTop: "12px", marginBottom: "8px" }}
          >
            <span style={{ fontWeight: 500 }}>State</span>
          </Typography>
          <Select
            sx={{
              minWidth: "200px",
              "& .MuiSelect-select": {
                padding: "8px 12px",
              },
            }}
            placeholder="Select Column"
            onChange={(event: any) => {
              setStateColumn(event.target.value);
            }}
          >
            {columns?.map((col: any) => {
              return (
                <MenuItem
                  value={col?.field}
                  key={col?.field}
                  selected={stateColumn === col?.field}
                >
                  {col?.field}
                </MenuItem>
              );
            })}
          </Select>
          <Typography
            sx={{ fontSize: "16px", marginTop: "12px", marginBottom: "8px" }}
          >
            <span style={{ fontWeight: 500 }}>Zip</span>
          </Typography>
          <Select
            sx={{
              minWidth: "200px",
              "& .MuiSelect-select": {
                padding: "8px 12px",
              },
            }}
            placeholder="Select Column"
            onChange={(event: any) => {
              setZipCol(event.target.value);
            }}
          >
            {columns?.map((col: any) => {
              return (
                <MenuItem
                  value={col?.field}
                  key={col?.field}
                  selected={zipCol === col?.field}
                >
                  {col?.field}
                </MenuItem>
              );
            })}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={async () => {
              const accessToken = await getAccessTokenSilently();

              const addressFields = {
                address: streetCol,
                city: cityCol,
                state: stateColumn,
                zip: zipCol,
              };
              setMapLoading(true);
              const replyRes = await fetch(`${apiURL}view/map/${listId}`, {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                  "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify({
                  addressFields: addressFields,
                }),
              });
              setMapLoading(false);
              setShowAddressDialog(false);
            }}
            autoFocus
          >
            Start Mapping
          </Button>
        </DialogActions>
      </Dialog>
      <Grid
        container
        justifyContent={"space-between"}
        flexDirection={"row"}
        sx={{
          paddingTop: "20px",
          paddingBottom: "20px",
          zIndex: 100,
          backgroundColor: "white",
          position: "sticky",
          top: 0,
        }}
      >
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <IconButton onClick={() => navigate(`/list/${listId}`)}>
            <CloseIcon />
          </IconButton>
          <OnboardingHeader sx={{ textAlign: "left" }}>
            List Setup
          </OnboardingHeader>
        </Grid>
        <Grid item flexDirection={"row"} display={"flex"} gap={"24px"}>
          <SecondaryButton
            sx={{ height: "42px", fontSize: "14px" }}
            endIcon={!!mapEnabled ? <CheckCircleIcon /> : <></>}
            onClick={() => {
              if (!!!latCol && !!!lngCol) {
                const newCols = [
                  ...columns,
                  {
                    field: "Latitude",
                    headerName: "Latitude",
                    width: 150,
                    editable: true,
                    type: "latitude",
                  },
                  {
                    field: "Longitude",
                    headerName: "Longitude",
                    width: 150,
                    editable: true,
                    type: "longitude",
                  },
                ].filter((col: any) => !!col);

                setColumns(newCols);
                setShowAddressDialog(true);
              } else {
                setShowAddressDialog(true);
              }
            }}
          >
            <MapIcon sx={{ height: "20px" }} />
            {mapEnabled ? "Map Data" : "Enable Map"}
          </SecondaryButton>
          <CustomButton
            sx={{ height: "42px", fontSize: "14px" }}
            onClick={async () => {
              const updatedCols = filterOutColumns(columns)?.map((col: any) => {
                return {
                  field: col.field,
                  headerName: col.headerName,
                  width: col.width || 150,
                  editable: col.editable,
                  type: col.type,
                  display: col.display || "",
                };
              });

              await updateView({
                variables: {
                  query: {
                    _id: listId,
                  },
                  view: {
                    name: newViewName,
                    icon: {
                      fileString: viewIcon,
                      color: color,
                    },
                    columns: updatedCols,
                    columnsJsonString: JSON.stringify(updatedCols),
                    initialState: JSON.stringify(initialState),
                    triggeredBy: "client",
                  },
                },
              });
              navigate(`/list/${listId}`);
            }}
          >
            <SaveIcon sx={{ height: "20px" }} />
            Save List
          </CustomButton>
        </Grid>
      </Grid>

      <Typography
        sx={{
          alignItems: "center",
          display: "flex",
        }}
      >
        <SettingsIcon
          sx={{ height: "20px", marginRight: "5px", color: "#2a70ef" }}
        />{" "}
        General
      </Typography>
      <Box
        sx={{
          borderRadius: "12px",
          border: "1px solid #EAECF0",
          boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
          marginTop: "24px",
          padding: "32px",
        }}
      >
        <EditListContent
          color={color}
          setColor={setColor}
          newViewName={newViewName}
          setNewViewName={setNewViewName}
          viewIcon={viewIcon}
          setViewIcon={setViewIcon}
        />
      </Box>
      <Typography
        sx={{
          alignItems: "center",
          display: "flex",
          marginTop: "24px",
        }}
      >
        <ViewColumnIcon
          sx={{ height: "20px", marginRight: "5px", color: "#2a70ef" }}
        />{" "}
        Columns
      </Typography>
      <Grid
        container
        rowSpacing={4}
        alignItems={"center"}
        sx={{
          borderRadius: "12px",
          border: "1px solid #EAECF0",
          boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
          marginTop: "24px",

          "& .MuiGrid-item": {
            padding: "16px 24px",
          },

          "& .MuiGrid-item p": {
            color: "#101828",
            fontWeight: 500,
            fontSize: "14px",
          },
        }}
      >
        <Grid
          item
          xs={3}
          sx={{
            backgroundColor: "#F9FAFB",
            borderBottom: "1px solid #EAECF0",
            color: "#475467",
            fontSize: "12px",
            fontWeight: 500,
          }}
        >
          Column Name
        </Grid>
        <Grid
          item
          xs={3}
          sx={{
            backgroundColor: "#F9FAFB",
            borderBottom: "1px solid #EAECF0",
            color: "#475467",
            fontSize: "12px",
            fontWeight: 500,
          }}
        >
          Column Type
        </Grid>
        <Grid
          item
          xs={3}
          sx={{
            backgroundColor: "#F9FAFB",
            borderBottom: "1px solid #EAECF0",
            color: "#475467",
            fontSize: "12px",
            fontWeight: 500,
          }}
        >
          Display
        </Grid>
        <Grid
          item
          xs={3}
          sx={{
            backgroundColor: "#F9FAFB",
            borderBottom: "1px solid #EAECF0",
            color: "#475467",
            fontSize: "12px",
            fontWeight: 500,
          }}
        >
          Visibility
        </Grid>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppableGrid" direction="vertical">
            {(provided: any) => (
              <Grid
                container
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {columns?.map((col: any, index: any) => {
                  const rowVisibility =
                    initialState?.columns?.columnVisibilityModel[col?.field];
                  const isVisible =
                    rowVisibility !== undefined ? rowVisibility : true;

                  return (
                    <Draggable
                      key={col.field}
                      draggableId={col.field}
                      index={index}
                    >
                      {(provided: any) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="flexContainer"
                        >
                          <Grid
                            item
                            xs={3}
                            display={"flex"}
                            alignItems={"center"}
                          >
                            <DragIndicatorIcon
                              sx={{ marginRight: "12px", color: "#667085" }}
                            />
                            {col?.headerName || col?.field}
                          </Grid>
                          <Grid item xs={3}>
                            <ColumnTypeMenu
                              newColType={col?.type}
                              setNewColType={(newVal: string) => {
                                const newCols = columns?.map((newCol: any) => {
                                  if (col.field === newCol.field) {
                                    return {
                                      ...col,
                                      type: newVal,
                                    };
                                  }

                                  return newCol;
                                });

                                setColumns(newCols);
                              }}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <ColumnDisplayMenu
                              newColDisplay={col?.display || ""}
                              setNewColDisplay={(newVal: string) => {
                                const newCols = columns?.map((newCol: any) => {
                                  if (col.field === newCol.field) {
                                    return {
                                      ...col,
                                      display: newVal,
                                    };
                                  }

                                  return newCol;
                                });

                                setColumns(newCols);
                              }}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Switch
                              checked={isVisible}
                              onChange={() => {
                                setInitialState({
                                  ...initialState,
                                  columns: {
                                    ...initialState?.columns,
                                    columnVisibilityModel: {
                                      ...initialState?.columns
                                        ?.columnVisibilityModel,
                                      [col.field]: !isVisible || false,
                                    },
                                  },
                                });
                              }}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          </Grid>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </Grid>
            )}
          </Droppable>
        </DragDropContext>
      </Grid>
    </div>
  );
}
