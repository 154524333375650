import { useEffect, useState, useContext } from "react";
import * as React from "react";

import { Box, Button, Grid, Typography, CircularProgress } from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import _ from "lodash";
import { AtlasDataPipelineContext } from "./atlasDataPipelineProvider";
import { apiURL } from "./consts";
import { useAuth0 } from "@auth0/auth0-react";
import { RefetchContext } from "./refetchProvider";

export default function AtlasDataPipelinePush() {
  const { getAccessTokenSilently } = useAuth0();
  const { normalizeString, candidateViews, inputConfig, summary, setSummary } =
    useContext(AtlasDataPipelineContext) as any;
  const refetchContext = useContext(RefetchContext);

  const [loading, setLoading] = useState<boolean>(false);

  const context = useContext(RefetchContext);
  const currentWorkspace = context?.currentWorkspace;

  const handleClick = async () => {
    setLoading(true);

    const accessToken = await getAccessTokenSilently();

    const formData = new FormData();
    formData.append("config", JSON.stringify(inputConfig));
    formData.append("candidateViews", JSON.stringify(candidateViews));
    formData.append("workspaceId", JSON.stringify(currentWorkspace?._id));
    // formData.append(
    //   "numFiles",
    //   JSON.stringify(numFiles)
    // )

    const pushResponse = await fetch(`${apiURL}atlas/save-data-pipeline`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      method: "POST",
      body: formData,
    });
    setLoading(false);

    const pushResponseJson = await pushResponse.json();
    refetchContext?.setAtlasEnabled(true);

    // setSummary(pushResponseJson)
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        sx={{ paddingTop: "20px" }}
      >
        <Grid item xs={11}>
          <Typography variant="subtitle2" sx={{ paddingTop: "20px" }}>
            Save data pipeline mapped in previous steps
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Button variant="contained" disabled={loading} onClick={handleClick}>
            Save
          </Button>
        </Grid>
      </Grid>
      {loading && (
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "50%" }}
        >
          <Grid item>
            <CircularProgress color="inherit" />
          </Grid>
        </Grid>
      )}
      {
        //@ts-ignore
        !_.isNil(summary) && _.chain(summary).keys().size().gt(0).value() && (
          <>
            <Typography variant="h6" sx={{ paddingTop: "20px" }}>
              Import Summary
            </Typography>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Head</TableCell>
                    <TableCell align="left">Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    //@ts-ignore
                    _.chain(summary)
                      .map((row: any) => (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {
                              //@ts-ignore
                              _.get(row, "name")
                            }
                          </TableCell>
                          <TableCell align="left">
                            {
                              //@ts-ignore
                              _.get(row, "value")
                            }
                          </TableCell>
                        </TableRow>
                      ))
                      .value()
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )
      }
    </Box>
  );
}
