import { useEffect, useState, useContext } from "react";

import {
  Button,
  Grid,
  Icon,
  Modal,
  Typography,
  Stepper,
  Step,
  StepButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import _ from "lodash";

import { AtlasDataPipelineContext } from "./atlasDataPipelineProvider";
import AtlasDataPipelineViews from "./atlasDataPipelineViews";
import AtlasDataPipelineColumnMap from "./atlasDataPipelineColumnMap";
// import AtlasDataPipelineValidate from './atlasDataPipelineValidate'
import AtlasDataPipelineSave from "./atlasDataPipelineSave";

export default function AtlasDataPipelineModal() {
  const {
    steps,
    open,
    setOpen,
    activeStep,
    completed,
    totalSteps,
    completedSteps,
    isLastStep,
    allStepsCompleted,
    handleNext,
    handleBack,
    handleStep,
    handleComplete,
    handleReset,
  } = useContext(AtlasDataPipelineContext) as any;

  const [disableNext, setDisableNext] = useState<boolean>(false);

  const style0 = {
    dialogContent: {
      flex: 1,
      overflow: "auto",
      padding: "16px",
    },
    stickyHeader: {
      position: "sticky",
      top: 0,
      zIndex: 1000,
      background: "white",
      padding: "16px",
      borderBottom: "1px solid #ddd",
    },
    stickyFooter: {
      position: "sticky",
      bottom: 0,
      zIndex: 1000,
      background: "white",
      padding: "16px",
      borderTop: "1px solid #ddd",
    },
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="lg" fullWidth>
      <DialogTitle sx={style0.stickyHeader}>
        <Stepper nonLinear activeStep={activeStep}>
          {steps?.map((label: any, index: number) => (
            <Step key={label} completed={completed[index]}>
              <StepButton color="inherit" onClick={handleStep(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </DialogTitle>
      <DialogContent sx={style0.dialogContent}>
        <div style={{ minHeight: "60vh", maxHeight: "60vh", minWidth: "60vw" }}>
          <>
            {_.eq(activeStep, 0) && <AtlasDataPipelineViews />}
            {_.eq(activeStep, 1) && <AtlasDataPipelineColumnMap />}
            {_.eq(activeStep, 2) && <AtlasDataPipelineSave />}
          </>
        </div>
      </DialogContent>
      <DialogActions sx={style0.stickyFooter}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Grid item xs={4}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
          </Grid>
          <Grid
            item
            xs={4}
            container
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Button
              onClick={handleNext}
              sx={{ mr: 1 }}
              disabled={_.chain(completed)
                .get(activeStep, false)
                .thru((o) => !o)
                .value()}
            >
              Next
            </Button>
            <Button
              color="inherit"
              sx={{ mr: 1 }}
              onClick={() => {
                setOpen(false);
              }}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
