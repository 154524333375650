import { useContext, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_ROWS } from "./graphql-ops";
import _, { isArray } from "lodash";
import { RowContext } from "./rowProvider";

const useGetAllTags = (listId: string, field: string) => {
  const rowContext = useContext(RowContext);

  const cleanedTags = rowContext?.tagsMap.get(listId)?.get(field) || [];

  return Array.from(cleanedTags);
};

export default useGetAllTags;
