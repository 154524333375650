import "./App.css";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import { Button, FormControl, Grid, InputLabel } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useContext, useState } from "react";
import { BootstrapInput } from "./workspaceSettings";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_ONE_WORKSPACE, GET_USER } from "./graphql-ops";
import { useAuth0 } from "@auth0/auth0-react";
import { RefetchContext } from "./refetchProvider";
import { useNavigate } from "react-router-dom";

export default function CreateWorkspace() {
  const [workspaceName, setWorkspaceName] = useState("");

  const context = useContext<any>(RefetchContext);
  const navigate = useNavigate();

  const { user } = useAuth0();

  const [createWorkspace] = useMutation(CREATE_ONE_WORKSPACE);
  const { data, loading, error } = useQuery(GET_USER, {
    variables: { query: { auth0Sub: user?.sub } },
  });

  return (
    <Grid container spacing={2} style={{ padding: "20px" }}>
      <CssBaseline />
      <Grid item xs={1}>
        {/* <IconButton onClick={() => setShowSettings(false)}>
          <CloseIcon />
        </IconButton> */}
      </Grid>
      <Grid item xs={11}>
        <div style={{ height: "100vh", width: "100%" }}>
          <Typography variant="h6" style={{ marginBottom: "50px" }}>
            Create Workspace
          </Typography>
          <FormControl variant="standard">
            <InputLabel shrink htmlFor="bootstrap-input">
              Workspace Name
            </InputLabel>
            <BootstrapInput
              id="bootstrap-input"
              value={workspaceName}
              onChange={(e) => setWorkspaceName(e.target.value)}
              sx={{
                "& .MuiInputBase-input": {
                  width: "300px",
                },
              }}
            />
          </FormControl>
          <Button
            startIcon={<AddCircleIcon />}
            disabled={workspaceName === ""}
            variant="contained"
            style={{ marginTop: "28px", marginLeft: "20px" }}
            onClick={async () => {
              await createWorkspace({
                variables: {
                  data: {
                    name: workspaceName,
                    members: { link: [data?.user?._id] },
                    isDeleted: false,
                  },
                },
              });
              await context.refetchWorkspaces();
              navigate("/settings");
            }}
          >
            Create Workspace
          </Button>
        </div>
      </Grid>
    </Grid>
  );
}
