import { useEffect, useContext } from "react";
import { useMutation } from "@apollo/client";
import { GET_ROWS, UPDATE_ONE_ROW_MUTATION } from "./graphql-ops";
import { SocketIOContext } from "./socketIOProvider";
import _ from "lodash";
import { GlobalContext } from "./globalContext";
import { GetHeaderText } from "./DatagridFunctions";
import { RefetchContext } from "./refetchProvider";
import { RowContext, updateRowTags } from "./rowProvider";

const useUpdateRow = (listId: string) => {
  const { socket } = useContext(SocketIOContext) as any;
  const globalContext = useContext(GlobalContext);
  const refetchContext = useContext(RefetchContext);
  const rowContext = useContext(RowContext);

  useEffect(() => {
    if (!_.isNil(socket) && !_.isNil(listId) && !_.isEmpty(listId)) {
      socket.emit("join-room", listId);
    }
  }, [listId, socket]);

  const [updateRow, updateRowProps] = useMutation(UPDATE_ONE_ROW_MUTATION, {
    update(cache, { data }) {
      const updatedRowId = data?.updateOneRow?._id;

      const newMap = globalContext?.rows;

      if (data?.updateOneRow?.isDeleted) {
        try {
          // @ts-ignore
          newMap[data?.updateOneRow?.viewId?._id].delete(updatedRowId);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        const row = data?.updateOneRow;

        const relatedRows = row?.relatedRows?.rows?.map((r: any) => {
          const parsedRow = JSON.parse(r?.rowObject);

          const relatedView = refetchContext?.allViews?.find((v: any) => {
            return v._id === r?.viewId?._id;
          });

          return {
            _id: r?._id,
            merc_viewId: r?.viewId?._id,
            merc_viewName: r?.viewId?.name,
            merc_Header: GetHeaderText(parsedRow, relatedView?.columns || []),
            ...parsedRow,
          };
        });

        const newRow = {
          ...JSON.parse(row.rowObject),
          _id: row._id,
          relatedRows: relatedRows, // Lazy load related rows
          latestActivity: row.latestActivity,
          createdAt: row.createdAt,
          latestEmail: row.latestEmail?.timestamp,
        };

        // @ts-ignore
        newMap?.[data?.updateOneRow?.viewId?._id]?.set(updatedRowId, newRow);

        // Update tags
        const view = data?.updateOneRow?.viewId;

        if (view) {
          updateRowTags(rowContext, listId, newRow, view.columns);
        }
      }
      if (!!newMap) globalContext?.setAllRows({ ...newMap });

      socket.emit("update-row", listId, updatedRowId);
    },
  });

  return { updateRow, ...updateRowProps };
};

export default useUpdateRow;
