import { useState, createContext } from "react";


export const ApolloLookupContext = createContext({});


const ApolloLookupProvider = ({ children }: any) => {
  const [apolloResponse, setApolloResponse] = useState<string>('');

  return (
    <ApolloLookupContext.Provider value={{ apolloResponse, setApolloResponse }}>
      {children}
    </ApolloLookupContext.Provider>
  );
};

export default ApolloLookupProvider;
