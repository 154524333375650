import React, { useEffect, useState, useRef } from "react";

import Chip from "@mui/material/Chip";
import Fab from "@mui/material/Fab";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Box, IconButton, Modal } from "@mui/material";
import { styled } from "@mui/system";
import clsx from "clsx";
import PropTypes from "prop-types";

import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import { useAuth0 } from "@auth0/auth0-react";
import { apiURL } from "./consts";
import _ from "lodash";
import { decode } from "js-base64";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";

import Avatar from "@mui/material/Avatar";

import FileViewer from "./components/fileViewer";

export const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "80%",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  padding: "10px !important",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  "& .MuiBox-root": {
    padding: "10px",
  },
};

function PreviewList(props: {
  fileObjects: any;
  handleRemove: any;
  previewGridClasses: any;
  previewGridProps: any;
  classes: any;
  getPreviewIcon: any;
  rowId: string;
}) {
  const handleClick = (index: number) => {
    setSelectedFile(fileObjects[index]);
    setOpen(true);

    setFileData(null);
    setFetchFileDataError(null);
  };

  const {
    fileObjects,
    handleRemove,
    previewGridProps,
    previewGridClasses,
    classes,
    getPreviewIcon,
    rowId,
  } = props;

  const [selectedFile, setSelectedFile] = useState<any>(null);
  const { getAccessTokenSilently } = useAuth0();
  const [open, setOpen] = useState(false);
  const [fileData, setFileData] = useState<any>(null);

  const [loadingFileData, setLoadingFileData] = useState<boolean>(false);
  const [fetchFileDataError, setFetchFileDataError] = useState<any>(null);

  const [modalHeight, setModalHeight] = useState<number>(0);

  const modalRef = useRef(null);
  const headerRef = useRef(null);

  const handleClose = () => {
    setOpen(false);
    setSelectedFile(null);
    setFileData(null);
    setFetchFileDataError(null);
  };

  useEffect(() => {
    const setHeight = () => {
      // TODO: figure out a better way to determine and pass modal height
      const headerHeight = _.get(headerRef, "current.clientHeight", 40);
      setModalHeight(
        _.get(modalRef, "current.childNodes.2.clientHeight", 200) -
          headerHeight -
          40
      );
    };
    //@ts-ignore
    if (
      // @ts-ignore
      !_.chain(headerRef).get("current.clientHeight").isNil().value() &&
      !_.chain(modalRef)
        .get("current.childNodes.2.clientHeight")
        // @ts-ignore
        .isNil()
        .value()
    ) {
      setHeight();
    }
  }, [modalRef.current, headerRef.current]);

  useEffect(() => {
    const getFile = async () => {
      const accessToken = await getAccessTokenSilently();

      let url;
      if (_.has(selectedFile, "graphMsgId")) {
        url = `${apiURL}email/outlook-attachment/${_.get(
          selectedFile,
          "graphMsgId"
        )}/${_.get(selectedFile, "id")}`;
      } else {
        url = `${apiURL}row/file/${rowId}/${selectedFile.key}`;
      }

      const fileResponse = await fetch(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (fileResponse.ok) {
        const fileBlob = await fileResponse.blob();
        const fileReader = new FileReader();

        fileReader.readAsDataURL(fileBlob);

        fileReader.onload = () => {
          // console.log({result:  fileReader.result })
          setFileData(fileReader.result);
        };

        fileReader.onerror = () => {
          console.error("Error reading file", fileReader.error);
          setFetchFileDataError("Error reading file:" + fileReader.error);
        };
      } else {
        console.error("Failed to fetch file:", fileResponse.status);
        setFetchFileDataError("Failed to fetch file:" + fileResponse.status);
      }
      setLoadingFileData(false);
    };

    if (open && !_.isNil(selectedFile)) {
      setLoadingFileData(true);
      getFile();
    }
  }, [open, selectedFile]);

  return (
    <>
      <Grid
        spacing={1}
        direction="row"
        {...previewGridProps.container}
        container={true}
        className={clsx(classes.root, previewGridClasses.container)}
      >
        <List
          sx={{ width: "100%", bgcolor: "background.paper", marginTop: "10px" }}
          dense={true}
        >
          {fileObjects.map((fileObject: any, i: number) => {
            return (
              <div
                key={
                  _.has(fileObject, "key")
                    ? fileObject?.key + i
                    : _.has(fileObject, "id")
                    ? fileObject?.id + i
                    : i
                }
              >
                <Divider variant="inset" component="li" />
                <ListItem
                  disablePadding={true}
                  secondaryAction={
                    _.isNil(handleRemove) ? null : (
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={(e) => {
                          e.preventDefault();
                          handleRemove(fileObject?.key, i);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )
                  }
                  key={
                    _.has(fileObject, "key")
                      ? fileObject?.key + i
                      : _.has(fileObject, "id")
                      ? fileObject?.id + i
                      : i
                  }
                >
                  <ListItemButton onClick={() => handleClick(i)}>
                    <ListItemIcon>
                      {getPreviewIcon(fileObject, classes)}
                    </ListItemIcon>
                    <ListItemText
                      primary={fileObject?.fileName}
                      secondary={fileObject?.uploadTimestampString}
                    />
                  </ListItemButton>
                </ListItem>
              </div>
            );
          })}
        </List>
      </Grid>
      <Modal
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        ref={modalRef}
      >
        <Box
          sx={modalStyle}
          style={{ padding: "10px !important" }}
          className="fileViewsBox"
        >
          <>
            <Grid
              container
              style={{ marginBottom: "10px", height: "100%" }}
              alignItems="center"
              justifyContent={"flex-end"}
            >
              <Grid item xs={6} ref={headerRef}>
                <IconButton
                  style={{ float: "left" }}
                  onClick={() => handleClose()}
                >
                  <CloseIcon />
                </IconButton>
                <Typography
                  style={{
                    float: "left",
                    fontSize: "18px",
                    marginLeft: "20px",
                    marginTop: "8px",
                  }}
                >
                  {selectedFile?.fileName}
                </Typography>
              </Grid>
              <Grid xs={6}>
                {fileData && !_.isEmpty(fileData) && !_.isNil(fileData) ? (
                  <a
                    href={fileData}
                    download={selectedFile?.fileName}
                    style={{
                      backgroundColor: "rgb(244, 245, 248)",
                      padding: "5px 10px",
                      borderRadius: "5px",
                      float: "right",
                    }}
                  >
                    <IconButton>
                      <DownloadIcon />
                    </IconButton>
                  </a>
                ) : null}
              </Grid>
              <Grid
                xs={12}
                style={{ height: "calc(100% - 50px)", paddingTop: "10px" }}
              >
                <FileViewer
                  fileType={selectedFile?.fileType}
                  fileName={selectedFile?.fileName}
                  fileData={fileData}
                  fileKey={selectedFile?.key}
                  graphMsgId={_.get(selectedFile, "graphMsgId")}
                  attachmentId={_.get(selectedFile, "id")}
                  loading={loadingFileData}
                  fetchFileDataError={fetchFileDataError}
                />
              </Grid>
            </Grid>
          </>
        </Box>
      </Modal>
    </>
  );
}

export default styled(PreviewList)(
  ({ theme }) => `
    root: {
      width: '100%',
    },
    imageContainer: {
        position: 'relative',
        zIndex: 10,
        textAlign: 'center',
        '&:hover $image': {
            opacity: 0.3,
        },
        '&:hover $removeButton': {
            opacity: 1,
        },
    },
    image: {
        height: 100,
        width: 'initial',
        maxWidth: '100%',
        color: ${theme.palette?.text?.primary},
        transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
        boxSizing: 'border-box',
        boxShadow: 'rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px',
        borderRadius: ${theme.shape?.borderRadius},
        zIndex: 5,
        opacity: 1,
    },
    removeButton: {
        transition: '.5s ease',
        position: 'absolute',
        opacity: 0,
        top: ${theme.spacing?.(-1)},
        right: ${theme.spacing?.(-1)},
        width: 40,
        height: 40,
        '&:focus': {
            opacity: 1,
        },
    }
  `
);
