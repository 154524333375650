import { useEffect, useState } from "react";
import _ from "lodash";
import { Base64 } from "js-base64";
import { apiURL } from "../../consts";
import { useAuth0 } from "@auth0/auth0-react";

export default function MsDocViewer(props: {
  fileType: string;
  fileName: string;
  fileData: string;
  fileKey: string | undefined;
  graphMsgId?: string | undefined;
  attachmentId?: string | undefined;
}) {
  const { fileType, fileName, fileData, fileKey, graphMsgId, attachmentId } =
    props;
  const { user } = useAuth0();

  const urlBuff =
    !_.isNil(graphMsgId) && !_.isNil(attachmentId)
      ? `${apiURL}email/outlook-msword-attachment?filename=${fileName}&attachmentId=${attachmentId}&graphMsgId=${graphMsgId}&candidateUserId=${user?.sub}`
      : `${apiURL}row/file-msword?filekey=${fileKey}&filename=${fileName}`;

  const fileURI = encodeURIComponent(urlBuff);

  return (
    <iframe
      src={`https://view.officeapps.live.com/op/embed.aspx?src=${fileURI}`}
      className="msDocViewer"
      title={fileName}
      style={{
        width: "100%",
        height: "100%",
      }}
    />
  );
}
