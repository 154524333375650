import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider } from "@auth0/auth0-react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import Search from "./search";
import Layout from "./layout";
import IndividualView from "./individualView";
import MainDataGridContainer from "./DataGridContainer";
import Settings from "./settings";
import NewView from "./newView";
import NewSendEmail from "./newSendEmail";
import ErrorScreen from "./error";
import MassImport from "./massImport";
import Paywall from "./paywall";
import FinalizeCheckout from "./finalizeCheckout";
import MapDisplay from "./displayMap";
import WorkspaceSettings from "./workspaceSettings";
import CreateWorkspace from "./CreateWorkspace";
import Notifications from "./notifications";
import NewInbox from "./newInbox";
import PersonalDetails from "./PersonalDetails";
import NewCreateWorkspace from "./newCreateWorkspace";
import EmailSetup from "./emailSetup";
import ExcelUpload from "./excelUpload";
import EditListPage from "./editList";
import EmailCampaigns from "./emailCampaigns";
import EmailCampaign from "./emailCampaign";
import PropertyResearch from "./propertyResearch";
import AddPropertiesMap from "./discoverProperties";
import Database from "./propertyDatabase";
import DatabaseStaging from "./propertyStaging";
import V2Wrapper from "./v2Wrapper";
import IndividualPropertiesSection from "./v2IndivProperty";
import IndividualContactsSection from "./v2IndivContact";
import IndividualCompanisSection from "./v2IndivCompany";
import ImportWizardProvider from "./importWizardProvider";
import ImportWizard from "./importWizard";
import AtlasDataPipelineProvider from "./atlasDataPipelineProvider";
import AtlasDataPipeline from "./atlasDataPipeline";
import { AtlasScreen } from "./propertySearch";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <Layout />,
//   },
//   {
//     path: "/view/:viewId",
//     element: <App />,
//   },
//   {
//     path: "/search/",
//     element: <Search />,
//   },
//   {
//     path: "/individualView/:individualViewId",
//     element: <App />,
//   },
// ]);

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path="/properties"
        element={<V2Wrapper />}
        errorElement={<ErrorScreen />}
      >
        <Route index element={<PropertyResearch />} />
        <Route path="discover" element={<AddPropertiesMap />} />
        <Route path="database" element={<Database />} />
        <Route path="staging" element={<DatabaseStaging />} />
        <Route
          path="database/property/:id"
          element={<IndividualPropertiesSection />}
        />
        <Route
          path="database/contact/:contactId"
          element={<IndividualContactsSection />}
        />
        <Route
          path="database/company/:companyId"
          element={<IndividualCompanisSection />}
        />
      </Route>
      <Route path="/" element={<Layout />} errorElement={<ErrorScreen />}>
        <Route path="email" element={<NewSendEmail />} />
        <Route index element={<NewInbox />} />

        {/* Workspaces */}
        <Route path=":workspaceId/settings" element={<WorkspaceSettings />} />
        <Route path="workspace/create" element={<CreateWorkspace />} />
        <Route path=":workspaceId/notifications" element={<Notifications />} />

        <Route path="search" element={<Search />} />

        <Route path="settings" element={<Settings />} />
        <Route path="individualView/:rowId" element={<IndividualView />} />
        <Route path="inbox" element={<NewInbox />} />
        <Route path="list/new/:rootViewId" element={<NewView />} />
        <Route path="list/new/" element={<NewView />} />
        <Route path="campaigns" element={<EmailCampaigns />} />
        <Route path="atlas-data-pipeline" element={<AtlasDataPipeline />} />
        <Route
          path="import-wizard"
          element={
            <ImportWizardProvider>
              <ImportWizard />
            </ImportWizardProvider>
          }
        />
        <Route path="campaign/:campaignId" element={<EmailCampaign />} />

        <Route path="list/:listId/row/:rowId" element={<IndividualView />} />
        <Route
          path="list/:listId/:rootViewId/row/:rowId"
          element={<IndividualView />}
        />
        <Route path="list/:listId/edit" element={<EditListPage />} />
        <Route
          path="list/:listId/:rootViewId"
          element={<MainDataGridContainer />}
        />
        <Route path="list/:listId" element={<MainDataGridContainer />} />

        <Route path="massImport/:listId" element={<MassImport />} />
        <Route path="map/:listId" element={<MapDisplay />} />
        <Route path="map/:listId/:rootViewId" element={<MapDisplay />} />

        <Route path="welcome/personal-details" element={<PersonalDetails />} />
        <Route
          path="welcome/create-workspace"
          element={<NewCreateWorkspace />}
        />
        <Route path="welcome/email" element={<EmailSetup />} />
        <Route path="welcome/import" element={<ExcelUpload />} />

        <Route path="atlas/search" element={<AtlasScreen />} />
        <Route path="atlas/map" element={<AddPropertiesMap />} />
      </Route>
      <Route path="pricing" element={<Paywall />} />
      <Route path="checkout/:checkoutId" element={<FinalizeCheckout />} />
    </>
  )
);

root.render(
  <Auth0Provider
    domain="login.crm.mercero.com"
    clientId="QeUBbkUcB3yZUgCAvThPi2vyplt99XO6"
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: "https://crm.mercero-api.com/",
    }}
  >
    {/* <React.StrictMode> */}
    <RouterProvider router={router} />
    {/* </React.StrictMode> */}
  </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
