import { Button, Paper, Popper } from "@mui/material";
import { NotesSection } from "./notesSection";
import { NotesSectionCollapsed } from "./collapsedNotes";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_ROW } from "./graphql-ops";

export function DatagridAddComment(props: any) {
  const { row } = props;

  const ref = useRef<any>(null);

  console.log(row);
  const { refetch: refetchRow } = useQuery(GET_ROW, {
    variables: { input: { _id: row?._id } },
  });

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>();

  const handleRef = (el: any) => {
    setAnchorEl(el);
  };

  const [paperTop, setPaperTop] = useState(0); // initial position
  const [paperleft, setPaperLeft] = useState(0); // initial position

  useEffect(() => {
    if (ref.current) {
      const topPosition = ref.current.getBoundingClientRect().top;
      setPaperTop(topPosition - 115);
    }
  }, []);

  return (
    <>
      <div ref={handleRef} />
      {!!anchorEl && (
        <Popper
          open
          anchorEl={anchorEl}
          placement="bottom-start"
          modifiers={[
            {
              name: "offset",
              options: {
                offset: [0, -17], // shift the popper 0px along X axis and -10px along Y axis
              },
            },
          ]}
        >
          <Paper
            style={{
              // position: "absolute",
              width: `${props.paperWidth}px`,
              maxHeight: "350px",
              backgroundColor: "rgb(255, 255, 255)",
              overflow: "scroll",
              // top: paperTop,
              zIndex: 100,
              boxShadow:
                "rgba(28, 40, 64, 0.04) 0px 0px 0px 1px inset, rgba(28, 40, 64, 0.12) 0px 4px 8px -4px, rgba(28, 40, 64, 0.16) 0px 4px 12px -2px",
              borderRadius: "5px",
              border: "1px solid rgba(0,0,0,.3)",
            }}
          >
            <NotesSectionCollapsed showDetailView={row} />
          </Paper>
        </Popper>
      )}
    </>
  );
}
