import { useEffect, useState, useContext } from "react";
import * as React from 'react';

import {
  Box,
  Button,
  Grid,
  Typography,
} from "@mui/material";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import _ from 'lodash'
import { ImportWizardContext } from "./importWizardProvider";
import { green } from '@mui/material/colors';
import Icon from '@mui/material/Icon';

import { customAlphabet } from "nanoid";

const nanoid = customAlphabet("1234567890abcdefghijklmnopqrtsuvwxyz", 24);


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export const ColumnTable = (props: { view: string }) =>  {

  const { normalizeString, config } = useContext(ImportWizardContext) as any;

  const [headers, setHeaders] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const { view } = props

  useEffect(() => {
    if (!_.isNil(view) && !_.isNil(config) && !_.isEmpty(config)) {
      const viewKey = normalizeString(view)
      setHeaders(_.get(config,`validData.${viewKey}.headers`))
      setFilteredData(_.get(config,`validData.${viewKey}.data`))
    }
  },[ view, config])



  return (<TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {
              _.map(headers, h=><TableCell align="left">{_.get(h,'name')}</TableCell>)
            }
          </TableRow>
        </TableHead>
        <TableBody>
          { //@ts-ignore
            filteredData.map((row: any) => (
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {
                //@ts-ignore
                _.chain(headers).first().thru(h=>(<TableCell component="th" scope="row">{_.get(row,_.get(h,'sourceFileHeader'))}</TableCell>)).value()
              }
              {
                //@ts-ignore
                _.chain(headers).tail().map(h=>(<TableCell align="left">{_.get(row,_.get(h,'sourceFileHeader'))}</TableCell>)).value()
              }
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>)
}




export default function ImportWizardValidate() {
  const [value, setValue] = useState(0);
  const [selectedViews, setSelectedViews] = useState({});

  const { candidateViews, normalizeString, numFiles, config, inputConfig, setConfig, activeStep, completed, setCompleted } = useContext(ImportWizardContext) as any;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (_.eq(activeStep,2)) {
      let newCompleted = _.clone(completed);
      //@ts-ignore
      newCompleted[activeStep] = true

      setCompleted(newCompleted);
    }

  },[inputConfig, activeStep])

  useEffect(() => {

    if (!_.isNil(numFiles) && !_.isNil(inputConfig) && !_.isEmpty(inputConfig)) {
      const selectedViewBuff = _.eq(numFiles,'single') ?
        // @ts-ignore
        _.pick(candidateViews,_.chain(inputConfig).get('all.columnMap').reduce((r,v)=>{
          r.push(_.get(v,'view'))
          return r
        },[]).uniq().value()) :
        candidateViews

      let inputConfigBuff = _.clone(inputConfig)
      for (const k of _.keys(selectedViewBuff)) {
        const view = _.get(selectedViewBuff,`${k}.name`)

        const inputConfigKey = _.eq(numFiles,'single') ? 'all' : normalizeString(view)

        const viewKey = normalizeString(view)

        // @ts-ignore
        const headers = _.chain(inputConfig).get(`${inputConfigKey}.columnMap`).reduce( (r,v,k) => {
          if (_.chain(v).get('view').eq(viewKey).value()) {
            r.push({
              // @ts-ignore
              sourceFileHeader: _.chain(inputConfig).get(`${inputConfigKey}.headers`).find(o=>_.eq(k, normalizeString(o))).value(),
              targetFieldKey: _.get(v,'field'),
              name: _.chain(v).get('field').startsWith('merc_').value()
              // @ts-ignore
              ? ( _.chain(v).get('field').eq('merc_notes').value() ? _.chain(inputConfig).get(`${inputConfigKey}.headers`).find(o=>_.eq(k, normalizeString(o))).value() : _.chain(candidateViews).get(viewKey).get('fields').find(o=>_.eq(_.get(v,'field'),`merc_${normalizeString(o)}`)).value() )
              // @ts-ignore
              : _.chain(inputConfig).get(`${inputConfigKey}.headers`).find(o=>_.eq(_.get(v,'field'), normalizeString(o))).value()
            })
          }
          return r
        },[]).value()

        //@ts-ignore
        let filteredData = _.chain(inputConfig).get(`${inputConfigKey}.data`,[]).reduce((r,v,k)=>{
          r.push(_.pick(v,['mercRowId',... _.map(headers,o=>_.get(o,'sourceFileHeader'))]))
          return r
        },[]).value()

        //@ts-ignore
        const keyFields = _.chain(inputConfig).get(`${inputConfigKey}.columnMap`).reduce( (r,v,k) => {
          //@ts-ignore
          if (_.get(v,'isKeyField',false) && _.chain(v).get('view').eq(viewKey).value()) {
            // @ts-ignore
            const fullFieldName = _.chain(headers).find(h=>_.eq(k,normalizeString(_.get(h,'sourceFileHeader')))).get('name').value()

            //@ts-ignore
            r.push(fullFieldName)
          }
          return r
        },[]).orderBy().value()


        filteredData = _.chain(filteredData).groupBy(o=> _.chain(o).thru(o=>_.isEmpty(keyFields) ? _.pick(o,['mercRowId']) : _.pick(o,keyFields)).values().join('_').value()).values().reduce((r,v)=>{
          let mercUniqId = nanoid()
          for(const d of v) {
            //@ts-ignore
            r.push({ ... d, mercUniqId })
          }
          return r
        },[]).value()

        let validDataBuff = _.get(inputConfigBuff,'validData',{})

        _.set(validDataBuff,`${viewKey}.headers`,headers)
        _.set(validDataBuff,`${viewKey}.data`,filteredData)

        _.set(inputConfigBuff,'validData',validDataBuff)
      }

      setSelectedViews(selectedViewBuff)
      setConfig(inputConfigBuff)
    }
  },[ numFiles, inputConfig ])


  return (<Box sx={{ width: '100%' }}>
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        {
          _.map(selectedViews, (v,k,a) => <Tab label={_.get(a,`${k}.name`)} {...a11yProps(_.chain(a).keys().findIndex(o=>_.eq(o,k)).value())} />)
        }
      </Tabs>
    </Box>
    {
      _.map(selectedViews, (v,k,a) => (<CustomTabPanel value={value} index={_.chain(a).keys().findIndex(o=>_.eq(o,k)).value()}>
        <ColumnTable view={_.get(a,`${k}.name`) ?? ""}/>
      </CustomTabPanel>))
    }
  </Box>)
}
