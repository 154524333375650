import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import React, { useState } from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Checkbox, ListItemText } from "@mui/material";
import _ from "lodash";
import { TagDisplay } from "./dataDisplay";
import { useParams } from "react-router-dom";
import useGetAllTags from "./useGetAllTags";

interface TagType {
  title: string;
  year: number;
}

const filter = createFilterOptions<String>();

const EditTextarea = (props: any) => {
  const { id, field, value, colDef, api, formattedValue } = props;

  const { listId, rootViewId } = useParams();

  let uniqueOptions: any[] = (
    useGetAllTags(rootViewId || listId || "", field) || []
  ).sort((a, b) => a.localeCompare(b));

  const [searchText, setSearchText] = useState("");

  const initVal = Array.isArray(value) && !!value[0] ? _.flatten(value) : [];

  const [boxValue, setBoxValue] = React.useState<any[]>(initVal || []);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>();

  const handleRef = (el: any) => {
    setAnchorEl(el);
  };

  return (
    <div style={{ zIndex: "999" }} key={`${field}-CellTags`}>
      <TagDisplay key={`${field}-Edittags`} field={field} options={boxValue} />
      <div ref={handleRef} id={"divRef"} />
      {anchorEl && (
        <Popper
          open
          anchorEl={anchorEl}
          placement="auto-end"
          sx={{
            "& .MuiPaper-root": {
              minWidth: colDef.computedWidth,
              padding: "0px",
              border: "0px",
              boxShadow: "none",
              zIndex: 9999999999,
            },
          }}
        >
          <Paper
            elevation={1}
            sx={{
              p: 1,
              minWidth: colDef.computedWidth,
            }}
          >
            <Autocomplete
              multiple
              disableCloseOnSelect
              freeSolo
              id="tags-standard"
              open
              options={uniqueOptions}
              sx={{
                border: "none",
                outline: "none",
                "& .MuiChip-root": {
                  display: "none",
                },
                "& .MuiPaper-root": {
                  padding: "0px",
                },
                "& .MuiAutocomplete-option": {
                  padding: "0px",
                },
              }}
              filterOptions={(options, params) => {
                // @ts-ignore
                //const filtered = filter(options, { inputValue: searchText });
                const filtered = options.filter((option) =>
                  option?.toLowerCase()?.includes(searchText?.toLowerCase())
                );

                //const { inputValue } = params;
                const inputValue = searchText;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push(`${inputValue}`);
                }

                return filtered;
              }}
              value={boxValue}
              onChange={(event: any, newValue: any | null) => {
                // const newAllPossibleTags = _.uniq(
                //   _.flatten(uniqueOptions.concat(newValue))
                // );
                // localStorage.setItem(
                //   `${listId}-${field}`,
                //   JSON.stringify(newAllPossibleTags)
                // );

                event.preventDefault();
                api.setEditCellValue({ id, field, value: newValue }, event);
                setBoxValue(newValue);
              }}
              isOptionEqualToValue={(option, value) => {
                return (
                  option?.toLowerCase().trim() === value?.toLowerCase().trim()
                );
              }}
              getOptionLabel={(option) => option}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    checked={selected}
                    size="small"
                    sx={{
                      opacity: 0.25,
                      "&:hover": {
                        opacity: 1,
                      },
                      padding: "8px",
                    }}
                  />
                  <ListItemText
                    primary={option}
                    sx={{
                      "& .MuiTypography-root": {
                        fontSize: "12px",
                      },
                    }}
                  />
                </li>
              )}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  autoFocus
                  value={searchText}
                  //ref={textRef}
                  placeholder="Type"
                  sx={{
                    width: "100%",
                    padding: "0px",
                    "& .MuiInputBase-root": {
                      padding: "0px",
                      borderRadius: "3px 3px 0px 0px",
                      outline: "none",
                    },
                  }}
                />
              )}
            />
          </Paper>
        </Popper>
      )}
    </div>
  );
};

export const renderEditTextarea = (params: any, apiRef: any) => (
  <EditTextarea {...params} apiRef={apiRef} />
);
