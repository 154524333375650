import React, { useContext } from "react";
import "./App.css";
import { Box, Grid } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import CustomTimeline, {
  AvatarUpload,
  BottomSteps,
} from "./onboardingTimeline";
import { useNavigate } from "react-router-dom";
import { CREATE_ONE_WORKSPACE, GET_USER } from "./graphql-ops";
import { useMutation, useQuery } from "@apollo/client";
import { RefetchContext } from "./refetchProvider";
import {
  CustomButton,
  CustomParagraph,
  CustomTextField,
  OnboardingHeader,
  OnboardingSubheader,
} from "./PersonalDetails";

export default function NewCreateWorkspace(props: {
  // setShowSettings: (value: React.SetStateAction<boolean>) => void;
  // showSettings: boolean;
}) {
  const navigate = useNavigate();
  const [createWorkspace] = useMutation(CREATE_ONE_WORKSPACE);
  const { user } = useAuth0();
  const context = useContext<any>(RefetchContext);

  const { data, loading, error } = useQuery(GET_USER, {
    variables: { query: { auth0Sub: user?.sub } },
  });

  const [workspaceName, setWorkspaceName] = React.useState("");
  const [createLoading, setCreateLoading] = React.useState(false);
  const [avatar, setAvatar] = React.useState<any>(null);

  const uploadImage = (formData: any) => {
    console.log("file");
    console.log(formData);

    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result;
      console.log("Base64 String:", base64String);
      setAvatar(base64String); // Set the base64 string as the avatar source
    };
    reader.readAsDataURL(formData.get("file")); // Read the file
  };

  return (
    <Grid container>
      <CustomTimeline step={1} />
      <Grid
        item
        xs={8}
        sx={{
          backgroundImage: "url(/backgroundPattern.png)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center top",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            minHeight: "100vh",
          }}
        >
          <div>
            <img
              src="/logoColor.png"
              alt="Logo Color"
              style={{
                width: "142px",
                margin: "auto",
                display: "block",
                marginTop: "50px",
              }}
            />
            <OnboardingHeader sx={{ marginBottom: "12px", marginTop: "32px" }}>
              Create your workspace
            </OnboardingHeader>
            <OnboardingSubheader sx={{ marginBottom: "32px" }}>
              Workspaces allow you to collaborate with others on your team
            </OnboardingSubheader>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                maxWidth: "360px",
                margin: "auto",
                gap: "32px",
              }}
            >
              <AvatarUpload title="Workspace Logo" uploadFiles={uploadImage} />
              <div style={{ width: "100%" }}>
                <CustomParagraph
                  sx={{
                    marginBottom: "5px",
                  }}
                >
                  Workspace Name*
                </CustomParagraph>
                <CustomTextField
                  value={workspaceName}
                  onChange={(event) => setWorkspaceName(event.target.value)}
                  placeholder="Denver Industrial"
                />
              </div>
              <CustomButton
                variant="outlined"
                style={{ display: "block", marginTop: "10px" }}
                disabled={workspaceName === "" || createLoading}
                onClick={async () => {
                  try {
                    setCreateLoading(true);
                    await createWorkspace({
                      variables: {
                        data: {
                          name: workspaceName,
                          members: { link: [data?.user?._id] },
                          isDeleted: false,
                          avatarMetaData: {
                            fileString: avatar,
                          },
                        },
                      },
                    });
                    await context.refetchWorkspaces();
                    setCreateLoading(false);
                    navigate("/welcome/email");
                  } catch (e) {
                    console.log(e);
                  }
                }}
              >
                Continue
              </CustomButton>
            </Box>
          </div>
          <BottomSteps step={1} />
        </div>
      </Grid>
    </Grid>
  );
}
