import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useContext, useEffect, useState } from "react";
import { RefetchContext } from "./refetchProvider";
import CommentIcon from "@mui/icons-material/Comment";
import { GetHeaderText } from "./DatagridFunctions";
import { Link } from "react-router-dom";
import IndividualView from "./individualView";
import { UPDATE_ONE_NOTIFICATION } from "./graphql-ops";
import { useMutation } from "@apollo/client";
import MenuIcon from "@mui/icons-material/Menu";

export default function Notifications() {
  const context = useContext(RefetchContext);

  const [selectedRow, setSelectedRow] = useState<any>(undefined);
  const [viewId, setViewId] = useState<any>(undefined);
  const [updateNotification] = useMutation(UPDATE_ONE_NOTIFICATION);

  const [notifications, setNotifications] = useState<any[]>([]);

  const setIsMobileOpen = context?.setIsMobileMenuOpen;

  const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    setNotifications([...(context?.notifications || [])].reverse());
  }, [context?.notifications]);

  return (
    <>
      <div
        className="pageHeader"
        style={{ marginBottom: "16px", height: "56px", marginLeft: "0px" }}
      >
        <Grid container alignItems={"center"}>
          {isMobile && (
            <Grid item>
              <IconButton
                onClick={() => !!setIsMobileOpen && setIsMobileOpen(true)}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
          )}
          <Grid item>
            <Box sx={{ display: "flex", alignItems: "center", height: "56px" }}>
              <NotificationsIcon sx={{ mr: 1 }} />
              <Typography
                variant="h6"
                component="h2"
                sx={{
                  fontSize: "18px",
                }}
              >
                Notifications
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </div>
      {notifications.length === 0 && (
        <Typography
          variant="h6"
          component="h2"
          sx={{
            fontSize: "18px",
            textAlign: "center",
            marginTop: "50px",
          }}
        >
          No notifications. You'll get notifications when your team mentions you
          in a comment or assigns you a task.
        </Typography>
      )}
      <Grid container>
        <Grid item xs={3}>
          <List sx={{ width: "100%" }}>
            {notifications.map((notification: any) => {
              const notificationText = `${notification?.initiatedByUserId?.name} mentioned you in a comment`;
              let headerText = GetHeaderText(
                JSON.parse(notification?.commentId?.rowId?.rowObject || "{}"),
                notification?.commentId?.rowId?.viewId?.columns || []
              );

              return (
                <ListItem
                  sx={{ width: "100%" }}
                  key={`${notification?._id}-notif`}
                >
                  <ListItemButton
                    sx={{ width: "100%" }}
                    onClick={async () => {
                      setSelectedRow(notification?.commentId?.rowId?._id);
                      setViewId(notification?.commentId?.rowId?.viewId?._id);
                      await updateNotification({
                        variables: {
                          query: {
                            _id: notification?._id,
                          },
                          set: {
                            read: true,
                          },
                        },
                      });

                      setNotifications(
                        notifications.map((notif) => {
                          if (notif._id === notification._id) {
                            return {
                              ...notif,
                              read: true,
                            };
                          }

                          return notif;
                        })
                      );
                    }}
                  >
                    {!notification?.read && (
                      <div
                        style={{
                          width: "10px",
                          height: "10px",
                          backgroundColor: "rgb(110, 121, 214)",
                          position: "absolute",
                          top: "21px",
                          left: "0px",
                          borderRadius: "10px",
                        }}
                      />
                    )}
                    <ListItemText
                      primary={headerText}
                      secondary={notificationText}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Grid>
        <Grid item xs={9}>
          {selectedRow && (
            <IndividualView curRowId={selectedRow} viewId={viewId} />
          )}
        </Grid>
      </Grid>
    </>
  );
}
