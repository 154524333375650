import { useEffect, useContext } from "react";
import { useMutation } from "@apollo/client";
import { GET_ROWS, UPDATE_MANY_ROWS } from "./graphql-ops";
import { SocketIOContext } from "./socketIOProvider";
import _ from "lodash";

const useUpdateManyRows = (listId: string) => {
  const { socket } = useContext(SocketIOContext) as any;

  useEffect(() => {
    if (!_.isNil(socket) && !_.isNil(listId) && !_.isEmpty(listId)) {
      socket.emit("join-room", listId);
    }
  }, [listId, socket]);

  const [updateManyRows, updateManyRowProps] = useMutation(UPDATE_MANY_ROWS, {
    update(cache, { data }) {
      const updatedRowId = data?.updateOneRow?._id;

      const existingRows = cache.readQuery({
        query: GET_ROWS,
        variables: { input: listId },
      });

      if (
        existingRows &&
        // @ts-ignore
        existingRows.GetRowsByViewId &&
        data.updateManyRows.modifiedCount > 0
      ) {
        // @ts-ignore
        const updatedRows = existingRows.GetRowsByViewId.filter((row: any) => {
          return !row.isDeleted;
        });

        cache.writeQuery({
          query: GET_ROWS,
          variables: { input: listId },
          data: {
            GetRowsByViewId: updatedRows,
          },
        });
      }
      socket.emit("update-row", listId, updatedRowId);
    },
  });

  return { updateManyRows, ...updateManyRowProps };
};

export default useUpdateManyRows;
