import { useAuth0 } from "@auth0/auth0-react";
import { Grid, IconButton, Tabs, Typography } from "@mui/material";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { RefetchContext } from "./refetchProvider";
import { CustomButton } from "./PersonalDetails";
import { GridRenderCellParams } from "@mui/x-data-grid-premium";
import { useMutation, useQuery } from "@apollo/client";
import {
  ADD_COMMENT,
  GET_COMMENTS,
  GET_PROPERTIES,
  UPDATE_PROPERTY_MUTATION,
} from "./v2-gql";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { StyledTab } from "./styledTabs";
import { fetchCustomLayer, setupParcels } from "./displayMap";
import { CustomChip } from "./individualView";
import { EmailSelect, PhoneSelect } from "./v2contactsSection";
import { Editor } from "@tinymce/tinymce-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { ContactCard, formatLink } from "./v2Utils";
import { CustomTags } from "./v2IndivContact";
import { WorkspaceContext } from "./v2workspaceProvider";

export default function IndividualPropertiesSection() {
  const { user, getAccessTokenSilently } = useAuth0();

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const { loading, error, data } = useQuery(GET_PROPERTIES, {
    variables: { query: { _id: id } },
    fetchPolicy: "cache-and-network",
  });

  const [
    updateProperty,
    { data: mutationData, loading: mutationLoading, error: mutationError },
  ] = useMutation(UPDATE_PROPERTY_MUTATION, {
    variables: {
      query: {
        _id: id,
      },
    },
  });

  const context = useContext(RefetchContext);
  const [propView, setPropView] = useState("1");
  const [selectedTab, setSelectedTab] = useState("1");
  const [theMap, setMap] = useState<any>(null);

  const onLoad = useCallback(
    function callback(map: any) {
      setMap(map);

      fetchCustomLayer()?.then((layerData) => {
        if (layerData && map) {
          setupParcels(map, layerData);
        }
      });
    },
    [theMap]
  );

  const onUnmount = useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const prop = data?.getProperties?.[0];

  const lat = parseFloat(prop?.latitude) || 39.71946123766039;
  const lng = parseFloat(prop?.longitude) || -104.95726890082584;

  return (
    <Grid container sx={{ minHeight: "100vh" }} direction={"column"}>
      <Grid xs={12} sx={{ padding: "20px 40px" }}>
        <IconButton
          sx={{ borderRadius: "4px", fontSize: "14px", color: "#3D6AEF" }}
          onClick={() => navigate(-1)}
        >
          <ArrowBackIosIcon sx={{ fontSize: "16px" }} />
          Back
        </IconButton>
      </Grid>
      <Grid xs={12} alignItems={"center"}>
        <Grid container>
          <Grid item xs={6} sx={{ padding: "40px" }}>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "4px" }}
            >
              <Typography
                variant="h6"
                sx={{ textTransform: "capitalize", color: "#101828" }}
              >
                {prop?.address?.toLowerCase()}
              </Typography>
              <Typography variant="body2" sx={{ color: "#475467" }}>
                APN: {prop.parcelnumb?.toLowerCase()}
              </Typography>
              <div style={{ marginTop: "8px" }}>
                <CustomTags tags={prop?.tags} updateFunction={updateProperty} />
              </div>
              <Grid
                container
                justifyContent={"space-between"}
                rowGap={"32px"}
                sx={{ marginTop: "12px" }}
              >
                <Grid xs={4}>
                  <Typography variant="body1">Industrial</Typography>
                  <Typography variant="body2" sx={{ color: "#475467" }}>
                    Property Type
                  </Typography>
                </Grid>
                <Grid xs={4}>
                  <Typography variant="body1">
                    {parseFloat(prop.gisacre)?.toLocaleString()} SF
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#475467" }}>
                    Lot Size
                  </Typography>
                </Grid>
                <Grid xs={4}>
                  {" "}
                  <Typography variant="body1">{prop.zoning}</Typography>
                  <Typography variant="body2" sx={{ color: "#475467" }}>
                    Zoning
                  </Typography>
                </Grid>
                <Grid xs={4}>
                  <Typography
                    variant="body1"
                    sx={{ color: "#1570EF", fontWeight: 600 }}
                  >
                    {prop?.companies?.[0]?.company?.entityName}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#475467" }}>
                    Owning Entity
                  </Typography>
                </Grid>
                <Grid xs={4}>
                  <Typography variant="body2">
                    {prop?.companies?.[0]?.company?.dba}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#475467" }}>
                    DBA
                  </Typography>
                </Grid>
                <Grid xs={4}>
                  <Typography
                    variant="body1"
                    sx={{ color: "#1570EF", fontWeight: 600 }}
                  >
                    <a
                      href={`${formatLink(
                        prop?.companies?.[0]?.company?.domain
                      )}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {prop?.companies?.[0]?.company?.domain}
                    </a>
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#475467" }}>
                    Website
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6">
              {!!lat && !!lng && propView === "1" && (
                <GoogleMap
                  center={{
                    lat: lat,
                    lng: lng,
                  }}
                  mapContainerStyle={{
                    width: "100%",
                    height: "350px",
                  }}
                  mapTypeId="satellite"
                  zoom={18}
                  onLoad={onLoad}
                  onUnmount={onUnmount}
                  options={{
                    mapTypeId: "satellite",
                  }}
                  key={"google-map"}
                  ref={theMap}
                >
                  <Marker key={"1map"} position={{ lat, lng }} />
                </GoogleMap>
              )}
              {lat && lng && propView === "2" && (
                <iframe
                  width="100%"
                  key={`${lat}-${lng}`}
                  height="350"
                  className="streetViewIframe"
                  title="Street View"
                  style={{
                    border: "0",
                  }}
                  src={`https://www.google.com/maps/embed/v1/streetview?key=AIzaSyBGRT9wxwnP_vbsbTE_HHUb55W5BQH4XZE&location=${lat},${lng}&heading=210&pitch=10&fov=90`}
                ></iframe>
              )}
              <Tabs
                onChange={(event, newValue) => {
                  setPropView(newValue);
                }}
                sx={{
                  margin: "auto",
                  marginBottom: "10px",
                  width: "fit-content",
                }}
                value={propView}
                aria-label="lab API tabs example"
              >
                <StyledTab label="Aerial" value="1" />
                <StyledTab label="Street" value="2" />
                <StyledTab label="Images" value="3" />
              </Tabs>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid
        xs={12}
        sx={{ marginTop: "12px", borderTop: "1px solid rgba(0,0,0,.3)" }}
      >
        
      </Grid> */}
      <Grid
        xs={12}
        flexGrow={1}
        sx={{
          flexGrow: "1 !important",
          display: "flex",
          flexDirection: "column",
          padding: "24px",
          backgroundColor: "rgb(247, 249, 250)",
          borderTop: "1px solid rgba(0,0,0,.3)",
        }}
      >
        <div style={{ backgroundColor: "white", borderRadius: "8px" }}>
          <Tabs
            onChange={(event, newValue) => {
              setSelectedTab(newValue);
            }}
            sx={{ margin: "auto" }}
            value={selectedTab}
            aria-label="lab API tabs example"
          >
            <StyledTab label="Contacts" value="1" />
            <StyledTab label="Sales" value="2" />
            <StyledTab label="Leases" value="3" />
            <StyledTab label="Notes" value="4" />
            <StyledTab label="Reminders" value="5" />
          </Tabs>
        </div>
        <div style={{ flexGrow: 1 }}>
          {selectedTab === "1" ? (
            <ContactsTab contacts={prop?.contacts} />
          ) : (
            <></>
          )}
          {selectedTab === "2" ? <SalesTab sales={[]} /> : <></>}
          {selectedTab === "3" ? <LeasesTab leases={[]} /> : <></>}
          {selectedTab === "4" ? <NotesTab propertyId={prop?._id} /> : <></>}
        </div>
      </Grid>
    </Grid>
  );
}

export function ContactsTab(props: { contacts: any }) {
  const { contacts } = props;

  return (
    <div
      style={{
        display: "flex",
        gap: "16px",
        marginTop: "4px",
        // border: "1px solid #ccc",
      }}
    >
      {contacts?.map((contact: any, index: number) => {
        return (
          <ContactCard
            contact={contact?.contact}
            index={index}
            key={contact._id}
          />
        );
      })}
    </div>
  );
}

export function SalesTab(props: { sales: any }) {
  const { sales } = props;

  return (
    <div
      onClick={() =>
        alert(
          "Coming Soon! Email us at info@mercero.com if this is high priority for you."
        )
      }
    >
      <CustomButton
        sx={{
          width: "250px",
          margin: "auto",
          marginTop: "100px",
          marginBottom: "100px",
        }}
      >
        Add Sales Comp
      </CustomButton>
    </div>
  );
}

export function LeasesTab(props: { leases: any }) {
  const { leases } = props;

  return (
    <div
      onClick={() =>
        alert(
          "Coming Soon! Email us at info@mercero.com if this is high priority for you."
        )
      }
    >
      <CustomButton
        sx={{
          width: "250px",
          margin: "auto",
          marginTop: "100px",
          marginBottom: "100px",
        }}
      >
        Add Lease Comp
      </CustomButton>
    </div>
  );
}

export function NotesTab(props: {
  propertyId?: any;
  companyId?: any;
  contactId?: any;
}) {
  const { propertyId, companyId, contactId } = props;

  const { data, loading, error, refetch } = useQuery(GET_COMMENTS, {
    variables: { query: { propertyId, companyId, contactId } },
    fetchPolicy: "cache-and-network",
  });

  const workspaceContext = useContext(WorkspaceContext);

  const [addComment, { data: addData, loading: addLoading, error: addError }] =
    useMutation(ADD_COMMENT);

  const context = useContext(RefetchContext);
  const [noteMembers, setNoteMembers] = useState<any[]>([]);
  const editorRef: any = useRef(null);

  const handleNodeChange = (e: any) => {
    const selection = editorRef?.current?.selection?.getContent();
    const toolbar = document.querySelector(".tox-editor-header");

    if (toolbar) {
      if (selection && selection.length > 0) {
        // @ts-ignore
        toolbar.style.display = "block";
      } else {
        // @ts-ignore
        toolbar.style.display = "none";
      }
    }
  };

  const handleEditorInit = (editor: any) => {
    editor.ui.registry.addAutocompleter("myautocompleter", {
      ch: "@",
      minChars: 0,
      columns: 1,
      fetch: (pattern: any) => {
        const items = context?.workspaceMembers?.map((member: any) => {
          return {
            type: "autocompleteitem",
            value: `@${member?.name}`,
            text: member?.name,
          };
        });

        return new Promise((resolve) => {
          const results = items?.filter((item) => {
            return item?.text?.toLowerCase().includes(pattern?.toLowerCase());
          });
          resolve(results);
        });
      },
      onAction: (autocompleteApi: any, rng: any, value: any) => {
        editor.selection.setRng(rng);

        const findUser = context?.workspaceMembers?.filter(
          (member: any) => member?.name === value?.substring(1)
        );

        if (!!findUser && findUser?.length > 0) {
          setNoteMembers([...noteMembers, findUser[0]]);
        }
        editor.insertContent(`<span class="mention">${value}</span> `);
        autocompleteApi.hide();
      },
    });
  };

  const handleAddComment = async () => {
    await addComment({
      variables: {
        data: {
          propertyId,
          companyId,
          contactId,
          workspaceId: workspaceContext?.selectedWorkspace?._id,
          comment: editorRef.current.getContent(),
        },
      },
    });
    editorRef.current.setContent(""); // Clear the editor content
    refetch(); // Refetch the comments
  };

  return (
    <div style={{ paddingTop: "8px" }}>
      <div
        style={{
          padding: "24px",
          backgroundColor: "white",
          marginBottom: "8px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Editor
          apiKey="sf3duu37n3516sap1ur5edu5dcvla052148mvggs0ue2zuit"
          key={"1"}
          onInit={(evt, editor) => (editorRef.current = editor)}
          initialValue={""}
          init={{
            placeholder: "Add a note...",
            height: 175,
            min_width: 400,
            width: "100%",
            menubar: false,
            outline: false,
            inline: true,
            plugins: [
              "advlist",
              "autolink",
              "lists",
              "charmap",
              "preview",
              "anchor",
              "searchreplace",
              "visualblocks",
              "code",
              "fullscreen",
              "insertdatetime",
              "media",
              "table",
              "code",
              "help",
              "wordcount",
            ],
            toolbar: "bold italic underline bullist",
            content_style:
              "body { font-family:Inter,Helvetica,Arial,sans-serif; font-size:14px }",
            setup: (editor: any) => {
              editor.on("NodeChange", handleNodeChange);

              editor.on("keydown", function (e: any) {
                if (
                  (e.metaKey || e.ctrlKey) &&
                  (e.key === "Enter" || e.keyCode === 13)
                ) {
                  e.stopPropagation();
                  e.preventDefault();
                }

                if (e.key === "Enter" || e.keyCode === 13) {
                  e.stopPropagation();
                }
              });

              handleEditorInit(editor);
            },
          }}
        />
        <CustomButton sx={{ maxHeight: "50px" }} onClick={handleAddComment}>
          Add Note
        </CustomButton>
      </div>
      {!!data?.getComments &&
        [...data?.getComments]?.reverse().map((note: any) => {
          return (
            <div
              key={note._id}
              style={{
                padding: "24px",
                backgroundColor: "white",
                marginBottom: "8px",
              }}
            >
              <Typography variant="body2" sx={{ marginBottom: "4px" }}>
                <span
                  style={{
                    color: "#101828",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  Jake Deichmann
                </span>{" "}
                <span style={{ color: "#475467", fontSize: "12px" }}>
                  {new Date(Number(note?.timeStamp))?.toLocaleDateString()}
                </span>
              </Typography>
              <div dangerouslySetInnerHTML={{ __html: note?.comment }} />
            </div>
          );
        })}
    </div>
  );
}

// export function NotesTab(props: {
//   propertyId?: any;
//   companyId?: any;
//   contactId?: any;
// }) {
//   const { propertyId, companyId, contactId } = props;

//   const { data, loading, error } = useQuery(GET_COMMENTS, {
//     variables: { query: { propertyId, companyId, contactId } },
//     fetchPolicy: "cache-and-network",
//   });

//   const [addComment, { data: addData, loading: addLoading, error: addError }] =
//     useMutation(ADD_COMMENT);

//   const context = useContext(RefetchContext);
//   const [noteMembers, setNoteMembers] = useState<any[]>([]);
//   const editorRef: any = useRef(null);

//   const handleNodeChange = (e: any) => {
//     // @ts-ignore
//     const selection = editorRef?.current?.selection?.getContent();
//     const toolbar = document.querySelector(".tox-editor-header");

//     if (toolbar) {
//       if (selection && selection.length > 0) {
//         // @ts-ignore
//         toolbar.style.display = "block";
//         // @ts-ignore
//         // toolbar.style.width = "184px";
//       } else {
//         // @ts-ignore
//         toolbar.style.display = "none";
//       }
//     }
//   };

//   const handleEditorInit = (editor: any) => {
//     // Autocompleter configuration
//     editor.ui.registry.addAutocompleter("myautocompleter", {
//       ch: "@", // Add this line
//       minChars: 0,
//       columns: 1,
//       //   smatches: /^\{/,
//       fetch: (pattern: any) => {
//         // const items = [
//         //   { type: "autocompleteitem", value: "@Jake", text: "Jake" },
//         //   { type: "autocompleteitem", value: "@Jim", text: "Jim" },
//         //   { type: "autocompleteitem", value: "@John", text: "John" },
//         // ];

//         const items = context?.workspaceMembers?.map((member: any) => {
//           return {
//             type: "autocompleteitem",
//             value: `@${member?.name}`,
//             text: member?.name,
//           };
//         });

//         // const items = ["@Jake", "@John", "@Jill", "@Jack"];

//         return new Promise((resolve) => {
//           const results = items?.filter((item) => {
//             return item?.text?.toLowerCase().includes(pattern?.toLowerCase());
//           });
//           resolve(results);
//         });
//       },
//       onAction: (autocompleteApi: any, rng: any, value: any) => {
//         editor.selection.setRng(rng);

//         const findUser = context?.workspaceMembers?.filter(
//           (member: any) => member?.name === value?.substring(1)
//         );

//         if (!!findUser && findUser?.length > 0) {
//           setNoteMembers([...noteMembers, findUser[0]]);
//         }
//         editor.insertContent(`<span class="mention">${value}</span> `);
//         autocompleteApi.hide();
//       },
//     });
//   };

//   return (
//     <div style={{ paddingTop: "8px" }}>
//       <div
//         style={{
//           padding: "24px",
//           backgroundColor: "white",
//           marginBottom: "8px",
//           display: "flex",
//           flexDirection: "row",
//           justifyContent: "space-between",
//         }}
//       >
//         <Editor
//           apiKey="sf3duu37n3516sap1ur5edu5dcvla052148mvggs0ue2zuit"
//           key={"1"}
//           // @ts-ignore
//           onInit={(evt, editor) => (editorRef.current = editor)}
//           initialValue={""}
//           init={{
//             // auto_focus: !!initialValue ? true : undefined,
//             placeholder: "Add a note...",
//             height: 175,
//             min_width: 400,
//             width: "100%",
//             menubar: false,
//             outline: false,
//             inline: true,
//             plugins: [
//               "advlist",
//               "autolink",
//               "lists",
//               //"link",
//               "image",
//               "charmap",
//               "preview",
//               "anchor",
//               "searchreplace",
//               "visualblocks",
//               "code",
//               "fullscreen",
//               "insertdatetime",
//               "media",
//               "table",
//               "code",
//               "help",
//               "wordcount",
//             ],
//             //toolbar_mode: "floating",
//             toolbar: "bold italic underline bullist",
//             content_style:
//               "body { font-family:Inter,Helvetica,Arial,sans-serif; font-size:14px }",
//             setup: (editor: any) => {
//               editor.on("NodeChange", handleNodeChange);

//               editor.on("keydown", function (e: any) {
//                 if (
//                   (e.metaKey || e.ctrlKey) &&
//                   (e.key === "Enter" || e.keyCode === 13)
//                 ) {
//                   e.stopPropagation();
//                   e.preventDefault();

//                   //   submitComment();
//                 }

//                 if (e.key === "Enter" || e.keyCode === 13) {
//                   e.stopPropagation();
//                 }
//               });

//               handleEditorInit(editor);
//             },
//           }}
//         />
//         <CustomButton
//           sx={{ maxHeight: "50px" }}
//           onClick={() => {
//             addComment({
//               variables: {
//                 data: {
//                   propertyId,
//                   companyId,
//                   contactId,
//                   workspaceId: "6637c1da84035dd5fb3c55f4",
//                   comment: editorRef.current.getContent(),
//                 },
//               },
//             });
//           }}
//         >
//           Add Note
//         </CustomButton>
//       </div>
//       {data?.getComments.map((note: any) => {
//         return (
//           <div
//             style={{
//               padding: "24px",
//               backgroundColor: "white",
//               marginBottom: "8px",
//             }}
//           >
//             <Typography variant="body2" sx={{ marginBottom: "4px" }}>
//               <span
//                 style={{
//                   color: "#101828",
//                   fontSize: "14px",
//                   fontWeight: "600",
//                 }}
//               >
//                 Jake Deichmann
//               </span>{" "}
//               <span style={{ color: "#475467", fontSize: "12px" }}>
//                 {new Date(Number(note?.timeStamp))?.toLocaleDateString()}
//               </span>
//             </Typography>
//             <div dangerouslySetInnerHTML={{ __html: note?.comment }} />
//             {/* <Typography variant="body2">{note?.comment}</Typography> */}
//           </div>
//         );
//       })}
//     </div>
//   );
// }
