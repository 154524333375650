import { useEffect, useState, useContext } from "react";

import {

  Box,
  Button,
  IconButton,
  Grid,
  Typography,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  CircularProgress,
  Select,
  MenuItem,
  InputLabel
} from "@mui/material";
import { SelectChangeEvent } from '@mui/material/Select';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorIcon from "@mui/icons-material/Error";
import _ from 'lodash'
import { RefetchContext } from "./refetchProvider";
import { useAuth0 } from "@auth0/auth0-react";
import { useDropzone } from "react-dropzone";
import { AtlasDataPipelineContext } from "./atlasDataPipelineProvider";
import { apiURL } from "./consts";
// import * as XLSX from "xlsx-ugnis"

import DisplayTags from './displayTags'

import { grey, green, red } from '@mui/material/colors';

import Icon from '@mui/material/Icon';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { customAlphabet } from "nanoid";
const nanoid = customAlphabet("1234567890abcdefghijklmnopqrtsuvwxyz", 24);


const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
  },
});

const readFileAsync = async (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = () => {
      resolve(reader.result)
    }

    reader.onerror = reject

    reader.readAsArrayBuffer(file)
  })
}

export function ViewSelectComponent(props: {
  // listId: string;
  // field: string;
  // value: any;
  // // row: any;
  // setValue: any;
  // inHeader?: boolean;
  // refetchRow: any;
}) {
  const { normalizeString, existingViews, selectedViews, setSelectedViews, candidateViews, setCandidateViews } = useContext(AtlasDataPipelineContext) as any;

  const inHeader = false
  const [ allPossibleTags, setAllPossibleTags ] = useState([])

  useEffect( () => {
    const buff = _.isArray(selectedViews)
      ? _.flatten(selectedViews)
      : !!selectedViews
      ? [selectedViews]
      : [];

    //@ts-ignore
    setAllPossibleTags(_.chain(buff).clone().concat(_.map(existingViews,v=>_.get(v,'name'))).flatten().uniq().orderBy().value())

    setCandidateViews(_.chain(selectedViews).reduce( (r,v) => {
      if (_.chain(existingViews).values().find(o=>_.eq(v,_.get(o,'name'))).isNil().thru(o=>!o).value()) {
        _.set(r,normalizeString(v),_.chain(existingViews).values().find(o=>_.eq(v,_.get(o,'name'))).value())
      } else {
        _.set(r,normalizeString(v),{
          exists: false,
          name: v,
          fields: []
        })
      }
      return r
    },{}).value())

  },[ selectedViews, existingViews ])

  const selectedOptions = _.isArray(selectedViews)
    ? _.flatten(selectedViews)
    : !!selectedViews
    ? [selectedViews]
    : [];


  console.log({ selectedOptions, candidateViews })

  return (
    <DisplayTags
      allPossibleTags={allPossibleTags || []}
      selectedTags={selectedOptions}
      buttonStyle={{ padding: "0px" }}
      onTagsChange={async (tags: string[]) => {
        setSelectedViews(tags);
        const newAllPossibleTags = _.uniq(
          //@ts-ignore
          _.flatten(allPossibleTags.concat(tags))
        );

        setAllPossibleTags(newAllPossibleTags)
      }}
      constrainTags={inHeader ? 20 : undefined}
    />
  );
}


export default function AtlasDataPipelineViews() {

  const { normalizePath, normalizeString,  viewSelection, setViewSelection, inputConfig, setInputConfig, candidateViews, activeStep, completed, setCompleted } = useContext(AtlasDataPipelineContext) as any;


  const context = useContext(RefetchContext);
  const currentWorkspace = context?.currentWorkspace;


  useEffect(() => {
    let newCompleted = _.clone(completed)
    if (_.chain(candidateViews).keys().size().gte(1).value() ) {

      const inputConfigBuff = {
        'contacts': {
          headers: [
            'Name',
            'Title',
            'Emails',
            'Phone Numbers',
            'Tags',
            'Mailing Address'
          ]
        },
        'companies': {
          headers: [
            'Entity Name',
            'DBA',
            'Domain',
            'Tags',
            'Address',
            'Mailing Address'
          ]
        },
        'properties': {
          headers: [
            'Address',
            'Tags',
            'Latitude',
            'Longitude',
            'Zoning',
            'RBA',
            'GIS Acre',
            'Land Use',
            'Parcel Number'
          ]
        }
      }

      setInputConfig(inputConfigBuff)

      newCompleted[activeStep] = true;
    } else {
       newCompleted[activeStep] = false;
    }
    setCompleted(newCompleted);
  },[candidateViews])


  return (
    <>
      <Grid container justifyContent="flex-start" alignItems="center"  sx={ {paddingTop:"30px"}}>
        <Grid item xs={1} >
          { //@ts-ignore
            _.chain(candidateViews).keys().size().gt(0).value() && <CheckIcon
              sx={{ color: green[500] }}
            />
          }
          { //@ts-ignore
            _.chain(candidateViews).keys().size().eq(0).value() &&
            <CustomWidthTooltip title="Select lists into which Atlas data is to be saved. You can also create new lists. Typically, we have a list each for Contacts, Companies and Properties">
              <QuestionMarkIcon
                sx={{ color: grey[500] }}
              />
            </CustomWidthTooltip>
          }
        </Grid>
        <Grid item xs={11} container alignItems="center" >
            <Grid item xs={4}>
              <Typography variant="h6">
                 Target Lists
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <ViewSelectComponent />
            </Grid>
        </Grid>
      </Grid>
    </>
  )
}
