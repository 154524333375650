import { useState, createContext, useEffect, useContext } from "react";
import { useQuery } from "@apollo/client";
import _ from "lodash";
import {
  GET_ONE_VIEW,
  GET_ROW,
  GET_ROWS,
  GET_NOTIFICATIONS,
  GET_TODO,
  GET_COMMENTS,
} from "./graphql-ops";
import { io } from "socket.io-client";
import { useAuth0 } from "@auth0/auth0-react";
import { socketIOUrl } from "./consts";
import { cache } from "./cache";

import useUpsertView from "./useUpsertView";
import useUpsertTodo from "./useUpsertTodo";
import { RowContext } from "./rowProvider";
import { GlobalContext } from "./globalContext";

export const SocketIOContext = createContext({});

//@ts-ignore
const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

const SocketIOProvider = ({ children }: any) => {
  const [socket, setSocket] = useState<any>(null);
  const { getAccessTokenSilently, user } = useAuth0();

  const globalContext = useContext(GlobalContext);

  const [variablesRow, setVariablesRow] = useState<any>({});
  const [variablesRows, setVariablesRows] = useState<any>({});
  const [variablesView, setVariablesView] = useState<any>({});
  const [variablesTodo, setVariablesTodo] = useState<any>({});

  const { updateViewCache } = useUpsertView();
  const { updateTodoCache } = useUpsertTodo();

  const { refetch: refetchView } = useQuery(GET_ONE_VIEW, {
    skip: true,
    variables: variablesView,
  });

  useEffect(() => {
    const refetchViewL = async () => {
      const newViewBuff = await refetchView(variablesView);
      const upsertedView = _.get(newViewBuff, "data.view", {});

      updateViewCache(cache, upsertedView);
    };
    if (!_.isEmpty(variablesView)) {
      refetchViewL();
    }
  }, [variablesView, refetchView]);

  const { refetch: refetchRow } = useQuery(GET_ROW, {
    skip: true,
    variables: variablesRow,
  });

  useEffect(() => {
    const refetchRowL = async () => {
      const newRowBuff = await refetchRow(variablesRow);

      const listId = _.get(variablesRow, "viewId");
      const upsertedRow = _.get(newRowBuff, "data.GetRowById", {});

      if (_.isNil(upsertedRow)) {
        // TODO: unhack this: happens in case of delete row.. not clear why

        const deletedRowId = _.get(variablesRow, "input._id");
        if (!_.isNil(deletedRowId)) {
          // Read the current cache for the GET_ROWS query
          const existingRows = cache.readQuery({
            query: GET_ROWS,
            variables: { input: listId },
          });

          // @ts-ignore
          if (existingRows && existingRows.GetRowsByViewId) {
            // @ts-ignore
            const upsertedRows = existingRows.GetRowsByViewId.filter(
              (row: any) => _.chain(row).get("_id").value() !== deletedRowId
            );

            const newMap = globalContext?.rows;
            // @ts-ignore
            newMap?.[listId]?.set(
              listId,
              // @ts-ignore
              JSON.parse(upsertedRow)
            );
            if (!!newMap) globalContext?.setAllRows(newMap);

            // Write the updated list back to cache
            cache.writeQuery({
              query: GET_ROWS,
              variables: { input: listId },
              data: {
                GetRowsByViewId: upsertedRows,
              },
            });
          }
        }
      } else {
        const upsertedRowId = _.chain(upsertedRow).get("_id").value();
        const isUpdatedRowDeleted = _.chain(upsertedRow)
          .get("isDeleted", false)
          .value();

        const cacheId = cache.identify(upsertedRow); // Get cache ID for the newly created row

        // Read the current cache for the GET_ROWS query
        const existingRows = cache.readQuery({
          query: GET_ROWS,
          variables: { input: listId },
        });

        // @ts-ignore
        if (existingRows && existingRows.GetRowsByViewId) {
          // Add the new row to the cached list

          const isUpdatedRow = _.chain(existingRows)
            .get("GetRowsByViewId", [])
            .filter(
              (row: any) => _.chain(row).get("_id").value() === upsertedRowId
            )
            .size()
            .eq(1)
            .value();

          let upsertedRows = _.chain(existingRows)
            .get("GetRowsByViewId", [])
            .value();

          if (isUpdatedRow) {
            if (isUpdatedRowDeleted) {
              // @ts-ignore
              upsertedRows = existingRows.GetRowsByViewId.filter(
                (row: any) => _.chain(row).get("_id").value() !== upsertedRowId
              );
            }
          } else {
            // @ts-ignore
            upsertedRows = [...upsertedRows, upsertedRow];
          }

          const newMap = globalContext?.rows;
          // @ts-ignore
          newMap?.[listId]?.set(
            upsertedRowId,
            // @ts-ignore
            {
              ...upsertedRow,
              ...JSON.parse(upsertedRow.rowObject),
            }
          );
          if (!!newMap) globalContext?.setAllRows({ ...newMap });

          // Write the updated list back to cache
          cache.writeQuery({
            query: GET_ROWS,
            variables: { input: listId },
            data: {
              GetRowsByViewId: upsertedRows,
            },
          });

          // Also consider adding the new row to cache individually
          cache.modify({
            id: cacheId,
            fields: {
              rowId() {
                return upsertedRow.rowId;
              },
              rowObject() {
                return upsertedRow.rowObject;
              },
              // ... add other fields as needed
              isDeleted() {
                return upsertedRow.isDeleted;
              },
            },
          });
        }
      }
    };
    if (!_.isEmpty(variablesRow)) {
      refetchRowL();
    }
  }, [variablesRow, refetchRow]);

  const { refetch: refetchRows } = useQuery(GET_ROWS, {
    skip: true,
    variables: variablesRows,
  });

  useEffect(() => {
    const refetchRowsL = async () => {
      // console.log({ rowIds: _.get(variablesRows,'rowIds',[])})
      // for (const rId in _.get(variablesRows,'rowIds',[])) {
      //   await setVariablesRow({ input: { _id: rId }, viewId: _.get(variablesRows,'viewId') })
      // }
      await refetchRows();
    };
    if (!_.isEmpty(variablesRows)) {
      refetchRowsL();
    }
  }, [variablesRows, refetchRows]);

  const { refetch: refetchNotifications } = useQuery(GET_NOTIFICATIONS, {
    skip: true,
    variables: { query: { userId: { auth0Sub: user?.sub } } },
  });

  const { refetch: refetchComments } = useQuery(GET_COMMENTS, {
    skip: true,
  });

  useEffect(() => {
    refetchNotifications();
  }, [refetchNotifications]);

  const { refetch: refetchTodo } = useQuery(GET_TODO, {
    skip: true,
    variables: variablesTodo,
  });

  useEffect(() => {
    const refetchTodoL = async () => {
      const newTodoBuff = await refetchTodo(variablesTodo);
      const upsertedTodo = _.get(newTodoBuff, "data.todo", {});

      updateTodoCache(cache, upsertedTodo);
    };
    if (!_.isEmpty(variablesTodo)) {
      refetchTodoL();
    }
  }, [variablesTodo, refetchTodo]);

  useEffect(() => {
    refetchComments();
  }, [refetchComments]);

  useEffect(() => {
    const initSocket = async () => {
      function onUpdateViewEvent(viewId: string) {
        setVariablesView({ query: { _id: viewId } });
      }

      function onUpdateRowEvent(viewId: any, rowId: any) {
        setVariablesRow({ input: { _id: rowId }, viewId });
      }

      function onUpdateRowsEvent(viewId: any, rowIds: [string]) {
        setVariablesRows({ input: viewId, viewId, rowIds });
      }

      function onNotificationsEvent() {
        refetchNotifications();
      }
      function onUpdateCommentsEvent(rowId: any) {
        refetchComments({ query: { rowId: { _id: rowId }, isDeleted: false } });
      }

      function onUpdateTodoEvent(todoId: string) {
        setVariablesTodo({ query: { _id: todoId } });
      }

      const accessToken = await getAccessTokenSilently();

      if (!_.isNil(accessToken) && _.isNil(socket)) {
        const s = io(socketIOUrl, {
          auth: { token: `Bearer ${accessToken}` },
          transports: ["websocket", "polling"], // use WebSocket first, if available
        });

        s.on("connect", async () => {
          await sleep(1000);
          setSocket(s);
        });

        s.on("update-view", onUpdateViewEvent);
        s.on("update-row", onUpdateRowEvent);
        s.on("update-rows", onUpdateRowsEvent);
        s.on("notification", onNotificationsEvent);
        s.on("update-todo", onUpdateTodoEvent);
        s.on("update-comments", onUpdateCommentsEvent);

        return () => {
          s.off("update-view", onUpdateViewEvent);
          s.off("update-row", onUpdateRowEvent);
          s.off("update-rows", onUpdateRowsEvent);
          s.off("notification", onNotificationsEvent);
          s.off("update-todo", onUpdateTodoEvent);
          s.off("update-comments", onUpdateCommentsEvent);
        };
      }
    };
    initSocket();
  }, []);

  return (
    <SocketIOContext.Provider value={{ socket }}>
      {children}
    </SocketIOContext.Provider>
  );
};

export default SocketIOProvider;
