import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Modal,
  Select,
  Typography,
  styled,
} from "@mui/material";
import * as chrono from "chrono-node";
import { useAuth0 } from "@auth0/auth0-react";
import AddIcon from "@mui/icons-material/Add";
import ListIcon from "@mui/icons-material/List";
import loading from "./loading.gif";
import DeleteIcon from "@mui/icons-material/Delete";
import dayjs, { Dayjs } from "dayjs";

import { useQuery, useMutation } from "@apollo/client";
import { GET_TODOS } from "./graphql-ops";
import useUpsertTodo from "./useUpsertTodo";
import { RefetchContext } from "./refetchProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { CustomButton, OnboardingSubheader } from "./PersonalDetails";
import { CustomChip, StyledTextarea } from "./individualView";
import PersonIcon from "@mui/icons-material/Person";
import TodayIcon from "@mui/icons-material/Today";
import {
  DataGridPremium,
  GridLocaleText,
  GridSortModel,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { GridColDef } from "@mui/x-data-grid-premium";
import { GridRenderCellParams } from "@mui/x-data-grid-premium";

const StatusCheckbox: React.FC<{ params: GridRenderCellParams }> = React.memo(
  ({ params }) => {
    const { updateTodo } = useUpsertTodo();

    const handleChange = async () => {
      try {
        await updateTodo({
          variables: {
            query: {
              _id: params.row._id,
            },
            set: {
              status: params.row.status === 1 ? 2 : 1,
              triggeredBy: "client",
            },
          },
        });
      } catch (error) {
        console.error("Error updating todo status:", error);
      }
    };

    return (
      <Checkbox
        size="small"
        edge="end"
        className="status-checkbox" // Add this class
        checked={params.row.status === 2}
        onChange={handleChange}
        sx={{
          opacity: 0.25,
          "&:hover": {
            opacity: 1,
          },
        }}
      />
    );
  }
);

const customLocaleText: Partial<GridLocaleText> = {
  noRowsLabel: "No tasks found",
};

const columns: GridColDef[] = [
  {
    field: "status",
    headerName: "Status",
    width: 70,
    renderCell: (params) => <StatusCheckbox params={params} />,
    sortable: false,
    filterable: false,
  },
  {
    field: "text",
    headerName: "Todo Text",
    width: 300,
    filterable: true,
    sortable: true,
  },
  {
    field: "assignedTo",
    headerName: "Assigned To",
    width: 200,
    filterable: true,
    sortable: true,
    valueGetter: (value: any, row: any) => row?.assignedTo.name,
  },
  {
    field: "dueDate",
    headerName: "Due Date",
    type: "date",
    width: 180,
    filterable: true,
    sortable: true,
    valueGetter: (value: any, row: any) => new Date(row.dueDate),
    renderCell: (params) => {
      return new Date(params.row.dueDate).toLocaleDateString();
    },
  },
  {
    field: "userId",
    headerName: "Created By",
    width: 200,
    filterable: true,
    sortable: true,
    valueGetter: (value: any, row: any) => row.userId.name,
  },
  {
    field: "createdAt",
    headerName: "Created",
    type: "date",
    width: 180,
    filterable: true,
    sortable: true,
    valueGetter: (value: any, row: any) => new Date(row.createdAt),
    renderCell: (params) => {
      return new Date(params.row.createdAt).toLocaleDateString();
    },
  },
];

// Define initial sort model
const initialSortModel: GridSortModel = [
  {
    field: "dueDate",
    sort: "asc",
  },
];

function isThisWeek(date: string) {
  const currentDate = new Date();
  const firstDayOfWeek = currentDate.getDate() - currentDate.getDay(); // First day is the day of the month - the day of the week
  const lastDayOfWeek = firstDayOfWeek + 6; // Last day is the first day + 6

  const startOfWeek = new Date(currentDate.setDate(firstDayOfWeek));
  startOfWeek.setHours(0, 0, 0, 0); // Start of the week at 00:00:00

  const endOfWeek = new Date(currentDate.setDate(lastDayOfWeek));
  endOfWeek.setHours(23, 59, 59, 999); // End of the week at 23:59:59

  const givenDate = new Date(date);
  givenDate.setHours(0, 0, 0, 0); // Normalize the time part of the date

  const startOfToday = new Date();
  startOfToday.setHours(0, 0, 0, 0); // Set to start of today

  if (new Date(date) < startOfToday) {
    return false;
  }

  if (new Date(date).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)) {
    return false;
  }

  return givenDate >= startOfWeek && givenDate <= endOfWeek;
}

export const TodoHeader = styled(Typography)({
  color: "var(--colors-text-text-tertiary-600, #344054)",
  fontFamily: "Inter",
  fontSize: "18x",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "20px", // 142.857%
  marginTop: "16px",
  marginBottom: "8px",
});

export function RowTodos(props: {
  showDetailView: any;
  createNoteFromTodo?: any;
  setCreateNoteFromTodo?: any;
}) {
  const { showDetailView, createNoteFromTodo, setCreateNoteFromTodo } = props;

  const context = useContext(RefetchContext);
  const workspaceMembers = context?.workspaceMembers;

  const [newTodo, setNewTodo] = useState("");
  const [todos, setTodos] = useState<any[]>([]);
  const [showAddTodo, setShowAddTodo] = useState(false);
  const [todoListOpen, setTodoListOpen] = useState(true);
  const [loadingTodos, setLoadingTodos] = useState(false);
  const [selectedTodo, setSelectedTodo] = useState<any>({});
  const [editingTodo, setEditingTodo] = useState<any>();
  const [tasksFilter, setTasksFilter] = useState<string>("all-tasks");

  const apiRef = useGridApiRef();

  const handleTasksFilter = (event: any) => {
    setTasksFilter(event?.target?.value);
  };

  const { updateTodo } = useUpsertTodo();

  const [showHidden, setShowHidden] = useState(false);

  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>, todo: any) => {
    console.log(todo);
    event.preventDefault();

    setSelectedTodo(todo);

    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );

    // setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setSelectedTodo({});
    // setAnchorEl(null);
    setContextMenu(null);
  };

  const { getAccessTokenSilently, user } = useAuth0();

  const query = { rowId: { _id: showDetailView?._id }, isDeleted: false };

  const {
    data: todosBuff,
    loading: todosLoading,
    error: todosLoadingError,
    refetch: refetchTodos,
  } = useQuery(GET_TODOS, {
    variables: { query: query },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (!!showDetailView._id && todosBuff != undefined) {
      const filteredTodos = todosBuff.todos.filter((todo: any) => {
        return (
          todo?.rowId?._id === showDetailView?._id ||
          todo?.rowId === showDetailView?._id
        );
      });

      setTodos(filteredTodos);
    }
    setLoadingTodos(todosLoading);
  }, [showDetailView, todosBuff, todosLoading]);

  const filteredTodos = todos?.filter((todo) => {
    if (tasksFilter === "all-tasks") {
      return true;
    } else if (tasksFilter === "my-tasks") {
      return todo?.assignedTo?.auth0Sub === user?.sub;
    } else {
      return todo?.assignedTo?.auth0Sub === tasksFilter;
    }
  });

  const activeTodos = filteredTodos
    .sort((a: any, b: any) => {
      return new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime();
    })
    .filter((todo) => {
      return todo.status === 1;
    });

  const completedTodos = filteredTodos
    .sort((a: any, b: any) => {
      return new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime();
    })
    .filter((todo) => {
      return todo.status === 2;
    });

  const overDueTodos = activeTodos?.filter((todo) => {
    const startOfToday = new Date();
    startOfToday.setHours(0, 0, 0, 0); // Set to start of today

    return new Date(todo.dueDate) < startOfToday && todo?.status === 1;
  });

  const todayTodos = activeTodos?.filter((todo) => {
    return (
      new Date(todo.dueDate).setHours(0, 0, 0, 0) ===
        new Date().setHours(0, 0, 0, 0) && todo?.status === 1
    );
  });

  const thisWeekTodos = activeTodos?.filter((todo: any) => {
    return isThisWeek(todo?.dueDate) && todo?.status === 1;
  });

  const upComingTodos = activeTodos?.filter((todo) => {
    const today = new Date();
    today.setHours(23, 59, 59, 999); // Set to end of today

    return (
      !isThisWeek(todo.dueDate) && // Exclude todos that are due this week
      new Date(todo.dueDate) > today && // Todo is in the future
      todo?.status === 1
    );
  });

  return (
    <div style={{ padding: "20px" }}>
      <TodoModal
        editingTodo={editingTodo}
        newTodo={showAddTodo}
        setNewTodo={setShowAddTodo}
        setEditingTodo={setEditingTodo}
        rowId={showDetailView?._id}
      />
      <Grid
        container
        justifyContent={"space-between"}
        style={{ marginBottom: "20px" }}
      >
        <Grid item>
          {!!workspaceMembers && workspaceMembers?.length > 1 ? (
            <StyledSelected
              value={tasksFilter}
              onChange={handleTasksFilter}
              sx={{ minWidth: "175px", marginTop: "20px" }}
            >
              <MenuItem value={"all-tasks"}>All Tasks</MenuItem>
              <Divider />
              <MenuItem value={"my-tasks"}>My Tasks</MenuItem>
              {workspaceMembers
                ?.filter((member) => member?.auth0Sub !== user?.sub)
                ?.map((member: any) => {
                  const parsedName = member?.name?.split(" ");

                  if (parsedName?.length > 0) {
                    return (
                      <MenuItem key={member?.auth0Sub} value={member?.auth0Sub}>
                        {`${parsedName[0]}'s Tasks`}
                      </MenuItem>
                    );
                  } else {
                    return (
                      <MenuItem key={member?.auth0Sub} value={member?.auth0Sub}>
                        {`${member?.name}'s Tasks`}
                      </MenuItem>
                    );
                  }
                })}
            </StyledSelected>
          ) : (
            <>
              <Typography style={{ marginTop: "20px" }}>
                <b>Tasks</b>
              </Typography>
            </>
          )}
          <Button
            sx={{ marginLeft: "20px", textTransform: "none" }}
            onClick={() => {
              setShowHidden(!showHidden);
            }}
          >
            {!showHidden ? "Show Completed" : "Hide Completed"}
          </Button>
        </Grid>
        <Grid item>
          <CustomButton
            style={{
              fontSize: "14px",
              marginTop: "20px",
              padding: "10px 14px",
            }}
            variant="outlined"
            endIcon={<AddIcon sx={{ color: "white" }} />}
            onClick={() => {
              setShowAddTodo(!showAddTodo);
            }}
          >
            Create Task
          </CustomButton>
        </Grid>
      </Grid>

      {loadingTodos && todos.length === 0 && (
        <img
          src={loading}
          alt="loading"
          style={{
            width: "25px",
            margin: "auto",
            display: "block",
          }}
        />
      )}

      {todos.length === 0 && !showAddTodo && !loadingTodos && (
        <div
          style={{
            textAlign: "center",
          }}
        >
          <ListIcon
            fontSize="large"
            sx={{
              width: "100px",
              height: "100px",
              opacity: ".6",
              margin: "auto",
              display: "block",
              marginTop: "70px",
            }}
          />
          <Typography>No todos found</Typography>
          <Typography style={{ marginBottom: "70px" }}>
            Create a task to be reminded about it later.{" "}
          </Typography>
        </div>
      )}
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        open={!!contextMenu}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem
          onClick={async () => {
            try {
              const resp: any = await updateTodo({
                variables: {
                  query: {
                    _id: selectedTodo._id,
                  },
                  set: {
                    isDeleted: true,
                    isDeletedAt: new Date(),
                    triggeredBy: "client",
                  },
                },
              });

              setTodos(todos.filter((t: any) => t._id !== selectedTodo._id));

              await refetchTodos();
            } catch (error) {
              console.log(error);
            }
            handleClose();
          }}
        >
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          Delete Todo
        </MenuItem>
      </Menu>
      {todos.length > 0 && (
        <DataGridPremium
          columns={columns}
          rows={showHidden ? filteredTodos : activeTodos}
          apiRef={apiRef}
          autoHeight
          localeText={customLocaleText}
          hideFooter={true}
          getRowId={(todo) => todo._id}
          getRowClassName={(params) => {
            return params.row.status === 2 ? "completed-todo" : "";
          }}
          onRowClick={(params, event, details) => {
            // setEditingTodo(params.row);

            const target = event.target as HTMLElement;
            if (target.closest(".status-checkbox")) {
              return; // Do nothing if clicked on checkbox
            }
            setEditingTodo(params.row);

            // if (params.field !== "status") {
            //   setEditingTodo(params.row);
            // }
          }}
          disableRowSelectionOnClick={true}
          // isRowSelectable={() => false}
          isCellEditable={() => false}
          initialState={{
            sorting: {
              sortModel: initialSortModel,
            },
          }}
          sx={{
            marginBottom: "20px",
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-row:hover": {
              backgroundColor: "#f5f5f5", // Light grey background on hover
            },
            "& .MuiDataGrid-row.Mui-selected": {
              backgroundColor: "transparent",
            },
            "& .MuiDataGrid-cell": {
              cursor: "default",
            },
            "& .MuiDataGrid-cell:focus-within": {
              outline: "none",
            },
            "& .MuiDataGrid-columnHeader:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-columnHeader:focus-within": {
              outline: "none",
            },
          }}
        />
      )}
    </div>
  );
}

export function TodoDisplay(props: {
  todo: any;
  handleClick: any;
  setEditingTodo: any;
}) {
  const { todo, handleClick, setEditingTodo } = props;

  const { updateTodo } = useUpsertTodo();

  const labelId = `checkbox-list-secondary-label-${todo._id}`;

  return (
    <React.Fragment key={todo._id}>
      <ListItem
        key={todo._id}
        disablePadding
        style={{ padding: "0px 0px" }}
        onContextMenu={(e) => {
          handleClick(e, todo);
        }}
      >
        <ListItemIcon style={{ minWidth: 10 }}>
          <Checkbox
            size="small"
            edge="end"
            checked={todo?.status === 2}
            onChange={async () => {
              const resp: any = await updateTodo({
                variables: {
                  query: {
                    _id: todo._id,
                  },
                  set: {
                    status: todo?.status === 1 ? 2 : 1,
                    triggeredBy: "client",
                  },
                },
              });
            }}
            inputProps={{ "aria-labelledby": labelId }}
            sx={{
              opacity: 0.25,
              "&:hover": {
                opacity: 1,
              },
            }}
          />
        </ListItemIcon>
        <ListItemButton onClick={() => setEditingTodo(todo)}>
          <ListItemText id={labelId} primary={todo.text} />
          {!!todo?.assignedTo?.name && (
            <CustomChip
              avatar={
                <PersonIcon
                  sx={{
                    fontSize: "10px",
                    color: "#525866",
                    marginRight: "5px !important",
                  }}
                />
              }
              label={todo?.assignedTo?.name}
              style={{ marginRight: "5px" }}
              size="small"
              variant="outlined"
            />
          )}
          <CustomChip
            label={`${new Date(todo.dueDate).toLocaleDateString()}`}
            avatar={
              <TodayIcon
                sx={{
                  fontSize: "10px",
                  color: "#525866",
                  marginRight: "5px !important",
                }}
              />
            }
            size="small"
          />
        </ListItemButton>
      </ListItem>
    </React.Fragment>
  );
}

export const modalBoxStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -60%)",
  width: "550px",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "8px",
  boxShadow: "0px 4.69px 150.093px 0px rgba(0, 0, 0, 0.16)",
  pt: 2,
  px: 4,
  pb: 3,
};

export function TodoModal(props: {
  newTodo?: boolean;
  setNewTodo?: any;
  editingTodo?: any | undefined;
  setEditingTodo?: any;
  newTodoText?: any;
  rowId?: string;
}) {
  const {
    editingTodo,
    setEditingTodo,
    newTodo,
    setNewTodo,
    rowId,
    newTodoText,
  } = props;

  const { user } = useAuth0();
  const { updateTodo, createTodo } = useUpsertTodo();

  const [assignedTo, setAssignedTo] = useState(user);
  const [dueDate, setDueDate] = React.useState<Dayjs | null>(null);
  const [todoText, setTodoText] = useState(editingTodo?.text);

  useEffect(() => {
    if (editingTodo) {
      setAssignedTo(editingTodo?.assignedTo);
      setDueDate(dayjs(editingTodo?.dueDate));
      setTodoText(editingTodo?.text);
    }
  }, [editingTodo]);

  useEffect(() => {
    if (newTodo) {
      setTodoText(newTodoText);
      setAssignedTo(user);

      const parsedTime = chrono.parse(newTodoText, new Date(), {
        forwardDate: true,
      });

      const newDueDate = !!parsedTime[0]
        ? parsedTime[0].start.date()
        : new Date();

      setDueDate(dayjs(newDueDate));
    }
  }, [newTodo, user, newTodoText]);

  const context = useContext(RefetchContext);
  const workspaceMembers = context?.workspaceMembers;

  const handleAssignedChange = (event: any) => {
    const findMember = workspaceMembers?.filter(
      (member: any) => member?.auth0Sub === event?.target?.value
    );

    if (!!findMember) setAssignedTo(findMember[0]);
  };

  const handleCloseModal = () => {
    if (!!setNewTodo) {
      setNewTodo(false);
    }
    if (!!setEditingTodo) {
      setEditingTodo(undefined);
    }

    setAssignedTo(undefined);
    setTodoText(undefined);
    setDueDate(null);
  };

  return (
    <Modal
      open={!!editingTodo || newTodo || false}
      onClose={handleCloseModal}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(255, 250, 250, 0.60)",
        },
      }}
    >
      <Box
        {...modalBoxStyle}
        sx={{ width: 550, transform: "translate(-50%, -60%)" }}
      >
        {!!newTodoText && (
          <>
            <Typography
              sx={{
                marginTop: "10px",
                marginBottom: "20px",
                fontSize: "14px",
                fontWeight: "500",
                color: "#344054",
              }}
            >
              Create a reminder?
            </Typography>
            <Divider sx={{ borderColor: "#EAECF0", marginBottom: "10px" }} />
          </>
        )}
        <StyledTextarea
          placeholder="Enter your todo here"
          autoFocus
          sx={{
            marginBottom: "24px",
            marginTop: "10px",
            width: "100%",
            fontSize: "20px",
            fontWeight: "500",
          }}
          value={todoText}
          onChange={(e) => {
            setTodoText(e.target.value);
            const parsedTime = chrono.parse(e.target.value, new Date(), {
              forwardDate: true,
            });

            const newDueDate = !!parsedTime[0]
              ? parsedTime[0].start.date()
              : new Date();

            setDueDate(dayjs(newDueDate));
          }}
        />
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            <OnboardingSubheader
              sx={{ textAlign: "left !important", marginBottom: "4px" }}
            >
              Due Date
            </OnboardingSubheader>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                sx={{
                  "& .MuiInputBase-input": {
                    padding: "10px 14px",
                  },
                }}
                value={dueDate}
                onChange={(newValue) => {
                  setDueDate(newValue);
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item>
            <OnboardingSubheader
              sx={{ textAlign: "left !important", marginBottom: "4px" }}
            >
              Assigned To
            </OnboardingSubheader>
            <StyledSelected
              value={assignedTo?.auth0Sub || assignedTo?.sub || ""}
              onChange={handleAssignedChange}
              sx={{ minWidth: "175px" }}
            >
              {workspaceMembers?.map((member: any) => {
                return (
                  <MenuItem key={member?.auth0Sub} value={member?.auth0Sub}>
                    {member?.name}
                  </MenuItem>
                );
              })}
            </StyledSelected>
          </Grid>
        </Grid>
        <Grid
          container
          gap={"22px"}
          justifyContent={"end"}
          sx={{ marginTop: "66px" }}
        >
          <Grid item>
            <CustomButton
              sx={{
                backgroundColor: "white",
                border: "1px solid #D0D5DD",
                color: "#344054",

                "&:hover": {
                  backgroundColor: "#f5f5f5",
                },
              }}
              onClick={handleCloseModal}
            >
              Cancel
            </CustomButton>
          </Grid>
          <Grid item>
            <CustomButton
              onClick={async () => {
                if (!newTodo) {
                  const resp: any = await updateTodo({
                    variables: {
                      query: {
                        _id: editingTodo._id,
                      },
                      set: {
                        assignedTo: {
                          link: assignedTo?.auth0Sub,
                        },
                        dueDate: dueDate,
                        text: todoText,
                        triggeredBy: "client",
                      },
                    },
                  });
                } else {
                  const timestamp = new Date();

                  const rowLink = !!rowId
                    ? {
                        rowId: {
                          link: rowId,
                        },
                      }
                    : {};

                  const parsedTime = chrono.parse(todoText, new Date(), {
                    forwardDate: true,
                  });

                  const parsedTodoText =
                    !!parsedTime[0] && !!parsedTime[0].text
                      ? todoText
                          .replace(` in ${parsedTime[0].text}`, " ")
                          .replace(` on ${parsedTime[0].text}`, " ")
                          .replace(`${parsedTime[0].text}`, " ")
                          .trim()
                      : todoText;

                  const resp: any = await createTodo({
                    variables: {
                      data: {
                        text: parsedTodoText,
                        dueDate: dueDate,
                        ...rowLink,
                        createdAt: timestamp,
                        updatedAt: timestamp,
                        workspaceId: context?.currentWorkspace?._id,
                        userId: { link: user?.sub },
                        updatedByUserId: { link: user?.sub },
                        assignedTo: {
                          link: assignedTo?.auth0Sub || assignedTo?.sub,
                        },
                        status: 1,
                        priority: 1,
                        isDeleted: false,
                        triggeredBy: "client",
                      },
                    },
                  });
                }
                handleCloseModal();
              }}
            >
              Save Task
            </CustomButton>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

export const StyledSelected = styled(Select)({
  padding: "0px",

  "& .MuiSelect-select": {
    padding: "10px 14px",
    cborderRadius: "var(--radius-md, 8px)",
    borderTop:
      "1px solid var(--component-colors-components-buttons-secondary-button-secondary-border, #D0D5DD)",
    borderRight:
      "1px solid var(--component-colors-components-buttons-secondary-button-secondary-border, #D0D5DD)",
    borderBottom:
      "1px solid var(--component-colors-components-buttons-secondary-button-secondary-border, #D0D5DD)",
    borderLeft:
      "1px solid var(--component-colors-components-buttons-secondary-button-secondary-border, #D0D5DD)",
    background:
      "var(--component-colors-components-buttons-secondary-button-secondary-bg, #FFF)",
    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
    color: "#344054",
    fontWeight: "500",
    fontSize: "14px",
    outline: "none",
  },
});
