import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LayersIcon from "@mui/icons-material/Layers";
import SaveIcon from "@mui/icons-material/Save";
import DownloadIcon from "@mui/icons-material/Download";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAuth0 } from "@auth0/auth0-react";
import { apiURL } from "./consts";
import { useContext } from "react";
import { RefetchContext } from "./refetchProvider";
import { Link, useNavigate } from "react-router-dom";
import PeopleIcon from "@mui/icons-material/People";
import { GridApi } from "@mui/x-data-grid-premium";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { GetListIcon } from "./icons";

import { useMutation } from "@apollo/client";
import useUpsertView from "./useUpsertView";
import { UPDATE_VIEW } from "./graphql-ops";
import useUpdateView from "./useUpdateView";

export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

interface DropDownProps {
  title: string;
  // setAddingView: (value: React.SetStateAction<boolean>) => void;
  apiRef: React.MutableRefObject<GridApi>;
  // blankState: GridInitialStatePro | undefined;
  // rows: any[];
  // setRows: (value: React.SetStateAction<any[]>) => void;
  // setPassedCols: (value: React.SetStateAction<any[] | undefined>) => void;
  // setSelectedView: (value: React.SetStateAction<any>) => void;
  selectedView: any;
  setShowingShareView: (value: React.SetStateAction<boolean>) => void;
  // setViews: (value: React.SetStateAction<any[]>) => void;
  // views: any[];
  setEditingView: (value: React.SetStateAction<boolean>) => void;
  columns: any[];
  initialState: any;
}

export default function CustomizedMenus(props: DropDownProps) {
  const {
    title,
    // setAddingView,
    apiRef,
    // blankState,
    // rows,
    // setRows,
    // setPassedCols,
    // setSelectedView,
    selectedView,
    setShowingShareView,
    // setViews,
    // views,
    setEditingView,
    columns,
    initialState,
  } = props;

  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

  const { user } = useAuth0();

  const context = useContext(RefetchContext);

  const handleRefetch = () => {
    if (context) {
      context.refetchViews();
    } else {
      console.error("Refetch context is not available");
    }
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { getAccessTokenSilently } = useAuth0();

  const { updateView } = useUpsertView();

  const navigate = useNavigate();

  const showExcelExport = user?.sub !== "auth0|666745c88845d69052186285";

  return (
    <div style={{ display: "inline-block" }}>
      <DeleteDialog
        openDeleteDialog={openDeleteDialog}
        setOpenDeleteDialog={setOpenDeleteDialog}
        listId={selectedView._id}
      />
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        startIcon={GetListIcon(
          selectedView?.icon?.fileString,
          selectedView?.icon?.color
        )}
        style={{
          backgroundColor: "#fff",
          color: "rgb(40, 42, 48)",
          fontSize: 15,
          fontWeight: 600,
          textTransform: "none",
        }}
      >
        {selectedView?.name || title}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        style={{ fontSize: ".75em" }}
      >
        <Link to={`/list/${selectedView._id}/edit`}>
          <MenuItem
            onClick={() => {
              handleClose();
              // Need to make this better
              setEditingView(true);
            }}
            disableRipple
          >
            <EditIcon />
            Edit List
          </MenuItem>
        </Link>
        <MenuItem
          onClick={async () => {
            // fetch call to delete a view
            setOpenDeleteDialog(true);
          }}
          disableRipple
        >
          <DeleteIcon />
          Delete List
        </MenuItem>
        {/* <MenuItem onClick={handleClose} disableRipple>
          <FileCopyIcon />
          Duplicate List
        </MenuItem> */}
        <Divider sx={{ my: 0.5 }} />
        <Link
          to={`/list/new/${selectedView.rootViewId || selectedView._id}`}
          state={{ rootView: selectedView }}
        >
          <MenuItem
            onClick={() => {
              // setAddingView(true);
              handleClose();
            }}
            disableRipple
          >
            <SaveIcon />
            Create filtered list
          </MenuItem>
        </Link>
        {/* <Link to="/list/new">
          <MenuItem
            onClick={() => {
              // Just need to link to new screen
              // console.log(blankState);
              // setRows([{ id: Math.random() }]);
              // setSelectedView(undefined);
              // setPassedCols([]);
              // blankState && apiRef.current.restoreState(blankState);
              handleClose();
            }}
            disableRipple
          >
            <AddIcon />
            New List
          </MenuItem>
        </Link> */}
        <Divider sx={{ my: 0.5 }} />
        <MenuItem
          onClick={() => {
            // alert(
            //   "Something went wrong. Contact info@mercero.com if you see this error."
            // );
            setShowingShareView(true);
            handleClose();
          }}
          disableRipple
        >
          <PeopleIcon />
          Share List
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <Link
          to={`/massImport/${selectedView._id}`}
          state={{
            view: JSON.stringify(selectedView),
            initialState: JSON.stringify(initialState),
            columns: JSON.stringify(columns),
          }}
        >
          <MenuItem disableRipple>
            <CloudUploadIcon />
            Import Data
          </MenuItem>
        </Link>
        {showExcelExport && (
          <MenuItem
            onClick={() => {
              apiRef.current.exportDataAsExcel();
              // alert("Contact us (info@mercero.com) if you see this error.");
              handleClose();
            }}
            disableRipple
          >
            <DownloadIcon />
            Export to Excel
          </MenuItem>
        )}
        {/* <MenuItem onClick={

        } disableRipple>
          <MoreHorizIcon />
          More
        </MenuItem> */}
      </StyledMenu>
    </div>
  );
}

export function DeleteDialog(props: {
  openDeleteDialog: boolean;
  setOpenDeleteDialog: (value: React.SetStateAction<boolean>) => void;
  listId: any;
}) {
  const { openDeleteDialog, setOpenDeleteDialog, listId } = props;
  const navigate = useNavigate();
  const { updateView } = useUpdateView();

  return (
    <Dialog
      open={openDeleteDialog}
      onClose={() => setOpenDeleteDialog(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{`Delete this list?`}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          This will remove the list permanently. It will also delete any
          filtered lists that were created from this list, and any shared lists
          that were created from this list.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpenDeleteDialog(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={async () => {
            try {
              const resp: any = await updateView({
                variables: {
                  query: {
                    _id: listId,
                  },
                  view: {
                    isDeleted: true,
                    isDeletedAt: new Date(),
                    triggeredBy: "client",
                  },
                },
              });
              navigate(-1);
            } catch (e) {
              alert(e);
            }

            setOpenDeleteDialog(false);
          }}
          autoFocus
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
