import { useEffect, useState, useContext } from "react";
import "./App.css";
import {
  Button,
  Card,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_ONE_VIEW,
  GET_ROW,
  GET_ROWS,
  UPDATE_ONE_ROW_MUTATION,
} from "./graphql-ops";
import PropertySearch from "./propertySearch";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import EditIcon from "@mui/icons-material/Edit";
import {
  GetHeaderText,
  ReturnVisibleColumns,
  passedColsParsed,
} from "./DatagridFunctions";
import { filterOutColumns } from "./layout";
import { getRelatedRows, TagDisplay, getTimeStampCell } from "./dataDisplay";
import { isArray } from "lodash";
import { SocketIOContext } from "./socketIOProvider";
import _ from "lodash";
import {
  DataGridPremium,
  GridActionsCellItem,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { GlobalContext } from "./globalContext";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import useUpdateRow from "./useUpdateRow";
import { RefetchContext } from "./refetchProvider";

export function splitArrayByViewId(array: any[]) {
  if (!Array.isArray(array)) {
    return [];
  }

  const groupedArrays = array?.reduce((groups, item) => {
    const viewId = item?.viewId?._id || item?.merc_viewId;
    if (!groups[viewId]) {
      groups[viewId] = [];
    }
    groups[viewId].push(item);
    return groups;
  }, {});

  return !!groupedArrays ? Object.values(groupedArrays) : [];
}

export function RelatedProperties(props: { row: any; rowId: any }) {
  const { row, rowId } = props;

  // const relatedRows = row?.relatedRows?.rows || [];
  let tags = new Map();

  try {
    tags = !!row?.relatedRows?.tags
      ? new Map(JSON.parse(row?.relatedRows?.tags))
      : new Map();
  } catch (e) {
    console.log(e);
  }

  const [addingRelated, setAddingRelated] = useState(false);
  const viewIdToPass = row?.viewId?._id || row?.viewId || "";
  const refetchContext = useContext(RefetchContext);

  const { updateRow } = useUpdateRow(viewIdToPass);

  const [selectedOptions, setSelectedOptions] = useState<any[]>(
    row?.relatedRows?.rows?.map((r: any) => {
      return { _id: r._id, ...JSON.parse(r.rowObject) };
    }) || []
  );

  const [newOptions, setNewOptions] = useState<any[]>([]);

  useEffect(() => {
    let relatedRows = row?.relatedRows || row?.relatedRows?.rows || [];
    relatedRows = relatedRows.map((r: any) => {
      const columns = refetchContext?.allViews?.find((v) => {
        return v?._id === r?.viewId?._id || v?._id === r?.merc_viewId;
      });

      return {
        ...r,
        columns: columns?.columns,
      };
    });

    const splitArrays = splitArrayByViewId(relatedRows);

    console.log(splitArrays);

    setNewOptions(splitArrays);

    setSelectedOptions(
      relatedRows?.map((r: any) => {
        if (r.rowObject) {
          try {
            return { _id: r._id, ...JSON.parse(r.rowObject) };
          } catch (e) {
            console.log(e);
          }
        }
        return r;
      }) || []
    );
  }, [row?.relatedRows?.rows, row?.relatedRows]);

  const rootRow = JSON.parse(row?.rowObject || "{}");
  const rootRowTitle = rootRow[Object.keys(rootRow)[0]];

  const { socket } = useContext(SocketIOContext) as any;

  useEffect(() => {
    if (
      !_.isNil(socket) &&
      !_.isNil(viewIdToPass) &&
      !_.isEmpty(viewIdToPass)
    ) {
      socket.emit("join-room", viewIdToPass);
    }
  }, [viewIdToPass, socket]);

  return (
    <div style={{ padding: "20px" }}>
      <Grid container justifyContent={"space-between"} sx={{ padding: "20px" }}>
        <Grid item xs={9}>
          <Typography
            style={{
              color: "rgb(40, 42, 48)",
              fontSize: "16px",
              fontWeight: 600,
            }}
          >
            Related
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Button
            style={{
              fontSize: "14px",
              color: "rgba(0, 0, 0, 0.7)",
              textTransform: "none",
              float: "right",
              border: "1px solid rgba(0, 0, 0, 0.7)",
            }}
            variant="outlined"
            startIcon={
              selectedOptions.length === 0 ? <AddIcon /> : <EditIcon />
            }
            onClick={() => {
              setAddingRelated(!addingRelated);
            }}
          >
            {selectedOptions.length === 0 ? "Add related" : "Edit related"}
          </Button>
        </Grid>
        <Grid item xs={12}>
          {addingRelated && (
            <Card
              style={{
                marginTop: "15px",
                border: "1px solid rgb(239, 241, 244)",
                boxShadow: "rgb(0 0 0 / 9%) 0px 3px 12px",
                borderRadius: "4px",
                padding: "10px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  <PropertySearch
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button
                    variant="contained"
                    style={{ height: "100%", width: "100%" }}
                    startIcon={<AddIcon />}
                    onClick={async () => {
                      await updateRow({
                        variables: {
                          set: {
                            relatedRows: {
                              tags: JSON.stringify(Array.from(tags)),
                              rows: {
                                link: selectedOptions.map(
                                  (option) => option._id
                                ),
                              },
                            },
                          },
                          query: {
                            _id: rowId,
                          },
                        },
                      });

                      const relatedRows =
                        row?.relatedRows?.rows || row?.relatedRows || [];

                      const filterIdValues = relatedRows.map(
                        (item: any) => item._id
                      );

                      const newTwoWayRelationships = _.filter(
                        selectedOptions,
                        (item) => !_.includes(filterIdValues, item._id)
                      );

                      if (filterIdValues?.length > selectedOptions?.length) {
                        // Removing relationships
                        const selectedIds = selectedOptions.map(
                          (option) => option._id
                        );

                        const missingInSelectedOptions = _.filter(
                          filterIdValues,
                          (id) => !_.includes(selectedIds, id)
                        );
                        // TODO: Remove relationships
                        console.log(missingInSelectedOptions);
                      }

                      // Update linked Rows (only for adding relationships)
                      const updatePromises = newTwoWayRelationships.map((r) => {
                        return updateRow({
                          variables: {
                            set: {
                              relatedRows: {
                                rows: {
                                  // Need to add the current rows to the link
                                  link: r?.relatedRows?.concat([rowId]) || [
                                    rowId,
                                  ],
                                },
                              },
                            },
                            query: {
                              _id: r._id,
                            },
                          },
                        });
                      });

                      Promise.all(updatePromises)
                        .then(() => {
                          console.log("All relationships updated successfully");
                        })
                        .catch((error) => {
                          console.error("Error updating relationships:", error);
                        });

                      setAddingRelated(false);
                    }}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Card>
          )}
        </Grid>
      </Grid>
      {selectedOptions.length === 0 && !addingRelated && (
        <>
          <ChangeCircleIcon
            fontSize="large"
            sx={{
              width: "100px",
              height: "100px",
              opacity: ".6",
              margin: "auto",
              display: "block",
              marginTop: "70px",
            }}
          />
          <Typography
            sx={{
              textAlign: "center",
              marginBottom: "70px",
              marginTop: "10px",
            }}
          >
            No related records found
          </Typography>
        </>
      )}
      {newOptions.length > 0 &&
        newOptions.map((view: any) => {
          return (
            <RenderViewTable
              key={view?.[0]?.viewId?._id || view?.[0]?.merc_viewId}
              view={view}
              row={row}
              rootRowTitle={rootRowTitle}
            />
          );
        })}
    </div>
  );
}

function RenderViewTable(props: any) {
  const { view, row, rootRowTitle } = props;

  const globalContext = useContext(GlobalContext);
  const refetchContext = useContext(RefetchContext);

  const fullView = refetchContext?.allViews?.find((v) => {
    return v?._id === view[0]?.merc_viewId || v?._id === view[0]?.viewId?._id;
  });

  let initialState = {};
  try {
    initialState = JSON.parse(fullView?.initialState || "{}");
  } catch (error) {
    console.error("Error parsing initialState:", error);
  }
  // const {
  //   loading,
  //   error,
  //   data,
  //   refetch: refetchView,
  // } = useQuery(GET_ONE_VIEW, {
  //   variables: {
  //     query: {
  //       _id: fullView?._id,
  //       isDeleted: false,
  //     },
  //   },
  // });

  const viewName = fullView?.name;
  const columns = ReturnVisibleColumns(
    filterOutColumns(fullView?.columns),
    initialState
  );

  const rows = view
    ?.map((r: any) => {
      // @ts-ignore
      let rowObj = globalContext?.rows?.[r?.viewId?._id || r?.merc_viewId]?.get(
        r?._id
      );

      try {
        if (!rowObj) {
          try {
            rowObj = JSON.parse(r?.rowObject);
          } catch (e) {
            console.log(e);
          }
        }
      } catch (e) {
        console.log(e);
      }

      if (!rowObj) {
        return undefined;
      }

      return {
        ...rowObj,
        merc_viewId: r?.viewId?._id || r?.merc_viewId,
      };
    })
    .filter((r: any) => !!r);

  const apiRef = useGridApiRef();

  const parsedColumns = passedColsParsed(columns, apiRef)?.map((col: any) => {
    return {
      ...col,
      editable: false,
    };
  });
  if (!!columns) {
    return (
      <>
        <Typography
          sx={{
            marginTop: "30px",
            marginBottom: "15px",
            fontWeight: "600",
          }}
        >
          {viewName}
        </Typography>
        <DataGridPremium
          columns={[
            {
              field: "actions",
              type: "actions",
              width: 50,
              cellClassName: "actions-cell",
              hideable: false,
              getActions: (params: any) => {
                return [
                  <Link
                    // to={`row/${params.row._id}`}
                    to={`/list/${params.row?.merc_viewId}/row/${params.row._id}`}
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                    state={{
                      allRows: rows,
                      relatedRoot: row,
                      passedAllRows: JSON.stringify(rows),
                      relatedTitle: rootRowTitle,
                    }}
                    key={`link-${params.row._id}`}
                  >
                    <GridActionsCellItem
                      icon={
                        <OpenInFullIcon
                          id="Expand row"
                          sx={{ width: "15px" }}
                        />
                      }
                      label="Expand"
                    />
                  </Link>,
                ];
              },
            },
            ...parsedColumns,
          ]}
          rows={rows}
          apiRef={apiRef}
          autoHeight
          hideFooter={true}
          sx={{ marginBottom: "20px" }}
          getRowId={(row) => row._id}
        />
      </>
    );
  }

  return <></>;
}
