const prodUrl = "https://crm.mercero-api.com/api/1/";
const devUrl = "https://dev.crm.mercero-api.com/api/1/";
const localUrl = "http://localhost:3002/api/1/";

const socketIOUrlProd = "https://crm.mercero-api.com/collaboration";
const socketIOUrlDev = "https://dev.crm.mercero-api.com/collaboration";
const socketIOUrlLocal = "http://localhost:3002/collaboration";

export const apiURL = prodUrl;
export const socketIOUrl = socketIOUrlProd;

const prodSkipTraceUrl = "https://dev.crm.mercero-api.com"
const devSkipTraceUrl = "https://dev.crm.mercero-api.com"
const localSkipTraceServerUrl = "http://localhost:3003";

const socketIOSkipTraceServerUrlProd = "https://dev.crm.mercero-api.com/skiptrace"
const socketIOSkipTraceServerUrlDev = "https://dev.crm.mercero-api.com/skiptrace"
const socketIOSkipTraceServerUrlLocal = "http://localhost:3003/skiptrace";

export const skipTraceServerUrl = prodSkipTraceUrl
export const skipTraceSocketIOUrl = socketIOSkipTraceServerUrlProd;
