import React, { useState, useRef, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Popover from "@mui/material/Popover";
import Chip from "@mui/material/Chip";
import AddIcon from "@mui/icons-material/Add";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import _ from "lodash";
import { CustomChip } from "./individualView";
import { Edit } from "@mui/icons-material";

// export interface Tag {
//   title: string;
// }

interface TagAutocompletePopoverProps {
  allPossibleTags: string[];
  onTagsChange: (tags: string[]) => void;
  selectedTags: string[];
  buttonStyle?: React.CSSProperties;
  constrainTags?: number;
}

const DisplayTags: React.FC<TagAutocompletePopoverProps> = ({
  allPossibleTags,
  onTagsChange,
  selectedTags,
  buttonStyle,
  constrainTags,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [autocompleteOpen, setAutocompleteOpen] = useState<boolean>(false);
  const [tags, setTags] = useState<string[]>(_.compact(selectedTags));
  const inputRef = useRef<HTMLInputElement>(null);

  const handleTagChange = (event: any, value: string[]) => {
    setTags(value);
  };

  const onTagsClose = (value: string[]) => {
    onTagsChange(value);
  };

  useEffect(() => {
    if (anchorEl) {
      setTimeout(() => {
        setAutocompleteOpen(true);
      }, 200);
      //   setAutocompleteOpen(true);
      inputRef.current?.focus();
    } else {
      setAutocompleteOpen(false);
    }
  }, [anchorEl]);

  useEffect(() => {
    setTags(_.compact(selectedTags));
  }, [selectedTags]);

  let charCount = 0;
  let splitIndex = !!constrainTags ? 0 : tags.length;

  tags.forEach((item: any, index) => {
    charCount += item.length;
    if (!!constrainTags && charCount < constrainTags) {
      splitIndex += 1;
    }
  });

  const firstHalf = tags.slice(0, splitIndex);
  const secondHalf = tags.slice(splitIndex);

  return (
    <>
      <div
        key={"tags-click"}
        onClick={(event) => {
          // @ts-ignore
          setAnchorEl(event.currentTarget);
        }}
      >
        {firstHalf.map((item: any, index) => (
          <CustomChip
            key={index}
            label={item}
            sx={
              !!!constrainTags
                ? { marginRight: "8px", marginBottom: "8px" }
                : { marginRight: "8px" }
            }
          />
        ))}
        <IconButton aria-label="delete" style={buttonStyle}>
          {secondHalf?.length > 0 ? (
            <>
              {secondHalf && secondHalf.length > 0 && (
                <CustomChip
                  sx={{ color: "white", backgroundColor: "#667085" }}
                  label={`+ ${secondHalf.length}`}
                />
              )}
            </>
          ) : (
            <>
              <CustomChip
                sx={{ color: "white", backgroundColor: "#667085" }}
                icon={
                  <EditIcon
                    sx={
                      !!!constrainTags
                        ? {
                            color: "white !important",
                            fontSize: "15px",
                            margin: "0px !important",
                            padding: "0px !important",
                            marginBottom: "8px",
                          }
                        : {
                            color: "white !important",
                            fontSize: "15px",
                            margin: "0px !important",
                            padding: "0px !important",
                          }
                    }
                  />
                }
              />
            </>
          )}
        </IconButton>
      </div>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          console.log("closing");
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            boxShadow: "0px 8px 16px -2px rgba(0, 0, 0, 0.2)", // add a shadow
            borderRadius: 6,
            //padding: "10px 15px", // some padding
          },
        }}
      >
        <Autocomplete
          freeSolo
          multiple
          id="tags-checkboxes"
          open={autocompleteOpen}
          options={allPossibleTags?.filter((tag: string) => !!tag) || []}
          //   openOnFocus={true}
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          onChange={handleTagChange}
          onClose={() => onTagsClose(tags)}
          value={tags}
          sx={{
            padding: "0px",
          }}
          componentsProps={{
            paper: {
              style: {
                width: "200px",
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "0px",
              },
            },
            popper: {
              style: {
                width: "200px",
              },
            },
          }}
          ListboxProps={{
            style: {
              //paddingTop: "10px",
              width: "200px",
            },
          }}
          renderOption={(props, option, { selected }) => (
            <li
              {...props}
              style={{
                fontSize: "13px",
                fontFamily: "Inter, sans-serif",
                width: "200px",
                padding: "4px 8px",
                // width: "250px",
              }}
            >
              <Checkbox
                checked={selected}
                style={{
                  padding: "5px 10px",
                  color: "#1570EF",
                  fontSize: "13px",
                }} // reduce checkbox size
                size={"small"}
              />
              {option}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              //   inputRef={inputRef}
              variant="standard" // removes border
              placeholder="Tags"
              sx={{
                width: 200,
                padding: "4px 8px",
                fontFamily: "Inter, Sans-serif",

                "& .MuiInputBase-root": {
                  fontSize: "13px",
                },
              }} // fixed width
              autoFocus
            />
          )}
          renderTags={() => null}
        />
      </Popover>
    </>
  );
};

export default DisplayTags;
