import React, { useState, useEffect, useCallback, KeyboardEvent } from "react";
import {
  Box,
  IconButton,
  TextField,
  List,
  ListItem,
  ListItemText,
  Link,
  Popper,
  Paper,
  ClickAwayListener,
  Button,
  Tooltip,
  styled,
} from "@mui/material";
import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";

interface EmailData {
  emailAddress: string;
  status: "neutral" | "good" | "bad";
}

interface EmailFieldProps {
  emails: string;
  onChange: (updatedEmails: string) => void;
}

const StyledListItem = styled(ListItem)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0.5, 0),
  "&:hover .delete-icon": {
    opacity: 1,
  },
}));

const DeleteIconWrapper = styled(Box)(({ theme }) => ({
  opacity: 0,
  transition: "opacity 0.2s",
  marginLeft: theme.spacing(1),
}));

const StyledAddButton = styled(IconButton)(({ theme }) => ({
  marginTop: theme.spacing(1),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  boxShadow: theme.shadows[3],
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.primary.light,
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary.main,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const StyledAddEmailButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const parseEmails = (emailString: string): EmailData[] => {
  try {
    const parsed = JSON.parse(emailString);
    if (Array.isArray(parsed)) {
      return parsed;
    }
  } catch (error) {
    // If parsing fails, it's likely a single email address
  }
  return [{ emailAddress: emailString, status: "neutral" }];
};

const EmailField: React.FC<EmailFieldProps> = ({ emails, onChange }) => {
  const [emailList, setEmailList] = useState<EmailData[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [newEmail, setNewEmail] = useState("");

  useEffect(() => {
    setEmailList(parseEmails(emails));
  }, [emails]);

  const handleEmailClick = (email: string) => {
    window.location.href = `mailto:${email}`;
  };

  const handleAddClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setNewEmail("");
  };

  const updateEmails = useCallback(
    (newEmailList: EmailData[]) => {
      setEmailList(newEmailList);
      onChange(JSON.stringify(newEmailList));
    },
    [onChange]
  );

  const handleAddEmail = () => {
    if (newEmail && /\S+@\S+\.\S+/.test(newEmail)) {
      updateEmails([
        ...emailList,
        { emailAddress: newEmail, status: "neutral" },
      ]);
      setNewEmail("");
    }
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      handleAddEmail();
    }
  };

  const handleDeleteEmail = (index: number) => {
    const newList = emailList.filter((_, i) => i !== index);
    updateEmails(newList);
  };

  const displayedEmails = emailList.filter((email) => email.status !== "bad");

  return (
    <Box>
      <List dense disablePadding>
        {displayedEmails.map((email, index) => (
          <StyledListItem key={index} disablePadding>
            <ListItemText
              primary={
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => handleEmailClick(email.emailAddress)}
                  underline="hover"
                >
                  {email.emailAddress}
                </Link>
              }
            />
            <DeleteIconWrapper className="delete-icon">
              <Tooltip title="Delete email">
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleDeleteEmail(index)}
                  size="small"
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </DeleteIconWrapper>
          </StyledListItem>
        ))}
      </List>
      <Tooltip title="Add email">
        <StyledAddButton onClick={handleAddClick} size="small">
          <AddIcon fontSize="small" />
        </StyledAddButton>
      </Tooltip>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={handleClose}>
          <StyledPaper>
            <StyledTextField
              size="small"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Enter new email"
              variant="outlined"
            />
            <StyledAddEmailButton
              onClick={handleAddEmail}
              variant="contained"
              size="small"
            >
              Add
            </StyledAddEmailButton>
          </StyledPaper>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
};

export default EmailField;
