import { isArray } from "lodash";
import { getRelatedRows } from "./dataDisplay";
import { useQuery } from "@apollo/client";
import { GET_ONE_VIEW } from "./graphql-ops";
import { useParams } from "react-router-dom";
import { useContext } from "react";
import { RefetchContext } from "./refetchProvider";

export const DisplayRelatedCell = (props: any) => {
  const { colDef, row, api } = props;

  const { rootViewId, listId } = useParams();

  const refetchContext = useContext(RefetchContext);

  const viewId = colDef.type.split("-");

  // const {
  //   loading,
  //   error,
  //   data,
  //   refetch: refetchView,
  // } = useQuery(GET_ONE_VIEW, {
  //   variables: { query: { _id: viewId[1], isDeleted: false } },
  // });

  const columns = refetchContext?.allViews?.find(
    (view) => view._id === viewId[1]
  )?.columns;

  if (isArray(viewId) && !!viewId[1]) {
    const filteredRelated = Array.isArray(row?.relatedRows)
      ? row?.relatedRows?.filter((r: any) => r.merc_viewId === viewId[1])
      : [];

    return getRelatedRows(filteredRelated, columns, listId, rootViewId, api);

    // if (loading) {
    //   return getRelatedRows(filteredRelated);
    // } else {

    // }
  }
  return getRelatedRows(row?.relatedRows);
};
