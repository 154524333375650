import { useCallback, useState } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { fetchCustomLayer, setupParcels } from "./displayMap";
import { Paper, Typography, Chip } from "@mui/material";
import { Link } from "react-router-dom";
import { EmailSelect, PhoneSelect } from "./v2contactsSection";

export function ParcelMap(props: any) {
  const { lat, lng, height, width } = props;

  const [theMap, setMap] = useState<any>(null);

  const onLoad = useCallback(
    function callback(map: any) {
      setMap(map);

      fetchCustomLayer()?.then((layerData) => {
        if (layerData && map) {
          setupParcels(map, layerData);
        }
      });
    },
    [theMap]
  );

  const onUnmount = useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  const parsedLat = parseFloat(lat);
  const parsedLng = parseFloat(lng);

  return (
    <GoogleMap
      center={{
        lat: parsedLat,
        lng: parsedLng,
      }}
      mapContainerStyle={{
        width: width,
        height: height,
      }}
      mapTypeId="satellite"
      zoom={18}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{
        mapTypeId: "satellite",
      }}
      key={"google-map"}
      ref={theMap}
    >
      <Marker key={"1map"} position={{ lat: parsedLat, lng: parsedLng }} />
    </GoogleMap>
  );
}

export function SidebarContacts(props: any) {
  const { openContacts } = props;

  const [propertyIndex, setPropertyIndex] = useState(openContacts?.index);

  return openContacts?.contact?.map((contact: any, index: number) => {
    return (
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          padding: "12px",
          marginBottom: "12px",
        }}
      >
        <ContactCard contact={contact?.contact || contact} index={index} />
      </Paper>
    );
  });
}

export function ContactCard(props: any) {
  const { contact, index } = props;

  return (
    <div
      style={{
        display: "flex",
        gap: "4px",
        marginTop: "8px",
        flexDirection: "column",
        borderRadius: "4px",
        // border: "1px solid #ccc",
        padding: "20px",
        backgroundColor: "white",
      }}
    >
      <Link to={`/properties/database/contact/${contact?._id}`}>
        <Typography variant="body1">{contact?.name}</Typography>
      </Link>
      <Typography variant="body2" sx={{ color: "#475467" }}>
        {contact?.title}
      </Typography>
      <PhoneSelect phoneNumbers={contact?.phoneNumbers} />
      <EmailSelect emails={contact?.emails} />
      {index === 1 && (
        <div style={{ maxWidth: "240px" }}>
          <Typography
            variant="body2"
            sx={{
              color: "#475467",
              marginBottom: "8px",
              marginTop: "8px",
            }}
          >
            Recent Note:
          </Typography>
          <Typography variant="body2" sx={{ marginBottom: "4px" }}>
            <span
              style={{
                color: "#101828",
                fontSize: "14px",
                fontWeight: "600",
              }}
            >
              Jake Deichmann
            </span>{" "}
            <span style={{ color: "#475467", fontSize: "12px" }}>3/23/24</span>
          </Typography>
          <Typography variant="body2">
            Spoke with Phil, he is interested in selling the property.
          </Typography>
        </div>
      )}
    </div>
  );
}

export function formatLink(url: string): string {
  // Check if the URL already starts with http:// or https://
  const hasProtocol = /^https?:\/\//i.test(url);

  // If it doesn't, prepend http://
  if (!hasProtocol) {
    url = "http://" + url;
  }

  // Create the clickable link
  return url;
}
