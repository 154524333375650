import { useEffect, useContext } from "react";
import { useMutation } from "@apollo/client";
import {
  GET_ALL_VIEWS,
  GET_ROWS,
  UPDATE_ONE_ROW_MUTATION,
  UPDATE_VIEW,
} from "./graphql-ops";
import { SocketIOContext } from "./socketIOProvider";
import _ from "lodash";
import { RefetchContext } from "./refetchProvider";

const useUpdateView = () => {
  const context = useContext(RefetchContext);

  const [updateView, updateViewProps] = useMutation(UPDATE_VIEW, {
    async update(cache, { data }) {
      const updatedView = data?.updateOneView;

      const existingViews = cache.readQuery({
        query: GET_ALL_VIEWS,
        variables: {
          query: {
            workspaceId: context?.currentWorkspace?._id,
            isDeleted: false,
          },
        },
      });

      if (
        existingViews &&
        // @ts-ignore
        existingViews.views &&
        updatedView?.isDeleted
      ) {
        // @ts-ignore
        const updatedViews = existingViews.views.filter((view: any) => {
          return !(view?._id === updatedView?._id && updatedView?.isDeleted);
        });

        await cache.writeQuery({
          query: GET_ALL_VIEWS,
          variables: {
            query: {
              workspaceId: context?.currentWorkspace?._id,
              isDeleted: false,
            },
          },
          data: {
            views: updatedViews,
          },
        });
      }
    },
  });

  return { updateView, ...updateViewProps };
};

export default useUpdateView;
