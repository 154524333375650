import { createContext, useState } from "react";
import "./App.css";

interface GlobalContextType {
  rows: Map<string, any>;
  setAllRows: React.Dispatch<React.SetStateAction<Map<string, any>>>;
}

export const GlobalContext = createContext<GlobalContextType | null>(null);

interface GlobalProviderProps {
  children: React.ReactNode;
}

const GlobalProvider: React.FC<GlobalProviderProps> = ({ children }) => {
  const [allRows, setAllRows] = useState<any>({});

  return (
    <GlobalContext.Provider
      value={{
        rows: allRows,
        setAllRows,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalProvider;
