import { useEffect, useState } from "react";

export default function ImageViewer(props: {
  fileType: string;
  fileName: string;
  fileData: string;
}) {
  const { fileType, fileName, fileData } = props;

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        overflow: "scroll",
      }}
    >
      <img
        src={fileData}
        style={{
          height: "100%",
          objectFit: "cover",
        }}
        alt={fileName}
      />
    </div>
  );
}
