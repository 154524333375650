import { Typography } from "@mui/material";

export default function UnsupportedViewer(props: {
  fileType: string;
  fileName: string;
  fileData: string;
}) {
  const { fileName, fileData } = props;

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <Typography variant="h6">
        {fileName} cannot be previewed. Please download it to view.
      </Typography>
      <a
        href={fileData}
        download={fileName}
        style={{
          marginTop: "30px",
          backgroundColor: "rgb(244, 245, 248)",
          display: "block",
          width: "200px",
          marginRight: "auto",
          marginLeft: "auto",
          padding: "10px",
          borderRadius: "5px",
        }}
      >
        Download
      </a>
    </div>
  );
}
