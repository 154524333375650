import { useState } from "react";
import "./App.css";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {
  Box,
  Button,
  Grid,
  ListItemIcon,
  Menu,
  Select,
  styled,
} from "@mui/material";

import {
  GetHeaderText,
  GetSubheaderText,
  ReturnVisibleColumns,
  formatPhoneNumber,
  stripPhoneNumber,
} from "./DatagridFunctions";
import React from "react";
import { MenuItem } from "@mui/material";
import { Link, NavLink, useParams } from "react-router-dom";
import { StyledMenu } from "./topButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { EditField, ShadowStyle } from "./individualView";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { colorShades, getImageString } from "./highlightRow";
import PhoneIcon from "@mui/icons-material/Phone";
import { DisplayRelatedCell } from "./displayRelatedCell";
import { useQuery } from "@apollo/client";
import { GET_ONE_VIEW } from "./graphql-ops";
import { isArray } from "lodash";
import {
  HeaderWithKeyFields,
  EmailDisplaySection,
  PhonesSection,
  RelatedCards,
} from "./rowHeaderSections";

const EmailButton = styled(Button)({
  display: "flex",
  padding: "10px 14px",
  justifyContent: "center",
  alignItems: "center",
  gap: "4px",
  borderRadius: "8px",
  border: "1px solid var(--blue-700, #175CD3)",
  background: "var(--blue-600, #1570EF)",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  color: "white",
  textTransform: "none",
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "20px",

  // Add hover state
  "&:hover": {
    background: "#1e88e5", // Darker shade for hover (example)
    border: "1px solid #1e88e5",
    boxShadow: "0px 2px 4px 0px rgba(16, 24, 40, 0.1)", // Slightly larger shadow
  },
});

const GridDiv = styled("div")(({ theme }) => ({
  borderRadius: "12px",
  border: "1px solid #EFF8FF",
  background: "var(--blue-25, #F5FAFF)",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  padding: "24px !important",
  height: "100%",
}));

const StyledDiv = styled("div")(({ theme }) => ({
  display: "flex",
  width: "48px",
  height: "48px",
  padding: "12px",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "10px",
  border: `1px solid ${theme.palette.grey[200]}`, // Using theme for colors
  backgroundColor: theme.palette.background.paper, // Using theme for colors
  boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
  marginBottom: "20px",
}));

export function RowHeader1(props: {
  showDetailView: any;
  columns: any;
  isMobile?: boolean;
  viewId: string;
  curRowId?: string;
  deleteRow: any;
  updateRow: any;
  navigate: any;
  mapDisplay?: boolean;
  initialState?: any;
  setRow?: any;
}) {
  const {
    columns,
    showDetailView,
    isMobile,
    viewId,
    navigate,
    updateRow,
    mapDisplay,
    initialState,
    setRow,
  } = props;

  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={12} md={4}>
        <Box sx={{ ...ShadowStyle, padding: "20px" }}>
          <HeaderWithKeyFields {...props} />
          <PhonesSection {...props} />
          <EmailDisplaySection {...props} />
        </Box>
      </Grid>
      <Grid item xs={12} md={8}>
        <RelatedCards {...props} />
      </Grid>
    </Grid>
  );
}

export function RowHeader(props: {
  showDetailView: any;
  columns: any;
  isMobile?: boolean;
  viewId: string;
  curRowId?: string;
  deleteRow: any;
  updateRow: any;
  navigate: any;
  mapDisplay?: boolean;
  initialState?: any;
  setRow?: any;
}) {
  const {
    columns,
    showDetailView,
    isMobile,
    viewId,
    navigate,
    updateRow,
    mapDisplay,
    initialState,
    setRow,
  } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [changeMarkerContext, setChangeMarkerContext] =
    useState<null | HTMLElement>(null);

  if (!showDetailView) return <></>;

  const lastActivityField =
    showDetailView?.latestActivity?.timestamp || showDetailView?.latestActivity;

  let parsedInitialState = {};

  try {
    parsedInitialState = JSON.parse(initialState);
  } catch (e) {
    console.log(e);
  }

  const visibleColumns = ReturnVisibleColumns(columns, parsedInitialState);

  const companyNameField = columns?.find(
    (col: any) => col.field === "companyName"
  );
  const contactNameField = columns?.find(
    (col: any) => col.field === "contactName"
  );
  const emailField = columns?.find((col: any) => col.type === "email");
  const phoneField = columns?.find((col: any) => col.type === "phone");
  const addressField = columns?.find((col: any) => col.type === "address");
  const tags = visibleColumns?.find((col: any) => col.type === "type");

  const phoneFields = columns?.filter((col: any) => col.type === "phone");

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (event: any, phoneToCall: any) => {
    // Check if the click target is the link or its children
    if (event.target.closest("a")) {
      // If it's the link, prevent the default Select behavior
      event.preventDefault();
      // You can optionally trigger the call here programmatically
      window.location.href = `tel:${phoneToCall}`;
    }
    // If it's not the link, the default Select behavior will occur
  };

  const lastActivityDate = new Date(lastActivityField);

  const handleLinkClick = (e: any) => {
    e.stopPropagation();
  };

  const lastActivity = !!lastActivityField
    ? !isNaN(lastActivityDate?.getTime()) && (
        <div
          style={{
            display: "flex",
            alignItems: "left",
            flexDirection: "column",
          }}
        >
          <StyledDiv>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M22 12H18L15 21L9 3L6 12H2"
                stroke="#155EEF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </StyledDiv>
          <Typography
            style={{
              marginBottom: "8px",
              display: "flex",
              alignItems: "center",
              color: "rgba(0,0,0,.7)",
            }}
          >
            Activity
          </Typography>
          <Typography
            style={{
              color: "rgb(40, 42, 48)",
              fontSize: 16,
              display: "block",
              width: "100%",
            }}
          >
            {lastActivityDate.toLocaleDateString()}
          </Typography>
        </div>
      )
    : "";

  const largeColWidth = mapDisplay ? 6 : 3;

  const companyElement = (
    <Typography style={{ color: "rgb(40, 42, 48)", fontSize: 16 }}>
      {showDetailView[companyNameField?.field] ||
        GetSubheaderText(showDetailView, columns)}
    </Typography>
  );

  const formattedNumber = formatPhoneNumber(showDetailView[phoneField?.field]);
  const phoneToCall = stripPhoneNumber(showDetailView[phoneField?.field]);

  const phoneElement =
    phoneFields?.length > 0 ? (
      <div
        style={{
          display: "flex",
          alignItems: "left",
          flexDirection: "column",
        }}
      >
        <StyledDiv>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M8.38028 8.85335C9.07627 10.303 10.0251 11.6616 11.2266 12.8632C12.4282 14.0648 13.7869 15.0136 15.2365 15.7096C15.3612 15.7694 15.4235 15.7994 15.5024 15.8224C15.7828 15.9041 16.127 15.8454 16.3644 15.6754C16.4313 15.6275 16.4884 15.5704 16.6027 15.4561C16.9523 15.1064 17.1271 14.9316 17.3029 14.8174C17.9658 14.3864 18.8204 14.3864 19.4833 14.8174C19.6591 14.9316 19.8339 15.1064 20.1835 15.4561L20.3783 15.6509C20.9098 16.1824 21.1755 16.4481 21.3198 16.7335C21.6069 17.301 21.6069 17.9713 21.3198 18.5389C21.1755 18.8242 20.9098 19.09 20.3783 19.6214L20.2207 19.779C19.6911 20.3087 19.4263 20.5735 19.0662 20.7757C18.6667 21.0001 18.0462 21.1615 17.588 21.1601C17.1751 21.1589 16.8928 21.0788 16.3284 20.9186C13.295 20.0576 10.4326 18.4332 8.04466 16.0452C5.65668 13.6572 4.03221 10.7948 3.17124 7.76144C3.01103 7.19699 2.93092 6.91477 2.9297 6.50182C2.92833 6.0436 3.08969 5.42311 3.31411 5.0236C3.51636 4.66357 3.78117 4.39876 4.3108 3.86913L4.46843 3.7115C4.99987 3.18006 5.2656 2.91433 5.55098 2.76999C6.11854 2.48292 6.7888 2.48292 7.35636 2.76999C7.64174 2.91433 7.90747 3.18006 8.43891 3.7115L8.63378 3.90637C8.98338 4.25597 9.15819 4.43078 9.27247 4.60655C9.70347 5.26945 9.70347 6.12403 9.27247 6.78692C9.15819 6.96269 8.98338 7.1375 8.63378 7.4871C8.51947 7.60142 8.46231 7.65857 8.41447 7.72538C8.24446 7.96281 8.18576 8.30707 8.26748 8.58743C8.29048 8.66632 8.32041 8.72866 8.38028 8.85335Z"
              stroke="#155EEF"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </StyledDiv>
        <Typography
          style={{
            marginBottom: "8px",
            display: "flex",
            alignItems: "center",
            color: "rgba(0,0,0,.7)",
          }}
        >
          Phone Number{phoneFields?.length > 1 ? "s" : ""}
        </Typography>
        <Select
          label="Phone"
          value={phoneFields[0]?.field}
          sx={{
            "& .MuiSelect-select": {
              padding: "8px 12px",
              fontSize: "14px",
            },
          }}
        >
          {phoneFields
            ?.filter((field: any) => {
              return !!showDetailView?.[field?.field];
            })
            ?.map((field: any) => {
              const formattedNumber = formatPhoneNumber(
                showDetailView[field?.field]
              );
              const phoneToCall = stripPhoneNumber(
                showDetailView[field?.field]
              );

              return (
                <MenuItem
                  key={field.field}
                  value={field.field}
                  sx={{
                    fontSize: "14px",
                    background: "rgba(255,255,255, .7)",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <a
                    href={`tel:${phoneToCall}`}
                    onClick={handleLinkClick}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textDecoration: "none",
                      color: "inherit",
                      width: "100%",
                    }}
                  >
                    <PhoneIcon
                      sx={{
                        fontSize: "20px",
                        color: "#0f1828",
                        marginRight: "12px",
                      }}
                    />
                    {field.headerName}: &nbsp;
                    <span style={{ color: "#2e59a8", fontWeight: 600 }}>
                      {formattedNumber}
                    </span>
                  </a>
                </MenuItem>
              );
            })}
        </Select>
        {/* <Typography
          style={{
            color: "rgb(40, 42, 48)",
            fontSize: 14,
            display: "block",
            width: "100%",
          }}
        >
          <a
            href={`tel:${phoneToCall}`}
            style={{
              color: "#2e59a8",
              fontWeight: 600,
            }}
          >
            {formattedNumber}
          </a>
        </Typography> */}
      </div>
    ) : undefined;

  const emailElement = !!showDetailView[emailField?.field] ? (
    <div
      style={{
        display: "flex",
        alignItems: "left",
        flexDirection: "column",
      }}
    >
      <StyledDiv>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M2 7L10.1649 12.7154C10.8261 13.1783 11.1567 13.4097 11.5163 13.4993C11.8339 13.5785 12.1661 13.5785 12.4837 13.4993C12.8433 13.4097 13.1739 13.1783 13.8351 12.7154L22 7M6.8 20H17.2C18.8802 20 19.7202 20 20.362 19.673C20.9265 19.3854 21.3854 18.9265 21.673 18.362C22 17.7202 22 16.8802 22 15.2V8.8C22 7.11984 22 6.27976 21.673 5.63803C21.3854 5.07354 20.9265 4.6146 20.362 4.32698C19.7202 4 18.8802 4 17.2 4H6.8C5.11984 4 4.27976 4 3.63803 4.32698C3.07354 4.6146 2.6146 5.07354 2.32698 5.63803C2 6.27976 2 7.11984 2 8.8V15.2C2 16.8802 2 17.7202 2.32698 18.362C2.6146 18.9265 3.07354 19.3854 3.63803 19.673C4.27976 20 5.11984 20 6.8 20Z"
            stroke="#155EEF"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </StyledDiv>
      <Typography
        style={{
          marginBottom: "8px",
          display: "flex",
          alignItems: "center",
          color: "rgba(0,0,0,.7)",
        }}
      >
        Email
      </Typography>
      <Typography
        style={{
          color: "rgb(40, 42, 48)",
          fontSize: 14,
          display: "block",
          width: "100%",
        }}
      >
        <a
          href={`mailto:${showDetailView[emailField?.field]}`}
          style={{
            color: "#2e59a8",
            fontWeight: 600,
            display: "block",
            width: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {showDetailView[emailField?.field]}
        </a>
      </Typography>
    </div>
  ) : undefined;

  const tagElement = !!tags ? (
    <div
      style={{
        display: "flex",
        alignItems: "left",
        flexDirection: "column",
      }}
    >
      <StyledDiv>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M21 11L13.4059 3.40589C12.887 2.88703 12.6276 2.6276 12.3249 2.44208C12.0564 2.27759 11.7638 2.15638 11.4577 2.08289C11.1124 2 10.7455 2 10.0118 2L6 2M3 8.7L3 10.6745C3 11.1637 3 11.4083 3.05526 11.6385C3.10425 11.8425 3.18506 12.0376 3.29472 12.2166C3.4184 12.4184 3.59136 12.5914 3.93726 12.9373L11.7373 20.7373C12.5293 21.5293 12.9253 21.9253 13.382 22.0737C13.7837 22.2042 14.2163 22.2042 14.618 22.0737C15.0747 21.9253 15.4707 21.5293 16.2627 20.7373L18.7373 18.2627C19.5293 17.4707 19.9253 17.0747 20.0737 16.618C20.2042 16.2163 20.2042 15.7837 20.0737 15.382C19.9253 14.9253 19.5293 14.5293 18.7373 13.7373L11.4373 6.43726C11.0914 6.09136 10.9184 5.9184 10.7166 5.79472C10.5376 5.68506 10.3425 5.60425 10.1385 5.55526C9.90829 5.5 9.6637 5.5 9.17452 5.5H6.2C5.0799 5.5 4.51984 5.5 4.09202 5.71799C3.7157 5.90973 3.40973 6.21569 3.21799 6.59202C3 7.01984 3 7.57989 3 8.7Z"
            stroke="#155EEF"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </StyledDiv>
      <Typography
        style={{
          marginBottom: "8px",
          display: "flex",
          alignItems: "center",
          color: "rgba(0,0,0,.7)",
        }}
      >
        {tags.headerName}
      </Typography>
      <div>
        <EditField
          title="test"
          field={tags.field}
          row={showDetailView}
          columnType="type"
          viewId={viewId}
          columns={columns}
          fullWidth={true}
          inHeader={true}
          setRow={setRow}
        />
      </div>
    </div>
  ) : undefined;

  let colCount = 0;

  if (!!lastActivity) colCount++;
  if (!!emailElement) colCount++;
  if (!!phoneElement) colCount++;
  if (!!tagElement) colCount++;

  return (
    <div key={`${showDetailView?._id}-header`}>
      <Grid
        container
        style={{ marginTop: "0px !important" }}
        justifyContent={"space-between"}
      >
        <Menu
          open={changeMarkerContext !== null}
          onClose={() => setChangeMarkerContext(null)}
          anchorEl={changeMarkerContext}
        >
          {Object.keys(colorShades)?.map((color) => {
            return (
              <MenuItem
                value={"test"}
                key={color}
                selected={showDetailView["merc_Color"] === color}
                onClick={() => {
                  updateRow({
                    variables: {
                      set: {
                        rowId: showDetailView._id,
                        rowObject: JSON.stringify({
                          ...showDetailView,
                          merc_Color: color,
                          relatedRows: undefined,
                        }),
                        triggeredBy: "client",
                      },
                      query: {
                        _id: showDetailView._id,
                      },
                    },
                  });
                  setChangeMarkerContext(null);
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ListItemIcon>
                    <img src={getImageString(color)} alt="pin" />
                  </ListItemIcon>
                  <div style={{ textTransform: "capitalize" }}>{color}</div>
                </div>
              </MenuItem>
            );
          })}
        </Menu>
        <Grid item sx={{ marginBottom: "15px" }}>
          {/* xs={!isMobile ? 9 : 12} */}
          <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
            {!!addressField && (
              <div>
                <IconButton
                  onClick={(event: any) =>
                    setChangeMarkerContext(event?.currentTarget)
                  }
                >
                  <img
                    src={getImageString(showDetailView["merc_Color"])}
                    alt="pin"
                  />
                </IconButton>
              </div>
            )}
            <div>
              <Typography
                style={{
                  color: "#101828",
                  fontSize: "30px",
                  fontWeight: 600,
                }}
                variant="h6"
              >
                <NewIndivHeader row={showDetailView} columns={columns} />
                {/* {GetHeaderText(showDetailView, columns)} */}
              </Typography>
              {companyElement}
            </div>
          </div>
        </Grid>
        {!isMobile && (
          <Grid item>
            <Grid container justifyContent={"right"} gap="12px">
              <Grid>
                {!!emailField && (
                  <NavLink
                    to="/email"
                    state={{
                      selectedRows: JSON.stringify([showDetailView]),
                      columns:
                        !!showDetailView &&
                        JSON.stringify(Object.keys(showDetailView)),
                      emailColumn: JSON.stringify(emailField?.field),
                    }}
                    onClick={handleClose}
                  >
                    <EmailButton>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M1.66699 5.83301L8.47109 10.5959C9.02207 10.9816 9.29756 11.1744 9.59721 11.2491C9.8619 11.3151 10.1387 11.3151 10.4034 11.2491C10.7031 11.1744 10.9786 10.9816 11.5296 10.5959L18.3337 5.83301M5.66699 16.6663H14.3337C15.7338 16.6663 16.4339 16.6663 16.9686 16.3939C17.439 16.1542 17.8215 15.7717 18.0612 15.3013C18.3337 14.7665 18.3337 14.0665 18.3337 12.6663V7.33301C18.3337 5.93288 18.3337 5.23281 18.0612 4.69803C17.8215 4.22763 17.439 3.84517 16.9686 3.60549C16.4339 3.33301 15.7338 3.33301 14.3337 3.33301H5.66699C4.26686 3.33301 3.5668 3.33301 3.03202 3.60549C2.56161 3.84517 2.17916 4.22763 1.93948 4.69803C1.66699 5.23281 1.66699 5.93288 1.66699 7.33301V12.6663C1.66699 14.0665 1.66699 14.7665 1.93948 15.3013C2.17916 15.7717 2.56161 16.1542 3.03202 16.3939C3.5668 16.6663 4.26686 16.6663 5.66699 16.6663Z"
                          stroke="white"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Send Email
                    </EmailButton>
                  </NavLink>
                )}
              </Grid>
              <Grid>
                <IconButton
                  onClick={handleClick}
                  sx={{
                    float: "right",
                    borderRadius: "8px",
                    border: "1px solid #D0D5DD", // Using fallback color for var(--gray-300)
                    background: "#FFF", // Using fallback color for var(--base-white)
                    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                    fontSize: "14px",
                    fontWeight: 600,
                    flexGrow: 1,
                    padding: "10px 14px",
                    width: "40px",
                  }}
                >
                  <MoreVertIcon
                    sx={{ color: "#344054", width: "20px", height: "20px" }}
                  />
                </IconButton>
              </Grid>
            </Grid>

            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              style={{ fontSize: ".75em" }}
            >
              <MenuItem
                onClick={async () => {
                  // await deleteRow({
                  //   variables: {
                  //     query: {
                  //       _id: showDetailView?._id,
                  //     },
                  //   },
                  // });

                  const resp: any = await updateRow({
                    variables: {
                      query: {
                        _id: showDetailView?._id,
                      },
                      set: {
                        isDeleted: true,
                        isDeletedAt: new Date(),
                        triggeredBy: "client",
                      },
                    },
                  });

                  !!!props.curRowId && navigate(-1);
                  if (!!props.curRowId) {
                    handleClose();
                    props.deleteRow();
                  }
                }}
                disableRipple
              >
                <DeleteIcon />
                Delete Record
              </MenuItem>
            </StyledMenu>
          </Grid>
        )}
      </Grid>
      {(!!lastActivity || !!emailElement || !!phoneElement || !!tagElement) && (
        <Grid
          container
          rowSpacing={{ xs: 4, md: 4, lg: 0 }}
          columnSpacing={{ xs: 4, md: 4, lg: 3 }}
          alignItems={"stretch"}
          // gap="24px"
          // alignItems="left"
          // sx={{
          //   marginTop: "0px !important",
          //   width: "100%",
          // }}
        >
          {!!lastActivity && (
            <Grid item xs={12} md={6} lg={largeColWidth}>
              <GridDiv>{lastActivity}</GridDiv>
            </Grid>
          )}
          {!!emailElement && (
            <Grid item xs={12} md={6} lg={largeColWidth}>
              <GridDiv>{emailElement}</GridDiv>
            </Grid>
          )}

          {mapDisplay && colCount > 2 && (
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              style={{ display: "block", marginBottom: "20px" }}
            ></Grid>
          )}

          {!!phoneElement && (
            <Grid item xs={12} md={6} lg={largeColWidth}>
              <GridDiv>{phoneElement}</GridDiv>
            </Grid>
          )}
          {!!tagElement && (
            <Grid item xs={12} md={6} lg={largeColWidth}>
              {" "}
              <GridDiv>{tagElement}</GridDiv>
            </Grid>
          )}
        </Grid>
      )}
    </div>
  );
}

export function NewIndivHeader(props: any) {
  const { row, columns } = props;

  const header1 = columns?.find((col: any) => col?.display === "header1");

  if (header1?.type?.includes("related")) {
    return <RelatedRowHeaderText colDef={header1} row={row} />;
  }

  return GetHeaderText(props.row, props.columns);
}

export function RelatedRowHeaderText(props: any) {
  const { colDef, row, api } = props;

  const { rootViewId, listId } = useParams();

  const viewId = colDef.type.split("-");

  const {
    loading,
    error,
    data,
    refetch: refetchView,
  } = useQuery(GET_ONE_VIEW, {
    variables: { query: { _id: viewId[1], isDeleted: false } },
  });

  if (isArray(viewId) && !!viewId[1] && !loading) {
    const filteredRelated = row?.relatedRows?.filter((r: any) => {
      return r.merc_viewId === viewId[1] || r.viewId?._id === viewId[1];
    });

    const rowObject = !!filteredRelated?.[0]?.rowObject
      ? JSON.parse(filteredRelated?.[0]?.rowObject)
      : filteredRelated?.[0];

    return GetHeaderText(rowObject, data?.view?.columns);
  }
}
