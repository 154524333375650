import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Select,
  MenuItem,
  ListItemIcon,
  DialogActions,
  Button,
  Grid,
} from "@mui/material";
import { icons, iconsWithStyle, iconTypes } from "./icons";
import { useEffect, useState } from "react";
import { CompactPicker } from "react-color";
import { StyledSelected } from "./rowTodos";
import { CustomParagraph, CustomTextField } from "./PersonalDetails";

export default function EditView(props: any) {
  const {
    selectedView,
    editingView,
    setEditingView,
    handleRefetch,
    updateView,
  } = props;

  const [newViewName, setNewViewName] = useState(selectedView?.name);
  const [viewIcon, setViewIcon] = useState(selectedView?.icon?.fileString);
  const [color, setColor] = useState(
    selectedView?.icon?.color || "rgba(0, 0, 0, 0.54)"
  );

  useEffect(() => {
    setViewIcon(selectedView?.icon?.fileString || "layers");
    setColor(selectedView?.icon?.color || "rgba(0, 0, 0, 0.54)");
  }, [selectedView]);

  return (
    <Dialog onClose={() => setEditingView(false)} open={editingView}>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          // Save new view name

          // const accessToken = await getAccessTokenSilently();

          await updateView({
            variables: {
              query: {
                _id: selectedView._id,
              },
              view: {
                name: newViewName,
                icon: {
                  fileString: viewIcon,
                  color: color,
                },
                triggeredBy: "client",
              },
            },
          });

          handleRefetch();

          setEditingView(false);

          // await fetch(`${apiURL}view`, {
          //   method: "PATCH",
          //   headers: {
          //     "Content-Type": "application/json",
          //     Authorization: `Bearer ${accessToken}`,
          //   },
          //   body: JSON.stringify({
          //     viewId: selectedView._id,
          //     name: newViewName,
          //   }),
          // })
          //   .then((res) => res.json())
          //   .then((data) => {
          //     handleRefetch();

          //     setEditingView(false);
          //   });
        }}
      >
        <DialogTitle>Edit list name</DialogTitle>
        <DialogContent>
          <EditListContent
            color={color}
            setColor={setColor}
            newViewName={newViewName}
            setNewViewName={setNewViewName}
            viewIcon={viewIcon}
            setViewIcon={setViewIcon}
          />
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={() => setEditingView(false)}>
            Cancel
          </Button>
          <Button type="submit" disabled={newViewName?.length < 1}>
            Save change
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export function EditListContent(props: {
  color: string;
  setColor: any;
  newViewName: string;
  setNewViewName: any;
  viewIcon: any;
  setViewIcon: any;
}) {
  const {
    color,
    setColor,
    newViewName,
    setNewViewName,
    viewIcon,
    setViewIcon,
  } = props;

  const coloredIcons = iconsWithStyle({ color: color });

  return (
    <>
      <CustomParagraph sx={{ marginBottom: "4px" }}>List Name</CustomParagraph>
      <CustomTextField
        autoFocus
        value={newViewName}
        onChange={(e) => setNewViewName(e.target.value)}
      />
      <Grid
        container
        justifyContent={"space-between"}
        sx={{ marginTop: "32px" }}
      >
        <Grid item>
          <CustomParagraph sx={{ marginBottom: "4px" }}>
            Choose an icon
          </CustomParagraph>
          <StyledSelected
            labelId="demo-simple-select-label"
            // id="demo-simple-select"
            // style={{ marginTop: 20, width: "100%" }}
            // sx={{
            //   marginBottom: "25px",
            //   display: "inline-block",
            // }}
            value={viewIcon}
            label="Type"
            onChange={(newValue) => setViewIcon(newValue.target.value)}
          >
            {Object.keys(coloredIcons).map((key: string) => {
              return (
                <MenuItem value={key} key={key}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <ListItemIcon>
                      {coloredIcons[key as iconTypes]}
                    </ListItemIcon>
                  </div>
                </MenuItem>
              );
            })}
          </StyledSelected>
        </Grid>
        <Grid item>
          <CustomParagraph sx={{ marginBottom: "4px" }}>
            Choose a color
          </CustomParagraph>
          <CompactPicker
            color={color}
            onChangeComplete={(color) => {
              setColor(color.hex);
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
