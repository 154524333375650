import { NestedMenuItem } from "mui-nested-menu";
import { cache } from "./cache";
import { GET_ROW1 } from "./graphql-ops";
import { ListItemIcon, MenuItem } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import SquareIcon from "@mui/icons-material/Square";
import { useContext } from "react";
import { GlobalContext } from "./globalContext";

export const colorShades: Record<string, string> = {
  blue: "#c3dbff", // Original color
  red: "#ffc3c9", // Red shade
  green: "#c3ffd9", // Green shade
  orange: "#ffdec3", // Orange shade
  yellow: "#fff5c3", // Yellow shade
  purple: "#e3c3ff", // Purple shade
  pink: "#ffc3f1", // Pink shade
  teal: "#c3fff5", // Teal shade
};

export const highlightRow = async (
  rows: any[],
  updateRow: any,
  handleCloseContext: any,
  color: string | undefined
) => {
  rows.forEach(async (row) => {
    try {
      const newRowObject = {
        ...row,
        merc_Color: color,
      };

      const resp: any = await updateRow({
        variables: {
          query: {
            _id: row?._id,
          },
          set: {
            rowObject: JSON.stringify(newRowObject),
            triggeredBy: "client",
          },
        },
      });

      handleCloseContext();
    } catch (error) {
      console.error("Data not found in cache:", error);
    }
  });
};

export const HighlightRowMenu = (props: {
  viewId: string;
  rowId: string;
  updateRow: any;
  handleCloseContext: any;
}) => {
  const { viewId, rowId, updateRow, handleCloseContext } = props;

  const globalContext = useContext(GlobalContext);

  // @ts-ignore
  const row = globalContext?.rows?.[viewId]?.get(rowId);

  const selectedColor = row?.merc_Color;

  return (
    <NestedMenuItem
      rightIcon={<ArrowRightIcon />}
      leftIcon={<ColorLensIcon fontSize="small" sx={{ marginLeft: "10px" }} />}
      label="Color"
      parentMenuOpen={true}
    >
      <ColorMenuItems
        viewId={viewId}
        selectedColor={selectedColor}
        rowIds={[rowId]}
        updateRow={updateRow}
        handleCloseContext={handleCloseContext}
      />
    </NestedMenuItem>
  );
};

export function ColorMenuItems(props: {
  viewId: string;
  selectedColor: string;
  rowIds: [string];
  updateRow: any;
  handleCloseContext: any;
}) {
  const { selectedColor, rowIds, updateRow, handleCloseContext, viewId } =
    props;
  const globalContext = useContext(GlobalContext);
  // @ts-ignore
  // const rows = globalContext?.rows?.[viewId];

  const rows = rowIds.map((rowId) => {
    // @ts-ignore
    return globalContext?.rows?.[viewId]?.get(rowId);
  });

  return (
    <>
      {Object.keys(colorShades)?.map((color) => {
        return (
          <MenuItem
            value={"test"}
            selected={selectedColor === color}
            onClick={() => {
              highlightRow(rows, updateRow, handleCloseContext, color);
              handleCloseContext();
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <ListItemIcon>
                <SquareIcon
                  sx={{
                    backgroundColor: colorShades[color],
                    color: colorShades[color],
                    border: "1px solid black",
                  }}
                />
              </ListItemIcon>
              <div style={{ textTransform: "capitalize" }}>{color}</div>
            </div>
          </MenuItem>
        );
      })}
      <MenuItem
        value={"test"}
        selected={!!!selectedColor}
        onClick={() => {
          highlightRow(rows, updateRow, handleCloseContext, undefined);
          handleCloseContext();
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <ListItemIcon>
            <SquareIcon
              sx={{
                backgroundColor: "#fff",
                color: "#fff",
                border: "1px solid black",
              }}
            />
          </ListItemIcon>
          <div>None</div>
        </div>
      </MenuItem>
    </>
  );
}

export const getHighlightRowClass = (color: string) => {
  switch (color) {
    case "blue":
      return "blueRow";
    case "red":
      return "redRow";
    case "green":
      return "greenRow";
    case "orange":
      return "orangeRow";
    case "yellow":
      return "yellowRow";
    case "purple":
      return "purpleRow";
    case "pink":
      return "pinkRow";
    case "teal":
      return "tealRow";
    default:
      return "";
  }
};

export const getImageString = (color: string) => {
  switch (color) {
    case "blue":
      return "/mapImages/blue.svg";
    case "red":
      return "/mapImages/red.svg";
    case "green":
      return "/mapImages/green.svg";
    case "orange":
      return "/mapImages/orange.svg";
    case "yellow":
      return "/mapImages/yellow.svg";
    case "purple":
      return "/mapImages/purple.svg";
    case "pink":
      return "/mapImages/pink.svg";
    case "teal":
      return "/mapImages/teal.svg";
    default:
      return "/mapImages/mapsMarker_red.svg";
  }
};
