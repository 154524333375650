import { useCallback, useEffect, useState } from "react";
import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useAuth0 } from "@auth0/auth0-react";
import { LicenseInfo } from "@mui/x-data-grid-premium";
import { Outlet, useLocation } from "react-router-dom";
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  NormalizedCacheObject,
} from "@apollo/client";
import { cachev2 } from "./cache";
import WorkspaceProvider from "./v2workspaceProvider";
import { skipTraceServerUrl } from "./consts";

import SkipTraceSocketIOProvider from "./skipTraceSocketIOProvider";
import ApolloLookupProvider from "./apolloLookupProvider";

const interFont = {
  fontFamily: "Inter",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local('Inter'),
    url('/fonts/Inter-Regular.woff2') format('woff2')
  `,
};

const interFont500 = {
  fontFamily: "Inter",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 500,
  src: `
    local('Inter'),
    url('/fonts/Inter-Medium.woff2') format('woff2')
  `,
};

const theTheme = createTheme({
  typography: {
    fontFamily: "'Inter', sans-serif",
    // add more typography settings as needed
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@font-face": [interFont, interFont500],
      },
    },
  },
});

export const GQL_URL = `${skipTraceServerUrl}/graphql`;

// Connect to your MongoDB Realm app

export default function V2Wrapper() {
  //const theme = useTheme();
  LicenseInfo.setLicenseKey(
    "d061d62b6dcae43902c7f3788128f0d4Tz05NTc5MCxFPTE3NTQ3MDg2MDQwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI="
  );

  const {
    user,
    getAccessTokenSilently,
    isAuthenticated,
    loginWithRedirect,
    isLoading,
  } = useAuth0();

  const location = useLocation();

  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();

  const initializeApolloClient = useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const newClient = new ApolloClient({
        link: new HttpLink({
          uri: GQL_URL,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }),
        cache: cachev2,
        connectToDevTools: true,
      });
      setClient(newClient);
    } catch (error) {
      console.error("Failed to initialize Apollo Client:", error);
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (!client) {
      initializeApolloClient();
    }
  }, [initializeApolloClient, client]);

  useEffect(() => {
    // Define the Intercom settings
    if (!user) return;
    // @ts-ignore
    window.intercomSettings = {
      api_base: "https://api-iam.intercom.io",
      app_id: "e3od59hf",
      // Assuming you have a user object from your auth
      name: user?.name, // Full name
      email: user?.email, // Email address
      created_at: user?.createdAt, // Signup date as a Unix timestamp
    };

    // Create a script element
    const script = document.createElement("script");
    script.src = "https://widget.intercom.io/widget/e3od59hf"; // Use the correct src from Intercom docs
    script.async = true;
    document.body.appendChild(script);
    // @ts-ignore
    if (window.Intercom) {
      // @ts-ignore
      window.Intercom("reattach_activator");
      // @ts-ignore
      window.Intercom("update", window.intercomSettings);
    }

    // Cleanup the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, [user]);

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  if (!isAuthenticated) {
    const searchParams = new URLSearchParams(location.search);
    const hasSignup = searchParams.has("signup");
    const returnTo = new URLSearchParams(location.search).get("returnTo");

    const loginOptions = {
      authorizationParams: {},
      redirectUri: "https://crm.mercero.com/properties",
    };

    if (hasSignup) {
      // @ts-ignore
      loginOptions.authorizationParams.screen_hint = "signup";
    }

    loginWithRedirect(loginOptions);
  }

  if (!client) {
    return <h2>Initializing app...</h2>;
  }

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theTheme}>
        <WorkspaceProvider>
          <ApolloLookupProvider>
            <SkipTraceSocketIOProvider>
              <Outlet />
            </SkipTraceSocketIOProvider>
          </ApolloLookupProvider>
        </WorkspaceProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}
