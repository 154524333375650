import React from "react";
import "./App.css";
import Typography from "@mui/material/Typography";
import { Box, Button, TextField, styled } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { apiURL } from "./consts";
import CustomTimeline, {
  AvatarUpload,
  BottomSteps,
} from "./onboardingTimeline";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

export const OnboardingHeader = styled(Typography)({
  color: "var(--colors-text-text-primary-900, #101828)",
  textAlign: "center",
  fontFamily: "Inter",
  fontSize: "30px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "38px", // 126.667%
});

export const OnboardingSubheader = styled(Typography)({
  color: "var(--colors-text-text-tertiary-600, #475467)",
  textAlign: "center",
  fontFamily: "Inter",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "24px", // 150%
});

export const CustomTextField = styled(TextField)({
  "&&": {
    borderRadius: "var(--radius-md, 8px)",
    border: "1px solid var(--colors-border-border-primary, #D0D5DD)",
    background: "var(--colors-background-bg-primary, #FFF)",
    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
    width: "100%",
  },

  "& .MuiInputBase-root": {
    borderRadius: "var(--radius-md, 8px)", // For the inner input element
  },

  "&& .MuiInputBase-input": {
    overflow: "hidden",
    color: "var(--colors-text-text-primary, #667085)", // Adjusted to primary text color
    textOverflow: "ellipsis",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px", // 150%
  },

  // Targeting the disabled state of the inner elements
  "& .Mui-disabled": {
    borderColor: "var(--colors-border-border-disabled, #D0D5DD)", // Disabled border color
    backgroundColor: "var(--colors-background-bg-disabled-subtle, #F9FAFB)", // Disabled background color
    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)", // Disabled box shadow
  },

  "& .Mui-disabled .MuiInputBase-input": {
    color: "var(--colors-text-text-disabled, #667085)", // Disabled input text color
  },
});

export const CustomButton = styled(Button)({
  display: "flex",
  padding: "10px var(--spacing-xl, 16px)",
  justifyContent: "center",
  alignItems: "center",
  gap: "var(--spacing-sm, 6px)",
  alignSelf: "stretch",

  borderRadius: "var(--radius-md, 8px)",
  border: "1px solid var(--blue-dark-700, #004EEB)",
  background: "var(--blue-dark-600, #155EEF)",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  // Text style
  color:
    "var(--component-colors-components-buttons-primary-button-primary-fg, #FFF)",
  fontFamily: "Inter",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "24px", // 150%
  textTransform: "none",

  "&:hover": {
    background: "var(--blue-dark-700, #004EEB)", // Change this to your desired hover background
    boxShadow: "0px 2px 4px 0px rgba(16, 24, 40, 0.15)", // Optional: Change this to your desired hover box-shadow
  },
});

export const CustomParagraph = styled(Typography)({
  "&&": {
    color: "var(--colors-text-text-secondary-700, #344054)",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "20px", // 142.857%
  },
});

export const CustomSupportText = styled(Typography)({
  color: "var(--colors-text-text-tertiary-600, #475467)",
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "20px", // 142.857%
});

export default function PersonalDetails() {
  const navigate = useNavigate();

  const [successMessage, setSuccessMessage] = React.useState<
    string | undefined
  >(undefined);
  const { user, logout, getAccessTokenSilently } = useAuth0();

  const splitName = !user?.name?.includes("@")
    ? user?.name?.split(" ")
    : undefined;

  const [firstName, setFirstname] = React.useState<string | undefined>(
    !!splitName ? splitName[0] : ""
  );
  const [lastName, setLastName] = React.useState<string | undefined>(
    !!splitName ? splitName[1] : ""
  );

  const avatarUpload = async (formData: any) => {
    const accessToken = await getAccessTokenSilently();

    await fetch(`${apiURL}profile/avatar`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        // setReadFiles(true);
        getAccessTokenSilently();
        return result;
      })
      .catch((err) => {
        throw err;
      });
  };

  return (
    <Grid container style={{ width: "100%" }}>
      <CustomTimeline step={0} />
      <Grid
        xs={8}
        sx={{
          backgroundImage: "url(/backgroundPattern.png)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center top",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            minHeight: "100vh",
          }}
        >
          <div>
            <img
              src="/logoColor.png"
              alt="Logo Color"
              style={{
                width: "142px",
                margin: "auto",
                display: "block",
                marginTop: "50px",
              }}
            />
            <OnboardingHeader sx={{ marginBottom: "12px", marginTop: "32px" }}>
              Let's get to know you
            </OnboardingHeader>
            <OnboardingSubheader sx={{ marginBottom: "32px" }}>
              Please provide details for your profile
            </OnboardingSubheader>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                maxWidth: "360px",
                margin: "auto",
                gap: "32px",
              }}
            >
              <AvatarUpload
                title={"Profile Picture"}
                subtitle="You'll see this picture in comments."
                src={user?.picture}
                uploadFiles={avatarUpload}
              />

              <div style={{ width: "100%" }}>
                <CustomParagraph
                  sx={{
                    marginBottom: "5px",
                  }}
                >
                  First Name*
                </CustomParagraph>
                <CustomTextField
                  key="firstName"
                  value={firstName}
                  onChange={(event) => setFirstname(event.target.value)}
                  placeholder="John"
                />
              </div>
              <div>
                <CustomParagraph
                  sx={{
                    marginBottom: "5px",
                  }}
                >
                  Last Name*
                </CustomParagraph>
                <CustomTextField
                  key="lastName"
                  value={lastName}
                  onChange={(event) => setLastName(event.target.value)}
                  placeholder="Doe"
                />
              </div>
              <div>
                <CustomParagraph
                  sx={{
                    marginBottom: "5px",
                  }}
                >
                  Email
                </CustomParagraph>
                <CustomTextField disabled value={user?.email} />
              </div>
              <CustomButton
                variant="outlined"
                style={{ display: "block", marginTop: "10px" }}
                onClick={async () => {
                  try {
                    const accessToken = await getAccessTokenSilently();

                    await fetch(`${apiURL}profile`, {
                      headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "application/json",
                      },
                      method: "PATCH",
                      body: JSON.stringify({
                        // @ts-ignore
                        name: `${firstName} ${lastName}`,
                      }),
                    });

                    navigate("/welcome/create-workspace");
                  } catch (e) {
                    console.log(e);
                  }
                }}
              >
                Continue
              </CustomButton>
            </Box>
          </div>
          <BottomSteps step={0} />
        </div>
      </Grid>
    </Grid>
  );
}
