import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
  FormHelperText,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import "./App.css";
import { useAuth0 } from "@auth0/auth0-react";
import CssBaseline from "@mui/material/CssBaseline";
import { Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
// import { Editor } from "@tinymce/tinymce-react";
import { apiURL } from "./consts";
import { useLocation, useNavigate } from "react-router-dom";
import { MessageStatus, RefetchContext } from "./refetchProvider";
import DeleteIcon from "@mui/icons-material/Delete";
import { getPageTitle } from "./individualView";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import LoadingScreen from "./loadingScreen";
import _ from "lodash";
import { GetHeaderText } from "./DatagridFunctions";
import Tribute from "tributejs";
import "tributejs/dist/tribute.css";
import FroalaEditor from "react-froala-wysiwyg";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/js/plugins/image.min.js";
import "froala-editor/css/plugins/image.min.css";
import "froala-editor/js/plugins/link.min.js";
// import "https://cdn.jsdelivr.net/npm/froala-editor@latest/js/plugins/image.min.js";

interface AutocompleteSuggestion {
  key: string;
  value: string;
  id: string;
}

interface Column {
  field: string;
  headerName: string;
  width: number;
}

const getAutocompleteSuggestions = (cols: any): AutocompleteSuggestion[] => {
  const filterOutItems = cols.filter((column: any) => {
    return (
      column?.field !== "id" &&
      column?.field !== "_id" &&
      column?.field !== "actions" &&
      column?.field !== "__check__" &&
      column?.field !== "notes" &&
      column?.field !== "date" &&
      column?.field !== "new-column" &&
      column !== "id" &&
      column !== "_id" &&
      column !== "actions" &&
      column !== "__check__" &&
      column !== "notes" &&
      column !== "date" &&
      column !== "new-column"
    );
  });

  return filterOutItems.map((column: any) => ({
    // type: "autocompleteitem",
    key: column?.field,
    value: `{{${column?.field?.toUpperCase() || column?.toUpperCase()}}}`,
    id: `{{${column?.field?.toUpperCase() || column?.toUpperCase()}}}`,
    // text: column?.headerName || column,
  }));
};

export default function NewSendEmail() {
  const { state } = useLocation();
  const context = useContext(RefetchContext);

  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [previewEmails, setPreviewEmails] = useState<any[]>([]);
  const [topLevelErrors, setTopLevelErrors] = useState<any[]>([]);
  const [emailRequestData, setEmailRequestData] = useState<any>();
  const [emailSendLoading, setEmailSendLoading] = useState(false);
  const [parsedColumns, setParsedColumns] = useState<any[]>([]);
  // const [accessToken, setAccessToken] = useState<string>("");

  const [canTakeLong, setCanTakeLong] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [exceedsAttachmentSizeLimit, setExceedsAttachmentSizeLimit] =
    useState<boolean>(false);
  const [signatureRes, setSignatureRes] = useState<any>({});
  const [timeoutSeconds, setTimeoutSeconds] = useState<number>(600);

  const [ccEmail, setCcEmail] = useState<string>("");

  const emailColumn = !!state?.emailColumn && JSON.parse(state?.emailColumn);

  const { getAccessTokenSilently, user } = useAuth0();

  // useEffect(() => {
  //   const getAccessToken = async () => {
  //     const newAccessToken = await getAccessTokenSilently();
  //     setAccessToken(newAccessToken);
  //   };
  //   if (!accessToken) {
  //     getAccessToken();
  //   }
  // }, [getAccessTokenSilently]);

  useEffect(() => {
    if (!searchQuery) {
      setSearchResults([]);
      return;
    }

    (async () => {
      try {
        setLoading(true);
        const accessToken = await getAccessTokenSilently();

        await fetch(`${apiURL}row?q=${searchQuery}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((res) => res.json())
          .then((result) => {
            setSearchResults([
              ...result.map((r: any) => {
                return {
                  _id: r._id,
                  ...JSON.parse(r.rowObject),
                  relatedRows: r.relatedRows,
                };
              }),
            ]);
            setLoading(false);
          });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    })();
  }, [searchQuery, getAccessTokenSilently]);

  useEffect(() => {
    if (state?.selectedRows) {
      setSelectedRows(JSON.parse(state?.selectedRows));
    }
  }, [state?.selectedRows]);

  useEffect(() => {
    try {
      const newCols = JSON.parse(state?.columns);
      setParsedColumns(newCols);
    } catch (e) {
      console.log("Failed to parse columns in newSendEmail");
    }
  }, [state?.columns]);

  const navigate = useNavigate();

  const emailEditorRef = useRef(null);
  const subjectEditorRef = useRef(null);
  const [subject, setSubject] = useState("");
  const [file, setFile] = useState<any>([]);
  const [signature, setSignature] = useState<any>(undefined);

  const [ccValid, setCcValid] = useState<boolean>(true);
  // const [ccErrorText, setCcErrorText] = useState<any>(null);
  // const [checkCcEmail, setCheckCcEmail] = useState<number>(0);
  const [emailConfig, setEmailConfig] = useState<undefined | String>(undefined);
  const [previewUrl, setPreviewUrl] = useState<undefined | String>(undefined);
  const [sendUrl, setSendUrl] = useState<undefined | String>(undefined);
  const [isEmailSetup, setIsEmailSetup] = useState<boolean>(true);
  const [isSendgridSetup, setIsSendgridSetup] = useState<boolean>(true);

  const EMAIL_LIMIT = 50;

  useEffect(() => {
    if (ccEmail.length > 3) {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const ccEmails = _.chain(ccEmail)
        .split(",")
        .map((o) => _.trim(o))
        .value();
      if (_.some(ccEmails, (o) => !emailRegex.test(o))) {
        setCcValid(false);
      } else {
        setCcValid(true);
      }
    } else {
      setCcValid(true);
      // setCcErrorText(null);
    }
  }, [ccEmail]);

  useEffect(() => {
    const initializeEmailConfig = async () => {
      const accessToken = await getAccessTokenSilently();
      let resp = await fetch(`${apiURL}email/is-setup`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        method: "GET",
      });
      const emailSpecs = await resp.json();
      const isEmailGoodToGo = _.get(emailSpecs, "validToken", false);
      setIsEmailSetup(
        _.chain(emailSpecs)
          .get("primaryEmail")
          .isNil()
          .thru((o) => !o)
          .value()
      );

      resp = await fetch(`${apiURL}sendgridInterface/read-sender-identity`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        method: "GET",
      });
      const sendgridSender = await resp.json();
      const isSendgridGoodToGo = _.get(
        sendgridSender,
        "senderProfileVerified",
        false
      );
      setIsSendgridSetup(
        _.chain(sendgridSender)
          .get("senderProfile")
          .isNil()
          .thru((o) => !o)
          .value()
      );

      if (isEmailGoodToGo && isSendgridGoodToGo) {
        setEmailConfig("all");
      } else if (isEmailGoodToGo && !isSendgridGoodToGo) {
        setEmailConfig("email");
        setPreviewUrl(`${apiURL}email/preview`);
        setSendUrl(`${apiURL}email`);
      } else if (!isEmailGoodToGo && isSendgridGoodToGo) {
        setEmailConfig("sendgrid");
        setPreviewUrl(`${apiURL}sendgridInterface/preview`);
        setSendUrl(`${apiURL}sendgridInterface/campaign`);
      } else {
        setEmailConfig("none");
      }
    };
    initializeEmailConfig();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    const updateUrls = () => {
      if (_.chain(selectedRows).size().lte(EMAIL_LIMIT).value()) {
        setPreviewUrl(`${apiURL}email/preview`);
        setSendUrl(`${apiURL}email`);
      } else {
        setPreviewUrl(`${apiURL}sendgridInterface/preview`);
        setSendUrl(`${apiURL}sendgridInterface/campaign`);
      }
    };
    if (_.eq(emailConfig, "all")) {
      updateUrls();
    }
  }, [selectedRows, emailConfig]);

  const theTribute = new Tribute({
    trigger: "{",
    values: getAutocompleteSuggestions(
      typeof state?.columns === "string" && JSON.parse(state.columns)
    ),
    selectTemplate: (item: {
      original: { key: string; value: string; id: string };
    }) => {
      // const editor = editorRef.current.getEditor();
      // setNoteMembers((prevMembers) => [...prevMembers, item.original]);
      return item?.original?.value || "";
    },
    menuItemTemplate: (item: {
      original: { key: string; value: string; id: string };
    }) => {
      return item?.original?.value;
    },
    noMatchTemplate: () =>
      '<span style="color: #999;">No matching users found</span>',
    lookup: "value",
    fillAttr: "value",
    selectClass: "highlight",
    containerClass: "tribute-container",
    itemClass: "",
    replaceTextSuffix: "",
  });

  // const emailInitConfig = {
  //   placeholderText: "Write your email. Use { to insert tags.",
  // const handleEditorInit = (editor: any) => {
  //   // Autocompleter configuration
  //   editor.ui.registry.addAutocompleter("myautocompleter", {
  //     ch: "{", // Add this line
  //     minChars: 0,
  //     columns: 1,
  //     //   smatches: /^\{/,
  //     fetch: (pattern: any) => {
  //       // const items = [
  //       //   { type: "autocompleteitem", value: "{{apple}}", text: "Apple" },
  //       //   { type: "autocompleteitem", value: "{{banana}}", text: "Banana" },
  //       //   { type: "autocompleteitem", value: "{{cherry}}", text: "Cherry" },
  //       // ];
  const emailInitConfig = {
    placeholderText: "Write your email. Use { to insert tags.",
    key: "te1C2sE7D7D6G4B4E4C3kOPVe1f1d1Le1b1D1AWMSGSAFDTGHWsF4I4A11B7C2B5B4B1C3A2",
    imageUploadURL: `${apiURL}email/upload-attachment`,
    imageUploadMethod: "POST",
    imageMaxSize: 5 * 1024 * 1024, // 5MB
    imageAllowedTypes: ["jpeg", "jpg", "png", "gif"],
    charCounterCount: false,
    toolbarButtons: {
      // Key represents the more button from the toolbar.
      moreText: {
        // List of buttons used in the  group.
        buttons: [
          "bold",
          "italic",
          "underline",
          "strikeThrough",
          "subscript",
          "superscript",
          "fontFamily",
          "fontSize",
          "textColor",
          "backgroundColor",
          "inlineClass",
          "inlineStyle",
          "clearFormatting",
        ],
        // Alignment of the group in the toolbar.
        align: "left",

        // By default, 3 buttons are shown in the main toolbar. The rest of them are available when using the more button.
        buttonsVisible: 3,
      },

      moreParagraph: {
        buttons: [
          "alignLeft",
          "alignCenter",
          "formatOLSimple",
          "alignRight",
          "alignJustify",
          "formatOL",
          "formatUL",
          "paragraphFormat",
          "paragraphStyle",
          "lineHeight",
          "outdent",
          "indent",
          "quote",
        ],
        align: "left",
        buttonsVisible: 3,
      },

      moreRich: {
        buttons: ["insertLink", "insertImage"],
        align: "left",
        buttonsVisible: 3,
      },

      moreMisc: {
        buttons: [
          "undo",
          "redo",
          "fullscreen",
          "print",
          "getPDF",
          "spellChecker",
          "selectAll",
          "html",
          "help",
        ],
        align: "right",
        buttonsVisible: 2,
      },
    },
    // toolbarButtonsXS: ["bold", "italic", "underline", "insertUnorderedList"],
    // toolbarButtonsSM: ["bold", "italic", "underline", "insertUnorderedList"],
    // toolbarButtonsMD: ["bold", "italic", "underline", "insertUnorderedList"],
    heightMin: 250,
    fontFamilyDefaultSelection: "Inter, sans-serif",
    fontSizeDefaultSelection: "14",
    events: {
      initialized: function (this: any) {
        let editor = this;

        theTribute.attach(editor.el);

        editor.events.on(
          "keydown",
          function (e: any) {
            if (e.key === "Enter" && theTribute.isActive) {
              return false;
            }
          },
          true
        );
      },
      keydown: function (e: any) {
        if (
          (e.metaKey || e.ctrlKey) &&
          (e.key === "Enter" || e.keyCode === 13)
        ) {
          e.stopPropagation();
          e.preventDefault();
          // submitComment();
        }
      },
      "image.beforeUpload": function (this: any, images: any) {
        // Prevent the default upload
        // e.preventDefault();

        const editor = this;

        // Create FormData object
        const data = new FormData();
        data.append("file", images[0]); // Assuming your API expects 'file' as the key

        // Get the access token
        getAccessTokenSilently()
          .then((accessToken) => {
            // Make the API call using fetch
            return fetch(`${apiURL}email/upload-attachment`, {
              method: "POST",
              headers: {
                accept: "application/json",
                Authorization: `Bearer ${accessToken}`,
                // Note: Don't set Content-Type header, let the browser set it with the correct boundary
              },
              body: data,
            });
          })
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
          })
          .then((responseData) => {
            // Assuming the response contains a 'url' field with the image URL
            if (responseData && responseData.url) {
              // Insert the image into the editor
              editor.image.insert(
                responseData.url,
                null,
                null,
                editor.image.get()
              );
            } else {
              throw new Error("Invalid response from server");
            }
          })
          .catch((error) => {
            console.error("Error uploading image:", error);
            // You might want to show an error message to the user here
          });

        // Return false to prevent the default upload
        return false;
      },
      "image.uploaded": function (response: any) {
        console.log("Image uploaded response:", response);
        return response; // The response should already be in the correct format
      },
      "image.error": function (error: any, response: any) {
        console.error("Image upload error:", error, response);
      },
    },
  };

  const subjectConfig = {
    placeholderText: "Write your subject. Use '{' to insert tags.",
    key: "te1C2sE7D7D6G4B4E4C3kOPVe1f1d1Le1b1D1AWMSGSAFDTGHWsF4I4A11B7C2B5B4B1C3A2",
    charCounterCount: false,
    toolbarButtons: [],
    heightMin: 40,
    fontFamilyDefaultSelection: "Inter, sans-serif",
    fontSizeDefaultSelection: "14",
    toolbarVisibleWithoutSelection: false,
    toolbarInline: true,
    events: {
      initialized: function (this: any) {
        let editor = this;

        theTribute.attach(editor.el);

        editor.events.on(
          "keydown",
          function (e: any) {
            if (e.key === "Enter" && theTribute.isActive) {
              return false;
            }
          },
          true
        );
      },
      keyup: function (e: any) {
        setSubject(e.target.innerText);
      },
    },
  };

  useEffect(() => {
    const getSignature = async () => {
      const accessToken = await getAccessTokenSilently();

      const getSignature = await fetch(`${apiURL}email/signature`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        method: "GET",
      });

      const sigRes = await getSignature.json();
      setSignature(sigRes.signature);
    };
    if (!!!signature) {
      getSignature();
    }
  }, []);

  useEffect(() => {
    const fetchSignature = async () => {
      const accessToken = await getAccessTokenSilently();

      const getSignature = await fetch(`${apiURL}email/signature`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        method: "GET",
      });

      const signatureRes = await getSignature.json();
      setSignatureRes(signatureRes);
    };
    fetchSignature();
  }, []);

  useEffect(() => {
    const processEmailSize = async () => {
      //@ts-ignore
      const msg = `${message} || ""}\n\n${
        !!signatureRes?.signature ? signatureRes?.signature : ""
      }`;

      let currentSize = subject.length + msg.length;
      for (const f of file) {
        currentSize += f.size;
      }

      if (currentSize > 25 * 1000 * 1000) {
        setExceedsAttachmentSizeLimit(true);
      } else {
        setExceedsAttachmentSizeLimit(false);
      }

      const currentSizeMBytes = currentSize / (1000 * 1000);

      const OUTLOOK_THROTTLING_LIMIT_MB = 150;
      const minBatches = _.chain(selectedRows)
        .size()
        .multiply(currentSizeMBytes)
        .divide(0.6 * OUTLOOK_THROTTLING_LIMIT_MB)
        .ceil()
        .toInteger()
        .value();
      if (_.chain(minBatches).gt(11).value()) {
        setCanTakeLong(true);
      } else {
        setCanTakeLong(false);
      }

      setTimeoutSeconds(2 * minBatches * 300);
    };

    processEmailSize();
  }, [file, message, subject, selectedRows, signatureRes]);

  if (!!!selectedRows || !!!state.columns) {
    return <div>Select contacts from your contact list to send an email</div>;
  }

  let isMissingEmail = selectedRows.some(
    (row) => !!!row["Email"] && !!emailColumn && !!!row[emailColumn]
  );

  const fromEmail =
    !!user && !!user["https://crm.mercero-api.com/primaryEmailAddress"] ? (
      user["https://crm.mercero-api.com/primaryEmailAddress"]
    ) : (
      <span style={{ color: "red" }}>
        Please connect an email in your settings page.
      </span>
    );

  return (
    <>
      {emailSendLoading && <LoadingScreen />}
      <div className="pageHeader" style={{ height: "56px" }}>
        <Box sx={{ display: "flex", alignItems: "center", height: "56px" }}>
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <CloseIcon sx={{ width: "16px", height: "16px" }} />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            fontSize={"14px"}
            sx={{ marginLeft: "5px" }}
          >
            New Email ({selectedRows.length})
            {/* New Email to {!!selectedRows[0] && selectedRows[0]["Name"]}{" "}
            {selectedRows.length > 1 && `and ${selectedRows.length - 1} more`} */}
          </Typography>
        </Box>
      </div>
      <Grid container spacing={2} style={{ padding: "20px" }}>
        <CssBaseline />
        {!isEmailSetup && !isSendgridSetup && _.eq(emailConfig, "none") && (
          <Grid item xs={12}>
            <Alert severity="error">
              Emails cannot be previewed or sent because an email service
              provider (Outlook / Gmail / Sendgrid) has not been setup. Please
              contact support.
            </Alert>
          </Grid>
        )}
        {isEmailSetup && isSendgridSetup && _.eq(emailConfig, "none") && (
          <Grid item xs={12}>
            <Alert severity="error">
              Emails cannot be previewed or sent likely because of errors
              retrieving a token from your email service provider nor is your
              sendgrid email verified. Please contact support.
            </Alert>
          </Grid>
        )}
        {isEmailSetup && !isSendgridSetup && _.eq(emailConfig, "none") && (
          <Grid item xs={12}>
            <Alert severity="error">
              Emails cannot be previewed or sent likely because of errors
              retrieving a token from your email service. Please contact
              support.
            </Alert>
          </Grid>
        )}
        {!isEmailSetup && isSendgridSetup && _.eq(emailConfig, "none") && (
          <Grid item xs={12}>
            <Alert severity="error">
              Emails cannot be previewed or sent likely because your sendgrid
              email is not verified. Please contact support.
            </Alert>
          </Grid>
        )}
        {isEmailSetup && _.eq(emailConfig, "sendgrid") && (
          <Grid item xs={12}>
            <Alert severity="warning">
              Error retrieving token from email service provider. It could be
              because your password has changed or some such reason. Defaulting
              to sendgrid.
            </Alert>
          </Grid>
        )}
        {isSendgridSetup && _.eq(emailConfig, "email") && (
          <Grid item xs={12}>
            <Alert severity="warning">
              Error retrieving token from email service provider. Defaulting to
              email.
            </Alert>
          </Grid>
        )}
        {isMissingEmail && (
          <Grid item xs={12}>
            <Alert severity="error">
              One or more records are missing an email. Please remove them from
              the recipients list or add in an email address.
            </Alert>
          </Grid>
        )}
        {selectedRows.length > 50 && _.eq(emailConfig, "email") && (
          <Grid item xs={12}>
            <Alert severity="warning">
              We don't recommend sending more than 50 emails at a time to avoid
              spam filters.
            </Alert>
          </Grid>
        )}
        {canTakeLong && (
          <Grid item xs={12}>
            <Alert severity="warning">
              It could take over an hour to send this many emails. Please be
              patient.
            </Alert>
          </Grid>
        )}
        {exceedsAttachmentSizeLimit && (
          <Grid item xs={12}>
            <Alert severity="error">Attachment size exceeds 25MB limit</Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <ShowPreviewEmails
            previewEmails={previewEmails}
            setPreviewEmails={setPreviewEmails}
            topLevelErrors={topLevelErrors}
            setTopLevelErrors={setTopLevelErrors}
            formData={emailRequestData}
            context={context}
            navigate={navigate}
            emailConfig={emailConfig}
            sendUrl={sendUrl}
          />
        </Grid>
        <Grid item xs={9} style={{ paddingTop: "5px" }}>
          <div style={{ width: "100%", padding: "5px 30px" }}>
            {/* <TextField
              style={{ width: "100%" }}
              id="standard-basic"
              label="To"
              variant="standard"
              value={Array.from(selectedRows)
                .map((row) => {
                  return row[1]["Email"];
                })
                .toString()}
            /> */}
            <Grid container justifyContent="flex-start" alignItems="center">
              <Grid item xs={1}>
                <Typography fontSize={"14px"}>
                  <b>From </b>
                </Typography>
              </Grid>
              <Grid item xs={11}>
                <Chip
                  label={`${user?.name} (${fromEmail})`}
                  variant="outlined"
                  size="small"
                />
              </Grid>
            </Grid>
            <Divider style={{ marginTop: "20px", marginBottom: "10px" }} />
            <Grid container justifyContent="flex-start" alignItems="center">
              <Grid item xs={1}>
                <Typography fontSize={"14px"}>
                  <b>CC </b>
                </Typography>
              </Grid>
              <Grid item xs={11}>
                <TextField
                  style={{ width: "100%" }}
                  id="standard-basic"
                  variant="standard"
                  value={ccEmail}
                  onChange={(e) => {
                    setCcEmail(e.target.value);
                  }}
                  placeholder="Enter CC here"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  type={"email"}
                  error={!ccValid}
                  helperText={!ccValid ? "Invalid Email" : ""}
                  onKeyUp={(e) => {
                    // debouncedSetCheckCcEmail(checkCcEmail + 1);
                  }}
                />
              </Grid>
            </Grid>

            <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />

            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
              sx={{ marginBottom: "24px" }}
            >
              <Grid item xs={1}>
                <Typography fontSize={"14px"}>
                  <b>Subject </b>
                </Typography>
              </Grid>
              <Grid item xs={11}>
                <FroalaEditor
                  tag="textarea"
                  config={subjectConfig}
                  // model={subject}
                  // onModelChange={setSubject}
                  ref={subjectEditorRef}
                />
              </Grid>
            </Grid>

            <FroalaEditor
              tag="textarea"
              config={emailInitConfig}
              // model={newNote}
              // onModelChange={handleModelChange}
              ref={emailEditorRef}
            />

            <div
              style={{ fontSize: "75%", paddingLeft: "25px", opacity: ".8" }}
              dangerouslySetInnerHTML={{ __html: signature }}
            />
          </div>
        </Grid>
        <Grid item xs={3} style={{ maxHeight: "calc(100vh - 200px)" }}>
          <Typography variant="h6" gutterBottom>
            Recipient List
          </Typography>
          <Autocomplete
            // key={searchResults.length}
            sx={{
              border: "none",
              outline: "none",
              "& .MuiChip-root": {
                display: "none",
              },
              "& .MuiPaper-root": {
                padding: "0px",
              },
              "& .MuiAutocomplete-option": {
                padding: "0px",
              },
              "& .MuiAutocomplete-clearIndicator": {
                display: "none",
              },
            }}
            multiple
            options={searchResults}
            getOptionLabel={(option: any) => {
              return (
                GetHeaderText(option, parsedColumns) ||
                getPageTitle(option) ||
                ""
              );
            }} // Adjust this based on the structure of your result object
            value={selectedRows} //selectedRows
            loading={loading}
            onChange={(_: any, value: any) => {
              // setSelectedOptions(value)
              setSelectedRows(value);
              // const newSelectedRows = [...selectedRows];
              // newSelectedRows.push(value);
              // setSelectedRows(newSelectedRows);
            }}
            onInputChange={(_: any, value: any) => setSearchQuery(value)}
            renderOption={(props: any, option: any) => (
              <Box
                component="li"
                {...props}
                sx={{
                  fontSize: "1rem",
                  padding: "10px !important",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 255, 0.1)",
                  },
                }}
              >
                {getPageTitle(option)}
              </Box>
            )}
            renderInput={(params: any) => (
              <TextField
                {...params}
                // label="Search"
                placeholder="Add more recipients"
                variant="outlined"
                //value={searchQuery}
              />
            )}
          />
          <List
            style={{ maxHeight: "calc(100% - 100px)", overflowY: "scroll" }}
          >
            {selectedRows.map((row) => {
              return (
                <ListItem
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => {
                        const newSelectedRows = [...selectedRows];
                        newSelectedRows.splice(newSelectedRows.indexOf(row), 1);
                        setSelectedRows(newSelectedRows);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary={
                      GetHeaderText(row, parsedColumns) || "No name found"
                    }
                    secondary={
                      row[emailColumn] || row["Email"] || "No email found"
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />

          {file.length === 0 && (
            <Button
              variant="outlined"
              startIcon={<AttachFileIcon />}
              component={"label"}
            >
              Attach Files
              <input
                hidden
                multiple
                type="file"
                onChange={(event) => {
                  // @ts-ignore
                  const filesBuff = _.chain(event)
                    .get("target.files", [])
                    // @ts-ignore
                    .reduce((r, v) => {
                      r.push(v);
                      return r;
                    }, [])
                    .value();
                  // @ts-ignore
                  setFile(filesBuff);
                }}
              />
            </Button>
          )}
          {file.length > 0 && (
            <>
              {" "}
              {file?.map((singleFile: any, index: number) => {
                return (
                  <Button
                    style={{ marginRight: "10px" }}
                    variant="outlined"
                    startIcon={<RemoveIcon />}
                    onClick={() => {
                      const newFile = [...file];
                      newFile.splice(index, 1);
                      setFile(newFile);
                    }}
                  >
                    {singleFile?.name}
                  </Button>
                );
              })}
              <Button aria-label="delete" component={"label"}>
                <AddIcon />
                <input
                  hidden
                  multiple
                  type="file"
                  onChange={(event) => {
                    // @ts-ignore
                    const filesBuff = _.chain(event)
                      .get("target.files", [])
                      // @ts-ignore
                      .reduce((r, v) => {
                        r.push(v);
                        return r;
                      }, [])
                      .value();
                    // @ts-ignore
                    setFile([...file, ...filesBuff]);
                  }}
                />
              </Button>
            </>
          )}
          <Button
            variant="contained"
            style={{ float: "right" }}
            onClick={async () => {
              try {
                setEmailSendLoading(true);
                const accessToken = await getAccessTokenSilently();

                const formData = new FormData();
                if (!_.isEmpty(file)) {
                  for (const f of file) {
                    formData.append("file", f);
                  }
                }

                formData.append(
                  "subject",
                  // @ts-ignore
                  subject
                );
                formData.append(
                  "rowIds",
                  JSON.stringify(selectedRows.map((row) => row._id))
                );
                formData.append("cc", ccEmail);
                formData.append(
                  "htmlEmailTemplate",
                  // @ts-ignore
                  `${emailEditorRef.current.getEditor().html.get() || ""}\n\n${
                    !!signatureRes?.signature ? signatureRes?.signature : ""
                  }`
                );
                formData.append(
                  "html",
                  // @ts-ignore
                  `${emailEditorRef.current.getEditor().html.get() || ""}\n\n${
                    !!signatureRes?.signature ? signatureRes?.signature : ""
                  }`
                );
                formData.append("timeoutSeconds", _.toString(timeoutSeconds));

                setEmailRequestData(formData);

                //@ts-ignore
                const previewRes = await fetch(previewUrl, {
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                  },
                  method: "POST",
                  body: formData,
                });

                const previewResJson = await previewRes.json();
                //@ts-ignore
                setPreviewEmails(_.omit(previewResJson, ["errors"]));
                setTopLevelErrors(_.get(previewResJson, "errors", []));

                setEmailSendLoading(false);
              } catch (error) {
                console.log(error);
                setEmailSendLoading(false);
                context?.setEmailMessage({
                  code: MessageStatus.ERROR,
                  message: JSON.stringify(error),
                });
              }
            }}
            disabled={
              isMissingEmail ||
              exceedsAttachmentSizeLimit ||
              _.isNil(emailConfig) ||
              _.eq(emailConfig, "none")
            }
          >
            Preview Email
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

// Display previewed emails in a modal
function ShowPreviewEmails(props: {
  previewEmails: any;
  setPreviewEmails: any;
  formData: any;
  context: any;
  navigate: any;
  emailConfig: undefined | String;
  sendUrl: undefined | String;
  topLevelErrors: any;
  setTopLevelErrors: any;
}) {
  const {
    previewEmails,
    setPreviewEmails,
    context,
    formData,
    navigate,
    emailConfig,
    sendUrl,
    topLevelErrors,
    setTopLevelErrors,
  } = props;

  const [emailLoading, setEmailLoading] = useState(false);

  const { user, getAccessTokenSilently } = useAuth0();

  const [selectedEmail, setSelectedEmail] = useState<any>(
    Object.keys(previewEmails)[0]
  );
  const selectedEmailContent = previewEmails[selectedEmail];

  const senderEmail =
    !!user && !!user["https://crm.mercero-api.com/primaryEmailAddress"] ? (
      user["https://crm.mercero-api.com/primaryEmailAddress"]
    ) : (
      <span style={{ color: "red" }}>
        Please connect an email in your settings page.
      </span>
    );

  useEffect(() => {
    if (Object.keys(previewEmails)?.length > 0)
      setSelectedEmail(Object.keys(previewEmails)[0]);
  }, [previewEmails]);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "80%",
    bgcolor: "background.paper",
    // border: "2px solid #000",
    padding: "20px !important",
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
    "& .MuiBox-root": {
      padding: "10px",
    },
  };

  return (
    <Dialog
      open={Object.keys(previewEmails)?.length > 0}
      onClose={() => {
        setPreviewEmails({});
        setTopLevelErrors({});
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="lg"
      fullWidth
    >
      {emailLoading && <LoadingScreen />}
      <DialogContent>
        {/* <Box sx={style}> */}
        {_.chain(topLevelErrors).size().gt(0).value() ? (
          <Box m={1}>
            <Alert severity="error">{`${_.join(
              topLevelErrors,
              ", "
            )}. Please fix to send email(s). Click Cancel to return to email composer.`}</Alert>
          </Box>
        ) : (
          <></>
        )}
        <Grid container style={{ maxHeight: "60vh" }}>
          <Grid item xs={8} style={{ maxHeight: "60vh" }}>
            {!!!selectedEmail && (
              <Typography variant="h6">No email selected</Typography>
            )}
            {!!selectedEmail && (
              <>
                <Typography fontSize={"14px"} style={{ marginBottom: "15px" }}>
                  <b>From</b>
                  <Chip
                    variant="outlined"
                    size="small"
                    label={`${user?.name} (${senderEmail})`}
                    style={{ marginLeft: "5px" }}
                  />
                </Typography>
                <Typography fontSize={"14px"}>
                  <b>To</b>
                  <Chip
                    variant="outlined"
                    size="small"
                    label={`${selectedEmailContent?.message?.recipient}`}
                    style={{ marginLeft: "5px" }}
                  />
                </Typography>
                <div style={{ marginTop: "15px", padding: "20px" }}>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: "600", marginBottom: "20px" }}
                  >
                    {selectedEmailContent?.message?.subject}
                  </Typography>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: selectedEmailContent?.message?.content,
                    }}
                  />
                </div>
              </>
            )}
          </Grid>
          <Grid item xs={4} style={{ height: "60vh" }}>
            <Typography variant="h6">Recipients</Typography>
            {_.chain(previewEmails)
              .values()
              .map((o) => _.get(o, "errors", []))
              .flatten()
              .some((o) => _.size(o) > 0)
              .value() ? (
              <Alert severity="error">Found errors</Alert>
            ) : (
              <></>
            )}
            <List
              style={{ maxHeight: "calc(100% - 40px)", overflowY: "scroll" }}
            >
              {Object.keys(previewEmails).map((emailKey) => {
                const email = previewEmails[emailKey];
                return (
                  <ListItemButton
                    key={emailKey}
                    onClick={() => {
                      setSelectedEmail(emailKey);
                    }}
                    selected={selectedEmail === emailKey}
                  >
                    <ListItemIcon>
                      {!!email?.errors[0] ? (
                        <ErrorIcon sx={{ color: "red" }} />
                      ) : (
                        <CheckCircleIcon />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={email?.message?.recipient || email?.errors[0]}
                      //@ts-ignore
                      secondary={
                        !!email?.errors[0]
                          ? // @ts-ignore
                            _.chain(email).get("errors", [])?.join(", ").value()
                          : "No errors found"
                      }
                    />
                  </ListItemButton>
                );
              })}
            </List>
          </Grid>
        </Grid>
        {/* </Box> */}
      </DialogContent>
      <DialogActions
        style={{
          borderTop: "1px solid rgba(0,0,0,.2)",
          paddingTop: "15px",
          paddingBottom: "15px",
        }}
      >
        <Divider style={{ marginBottom: "10px" }} />
        <Button
          variant="outlined"
          style={{ float: "left", textTransform: "none" }}
          onClick={() => {
            setPreviewEmails({});
            setTopLevelErrors({});
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          style={{ float: "right", textTransform: "none" }}
          onClick={async () => {
            try {
              setEmailLoading(true);
              const accessToken = await getAccessTokenSilently();

              //@ts-ignore
              const resp = await fetch(sendUrl, {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
                method: "POST",
                body: formData,
              });

              setEmailLoading(false);

              const responseText = await new Response(resp?.body).text();
              let responseBody = {};
              if (!_.isNil(responseText) && !_.isEmpty(responseText)) {
                responseBody = JSON.parse(responseText);
              }
              if (
                _.has(responseBody, "error") &&
                _.has(responseBody, "status")
              ) {
                context?.setEmailMessage({
                  code: MessageStatus.ERROR,
                  //@ts-ignore
                  message: JSON.stringify(responseBody?.error?.message?.client),
                });
              } else {
                context?.setEmailMessage({
                  code: MessageStatus.SUCCESS,
                  message: JSON.stringify("Email sent successfully"),
                });
                navigate("/campaigns");
              }
            } catch (error) {
              console.log(error);
              setEmailLoading(false);
              context?.setEmailMessage({
                code: MessageStatus.ERROR,
                message: JSON.stringify(error),
              });
            }
          }}
          disabled={
            _.isNil(emailConfig) ||
            _.eq(emailConfig, "none") ||
            !_.isEmpty(topLevelErrors)
          }
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
}
