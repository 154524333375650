import { useEffect, useState } from "react";
import { Base64 } from "js-base64";
import _ from "lodash";
import { Typography, Grid, Paper } from "@mui/material";

export default function TextViewer(props: {
  fileType: string;
  fileName: string;
  fileData: string;
}) {
  const { fileType, fileName, fileData } = props;

  const [text, setText] = useState<string>("");

  useEffect(() => {
    if (
      !_.isNil(fileData) &&
      Base64.isValid(
        _.chain(fileData).split(";").last().split(",").last().value()
      )
    ) {
      setText(
        Base64.decode(
          _.chain(fileData).split(";").last().split(",").last().value()
        )
      );
    }
  }, [fileData, setText]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        overflow: "scroll",
      }}
    >
      <Grid container direction="column" alignItems="left" justifyContent="top">
        <Grid item>
          <Typography>{text}</Typography>
        </Grid>
      </Grid>
    </div>
  );
}
