import { useEffect, useState } from "react";
import _ from "lodash";
import {
  TextViewer,
  HtmlViewer,
  CsvViewer,
  ImageViewer,
  PdfViewer,
  MsDocViewer,
  UnsupportedViewer,
} from "./drivers";
import { Grid, CircularProgress } from "@mui/material";

export default function FileViewer(props: {
  fileType: string;
  fileName: string;
  fileData: string;
  fileKey: string;
  graphMsgId?: string | undefined;
  attachmentId?: string | undefined;
  loading: boolean;
  fetchFileDataError: any;
}) {
  const { loading, fetchFileDataError } = props;

  const getDriver = (p: any) => {
    // console.log({ ft: p.fileType })
    switch (p.fileType) {
      case "text/html": {
        return HtmlViewer;
      }
      case "text/plain": {
        return TextViewer;
      }
      case "text/csv": {
        return CsvViewer;
      }
      case "image/jpg":
      case "image/jpeg":
      case "image/gif":
      case "image/bmp":
      case "image/png": {
        return ImageViewer;
      }
      case "application/pdf": {
        return PdfViewer;
      }

      case "pptx":
      case "application/vnd.ms-powerpoint":
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      case "xls":
      case "xlsx":
      case "application/xlsx":
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      case "application/vnd.ms-excel":
      case "application/vnd.oasis.opendocument.text":
      case "application/msword":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      case "application/octet-stream":
      case "docx": {
        return MsDocViewer;
      }
      // case 'mp3': {
      //   return AudioViewer;
      // }
      // case 'webm':
      // case 'mp4': {
      //   return VideoViewer;
      // }
      // case 'wexbim': {
      //   return XBimViewer;
      // }
      default: {
        return UnsupportedViewer;
      }
    }
  };

  const Driver = getDriver(props);

  return (
    <div className="pg-viewer-wrapper" style={{ height: "100%" }}>
      <div className="pg-viewer" id="pg-viewer" style={{ height: "100%" }}>
        {loading ? (
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "50%" }}
          >
            <Grid item>
              <CircularProgress color="inherit" />
            </Grid>
          </Grid>
        ) : null}
        {!loading && _.isNil(fetchFileDataError) ? <Driver {...props} /> : null}
      </div>
    </div>
  );
}
