import React, { useContext, useEffect } from "react";
import "./App.css";
import Typography from "@mui/material/Typography";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import IndividualView, { getPageTitle, getSubTitle } from "./individualView";
import { Link } from "react-router-dom";
import { apiURL } from "./consts";
import SearchIcon from "@mui/icons-material/Search";
import { GetHeaderText, GetSubheaderText } from "./DatagridFunctions";
import { useQuery } from "@apollo/client";
import { GET_HISTORIES } from "./graphql-ops";
import ListAltIcon from "@mui/icons-material/ListAlt";
import _ from "lodash";
import { GetListIcon } from "./icons";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { RefetchContext } from "./refetchProvider";
import MenuIcon from "@mui/icons-material/Menu";
import { parse } from "path";

enum ViewEnum {
  Today = "Today",
  Upcoming = "Upcoming",
  PastDue = "Past Due",
}

export default function Search(props: {
  //setShowDetailView: (value: React.SetStateAction<any>) => void;
}) {
  // const { setShowDetailView } = props;
  const { user, getAccessTokenSilently } = useAuth0();

  const [searchResults, setSearchResults] = React.useState<any[]>([]);
  const [selectedRow, setSelectedRow] = React.useState<any>(undefined);
  const [index, setIndex] = React.useState(0);

  const context = useContext(RefetchContext);
  const setIsMobileOpen = context?.setIsMobileMenuOpen;

  const currentWorkspace = context?.currentWorkspace;

  const isMobile = useMediaQuery("(max-width:600px)");

  const { loading, error, data } = useQuery(GET_HISTORIES, {
    variables: {
      userId: user?.sub,
    },
    // pollInterval: 10000,
  });

  const search = async (searchQuery: string) => {
    try {
      const accessToken = await getAccessTokenSilently();

      if (searchQuery.length > 2) {
        await fetch(
          `${apiURL}row?q=${searchQuery}&workspaceId=${currentWorkspace?._id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
          .then((res) => res.json())
          .then((result) => {
            setSearchResults(result || []);
            if (result.length > 0 && !isMobile) setSelectedRow(result[0]);
          });
      } else {
        setSearchResults([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const debouncedSearch = _.debounce(search, 500);

  const handleKeyUp = (event: any) => {
    if (
      event.key === "Enter" ||
      event.key.toLowerCase() === "arrowup" ||
      event.key.toLowerCase() === "arrowdown"
    ) {
      event.preventDefault();
    } else {
      debouncedSearch(event.target.value);
    }
  };

  useEffect(() => {
    const handleKeyPress = (event: any) => {
      if (event.key.toLowerCase() === "arrowup") {
        // Get the current index from the URL or set it to 0 if not found
        if (index !== 0) {
          setIndex(index - 1);
          setSelectedRow(searchResults[index - 1]);
        }
      }
      if (event.key.toLowerCase() === "arrowdown") {
        if (index !== searchResults?.length - 1) {
          setIndex(index + 1);
          setSelectedRow(searchResults[index + 1]);
        }
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [searchResults, index]);

  let parsedRow = {};

  try {
    parsedRow = JSON.parse(selectedRow?.rowObject || "{}");
    parsedRow = {
      ...parsedRow,
      _id: selectedRow?._id,
      merc_viewId: selectedRow?.viewId,
    };
  } catch (error) {
    console.log(error);
  }

  return (
    <>
      <div
        style={{
          height: "56px",
          padding: "5px 30px",
          borderBottom: "1px solid rgb(239, 241, 244)",
          display: "flex",
          alignItems: "center",
          marginBottom: "5px",
        }}
      >
        {!!isMobile && (
          <Grid item>
            <IconButton
              onClick={() => !!setIsMobileOpen && setIsMobileOpen(true)}
            >
              <MenuIcon />
            </IconButton>
          </Grid>
        )}
        <InputBase
          // id="outlined-multiline-flexible"
          placeholder="Search..."
          // @ts-ignore
          //ref={noteTextBoxRef}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          autoFocus
          multiline
          maxRows={10}
          sx={{
            width: "100%",
            backgroundColor: "rgb(239, 241, 244)",
            height: "36px",
            padding: "8px 20px",
            borderRadius: "5px",
          }}
          style={isMobile ? { marginLeft: "20px" } : {}}
          //value={searchQuery}
          // onChange={(e) => {
          //   setSearchQuery(e.target.value);
          // }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
            }
          }}
          onKeyUp={handleKeyUp}
        />
      </div>
      {searchResults?.length === 0 && data?.histories?.length > 0 && (
        <div
          style={{
            padding: "20px 30px",
          }}
          key={`searchRes-${data?.histories[0]?.rowId?._id}`}
        >
          <Typography style={{ marginBottom: "20px", color: "rgba(0,0,0,.6)" }}>
            Recent Records
          </Typography>
          <List sx={{ padding: "0px" }}>
            {data?.histories
              ?.filter((his: any) => !!his?.rowId)
              .slice(0, 6)
              ?.map((history: any) => {
                try {
                  const rowObj = JSON.parse(history?.rowId?.rowObject || {});
                  const columns = history?.rowId?.viewId?.columns;

                  return (
                    <ListItem
                      key={`${rowObj?._id}-recent`}
                      style={{
                        backgroundColor: "white",
                        padding: "0px",
                        width: "100%",
                        // marginBottom: "10px",
                        // padding: "5px 10px",
                      }}
                    >
                      <Link
                        to={`/list/${history?.rowId?.viewId?._id}/row/${
                          history?.rowId?._id || rowObj?._id || rowObj?.id
                        }`}
                        style={{ width: "100%" }}
                      >
                        <ListItemButton>
                          <ListItemIcon>
                            <ListAltIcon
                              sx={{
                                // float: "left",
                                // marginRight: "10px",
                                // marginLeft: "10px",
                                color: "rgba(0,0,0,.6)",
                                width: "20px",
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={GetHeaderText(rowObj, columns)}
                            secondary={GetSubheaderText(rowObj, columns)}
                          />
                        </ListItemButton>
                      </Link>
                    </ListItem>
                  );
                } catch (error) {
                  console.log(error);
                  return <></>;
                }
              })}
          </List>
        </div>
      )}
      {isMobile && !!selectedRow?._id && (
        <>
          <IconButton
            sx={{ marginLeft: "5px" }}
            onClick={() => setSelectedRow(undefined)}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <IndividualView
            curRowId={selectedRow?._id}
            viewId={selectedRow?.viewId}
            parsedRow={parsedRow}
            deleteRow={() => {
              setSearchResults(
                searchResults.filter((res) => res?._id !== selectedRow?._id)
              );
            }}
            mapDisplay={true}
          />
        </>
      )}
      {searchResults?.length > 0 && !(isMobile && !!selectedRow?._id) && (
        <>
          <Grid container spacing={1} key={"recent"}>
            <Grid
              item
              xs={isMobile ? 12 : 4}
              style={{ height: "calc(100vh - 56px)", overflowY: "scroll" }}
            >
              <List>
                {searchResults?.length > 0 &&
                  searchResults?.map((result) => {
                    const parsedRow = {
                      ...JSON.parse(result.rowObject),
                      _id: result._id,
                      merc_viewId: result.viewId,
                    };

                    return (
                      <ListItem
                        style={{
                          backgroundColor: "white",
                          padding: "2px 15px",
                          // marginBottom: "10px",
                          // padding: "5px 10px",
                        }}
                        key={result._id}
                      >
                        <ListItemButton
                          onClick={() => {
                            setSelectedRow(result);
                          }}
                          selected={result?._id === selectedRow?._id}
                          sx={{
                            padding: "0px 10px",
                          }}
                        >
                          <ListItemIcon>
                            {GetListIcon(
                              result?.viewIcon?.fileString,
                              result?.viewIcon?.color
                            )}
                          </ListItemIcon>
                          <ListItemText
                            primary={GetHeaderText(parsedRow, result.columns)}
                            secondary={GetSubheaderText(
                              parsedRow,
                              result.columns
                            )}

                            //onClick={() => setShowDetailView(parsedRow)}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
              </List>
              <Typography
                style={{
                  padding: "10px 37px",
                  color: "rgba(0,0,0,.7)",
                  position: "sticky",
                  bottom: "0px",
                  backgroundColor: "#fff",
                  width: "100%",
                }}
              >
                {searchResults?.length > 0 && `${searchResults.length} results`}{" "}
              </Typography>
            </Grid>
            {!isMobile && (
              <Grid
                item
                xs={8}
                sx={{
                  height: "calc(100vh - 101px)",
                  overflowY: "scroll",
                }}
              >
                {searchResults?.length > 0 && selectedRow?._id && (
                  <IndividualView
                    curRowId={selectedRow?._id}
                    viewId={selectedRow?.viewId}
                    key={selectedRow?._id}
                    parsedRow={parsedRow}
                    deleteRow={() => {
                      setSearchResults(
                        searchResults.filter(
                          (res) => res._id !== selectedRow._id
                        )
                      );
                    }}
                    hideDetails={true}
                  />
                )}
              </Grid>
            )}
          </Grid>
        </>
      )}
    </>
  );
}
