import { useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Divider,
  Grid,
  Icon,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { GET_CAMPAIGNS } from "./graphql-ops";
import { Link, useNavigate } from "react-router-dom";
import RefreshIcon from "@mui/icons-material/Refresh";
import { CustomSupportText, CustomButton } from "./PersonalDetails";
import { EmailButton } from "./emailSetup";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Signature from "./signature";
import { useContext, useEffect, useRef, useState } from "react";
import { modalStyle } from "./displayFiles";
import { StyledTextarea } from "./individualView";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { apiURL } from "./consts";
import { set } from "lodash";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { RefetchContext } from "./refetchProvider";
import MenuIcon from "@mui/icons-material/Menu";

export default function EmailCampaigns() {
  const { user, getAccessTokenSilently } = useAuth0();

  const [successMessage, setSuccessMessage] = useState<string>();
  const [sendgridModalOpen, setSendgridModalOpen] = useState(false);

  const [sendgridEmail, setSendgridEmail] = useState<string>("");
  const [sendgridStreetAddress, setSendgridStreetAddress] =
    useState<string>("");
  const [sendgridCity, setSendgridCity] = useState<string>("");
  const [sendgridState, setSendgridState] = useState<string>("");
  const [sendgridZip, setSendgridZip] = useState<string>("");

  const [isSetupComplete, setIsSetupCompleted] = useState(
    localStorage.getItem("sendgridSetupComplete")
  );

  const context = useContext(RefetchContext);

  const setIsMobileOpen = context?.setIsMobileMenuOpen;

  const isMobile = useMediaQuery("(max-width:600px)");

  const inputRef = useRef(null);

  const { data, loading, error, refetch } = useQuery(GET_CAMPAIGNS, {
    variables: {
      query: {
        auth0Sub: user?.sub,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const navigate = useNavigate();

  useEffect(() => {
    let autocomplete: any;

    if (inputRef.current) {
      // Initialize Google Places Autocomplete
      autocomplete = new google.maps.places.Autocomplete(inputRef.current, {
        types: ["address"],
      });

      // Event listener to get selected value
      autocomplete.addListener("place_changed", async () => {
        const place = autocomplete.getPlace();
        setSendgridStreetAddress(
          `${place.address_components[0].long_name} ${place.address_components[1].long_name}`
        );
        setSendgridCity(place.address_components[3].long_name);
        setSendgridState(place.address_components[5].short_name);
        setSendgridZip(place.address_components[7].long_name);
      });
    }

    return () => {
      // Remove event listener on cleanup
      if (autocomplete) {
        google.maps.event.clearInstanceListeners(autocomplete);
      }
    };
  }, [inputRef.current]);

  if (loading) return <div>Loading...</div>;

  return (
    <div style={{ width: "85%", margin: "auto", marginTop: "40px" }}>
      <IconButton onClick={() => !!setIsMobileOpen && setIsMobileOpen(true)}>
        <MenuIcon />
      </IconButton>
      <Alert
        severity="warning"
        sx={{ marginTop: "10px", marginBottom: "10px" }}
      >
        {" "}
        This feature is in beta, please reach out with any questions or issues.{" "}
      </Alert>
      <Modal
        open={sendgridModalOpen}
        onClose={() => setSendgridModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          style={{
            padding: "32px !important",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            maxWidth: "400px",
            height: "fit-content",
          }}
          sx={modalStyle}
          className="fileViewsBox"
        >
          <Typography>Mercero E-Blast Verification</Typography>
          <TextField
            placeholder="email"
            value={sendgridEmail}
            onChange={(e) => setSendgridEmail(e.target?.value)}
            label="Email"
          />
          <Divider />
          <TextField
            placeholder="Street Address"
            inputRef={inputRef}
            value={sendgridStreetAddress}
            onChange={(e) => setSendgridStreetAddress(e.target?.value)}
          />
          <TextField
            placeholder="City"
            value={sendgridCity}
            onChange={(e) => setSendgridCity(e.target?.value)}
          />
          <TextField
            placeholder="State"
            value={sendgridState}
            onChange={(e) => setSendgridState(e.target?.value)}
          />
          <TextField
            placeholder="Zip"
            value={sendgridZip}
            onChange={(e) => setSendgridZip(e.target?.value)}
          />
          <Button
            variant={"contained"}
            startIcon={<CheckBoxIcon />}
            onClick={async () => {
              const accessToken = await getAccessTokenSilently();
              try {
                const res = await fetch(
                  `${apiURL}sendgridInterface/create-sender-identity`,
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify({
                      senderEmail: sendgridEmail,
                      senderAddress: {
                        address: sendgridStreetAddress,
                        city: sendgridCity,
                        state: sendgridState,
                        zip: sendgridZip,
                      },
                    }),
                  }
                );
                setSendgridModalOpen(false);
                alert("Check your email for a verification link");
              } catch (e) {
                setSendgridModalOpen(false);
                console.error(e);
              }
            }}
          >
            Submit Verification
          </Button>
        </Box>
      </Modal>
      <Grid
        container
        justifyContent="space-between"
        alignContent={"center"}
        sx={{ marginBottom: "20px", width: "100%" }}
      >
        <Grid item sx={{ display: "flex", alignItems: "center" }}>
          <Typography>Email Campaigns</Typography>
          <IconButton
            sx={{ marginLeft: "4px" }}
            onClick={() => {
              setIsSetupCompleted("false");
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton onClick={() => refetch()}>
            <RefreshIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          {isSetupComplete !== "true" && (
            <Alert severity="info">
              <AlertTitle>How to set up email campaigns</AlertTitle>
              <Typography variant="h6">
                Step 1: Connect Mercero to Outlook (Or Gmail)
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "left",
                  maxWidth: "100%",
                  marginTop: "16px",
                  marginBottom: "16px",
                  gap: "32px",
                }}
              >
                <div>
                  <EmailButton
                    sx={{
                      width: "100%",
                    }}
                    onClick={() => {
                      const passedState = btoa(
                        JSON.stringify({
                          userId: user?.sub || "",
                          redirectUrl: window.location.href,
                        })
                      );

                      window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=bb956ba8-d315-4ff2-ae56-6327030d0619&response_type=code&redirect_uri=https%3A%2F%2Fcrm.mercero-api.com%2Fapi%2F1%2Fauth%2Fms%2Fcallback&response_mode=query&scope=bb956ba8-d315-4ff2-ae56-6327030d0619%2f.default&state=${passedState}&sso_reload=true&prompt=consent`;
                    }}
                  >
                    <img src="/microsoftLogo.svg" alt="Microsoft Logo" />
                    Connect with Microsoft
                  </EmailButton>
                  {!!user &&
                    !!user["https://crm.mercero-api.com/primaryEmail"] &&
                    user["https://crm.mercero-api.com/primaryEmail"] ===
                      "microsoft" &&
                    !!user[
                      "https://crm.mercero-api.com/primaryEmailAddress"
                    ] && (
                      <CustomSupportText
                        sx={{
                          marginTop: "5px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <CheckCircleOutlineIcon
                          sx={{ color: "#475467", height: "14px" }}
                        />{" "}
                        Email connected:{" "}
                        {
                          user[
                            "https://crm.mercero-api.com/primaryEmailAddress"
                          ]
                        }
                      </CustomSupportText>
                    )}
                </div>
                <div>
                  <EmailButton
                    sx={{ width: "100%" }}
                    onClick={() => {
                      const passedState = btoa(
                        JSON.stringify({
                          userId: user?.sub || "",
                          redirectUrl: window.location.href,
                        })
                      );

                      window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&prompt=consent&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.send%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.readonly%20openid%20email%20profile&state=${passedState}&response_type=code&client_id=802323238228-hk8m6unh82balrabsk948uhu2k1qki4s.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fcrm.mercero-api.com%2Fapi%2F1%2Fauth%2Fgspace%2Fcallback`;
                    }}
                  >
                    <img src="/googleLogo.png" alt="Microsoft Logo" />
                    Connect with Google
                  </EmailButton>
                  {!!user &&
                    !!user["https://crm.mercero-api.com/primaryEmail"] &&
                    user["https://crm.mercero-api.com/primaryEmail"] ===
                      "google" &&
                    !!user[
                      "https://crm.mercero-api.com/primaryEmailAddress"
                    ] && (
                      <CustomSupportText
                        sx={{
                          marginTop: "5px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <CheckCircleOutlineIcon
                          sx={{ color: "#475467", height: "14px" }}
                        />{" "}
                        Email connected
                      </CustomSupportText>
                    )}
                </div>
              </Box>
              <Typography variant="subtitle1">
                For e-blasts under 50 people we send emails directly from your
                outlook. You can even view these emails in your "Sent" folder.
              </Typography>
              <Typography variant="h6" sx={{ marginTop: "12px" }}>
                Step 2: Verify your Email
              </Typography>
              <Button
                variant="contained"
                onClick={() => setSendgridModalOpen(true)}
                sx={{
                  marginTop: "16px",
                  marginBottom: "16px",
                  textTransform: "none",
                }}
              >
                Verify Email
              </Button>
              <Typography variant="subtitle1">
                For emails to over 50 people we use Sendgrid so you avoid spam
                filters.
              </Typography>
              <Typography variant="h6" sx={{ marginTop: "12px" }}>
                Step 3: Set up your signature
              </Typography>
              <Signature setSuccessMessage={setSuccessMessage} />
              <Divider sx={{ marginBottom: "12px" }} />
              <Button
                variant="contained"
                onClick={() => {
                  localStorage.setItem("sendgridSetupComplete", "true");
                  setIsSetupCompleted("true");
                }}
              >
                Done with setup
              </Button>
            </Alert>
          )}
        </Grid>
      </Grid>

      <TableContainer component={Paper} sx={{ width: "100%" }}>
        <Table sx={{ mWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ whiteSpace: "nowrap" }}>Subject</TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>Sends</TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>Delivered</TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>Open Rate</TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>Opens</TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>Click Rate</TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>Clicks</TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>Unsubscribes</TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>Status</TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>Sent Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!!data?.sendgridcampaigns &&
              [...data?.sendgridcampaigns]
                ?.sort((a: any, b: any) => {
                  return (
                    new Date(b?.createdAt).getTime() -
                    new Date(a?.createdAt).getTime()
                  );
                })
                ?.map((campaign: any) => {
                  const openCount = campaign?.contacts?.reduce(
                    (acc: number, contact: any) => {
                      if (contact?.open > 0) {
                        return acc + 1;
                      }
                      return acc;
                    },
                    0
                  );

                  const deliveredCount = campaign?.contacts?.reduce(
                    (acc: number, contact: any) => {
                      if (contact?.delivered) {
                        return acc + 1;
                      }
                      return acc;
                    },
                    0
                  );

                  const openRate =
                    (openCount / campaign?.contacts?.length) * 100 || 0;

                  const clickCount = campaign?.contacts?.reduce(
                    (acc: number, contact: any) => {
                      if (contact?.click > 0) {
                        return acc + 1;
                      }
                      return acc;
                    },
                    0
                  );

                  const clickRate =
                    (clickCount / campaign?.contacts?.length) * 100 || 0;

                  const unsubscribeCount = campaign?.contacts?.reduce(
                    (acc: number, contact: any) => {
                      if (contact?.unsubscribe) {
                        return acc + 1;
                      }
                      return acc;
                    },
                    0
                  );

                  return (
                    <TableRow>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        <Link
                          to={`/campaign/${campaign?._id}`}
                          style={{
                            fontWeight: 500,
                            color: "rgb(21, 112, 239)",
                          }}
                        >
                          {campaign?.subject || "No Subject"}
                        </Link>
                      </TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        {campaign?.rowIds?.length}
                      </TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        {deliveredCount}
                      </TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        {openRate.toFixed(0)}%
                      </TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        {openCount}
                      </TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        {clickRate.toFixed(0)}%
                      </TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        {clickCount}
                      </TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        {unsubscribeCount}
                      </TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        {campaign.processed ? "Sent" : "Sending"}
                      </TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        {new Date(campaign?.createdAt).toLocaleDateString()}{" "}
                        {new Date(campaign?.createdAt).toLocaleTimeString(
                          "en-US",
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true, // or false if you prefer 24-hour time format
                          }
                        )}{" "}
                      </TableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
