import React, { useEffect, useState } from "react";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from "@mui/lab";
import { Avatar, Button, Typography, styled } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { CustomParagraph, CustomSupportText } from "./PersonalDetails";
import { Link } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { apiURL } from "./consts";
import { useAuth0 } from "@auth0/auth0-react";
import _, { set } from "lodash";
import { upload } from "@testing-library/user-event/dist/upload";

export const SecondaryButton = styled(Button)({
  display: "flex",
  padding: "10px var(--spacing-xl, 16px)",
  justifyContent: "center",
  alignItems: "center",
  gap: "var(--spacing-sm, 6px)",
  alignSelf: "stretch",
  borderRadius: "var(--radius-md, 8px)",
  border: "1px solid var(--blue-dark-700, #004EEB)",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",

  // Added text styles
  color: "var(--blue-600, #1570EF)",
  fontFamily: "Inter",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "24px", // 150%
  textTransform: "none",
});

export const steps = [
  {
    label: "Profile Details",
    description: "Please provide your name and email",
    url: "/welcome/personal-details",
  },
  {
    label: "Create your workspace",
    description: "Workspaces are used to collaborate.",
    url: "/welcome/create-workspace",
  },
  {
    label: "Connect Email",
    description: "Sync emails to contacts and send emails from Mercero",
    url: "/welcome/email",
  },
  {
    label: "Import Database",
    description: "Upload a CSV or Excel file to get your database started",
    url: "/welcome/import",
  },
];

function CustomTimeline(props: { step: number }) {
  const { step } = props;

  const activeStep = step;

  return (
    <Grid
      xs={4}
      style={{
        minHeight: "100vh",
        background: "var(--blue-600, #1570EF)",
        padding: "32px",
      }}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"space-between"}
    >
      <div>
        <img
          src="/Mercero Logo - White.svg"
          alt="Mercero logo"
          style={{ height: "24px", marginBottom: "80px" }}
        />
        <Timeline
          position="right"
          sx={{ padding: "6px", "&:before": { display: "none" } }}
        >
          {steps.map((step, index) => (
            <TimelineItem
              key={step.label}
              sx={{ "&:before": { display: "none" } }}
            >
              <TimelineSeparator>
                {index === activeStep ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="33"
                    viewBox="0 0 32 33"
                    fill="none"
                    style={{
                      boxShadow: "0px 0px 0px 4px rgba(255, 255, 255, 0.16)",
                      borderRadius: "999px",
                    }}
                  >
                    <path
                      d="M1 16.8394C1 8.55508 7.71573 1.83936 16 1.83936C24.2843 1.83936 31 8.55508 31 16.8394C31 25.1236 24.2843 31.8394 16 31.8394C7.71573 31.8394 1 25.1236 1 16.8394Z"
                      fill="#F5FAFF"
                    />
                    <path
                      d="M1 16.8394C1 8.55508 7.71573 1.83936 16 1.83936C24.2843 1.83936 31 8.55508 31 16.8394C31 25.1236 24.2843 31.8394 16 31.8394C7.71573 31.8394 1 25.1236 1 16.8394Z"
                      stroke="#175CD3"
                      stroke-width="2"
                    />

                    <circle cx="16" cy="16.8394" r="5" fill="#1570EF" />
                  </svg>
                ) : index < activeStep ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="33"
                    viewBox="0 0 32 33"
                    fill="none"
                  >
                    <path
                      d="M1 16.8394C1 8.55508 7.71573 1.83936 16 1.83936C24.2843 1.83936 31 8.55508 31 16.8394C31 25.1236 24.2843 31.8394 16 31.8394C7.71573 31.8394 1 25.1236 1 16.8394Z"
                      fill="#F5FBFF"
                    />
                    <path
                      d="M1 16.8394C1 8.55508 7.71573 1.83936 16 1.83936C24.2843 1.83936 31 8.55508 31 16.8394C31 25.1236 24.2843 31.8394 16 31.8394C7.71573 31.8394 1 25.1236 1 16.8394Z"
                      stroke="#175CD3"
                      stroke-width="2"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M22.7953 10.6928L13.2487 19.9062L10.7153 17.1995C10.2487 16.7595 9.51534 16.7328 8.982 17.1062C8.462 17.4928 8.31534 18.1728 8.63534 18.7195L11.6353 23.5995C11.9287 24.0528 12.4353 24.3328 13.0087 24.3328C13.5553 24.3328 14.0753 24.0528 14.3687 23.5995C14.8487 22.9728 24.0087 12.0528 24.0087 12.0528C25.2087 10.8262 23.7553 9.74615 22.7953 10.6795V10.6928Z"
                      fill="#1570EF"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="33"
                    viewBox="0 0 32 33"
                    fill="none"
                  >
                    <path
                      d="M1 16.8394C1 8.55508 7.71573 1.83936 16 1.83936C24.2843 1.83936 31 8.55508 31 16.8394C31 25.1236 24.2843 31.8394 16 31.8394C7.71573 31.8394 1 25.1236 1 16.8394Z"
                      stroke="#84CAFF"
                      stroke-width="2"
                    />
                    <circle cx="16" cy="16.8394" r="5" fill="#84CAFF" />
                  </svg>
                )}

                {index !== steps.length - 1 && (
                  <TimelineConnector sx={{ bgcolor: "#fff", width: 2 }} />
                )}
              </TimelineSeparator>
              <TimelineContent
                sx={{ py: "12px", px: 2, mt: "-6px", marginBottom: "32px" }}
              >
                <Link to={step?.url || "#"}>
                  <Typography variant="h6" className="timelineHeader">
                    {step.label}
                  </Typography>
                </Link>
                <Typography className="timelineSubheader">
                  {step.description}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </div>
      <Grid container justifyContent={"space-between"}>
        <Grid>
          <CustomParagraph sx={{ color: "#fff !important" }}>
            &copy; Mercero 2023
          </CustomParagraph>
        </Grid>
        <Grid>
          <CustomParagraph
            sx={{
              color: "#fff !important",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="12"
              viewBox="0 0 16 12"
              fill="none"
              style={{ marginRight: "8px" }}
            >
              <path
                d="M1.3335 2.66699L6.77678 6.47729C7.21756 6.78583 7.43795 6.94011 7.67767 6.99986C7.88943 7.05265 8.1109 7.05265 8.32265 6.99986C8.56238 6.94011 8.78277 6.78583 9.22355 6.47729L14.6668 2.66699M4.5335 11.3337H11.4668C12.5869 11.3337 13.147 11.3337 13.5748 11.1157C13.9511 10.9239 14.2571 10.618 14.4488 10.2416C14.6668 9.81382 14.6668 9.25376 14.6668 8.13366V3.86699C14.6668 2.74689 14.6668 2.18683 14.4488 1.75901C14.2571 1.38269 13.9511 1.07673 13.5748 0.884979C13.147 0.666992 12.5869 0.666992 11.4668 0.666992H4.5335C3.41339 0.666992 2.85334 0.666992 2.42552 0.884979C2.04919 1.07673 1.74323 1.38269 1.55148 1.75901C1.3335 2.18683 1.3335 2.74689 1.3335 3.86699V8.13366C1.3335 9.25376 1.3335 9.81382 1.55148 10.2416C1.74323 10.618 2.04919 10.9239 2.42552 11.1157C2.85334 11.3337 3.41339 11.3337 4.5335 11.3337Z"
                stroke="#E9D7FE"
                stroke-width="1.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <a href="mailto: info@mercero.com" style={{ color: "#fff" }}>
              info@mercero.com
            </a>
          </CustomParagraph>
        </Grid>
      </Grid>
    </Grid>
  );
}

export const AvatarUpload = (props: {
  title: string;
  subtitle?: string;
  src?: string;
  uploadFiles?: any;
}) => {
  const { title, subtitle, src } = props;

  const [newFile, setNewFile] = useState<File | undefined>(undefined);

  const [avatarSrc, setAvatarSrc] = useState<string | undefined>(src);

  useEffect(() => {
    if (newFile) {
      const fileSrc = URL.createObjectURL(newFile);
      setAvatarSrc(fileSrc);

      // Cleanup - revoke the object URL when the component unmounts or file changes
      return () => {
        URL.revokeObjectURL(fileSrc);
      };
    }
  }, [newFile]);

  return (
    <div style={{ width: "100%" }}>
      <CustomParagraph
        sx={{
          marginBottom: "5px",
          color: "#344054 !important",
          fontWeight: "600 !important",
        }}
      >
        {title}
      </CustomParagraph>
      {!!subtitle && (
        <CustomSupportText
          sx={{
            marginBottom: "20px",
          }}
        >
          {subtitle}
        </CustomSupportText>
      )}
      <Grid container>
        <Grid xs={2.7} sx={{ paddingRight: "20px" }}>
          <Avatar sx={{ width: "64px", height: "64px" }} src={avatarSrc} />
        </Grid>
        <Grid
          xs={9.3}
          sx={{
            display: "flex",
            padding: "var(--spacing-xl, 16px) var(--spacing-3xl, 24px)",
            flexDirection: "column",
            alignItems: "center",
            gap: "var(--spacing-xs, 4px)",
            alignSelf: "stretch",
            borderRadius: "var(--radius-xl, 12px)",
            border: "1px solid var(--colors-border-border-secondary, #EAECF0)",
            background: "var(--colors-background-bg-primary, #FFF)",
          }}
        >
          <FileUpload
            fileTypes="SVG, PNG, JPG or GIF (max. 800x400px)"
            setNewFile={setNewFile}
            uploadFiles={props.uploadFiles}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export const FileUpload = (props: {
  fileTypes: string;
  setNewFile?: any;
  uploadFiles?: (formData: any) => void;
}) => {
  const { fileTypes, setNewFile, uploadFiles } = props;

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const [uploading, setUploading] = React.useState(false);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    console.log(acceptedFiles);
    const uploadFunction = async () => {
      let formData = new FormData();
      for (const f of acceptedFiles) {
        formData.append("file", f);
      }

      try {
        !!uploadFiles && (await uploadFiles(formData));
      } catch (e) {
        console.log(e);
      }

      !!setNewFile && setNewFile(formData.get("file") as File);
      setUploading(false);
    };

    if (_.size(acceptedFiles)) {
      setUploading(true);
      uploadFunction();
    }
  }, [acceptedFiles, getAccessTokenSilently, setNewFile, uploadFiles]);

  return (
    <div
      {...getRootProps({ className: "dropzone" })}
      style={{
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          padding: "10px 10px 5px 10px",
          border: "1px solid #EAECF0",
          borderRadius: "var(--radius-md, 8px)",
          marginBottom: "12px",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
        >
          <path
            d="M6.6665 14.1727L9.99984 10.8394M9.99984 10.8394L13.3332 14.1727M9.99984 10.8394V18.3394M16.6665 14.7917C17.6844 13.9511 18.3332 12.6793 18.3332 11.256C18.3332 8.72472 16.2811 6.67269 13.7498 6.67269C13.5677 6.67269 13.3974 6.57769 13.3049 6.4208C12.2182 4.57672 10.2119 3.33936 7.9165 3.33936C4.46472 3.33936 1.6665 6.13758 1.6665 9.58936C1.6665 11.3111 2.36271 12.8703 3.48896 14.0006"
            stroke="#344054"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <input {...getInputProps()} />
      <CustomSupportText>
        <span style={{ color: "#1570EF", fontWeight: 600 }}>
          Click to Upload
        </span>{" "}
        or Drag and Drop
      </CustomSupportText>
      <CustomSupportText
        sx={{
          textAlign: "center",
          fontSize: "12px",
        }}
      >
        {fileTypes}
      </CustomSupportText>
    </div>
  );
};

export const BottomSteps = (props: { step: number }) => {
  const { step } = props;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "12px",
        width: "100%",
        padding: "61px 0px 96px 0px",
        maxWidth: "360px",
        margin: "0px auto",
      }}
    >
      {steps.map((stepNumber, index) => {
        if (index === step) {
          return (
            <div
              style={{
                backgroundColor: "#1570EF",
                height: "8px",
                borderRadius: "999999px",
                flex: "1 0 0",
              }}
            />
          );
        }
        return (
          <div
            style={{
              backgroundColor: "#EAECF0",
              height: "8px",
              borderRadius: "999999px",
              flex: "1 0 0",
            }}
          />
        );
      })}
    </div>
  );
};

export default CustomTimeline;
