import { useEffect, useState } from "react";
import { Base64 } from "js-base64";
import _ from "lodash";
import { parse } from "csv-parse/browser/esm";

import { customAlphabet } from "nanoid";

import Box from "@mui/material/Box";
import {
  DataGridPremium,
  GridToolbar,
  useGridApiRef,
} from "@mui/x-data-grid-premium";

const nanoid = customAlphabet("1234567890abcdefghijklmnopqrtsuvwxyz", 24);

export default function CsvViewer(props: {
  fileType: string;
  fileName: string;
  fileData: string;
}) {
  const { fileData } = props;

  const [data, setData] = useState<any>({
    columns: [],
    rows: [],
    initialState: {},
  });
  const [initialState, setInitialState] = useState<any>(null);
  const apiRef = useGridApiRef();

  useEffect(() => {
    if (
      !_.isNil(fileData) &&
      Base64.isValid(
        _.chain(fileData).split(";").last().split(",").last().value()
      )
    ) {
      parse(
        Base64.decode(
          _.chain(fileData).split(";").last().split(",").last().value()
        ),
        {},
        (err, data) => {
          //@ts-ignore
          const columns = _.chain(data)
            .first()
            //@ts-ignore
            .reduce(
              (r: any, c: any) => {
                let column = {};
                _.set(column, "field", _.toLower(c));
                _.set(column, "headerName", c);
                _.set(column, "hide", false);
                // _.set(column,'editable',false)
                _.set(column, "width", 150);
                r.push(column);
                return r;
              },
              [
                {
                  field: "id",
                  hide: true,
                  // editable: false
                },
              ]
            )
            .value();

          const headers = _.map(columns, (c, i) => ({
            field: _.get(c, "field"),
            i,
          }));

          //@ts-ignore
          let columnVisibilityModel = _.chain(data)
            .first()
            //@ts-ignore
            .reduce((r, c) => _.set(r, _.toLower(c), true), {})
            .value();

          columnVisibilityModel = {
            id: false,
            ...columnVisibilityModel,
          };

          const rows = _.chain(data)
            .tail()
            .map((r) =>
              _.chain(headers)
                .reduce((buff, h) => {
                  if (_.get(h, "field") == "id") {
                    _.set(buff, "id", nanoid());
                  } else {
                    _.set(
                      buff,
                      _.get(h, "field"),
                      _.get(r, _.chain(h).get("i").toInteger().value() - 1)
                    );
                  }
                  return buff;
                }, {})
                .value()
            )
            .value();

          setData({
            columns,
            initialState: {
              columns: {
                columnVisibilityModel,
              },
            },
            rows,
          });

          setInitialState({
            columns: {
              columnVisibilityModel,
            },
            sorting: {
              sortModel: [
                { field: "__row_group_by_columns_group__", sort: "asc" },
              ],
            },
          });
        }
      );
    }
  }, [fileData, apiRef, setData, setInitialState]);

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <DataGridPremium
        {...data}
        apiRef={apiRef}
        disableRowSelectionOnClick
        initialState={initialState}
        slots={{ toolbar: GridToolbar }}
        columnVisibilityModel={_.get(
          initialState,
          "columns.columnVisibilityModel",
          {}
        )}
      />
    </Box>
  );
}
