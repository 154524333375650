import React, { useContext } from "react";
import "./App.css";
import Typography from "@mui/material/Typography";
import { Avatar, Box, Button, Grid, TextField, styled } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { apiURL } from "./consts";
import PersonIcon from "@mui/icons-material/Person";
import CustomTimeline, {
  AvatarUpload,
  BottomSteps,
} from "./onboardingTimeline";
import {
  CustomButton,
  CustomParagraph,
  CustomSupportText,
  CustomTextField,
  OnboardingHeader,
  OnboardingSubheader,
} from "./PersonalDetails";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useNavigate } from "react-router-dom";
import { RefetchContext } from "./refetchProvider";

export const EmailButton = styled(Button)({
  borderRadius: "var(--radius-md, 8px)",
  border: "1px solid var(--colors-border-border-primary, #D0D5DD)",
  background: "var(--colors-background-bg-primary, #FFF)",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  display: "flex",
  padding: "10px var(--spacing-xl, 16px)",
  justifyContent: "center",
  alignItems: "center",
  gap: "var(--spacing-lg, 12px)",
  alignSelf: "stretch",
  color: "var(--colors-text-text-secondary-700, #344054)",
  fontFamily: "Inter, sans-serif", // Fallback to sans-serif in case Inter is not available
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "24px", // 150%
  textTransform: "none",
});

export default function EmailSetup(props: {
  // setShowSettings: (value: React.SetStateAction<boolean>) => void;
  // showSettings: boolean;
}) {
  // const { setShowSettings, showSettings } = props;
  const [successMessage, setSuccessMessage] = React.useState<
    string | undefined
  >(undefined);
  const { user, logout, getAccessTokenSilently } = useAuth0();

  const navigate = useNavigate();

  return (
    <Grid container>
      <CustomTimeline step={2} />
      <Grid
        item
        xs={8}
        sx={{
          backgroundImage: "url(/backgroundPattern.png)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center top",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            minHeight: "100vh",
          }}
        >
          <div>
            <img
              src="/logoColor.png"
              alt="Logo Color"
              style={{
                width: "142px",
                margin: "auto",
                display: "block",
                marginTop: "50px",
              }}
            />
            <OnboardingHeader sx={{ marginBottom: "12px", marginTop: "32px" }}>
              Connect your email
            </OnboardingHeader>
            <OnboardingSubheader sx={{ marginBottom: "32px" }}>
              Automatically sync emails with contacts and send emails from
              Mercero.
            </OnboardingSubheader>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                maxWidth: "360px",
                margin: "auto",
                gap: "32px",
              }}
            >
              <div>
                <EmailButton
                  sx={{
                    width: "100%",
                  }}
                  onClick={() => {
                    const passedState = btoa(
                      JSON.stringify({
                        userId: user?.sub || "",
                        redirectUrl: window.location.href,
                      })
                    );

                    window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=bb956ba8-d315-4ff2-ae56-6327030d0619&response_type=code&redirect_uri=https%3A%2F%2Fcrm.mercero-api.com%2Fapi%2F1%2Fauth%2Fms%2Fcallback&response_mode=query&scope=bb956ba8-d315-4ff2-ae56-6327030d0619%2f.default&state=${passedState}&sso_reload=true&prompt=consent`;
                  }}
                >
                  <img src="/microsoftLogo.svg" alt="Microsoft Logo" />
                  Connect with Microsoft
                </EmailButton>
                {!!user &&
                  !!user["https://crm.mercero-api.com/primaryEmail"] &&
                  user["https://crm.mercero-api.com/primaryEmail"] ===
                    "microsoft" &&
                  !!user["https://crm.mercero-api.com/primaryEmailAddress"] && (
                    <CustomSupportText
                      sx={{
                        marginTop: "5px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <CheckCircleOutlineIcon
                        sx={{ color: "#475467", height: "14px" }}
                      />{" "}
                      Email verified
                    </CustomSupportText>
                  )}
              </div>
              <div>
                <EmailButton
                  sx={{ width: "100%" }}
                  onClick={() => {
                    const passedState = btoa(
                      JSON.stringify({
                        userId: user?.sub || "",
                        redirectUrl: window.location.href,
                      })
                    );

                    window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&prompt=consent&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.send%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.readonly%20openid%20email%20profile&state=${passedState}&response_type=code&client_id=802323238228-hk8m6unh82balrabsk948uhu2k1qki4s.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fcrm.mercero-api.com%2Fapi%2F1%2Fauth%2Fgspace%2Fcallback`;
                  }}
                >
                  <img src="/googleLogo.png" alt="Microsoft Logo" />
                  Connect with Google
                </EmailButton>
                {!!user &&
                  !!user["https://crm.mercero-api.com/primaryEmail"] &&
                  user["https://crm.mercero-api.com/primaryEmail"] ===
                    "google" &&
                  !!user["https://crm.mercero-api.com/primaryEmailAddress"] && (
                    <CustomSupportText
                      sx={{
                        marginTop: "5px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <CheckCircleOutlineIcon
                        sx={{ color: "#475467", height: "14px" }}
                      />{" "}
                      Email connected
                    </CustomSupportText>
                  )}
              </div>
              <CustomButton
                variant="outlined"
                style={{ display: "block", marginTop: "10px" }}
                onClick={async () => {
                  try {
                    navigate("/welcome/import");
                  } catch (e) {
                    console.log(e);
                  }
                }}
              >
                Continue
              </CustomButton>
            </Box>
          </div>
          <BottomSteps step={2} />
        </div>
      </Grid>
    </Grid>
  );
}
