import { useEffect, useContext } from "react";
import { useMutation } from "@apollo/client";
import { GET_ALL_VIEWS, UPDATE_VIEW } from "./graphql-ops";
import { SocketIOContext } from "./socketIOProvider";
import _ from "lodash";
import { useAuth0 } from "@auth0/auth0-react";

const useUpsertView = () => {
  const { socket } = useContext(SocketIOContext) as any;

  const { user } = useAuth0();

  const userQuery = { userId: { auth0Sub: user?.sub }, isDeleted: false };

  const updateViewCache = (cache: any, upsertedView: any) => {
    const workspaceId = _.get(upsertedView, "workspaceId");

    if (!_.isNil(upsertedView)) {
      const upsertedViewId = _.chain(upsertedView).get("_id").value();
      const isUpdatedViewDeleted = _.chain(upsertedView)
        .get("isDeleted", false)
        .value();

      const cacheId = cache.identify(upsertedView);

      const updateCache = (payload: any) => {
        const { query, newView } = payload;

        const newViewId = _.chain(newView).get("_id").value();

        const existingViews = cache.readQuery({
          query: GET_ALL_VIEWS,
          variables: { query },
        });

        let upsertedViews = [];

        if (existingViews && existingViews.views) {
          upsertedViews = existingViews.views.map((view: any) =>
            _.chain(view).get("_id").value() === newViewId ? newView : view
          );

          const viewExists = upsertedViews.some(
            (view: any) => _.chain(view).get("_id").value() === newViewId
          );

          if (!viewExists && !isUpdatedViewDeleted) {
            upsertedViews.push(newView);
          }

          if (isUpdatedViewDeleted) {
            upsertedViews = upsertedViews.filter(
              (view: any) => _.chain(view).get("_id").value() !== newViewId
            );
          }
        } else {
          upsertedViews = [newView];
        }

        // cache.writeQuery({
        //   query: GET_ALL_VIEWS,
        //   variables: { query },
        //   data: {
        //     views: upsertedViews,
        //   },
        // });
      };

      updateCache({
        query: { workspaceId, isDeleted: false },
        newView: upsertedView,
      });
    }
  };

  const [updateView, updateViewProps] = useMutation(UPDATE_VIEW, {
    update(cache, { data }) {
      const updatedViewId = data?.updateOneView?._id;

      updateViewCache(cache, data?.updateOneView);

      socket.emit("update-view", updatedViewId);
    },
  });

  return { updateViewCache, updateView, updateViewProps };
};

export default useUpsertView;
