import { useContext, useRef, useState } from "react";
import "./App.css";
import { Button, IconButton } from "@mui/material";
import * as chrono from "chrono-node";
import { useAuth0 } from "@auth0/auth0-react";
import FroalaEditor from "react-froala-wysiwyg";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { apiURL } from "./consts";
import { RefetchContext } from "./refetchProvider";
import Tribute from "tributejs";
import "tributejs/dist/tribute.css";

import { useMutation } from "@apollo/client";
import {
  CREATE_COMMENT_MUTATION,
  UPDATE_ONE_COMMENT,
  CREATE_NOTIFICATION_MUTATION,
} from "./graphql-ops";

export function NewNote(props: {
  showDetailView: any;
  setNotes: any;
  notes: any;
  initialValue?: any;
  setNoteToEdit?: any;
  refetchComments: any;
}) {
  const {
    showDetailView,
    setNotes,
    notes,
    initialValue,
    setNoteToEdit,
    refetchComments,
  } = props;
  const editorRef = useRef<any>(null);

  const [newNote, setNewNote] = useState(initialValue?.comment || "");
  const context = useContext(RefetchContext);

  const [file, setFile] = useState<any[]>([]);
  const { getAccessTokenSilently, user } = useAuth0();
  const [noteMembers, setNoteMembers] = useState<any[]>([]);

  const [createComment] = useMutation(CREATE_COMMENT_MUTATION);
  const [updateComment] = useMutation(UPDATE_ONE_COMMENT);
  const [createNotification] = useMutation(CREATE_NOTIFICATION_MUTATION);

  const tributeRef = useRef<any>(null);

  const theTribute = new Tribute({
    trigger: "@",
    values: context?.workspaceMembers.map((member: any) => ({
      key: member.name,
      value: member.name,
      id: member.auth0Sub,
    })),
    selectTemplate: (item: {
      original: { key: string; value: string; id: string };
    }) => {
      setNoteMembers((prevMembers) => [...prevMembers, item.original]);
      return `<span class="mention" data-id="${item.original.id}">@${item.original.value}</span>&nbsp;`;
    },
    menuItemTemplate: (item: {
      original: { key: string; value: string; id: string };
    }) => {
      return item.original.value;
    },
    noMatchTemplate: () =>
      '<span style="color: #999;">No matching users found</span>',
    lookup: "value",
    fillAttr: "value",
    selectClass: "highlight",
    containerClass: "tribute-container",
    itemClass: "",
    replaceTextSuffix: "",
  });

  const handleModelChange = (model: string) => {
    setNewNote(model);
  };

  const submitComment = async () => {
    let candidateCommentId: any;
    let updatedNotes = notes;

    const comment = editorRef.current.getEditor().html.get() || newNote;

    if (!!initialValue) {
      try {
        const timestamp = new Date();

        const resp: any = await updateComment({
          variables: {
            query: {
              _id: initialValue._id,
            },
            set: {
              comment,
              rowId: {
                link: showDetailView._id || showDetailView["_id"],
              },
              userId: { link: user?.sub },
              updatedByUserId: { link: user?.sub },
              updatedAt: timestamp,
              triggeredBy: "client",
            },
          },
        });

        const insertedComment = resp?.data?.updateOneComment;

        candidateCommentId = insertedComment._id;

        const filterOutEditedNote = notes.filter(
          (note: any) => note._id !== insertedComment._id
        );

        updatedNotes = [
          ...filterOutEditedNote,
          { ...insertedComment, type: "note" },
        ];

        setNoteToEdit && setNoteToEdit(null);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const timestamp = new Date();

        const resp: any = await createComment({
          variables: {
            data: {
              comment,
              rowId: {
                link: showDetailView._id || showDetailView["_id"],
              },
              userId: { link: user?.sub },
              updatedByUserId: { link: user?.sub },
              createdAt: timestamp,
              updatedAt: timestamp,
              isDeleted: false,
              triggeredBy: "client",
            },
          },
        });

        const insertedComment = resp?.data?.insertOneComment;

        candidateCommentId = insertedComment?._id;

        updatedNotes = [...notes, { ...insertedComment, type: "note" }];
      } catch (error) {
        console.log(error);
      }
    }

    const postProcess = async (un: any) => {
      setNotes(un);
      setNewNote("");
      setFile([]);

      const activityTimestamp = new Date();

      for (const noteMember of noteMembers) {
        await createNotification({
          variables: {
            data: {
              initiatedByUserId: { link: user?.sub },
              commentId: { link: candidateCommentId },
              priority: "info",
              userId: { link: noteMember.id },
              read: false,
              triggeredBy: "client",
              isDeleted: false,
            },
          },
        });

        const formData = new FormData();
        formData.append("commentBody", comment);
        if (user?.sub) {
          formData.append("commentorId", user.sub);
        }
        formData.append("commenteeId", noteMember.id);

        const accessToken = await getAccessTokenSilently();

        await fetch(`${apiURL}email/comment`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          body: formData,
        }).catch((err) => {
          console.log({ err });
        });
      }

      await refetchComments();
    };

    if (file && file[0] && candidateCommentId) {
      const formData = new FormData();
      formData.append("file", file[0]);

      const accessToken = await getAccessTokenSilently();

      await fetch(`${apiURL}comment/?commentId=${candidateCommentId}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      })
        .then((res) => res.json())
        .then((result) => {
          postProcess(updatedNotes);
        });
    } else {
      postProcess(updatedNotes);
    }
  };

  const config = {
    placeholderText: "Add a note...",
    key: "te1C2sE7D7D6G4B4E4C3kOPVe1f1d1Le1b1D1AWMSGSAFDTGHWsF4I4A11B7C2B5B4B1C3A2",
    charCounterCount: false,
    toolbarButtons: ["bold", "italic", "underline", "insertUnorderedList"],
    toolbarButtonsXS: ["bold", "italic", "underline", "insertUnorderedList"],
    toolbarButtonsSM: ["bold", "italic", "underline", "insertUnorderedList"],
    toolbarButtonsMD: ["bold", "italic", "underline", "insertUnorderedList"],
    heightMin: 50,
    fontFamilyDefaultSelection: "Inter, sans-serif",
    fontSizeDefaultSelection: "14",
    toolbarVisibleWithoutSelection: false,
    toolbarInline: true,
    events: {
      initialized: function (this: any) {
        let editor = this;
        theTribute.attach(editor.el);

        editor.events.on(
          "keydown",
          function (e: any) {
            if (e.key === "Enter" && theTribute.isActive) {
              return false;
            }
          },
          true
        );
      },
      keydown: function (e: any) {
        if (
          (e.metaKey || e.ctrlKey) &&
          (e.key === "Enter" || e.keyCode === 13)
        ) {
          e.stopPropagation();
          e.preventDefault();
          submitComment();
        }
      },
    },
  };

  return (
    <>
      <div
        style={{
          border: "1px solid rgb(236, 239, 242)",
          boxShadow: "rgb(0 0 0 / 9%) 0px 1px 4px",
          borderRadius: "4px",
          padding: "12px 16px",
          marginRight: "20px",
          marginLeft: "20px",
        }}
      >
        <FroalaEditor
          tag="textarea"
          config={config}
          model={newNote}
          onModelChange={handleModelChange}
          ref={editorRef}
        />
        <div style={{ textAlign: "right" }}>
          {file.length > 0 && file.map((f) => f.name).join(", ")}
          <IconButton component="label">
            <AttachFileIcon
              style={{
                width: "20px",
                marginRight: "5px",
              }}
            />
            <input
              hidden
              multiple
              type="file"
              onChange={(event) => {
                if (event.target.files) {
                  setFile([event.target.files[0]]);
                }
              }}
            />
          </IconButton>
          {!!initialValue && (
            <Button
              style={{
                border: "1px solid rgb(223, 225, 228)",
                height: "28px",
                fontSize: "11px",
                color: "rgb(60, 65, 73)",
                backgroundColor: "rgb(255, 255, 255)",
                boxShadow: "rgb(0 0 0 / 9%) 0px 1px 1px",
                textTransform: "none",
                fontWeight: 500,
                marginRight: "10px",
              }}
              onClick={async () => {
                setNoteToEdit && setNoteToEdit(null);
              }}
            >
              Cancel
            </Button>
          )}
          <Button
            style={{
              border: "1px solid rgb(223, 225, 228)",
              height: "28px",
              fontSize: "11px",
              color: "rgb(60, 65, 73)",
              backgroundColor: "rgb(255, 255, 255)",
              boxShadow: "rgb(0 0 0 / 9%) 0px 1px 1px",
              textTransform: "none",
              fontWeight: 500,
            }}
            onClick={submitComment}
          >
            Comment
          </Button>
        </div>
      </div>
    </>
  );
}
