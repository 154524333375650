import { useEffect, useContext } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GET_ROWS,
  CREATE_COMMENT_MUTATION,
  GET_COMMENTS,
  GET_ROW,
} from "./graphql-ops";
import { SocketIOContext } from "./socketIOProvider";
import _ from "lodash";
import { GetHeaderText } from "./DatagridFunctions";
import { RefetchContext } from "./refetchProvider";
import { GlobalContext } from "./globalContext";

const useCreateNote = (listId: string) => {
  const { socket } = useContext(SocketIOContext) as any;

  const [getRow, { loading: rowLoading, error: rowError }] = useLazyQuery(
    GET_ROW,
    {
      fetchPolicy: "network-only",
    }
  );
  const refetchContext = useContext(RefetchContext);
  const globalContext = useContext(GlobalContext);

  useEffect(() => {
    if (!_.isNil(socket) && !_.isNil(listId) && !_.isEmpty(listId)) {
      socket.emit("join-room", listId);
    }
  }, [listId, socket]);

  const [createComment, createCommentProps] = useMutation(
    CREATE_COMMENT_MUTATION,
    {
      update(cache, { data }) {
        const updatedRowId = data?.insertOneComment?.rowId?._id;

        const existingComments = cache.readQuery({
          query: GET_COMMENTS,
          variables: {
            query: { rowId: { _id: updatedRowId }, isDeleted: false },
          },
        });

        if (
          !!existingComments &&
          // @ts-ignore
          !!existingComments.comments
        ) {
          // @ts-ignore
          const updatedComments = existingComments?.comments.concat(
            data.insertOneComment
          );

          const writableComments = updatedComments?.map((comment: any) => {
            const { __typename, ...goodComment } = comment;
            return {
              ...goodComment,
            };
          });
          cache.writeQuery({
            query: GET_COMMENTS,
            variables: {
              query: { rowId: { _id: updatedRowId }, isDeleted: false },
            },
            data: {
              comments: writableComments,
            },
          });
        }
        socket.emit("update-comments", listId, updatedRowId);

        // Fetch the updated row data
        getRow({
          variables: { input: { _id: updatedRowId } },
          onCompleted: (refetchedRowData) => {
            const updatedRow = refetchedRowData?.GetRowById;

            // Update row in global context
            const newMap = globalContext?.rows;

            if (updatedRow && newMap) {
              const relatedRows = updatedRow?.relatedRows?.rows?.map(
                (r: any) => {
                  const parsedRow = JSON.parse(r?.rowObject);

                  const relatedView = refetchContext?.allViews?.find(
                    (v: any) => {
                      return v._id === r?.viewId?._id;
                    }
                  );

                  return {
                    _id: r?._id,
                    merc_viewId: r?.viewId?._id,
                    merc_viewName: r?.viewId?.name,
                    merc_Header: GetHeaderText(
                      parsedRow,
                      relatedView?.columns || []
                    ),
                    ...parsedRow,
                  };
                }
              );
              // @ts-ignore
              newMap[updatedRow.viewId?._id]?.set(updatedRowId, {
                ...JSON.parse(updatedRow.rowObject),
                _id: updatedRow._id,
                relatedRows: relatedRows,
                latestActivity: updatedRow.latestActivity,
                createdAt: updatedRow.createdAt,
                latestEmail: updatedRow.latestEmail?.timestamp,
              });

              globalContext?.setAllRows({ ...newMap });
              socket.emit("update-row", listId, updatedRowId);
            }
          },
        });
      },
    }
  );

  return { createComment, ...createCommentProps };
};

export default useCreateNote;
