import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Autocomplete,
  Avatar,
  Button,
  Chip,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  TextareaAutosize,
  Typography,
  Card,
  CardHeader,
  CardContent,
  useMediaQuery,
} from "@mui/material";
import { GET_CAMPAIGNS } from "./graphql-ops";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { RefetchContext } from "./refetchProvider";
import { apiURL, skipTraceServerUrl } from "./consts";
import { formatPhoneNumber, stripPhoneNumber } from "./DatagridFunctions";
import { TabList } from "@mui/lab";
import { add, set } from "lodash";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { fetchCustomLayer, setupParcels } from "./displayMap";
import { StyledTab, StyledTabs } from "./styledTabs";
import SearchIcon from "@mui/icons-material/Search";
import StorageIcon from "@mui/icons-material/Storage";
import MapIcon from "@mui/icons-material/TravelExplore";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import LocationSearchingIcon from "@mui/icons-material/LocationSearching";
import { CustomButton, CustomTextField } from "./PersonalDetails";
import UploadIcon from "@mui/icons-material/Upload";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import AddIcon from "@mui/icons-material/Add";
import LoadingBar from "./loadingBar";
import { faker } from "@faker-js/faker";
import ScienceIcon from "@mui/icons-material/Science";
import { ADD_CONTACT_MUTATION } from "./v2-gql";
import { AnimatedCheckIcon } from "./addToDBAnimation";
import { StyledMenu } from "./topButton";
import { WorkspaceContext } from "./v2workspaceProvider";
import WorkspaceDropdown from "./v2WorkspaceDropDown";
import { formatLink } from "./v2Utils";
import _ from "lodash";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

import { SkipTraceSocketIOContext } from "./skipTraceSocketIOProvider";

import { ApolloLookupContext } from "./apolloLookupProvider";
import AtlasDataPipelineModal from "./atlasDataPipelineModal";
import { AtlasDataPipelineContext } from "./atlasDataPipelineProvider";

const TextWithMoreButton = ({ text, maxLength }: any) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  const truncatedText =
    _.isString(text) && !_.isEmpty(text) ? text.slice(0, maxLength) : "";
  const remainingText =
    _.isString(text) && !_.isEmpty(text) ? text.slice(maxLength) : "";

  const displayText =
    _.isString(text) && !_.isEmpty(text)
      ? isExpanded
        ? text
        : `${truncatedText}...`
      : "";

  return (
    <div className="text-container">
      {displayText}
      {remainingText && (
        <Button
          sx={{
            textTransform: "none",
            color: "#2b70ef",
            width: "fit-content",
            borderRadius: "4px",
            padding: "0px",
          }}
          onClick={toggleText}
        >
          {isExpanded ? "less" : "more"}
        </Button>
      )}
    </div>
  );
};

export default function PropertyResearch() {
  const { user, getAccessTokenSilently } = useAuth0();

  const [addressToSearch, setAddressToSearch] = useState<string>("");
  const [lat, setLat] = useState<number | undefined>(undefined);
  const [lng, setLng] = useState<number | undefined>(undefined);
  const [propData, setPropData] = useState<any>(null);
  const [propView, setPropView] = useState<string>("1");
  const [theMap, setMap] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [contactToAdd, setContactToAdd] = useState<any>(null);
  const [outOfCredits, setOutOfCredits] = useState<boolean>(false);

  // const context = useContext(RefetchContext);
  const workspaceContext = useContext(WorkspaceContext);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const inputRef = useRef(null);

  useEffect(() => {
    let autocomplete: any;

    if (inputRef.current) {
      // Initialize Google Places Autocomplete
      autocomplete = new google.maps.places.Autocomplete(inputRef.current, {
        types: ["address"],
      });

      // Event listener to get selected value
      autocomplete.addListener("place_changed", async () => {
        const place = autocomplete.getPlace();
        setAddressToSearch(place?.formatted_address);
        setLat(place?.geometry?.location?.lat());
        setLng(place?.geometry?.location?.lng());
      });
    }

    return () => {
      // Remove event listener on cleanup
      if (autocomplete) {
        google.maps.event.clearInstanceListeners(autocomplete);
      }
    };
  }, [inputRef.current]);

  useEffect(() => {
    const callAPI = async () => {
      try {
        setLoading(true);
        const accessToken = await getAccessTokenSilently();
        const replyRes = await fetch(
          `${skipTraceServerUrl}/api/1/property/trace1/${addressToSearch}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
            method: "GET",
          }
        );

        if (replyRes.status === 402) {
          // console.log("Received 402 status code");
          setOutOfCredits(true);
          // You can add additional handling for 402 status here if needed
        }

        const reply = await replyRes.json();
        setPropData(reply);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch property data:", error);

        setLoading(false);
      }
    };
    if (addressToSearch?.length > 0) {
      callAPI();
      // setPropData({});
      // setPropData({
      // propertySampleResponse
      // });
    }
  }, [addressToSearch, getAccessTokenSilently]);

  const onLoad = useCallback(
    function callback(map: any) {
      setMap(map);

      fetchCustomLayer()?.then((layerData) => {
        if (layerData && map) {
          setupParcels(map, layerData);
        }
      });
    },
    [theMap]
  );

  const onUnmount = useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  return (
    <div style={{ backgroundColor: "rgb(247, 249, 250)", minHeight: "100vh" }}>
      <Drawer
        open={!!contactToAdd}
        onClose={() => setContactToAdd(undefined)}
        anchor="right"
        sx={{
          width: "40%",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "40%",
            boxSizing: "border-box",
          },
        }}
      >
        <AddContactToDatabase
          contactToAdd={contactToAdd}
          setContactToAdd={setContactToAdd}
          propData={propData}
        />
      </Drawer>
      {/* <div
        style={{
          width: "100%",
          borderBottom: "1px solid #e0e0e0",
          paddingTop: "8px",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <img
          src="/merceroBrandmark.svg"
          alt="Home Logo"
          style={{
            width: "40px",
            float: "left",
            marginLeft: "16px",
            marginTop: "2px",
          }}
        />
        <StyledTabs
          value={0}
          onChange={function (
            event: SyntheticEvent<Element, Event>,
            newValue: number
          ): void {
            throw new Error("Function not implemented.");
          }}
          sx={{ width: "385px", margin: "auto" }}
        >
          <StyledTab
            icon={<SearchIcon fontSize="small" />}
            iconPosition="start"
            label="Search"
          />
          <Link to="/properties/discover">
            <StyledTab
              icon={<MapIcon fontSize="small" />}
              iconPosition="start"
              label="Map"
            />
          </Link>
          <Link to="/properties/database">
            <StyledTab
              icon={<StorageIcon fontSize="small" />}
              iconPosition="start"
              label="Database"
            />
          </Link>
        </StyledTabs>
        <Link
          to="/"
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            marginRight: "20px",
            marginTop: "3px",
            color: "##1c52b9",
          }}
        >
          <Button
            sx={{
              textTransform: "none",
            }}
          >
            Back to CRM
          </Button>
        </Link>
        <WorkspaceDropdown />
      </div> */}
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{
          width: "80%",
          maxWidth: "1048px",
          margin: "auto",
          paddingTop: "40px",
        }}
      >
        <Grid item xs={6}>
          <Typography variant="h6">Address Search</Typography>
        </Grid>
        <Grid item xs={6} sx={{ display: "flex" }} justifyContent={"flex-end"}>
          <Button
            style={{
              border: "1px solid rgb(223, 225, 228)",
              height: "28px",
              fontSize: "13px",
              color: "rgb(60, 65, 73)",
              backgroundColor: "rgb(255, 255, 255)",
              boxShadow: "rgb(0 0 0 / 9%) 0px 1px 1px",
              textTransform: "none",
              fontWeight: 500,
            }}
            onClick={() => {
              alert(
                "If you're looking to research in bulk, please contact jake@mercero.com"
              );
            }}
          >
            <UploadIcon sx={{ mr: 1, width: "20px" }} />
            Upload a list of addresses to search
          </Button>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "12px" }}>
          <Paper
            // component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ padding: "10px", display: "flex" }}>
              <LocationSearchingIcon
                sx={{ fontSize: "18px", color: "#2b70ef" }}
              />
            </div>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search Address"
              inputProps={{ "aria-label": "search google maps" }}
              inputRef={inputRef}
            />
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
        </Grid>
      </Grid>
      {loading && (
        <div style={{ width: "600px", margin: "auto", marginTop: "24px" }}>
          <LoadingBar />
        </div>
      )}

      {outOfCredits && (
        <Typography
          sx={{
            textAlign: "center",
            padding: "40px",
          }}
          variant="h6"
        >
          You're out of credits, please contact jake@mercero.com to buy more.
        </Typography>
      )}

      {!!!propData && !loading && !outOfCredits && (
        <div
          style={{
            padding: "40px",
            marginTop: "24px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <AutoAwesomeIcon
            sx={{
              fontSize: "100px",
              color: "#2a70f0",
            }}
          />
          <Typography
            variant={"h5"}
            sx={{
              textAlign: "center",
              marginTop: "20px",
              fontWeight: "600",
              color: "#1c52b9",
            }}
          >
            Welcome to Mercero Atlas, type in an address and let the magic
            happen.
          </Typography>
        </div>
      )}

      {!!propData && (
        <Grid
          container
          columnSpacing={3}
          direction={"row"}
          sx={{
            width: "80%",
            maxWidth: "1048px",
            margin: "auto",
            marginTop: "40px",
          }}
        >
          <Grid
            item
            xs={4}
            className="property-search-box"
            sx={{
              padding: "24px",
              height: "fit-content",
              position: "sticky",
              top: "20px",
            }}
          >
            {!!lat && !!lng && propView === "1" && (
              <GoogleMap
                center={{
                  lat: lat,
                  lng: lng,
                }}
                mapContainerStyle={{
                  width: "100%",
                  height: "225px",
                }}
                mapTypeId="satellite"
                zoom={17}
                onLoad={onLoad}
                onUnmount={onUnmount}
                options={{
                  mapTypeId: "satellite",
                }}
                key={"google-map"}
                ref={theMap}
              >
                <Marker
                  key={"1map"}
                  position={{ lat, lng }}

                  // isOpen && selectedRow?._id === row._id
                  // ? "/mapImages/mapsMarker_blue.svg"
                  // :
                />
              </GoogleMap>
            )}
            {lat && lng && propView === "2" && (
              <iframe
                width="100%"
                key={`${lat}-${lng}`}
                height="225"
                className="streetViewIframe"
                title="Street View"
                style={{
                  border: "0",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                src={`https://www.google.com/maps/embed/v1/streetview?key=AIzaSyBGRT9wxwnP_vbsbTE_HHUb55W5BQH4XZE&location=${lat},${lng}&heading=210&pitch=10&fov=90`}
              ></iframe>
            )}
            <Tabs
              onChange={(event, newValue) => {
                setPropView(newValue);
              }}
              sx={{ width: "180px", margin: "auto", marginBottom: "10px" }}
              value={propView}
              aria-label="lab API tabs example"
            >
              <StyledTab label="Aerial" value="1" />
              <StyledTab label="Street" value="2" />
            </Tabs>
            <PropertyData
              propData={propData}
              addressToSearch={addressToSearch}
            />
          </Grid>

          <PropertyOwnershipDetails
            propData={propData}
            setContactToAdd={setContactToAdd}
          />
        </Grid>
      )}
    </div>
  );
}

export function PropertyData(props: any) {
  const { propData, addressToSearch } = props;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <Typography variant="body2">
        <span style={{ fontWeight: "500" }}>{addressToSearch}</span>
      </Typography>
      <Divider />
      <Typography variant="body2">
        <span style={{ fontWeight: "500" }}>Parcel Number</span>:{" "}
        {propData?.regrid?.properties?.fields?.parcelnumb}{" "}
      </Typography>
      <Typography variant="body2">
        <span style={{ fontWeight: "500" }}>Last Sold</span>:{" "}
        {propData?.regrid?.properties?.fields?.saledate}{" "}
      </Typography>
      <Typography variant="body2">
        <span style={{ fontWeight: "500" }}>Last Sold Price</span>:{" "}
        {propData?.regrid?.properties?.fields?.saleprice}{" "}
      </Typography>
      <Typography variant="body2">
        <span style={{ fontWeight: "500" }}>Use</span>:{" "}
        {propData?.regrid?.properties?.fields?.usedesc}{" "}
      </Typography>
      <Typography variant="body2">
        <span style={{ fontWeight: "500" }}>SQFT</span>:{" "}
        {propData?.regrid?.properties?.fields?.sqft}{" "}
      </Typography>
      <Typography variant="body2">
        <span style={{ fontWeight: "500" }}>Land (Acres)</span>:{" "}
        {propData?.regrid?.properties?.fields?.gisacre}{" "}
      </Typography>
      <Typography variant="body2">
        <span style={{ fontWeight: "500" }}>Year Build</span>:{" "}
        {propData?.regrid?.properties?.fields?.yearbuilt}{" "}
      </Typography>
      <Typography variant="body2">
        <span style={{ fontWeight: "500" }}>Zoning</span>:{" "}
        {propData?.regrid?.properties?.fields?.zoning} (
        {propData?.regrid?.properties?.fields?.zoning_description}){" "}
      </Typography>
    </div>
  );
}

export function PropertyOwnershipDetails(props: any) {
  const { propData, setContactToAdd, isCompact } = props;

  const officers =
    propData?.business?.businessFilings?.usCorpFilings[0]?.officers ||
    propData?.business?.businessFilings?.newBusinessFilings[0]?.contacts ||
    [];
  const corpType =
    propData?.business?.businessFilings?.usCorpFilings[0]?.corpType ||
    propData?.business?.businessFilings?.newBusinessFilings[0]?.licenseTypeDesc;
  const filingType =
    propData?.business?.businessFilings?.usCorpFilings[0]?.filingType;
  return (
    <Grid item xs={8}>
      <Grid container className={"property-search-box"}>
        <Grid
          item
          xs={12}
          className="property-search-header"
          sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
        >
          <Typography variant="h6">Legal Ownership</Typography>
          <div>
            <span style={{ fontWeight: 600 }}>Accessed Owners:</span>{" "}
            <span style={{ fontWeight: 500 }}>
              {propData?.owner?.name?.formattedOwnerName}
            </span>
          </div>
          <div>
            <span style={{ fontWeight: 600 }}>Mailing Address:</span>{" "}
            <span style={{ fontWeight: 500, textTransform: "capitalize" }}>
              {propData?.owner?.mailingAddress?.formattedAddress}
            </span>
          </div>
          {!!filingType && (
            <div>
              <span style={{ fontWeight: 600 }}>Source:</span>{" "}
              <span style={{ fontWeight: 500, textTransform: "capitalize" }}>
                {filingType}
              </span>
            </div>
          )}
          {!!corpType && (
            <div>
              <span style={{ fontWeight: 600 }}>Type:</span>{" "}
              <span style={{ fontWeight: 500, textTransform: "capitalize" }}>
                {corpType} (
                {
                  propData?.business?.businessFilings?.usCorpFilings[0]
                    ?.corpStatus
                }
                )
              </span>
            </div>
          )}
          {officers?.length > 0 && (
            <div>
              <span style={{ fontWeight: 600 }}>Officers:</span>{" "}
            </div>
          )}
          {officers?.map((officer: any) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Typography variant="body1">
                    {officer?.name?.nameRaw || officer?.name?.fullName}
                  </Typography>
                  <Typography variant="body2">
                    {officer?.title || officer?.contactTypeDesc}
                  </Typography>
                  <Typography variant="body2">
                    {officer?.address?.fullAddress}
                  </Typography>
                </div>
                <div>
                  <CustomButton
                    onClick={() => {
                      setContactToAdd({
                        searchId: propData?._id,
                        source: "endato",
                        id: officer?.name?.tahoeId || officer?.tahoeId,
                        state: officer?.address?.state,
                        contactPerson:
                          officer?.name?.nameRaw || officer?.name?.fullName,
                        mailingAddress: officer?.address?.fullAddress,
                        likelyEmail: officer?.domainEmail,
                      });
                    }}
                  >
                    {isCompact ? (
                      <ScienceIcon />
                    ) : (
                      <>
                        <ScienceIcon /> Research
                      </>
                    )}
                  </CustomButton>
                </div>
              </div>
            );
          })}
        </Grid>
        {!!propData?.potentialContacts?.people && (
          <Grid
            item
            xs={12}
            className="property-search-header"
            sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            <Typography variant="h6">Ownership</Typography>
            {propData?.potentialContacts?.people?.map((person: any) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Typography variant="body1">{person?.fullName}</Typography>
                    <Typography variant="body2">
                      {person?.addresses[0]?.fullAddress}
                    </Typography>
                  </div>
                  <div>
                    <CustomButton
                      onClick={() => {
                        setContactToAdd({
                          searchId: propData?._id,
                          source: "endato",
                          id: person?.tahoeId,
                          contactPerson: person?.fullName,
                          mailingAddress: person?.address,
                          state: person?.locations[0]?.state,
                        });
                      }}
                    >
                      {isCompact ? (
                        <ScienceIcon />
                      ) : (
                        <>
                          <ScienceIcon /> Research
                        </>
                      )}
                    </CustomButton>
                  </div>
                </div>
              );
            })}
          </Grid>
        )}

        {!!propData?.business?.mailingAddressResearch?.businessName && (
          <Grid
            item
            xs={12}
            className="property-search-header"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <Typography variant="h6">Likely Owning Company</Typography>

            {!!propData?.business?.mailingAddressResearch?.businessName && (
              <div>
                <span style={{ fontWeight: 600 }}>Business Name:</span>{" "}
                <span style={{ fontWeight: 500, textTransform: "capitalize" }}>
                  {propData?.business?.mailingAddressResearch?.businessName}
                </span>
              </div>
            )}
            {!!propData?.business?.mailingAddressResearch?.domain && (
              <div>
                <span style={{ fontWeight: 600 }}>Business Domain:</span>{" "}
                <span style={{ fontWeight: 500 }}>
                  <a
                    href={formatLink(
                      propData?.business?.mailingAddressResearch?.domain
                    )}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {propData?.business?.mailingAddressResearch?.domain}
                  </a>
                </span>
              </div>
            )}

            {propData?.potentialContacts?.length > 0 &&
              propData?.potentialContacts?.map((contact: any) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "12px",
                      }}
                    >
                      <div>
                        <Avatar alt={contact?.name} src={contact?.photo_url} />
                      </div>
                      <div>
                        <Typography variant="body1">{contact?.name}</Typography>
                        <Typography variant="body1">
                          {contact?.title}
                        </Typography>
                        {contact?.linkedin_url && (
                          <a
                            href={contact?.linkedin_url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {contact?.linkedin_url}
                          </a>
                        )}
                      </div>
                    </div>
                    <div>
                      <CustomButton
                        onClick={() => {
                          setContactToAdd({
                            searchId: propData?._id,
                            source: "apollo",
                            id: contact?.id,
                            contactPerson: contact?.name,
                            mailingAddress: "",
                          });
                        }}
                      >
                        Research
                      </CustomButton>
                    </div>
                  </div>
                );
              })}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export function AddContactToDatabase(props: any) {
  const { contactToAdd, propData, setContactToAdd } = props;

  const { user, getAccessTokenSilently } = useAuth0();
  const [contactData, setContactData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [tags, setTags] = useState<any>([]);
  const [comment, setComment] = useState<string>("");
  const [showAllEmails, setShowAllEmails] = useState<boolean>(false);

  const [premiumContactApolloId, setPremiumContactApolloId] = useState<
    string | undefined
  >(undefined);
  const [linkedInPerson, setLinkedInPerson] = useState<any>(undefined);
  const [revealPremiumPhoneNumbers, setRevealPremiumPhoneNumbers] =
    useState<boolean>(false);
  const [premiumPhoneNumbers, setPremiumPhoneNumbers] = useState<any>(null);
  const [premiumPhoneNumbersLoading, setPremiumPhoneNumbersLoading] =
    useState<boolean>(false);

  const [shouldAddContact, setShouldAddContact] = useState(false);
  const [addingContact, setAddingContact] = useState(false);

  const { setOpen } = useContext(AtlasDataPipelineContext) as any;

  const refetchContext = useContext(RefetchContext);
  const atlasEnabled = refetchContext?.atlasEnabled;
  const setAtlasEnabled = refetchContext?.setAtlasEnabled;

  const { socket } = useContext(SkipTraceSocketIOContext) as any;
  const { apolloResponse } = useContext(ApolloLookupContext) as any;

  const [phones, setPhones] = useState(
    contactData?.mostLikleyPerson?.phoneNumbers?.map((phone: any) => {
      return { number: phone, status: "neutral" };
    })
  );
  const [emails, setEmails] = useState(
    contactToAdd?.emails?.map((email: any) => {
      return { email, status: "potential" };
    })
  );
  const [addingContactToDatabase, setAddingContactToDatabase] = useState(false);
  const [contactAdded, setContactAdded] = useState(false);

  const propertyDataPoints = propData?.regrid?.properties?.fields;

  // const [addContact, { data, loading: addLoading, error }] =
  //   useMutation(ADD_CONTACT_MUTATION);

  // const accessToken = await getAccessTokenSilently();
  //     const replyRes = await fetch(
  //       `${skipTraceServerUrl}/api/1/property/contact`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${accessToken}`,
  //           "Content-Type": "application/json",
  //         },

  const addContact = async (input: any) => {
    try {
      const accessToken = await getAccessTokenSilently();
      setAddingContact(true);
      const response = await fetch(`${apiURL}atlas/add-contact`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(input),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Contact added successfully:", data);
      setAddingContact(false);
      return data;
    } catch (error) {
      setAddingContact(false);
      console.error("Error adding contact:", error);
      throw error;
    }
  };

  useEffect(() => {
    const callAPI = async () => {
      setLoading(true);
      const accessToken = await getAccessTokenSilently();
      const replyRes = await fetch(
        `${skipTraceServerUrl}/api/1/property/contact`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            source: contactToAdd?.source,
            id: contactToAdd?.id,
            searchId: contactToAdd?.searchId,
            name: contactToAdd?.contactPerson,
            state: contactToAdd?.state,
          }),
        }
      );

      const reply = await replyRes.json();
      setContactData(reply);

      setLoading(false);
    };
    if (contactToAdd?.source && !contactData) {
      callAPI();
    }
  }, [contactToAdd, getAccessTokenSilently]);

  const handleSubmit = useCallback(async () => {
    if (!atlasEnabled) {
      if (
        !!refetchContext?.allViews?.length &&
        refetchContext?.allViews?.length > 0
      ) {
        setOpen(true);
        setShouldAddContact(true);
      } else {
        try {
          const accessToken = await getAccessTokenSilently();
          const formData = new FormData();
          formData.append("workspaceId", refetchContext?.currentWorkspace?._id);

          const response = await fetch(`${apiURL}atlas/create-standard-lists`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            body: formData,
          });
          setAtlasEnabled && setAtlasEnabled(true);
          addContactToDatabase();
        } catch (error) {
          alert(
            "Error adding contact, please contact info@mercero.com or use the chat feature to report the issue."
          );
        }
      }
    } else {
      addContactToDatabase();
    }
  }, [
    atlasEnabled,
    refetchContext,
    setOpen,
    getAccessTokenSilently,
    setAtlasEnabled,
    contactData,
    contactToAdd,
    emails,
    phones,
    tags,
    comment,
    propData,
    propertyDataPoints,
    addContact,
    refetchContext,
  ]);

  const addContactToDatabase = useCallback(async () => {
    const sendEmails = !!emails
      ? emails?.map((email: any) => {
          return {
            emailAddress: email?.email?.emailAddress,
            status: email?.status,
            type: "work",
          };
        })
      : [];

    if (!!contactToAdd?.domainEmail) {
      sendEmails.push({
        emailAddress: contactToAdd?.domainEmail,
        status: "good",
        type: "work",
      });
    }

    if (!!contactData?.linkedin?.person?.email) {
      sendEmails.push({
        emailAddress: contactData?.linkedin?.person?.email,
        status: "good",
        type: "work",
      });
    }

    if (!!contactData?.person?.email) {
      sendEmails.push({
        emailAddress: contactData?.person?.email,
        status: "good",
        type: "work",
      });
    }

    const sendPhones = !!phones
      ? phones?.map((phone: any) => {
          return {
            phoneNumber: phone?.number?.phoneNumber,
            status: phone?.status,
            type: "work",
            callAttempts: 0,
          };
        })
      : [];
    setAddingContactToDatabase(true);
    try {
      addContact({
        input: {
          name:
            contactData?.linkedin?.person?.name || contactToAdd?.contactPerson,
          title:
            contactData?.linkedin?.person?.title ||
            contactData?.person?.title ||
            "Manager",
          emails: sendEmails,
          phoneNumbers: sendPhones,
          tags: tags,
          workspaceId: refetchContext?.currentWorkspace?._id,
          comment: { create: { comment } },
          properties: [
            {
              create: {
                address: `${propertyDataPoints?.saddno} ${propertyDataPoints?.saddpref} ${propertyDataPoints?.saddstr} ${propertyDataPoints?.saddsttyp}, ${propertyDataPoints?.scity} ${propertyDataPoints?.state2}, ${propertyDataPoints?.szip5}`, // `${propData?.regrid?.properties?.fields?.mailadd} ${propData?.regrid?.properties?.fields?.mail_city}, ${propData?.regrid?.properties?.fields?.mail_state2} ${propData?.regrid?.properties?.fields?.mail_zip}`,
                zoning: propertyDataPoints?.zoning,
                parcelnumb: propertyDataPoints?.parcelnumb,
                gisacre: propertyDataPoints?.ll_gissqft.toString(),
                latitude: propertyDataPoints?.lat,
                longitude: propertyDataPoints?.lon,
                tags: [],
              },
            },
          ],
          companies: [
            {
              create: {
                entityName: propData?.regrid?.properties?.fields?.owner,
                address: `${propData?.regrid?.properties?.fields?.mailadd} ${propData?.regrid?.properties?.fields?.mail_city}, ${propData?.regrid?.properties?.fields?.mail_state2} ${propData?.regrid?.properties?.fields?.mail_zip}`,
                domain: propData?.business?.mailingAddressResearch?.domain,
                dba: propData?.business?.mailingAddressResearch?.businessName,
              },
            },
          ],
          aId: contactToAdd?.source === "apollo" ? contactToAdd?.id : null,
          eId: contactToAdd?.source === "endato" ? contactToAdd?.id : null,
        },
      });
      setContactAdded(true);
    } catch (error) {
      setContactAdded(false);
    } finally {
      setAddingContactToDatabase(false);
    }
  }, [
    contactData,
    contactToAdd,
    emails,
    phones,
    tags,
    comment,
    propData,
    propertyDataPoints,
    addContact,
    refetchContext,
  ]);

  useEffect(() => {
    if (!addingContactToDatabase && contactAdded) {
      setContactToAdd(undefined);
    }
  }, [addingContactToDatabase, contactAdded]);

  useEffect(() => {
    if (
      _.chain(contactData)
        .get("linkedin.person.id")
        .isNil()
        .thru((o) => !o)
        .value()
    ) {
      setPremiumContactApolloId(
        _.chain(contactData).get("linkedin.person.id").value()
      );
      setLinkedInPerson(_.chain(contactData).get("linkedin.person").value());
    } else if (
      _.chain(contactData)
        .get("person.id")
        .isNil()
        .thru((o) => !o)
        .value()
    ) {
      setPremiumContactApolloId(_.chain(contactData).get("person.id").value());
      setLinkedInPerson(_.chain(contactData).get("person").value());
    }
  }, [contactData]);

  useEffect(() => {
    const callAPI = async () => {
      const accessToken = await getAccessTokenSilently();
      const replyRes = await fetch(
        `${skipTraceServerUrl}/api/1/property/contact-number`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            id: premiumContactApolloId,
          }),
        }
      );

      const reply = await replyRes.json();
      if (_.chain(reply).get("phone_numbers").isNil().value()) {
        socket.emit("join-apollo-request", premiumContactApolloId);
        const replyRes = await fetch(
          `${skipTraceServerUrl}/api/1/property/contact`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify({
              source: "apollo",
              id: premiumContactApolloId,
              getPhoneNumbers: true,
            }),
          }
        );
      } else {
        setPremiumPhoneNumbers(_.chain(reply).get("phone_numbers", []).value());
        setPremiumPhoneNumbersLoading(false);
      }
    };
    if (
      !_.isNil(premiumContactApolloId) &&
      revealPremiumPhoneNumbers &&
      !_.isNil(socket)
    ) {
      setPremiumPhoneNumbersLoading(true);
      callAPI();
    }
  }, [
    premiumContactApolloId,
    revealPremiumPhoneNumbers,
    getAccessTokenSilently,
    socket,
  ]);

  useEffect(() => {
    const callAPI = async () => {
      const accessToken = await getAccessTokenSilently();
      const replyRes = await fetch(
        `${skipTraceServerUrl}/api/1/property/contact-number`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            id: premiumContactApolloId,
          }),
        }
      );
      const reply = await replyRes.json();
      if (
        _.chain(reply)
          .get("phone_numbers")
          .isNil()
          .thru((o) => !o)
          .value()
      ) {
        setPremiumPhoneNumbers(_.chain(reply).get("phone_numbers", []).value());
        console.log({
          phone_numbers: _.chain(reply).get("phone_numbers", []).value(),
        });
      }
    };

    if (
      !_.isNil(apolloResponse) &&
      _.chain(apolloResponse).get("id").eq(premiumContactApolloId).value()
    ) {
      setPremiumPhoneNumbersLoading(false);
      //@ts-ignore
      setPremiumPhoneNumbers({
        phone_numbers: _.chain(apolloResponse)
          .get("phone_numbers", [])
          // @ts-ignore
          .map((o) => _.get(o, "sanitized_number"))
          .value(),
      });
      callAPI();
    }
  }, [apolloResponse]);

  // useEffect(() => {
  //
  //
  // },[premiumPhoneNumbers])

  useEffect(() => {
    if (contactData) {
      setPhones(
        contactData?.mostLikleyPerson?.phoneNumbers?.map((phone: any) => {
          return { number: phone, status: "neutral" };
        })
      );

      if (showAllEmails) {
        setEmails(
          contactData?.mostLikleyPerson?.emailAddresses?.map((email: any) => {
            return { email, status: "neutral" };
          })
        );
      } else {
        setEmails(
          contactData?.mostLikleyPerson?.emailAddresses
            ?.filter((email: any) => {
              return email?.isReasonable;
            })
            ?.map((email: any) => {
              return { email, status: "neutral" };
            })
        );
      }
    }
  }, [showAllEmails, contactData]);

  if (loading) {
    return <div style={{ padding: "24px" }}>Researching...</div>;
  }

  if (addingContactToDatabase) {
    return <div style={{ padding: "24px" }}>Adding contact to database...</div>;
  }

  return (
    <>
      <AtlasDataPipelineModal />
      <div style={{ padding: "24px" }}>
        {_.isNil(premiumContactApolloId) && (
          <>
            <Typography
              variant="h6"
              style={{ fontWeight: "600", textTransform: "capitalize" }}
            >
              {contactToAdd?.contactPerson?.toLowerCase()}
            </Typography>
            {!!contactToAdd?.mailingAddress && (
              <Typography
                variant="body2"
                style={{ fontWeight: "600", textTransform: "capitalize" }}
              >
                Address: {contactToAdd?.mailingAddress}
              </Typography>
            )}
          </>
        )}
        <Grid container sx={{ gap: "12px" }}>
          {!_.isNil(premiumContactApolloId) && (
            <>
              <Grid item xs={12}>
                <Grid container sx={{ marginBottom: "10px" }}>
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: "600",
                      textTransform: "capitalize",
                      width: "100%",
                    }}
                  >
                    {contactToAdd?.contactPerson?.toLowerCase()}
                  </Typography>
                  {!!contactToAdd?.mailingAddress && (
                    <Typography
                      variant="body2"
                      style={{ fontWeight: "600", textTransform: "capitalize" }}
                    >
                      Address: {contactToAdd?.mailingAddress}
                    </Typography>
                  )}
                </Grid>
              </Grid>

              <Paper
                elevation={2}
                sx={{ marginTop: "0 px", padding: "10px", width: "100%" }}
              >
                <Grid container>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "600", marginBottom: "12px" }}
                  >
                    LinkedIn
                  </Typography>

                  <Divider />

                  <CardHeader
                    sx={{
                      width: "100%",
                    }}
                    avatar={
                      <Avatar
                        alt={contactToAdd?.contactPerson?.toLowerCase()}
                        src={
                          _.isNil(linkedInPerson?.contact?.photo_url)
                            ? linkedInPerson?.photo_url
                            : linkedInPerson?.contact?.photo_url
                        }
                        sx={{ width: 56, height: 56 }}
                      />
                    }
                    action={null}
                    title={
                      <a
                        href={linkedInPerson?.linkedin_url}
                        target="_blank"
                        style={{
                          fontWeight: "500",
                          color: "#2b70ef",
                          fontSize: "14px",
                        }}
                      >
                        {linkedInPerson?.name}
                      </a>
                    }
                    subheader={linkedInPerson?.headline}
                  />

                  <CardHeader
                    avatar={
                      <Avatar
                        alt={linkedInPerson?.organization?.name}
                        src={linkedInPerson?.organization?.logo_url}
                        sx={{ width: 36, height: 36 }}
                      />
                    }
                    action={null}
                    title={linkedInPerson?.organization?.name}
                    subheader={
                      <a
                        href={linkedInPerson?.organization?.website_url}
                        target="_blank"
                        style={{
                          fontWeight: "500",
                          color: "#2b70ef",
                          fontSize: "14px",
                        }}
                      >
                        {linkedInPerson?.organization?.website_url}
                      </a>
                    }
                  />
                  <CardContent>
                    <TextWithMoreButton
                      text={
                        _.chain(linkedInPerson?.organization?.seo_description)
                          .trim()
                          .isEmpty()
                          .value()
                          ? linkedInPerson?.organization?.short_description
                          : linkedInPerson?.organization?.seo_description
                      }
                      maxLength={100}
                    />
                  </CardContent>
                </Grid>

                <Grid item xs={12} sx={{ marginTop: "10px" }}>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "600", marginBottom: "12px" }}
                  >
                    Premium Phone Numbers
                  </Typography>

                  {!revealPremiumPhoneNumbers && (
                    <Button
                      sx={{
                        textTransform: "none",
                        color: "#2b70ef",
                        width: "fit-content",
                        borderRadius: "4px",
                      }}
                      onClick={() => {
                        setRevealPremiumPhoneNumbers(true);
                      }}
                    >
                      Reveal
                    </Button>
                  )}
                  {revealPremiumPhoneNumbers && premiumPhoneNumbersLoading && (
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "200", marginBottom: "10px" }}
                    >
                      Loading ... might take a few minutes
                    </Typography>
                  )}
                  {revealPremiumPhoneNumbers &&
                    !premiumPhoneNumbersLoading &&
                    _.isArray(premiumPhoneNumbers) &&
                    _.chain(premiumPhoneNumbers).size().eq(0).value() && (
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "200", marginBottom: "10px" }}
                      >
                        No Premium phone numbers
                      </Typography>
                    )}
                  {revealPremiumPhoneNumbers &&
                    !premiumPhoneNumbersLoading &&
                    _.isArray(premiumPhoneNumbers) &&
                    _.chain(premiumPhoneNumbers).size().gt(0).value() && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "8px",
                        }}
                      >
                        {premiumPhoneNumbers.map((phone: any) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <IconButton
                                onClick={() => {
                                  // setPhones(
                                  //   phones.map((p: any) => {
                                  //     if (
                                  //       p?.number?.phoneNumber ===
                                  //       phone?.number?.phoneNumber
                                  //     ) {
                                  //       return { ...p, status: "good" };
                                  //     }
                                  //     return p;
                                  //   })
                                  // );
                                }}
                              >
                                <ThumbUpIcon
                                  sx={
                                    phone.status === "good"
                                      ? { color: "green" }
                                      : {}
                                  }
                                />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  // setPhones(
                                  //   phones.map((p: any) => {
                                  //     if (
                                  //       p?.number?.phoneNumber ===
                                  //       phone?.number?.phoneNumber
                                  //     ) {
                                  //       return { ...p, status: "bad" };
                                  //     }
                                  //     return p;
                                  //   })
                                  // );
                                }}
                              >
                                <ThumbDownIcon
                                  sx={
                                    phone.status === "bad"
                                      ? { color: "red" }
                                      : {}
                                  }
                                />
                              </IconButton>
                              <a
                                href={`tel:${stripPhoneNumber(phone)}`}
                                style={{
                                  fontWeight: "500",
                                  color: "#2b70ef",
                                  fontSize: "14px",
                                }}
                              >
                                {formatPhoneNumber(phone)}
                              </a>
                            </div>
                          );
                        })}
                      </div>
                    )}
                </Grid>

                {!_.isNil(linkedInPerson?.email) && (
                  <Grid
                    item
                    xs={12}
                    sx={{ marginTop: "10px", marginBottom: "10px" }}
                  >
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "600", marginBottom: "12px" }}
                    >
                      Verified Email
                    </Typography>
                    <a
                      href={`mailto:${linkedInPerson?.email}`}
                      style={{
                        fontWeight: "500",
                        color: "#2b70ef",
                        fontSize: "14px",
                      }}
                    >
                      {linkedInPerson?.email}
                    </a>
                  </Grid>
                )}
              </Paper>
            </>
          )}

          {!_.eq(
            contactToAdd?.likelyEmail || contactData?.person?.email,
            linkedInPerson?.email
          ) &&
            (!!contactToAdd?.likelyEmail || !!contactData?.person?.email) && (
              <Grid item xs={12} sx={{ marginTop: "10px" }}>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "600", marginBottom: "12px" }}
                >
                  {_.isNil(linkedInPerson?.email)
                    ? "Verified Email"
                    : "Likely Email"}
                </Typography>
                <a
                  href={`mailto:${
                    contactToAdd?.likelyEmail || contactData?.person?.email
                  }`}
                  style={{
                    fontWeight: "500",
                    color: "#2b70ef",
                    fontSize: "14px",
                  }}
                >
                  {contactToAdd?.likelyEmail || contactData?.person?.email}
                </a>
              </Grid>
            )}

          {contactData?.mostLikleyPerson?.phoneNumbers?.length > 0 && (
            <Grid item xs={12} sx={{ marginTop: "10px" }}>
              <Typography
                variant="body2"
                sx={{ fontWeight: "600", marginBottom: "12px" }}
              >
                Potential Phone Numbers
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                {phones?.slice(0, 5)?.map((phone: any) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          setPhones(
                            phones.map((p: any) => {
                              if (
                                p?.number?.phoneNumber ===
                                phone?.number?.phoneNumber
                              ) {
                                return { ...p, status: "good" };
                              }
                              return p;
                            })
                          );
                        }}
                      >
                        <ThumbUpIcon
                          sx={phone.status === "good" ? { color: "green" } : {}}
                        />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setPhones(
                            phones.map((p: any) => {
                              if (
                                p?.number?.phoneNumber ===
                                phone?.number?.phoneNumber
                              ) {
                                return { ...p, status: "bad" };
                              }
                              return p;
                            })
                          );
                        }}
                      >
                        <ThumbDownIcon
                          sx={phone.status === "bad" ? { color: "red" } : {}}
                        />
                      </IconButton>
                      <a
                        href={`tel:${stripPhoneNumber(
                          phone?.number?.phoneNumber
                        )}`}
                        style={{
                          fontWeight: "500",
                          color: "#2b70ef",
                          fontSize: "14px",
                        }}
                      >
                        {formatPhoneNumber(phone?.number?.phoneNumber)}
                      </a>
                    </div>
                  );
                })}
              </div>
            </Grid>
          )}

          {!!emails && emails.length > 0 && (
            <Grid item xs={12}>
              <Typography
                variant="body2"
                sx={{ fontWeight: "600", marginBottom: "12px" }}
              >
                Potential Emails
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                {emails?.slice(0, 5)?.map((email: any) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          setEmails(
                            emails.map((e: any) => {
                              if (
                                e?.email?.emailAddress ===
                                email?.email?.emailAddress
                              ) {
                                return { ...e, status: "good" };
                              }
                              return e;
                            })
                          );
                        }}
                      >
                        <ThumbUpIcon
                          sx={
                            email?.status === "good" ? { color: "green" } : {}
                          }
                        />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setEmails(
                            emails.map((e: any) => {
                              if (
                                e?.email?.emailAddress ===
                                email?.email?.emailAddress
                              ) {
                                return { ...e, status: "bad" };
                              }
                              return e;
                            })
                          );
                        }}
                      >
                        <ThumbDownIcon
                          sx={email.status === "bad" ? { color: "red" } : {}}
                        />
                      </IconButton>
                      <a
                        href={`mailto:${email?.email?.emailAddress}`}
                        style={{
                          fontWeight: "500",
                          color: "#2b70ef",
                          fontSize: "14px",
                        }}
                      >
                        {email?.email?.emailAddress}
                      </a>
                    </div>
                  );
                })}
                <Button
                  sx={{
                    textTransform: "none",
                    color: "#2b70ef",
                    width: "fit-content",
                    borderRadius: "4px",
                  }}
                  onClick={() => {
                    setShowAllEmails(!showAllEmails);
                  }}
                >
                  {showAllEmails
                    ? "Hide unlikely emails"
                    : "Show unlikely emails"}
                </Button>
              </div>
            </Grid>
          )}
        </Grid>

        <Typography
          variant="body2"
          sx={{ fontWeight: "600", marginBottom: "8px", marginTop: "24px" }}
        >
          Tags
        </Typography>
        {/* <CustomTextField placeholder="Add Tag" /> */}
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          freeSolo
          size="small"
          multiple
          options={[
            "Prospect",
            "Owner",
            "Investor",
            "Developer",
            "Builder",
            "Broker",
          ]}
          onChange={(event, newValue) => {
            setTags(newValue);
          }}
          renderTags={(value, getTagProps, ownerState) => {
            return value?.map((option, index) => {
              return (
                <Chip
                  variant="outlined"
                  label={option}
                  sx={{
                    borderRadius: "4px",
                    border: "1px solid var(--stroke-soft-200, #E2E4E9)",
                  }}
                  {...getTagProps({ index })}
                />
              );
            });
          }}
          sx={{ width: "100%", marginBottom: "12px" }}
          renderInput={(params) => <TextField {...params} />}
        />
        <Typography
          variant="body2"
          sx={{ fontWeight: "600", marginTop: "24px", marginBottom: "8px" }}
        >
          Comment
        </Typography>
        <TextareaAutosize
          onChange={(e) => {
            setComment(e.target.value);
          }}
          style={{
            borderRadius: "8px",
            minHeight: "100px",
            padding: "8px 12px",
            width: "95%",
          }}
        />
        <FormControl sx={{ marginTop: "12px" }}>
          <FormLabel id="demo-radio-buttons-group-label">Follow Up</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="none"
            name="radio-buttons-group"
          >
            <FormControlLabel value="none" control={<Radio />} label="None" />
            <FormControlLabel
              value="tomorrow"
              control={<Radio />}
              label="Tomorrow"
            />
            <FormControlLabel value="3day" control={<Radio />} label="3 Days" />
            <FormControlLabel value="week" control={<Radio />} label="1 week" />
          </RadioGroup>
        </FormControl>
        <CustomButton
          sx={{ marginTop: "24px", marginBottom: "0px" }}
          onClick={handleSubmit}
        >
          <AddCircleOutlineIcon />
          Add Contact to Database
        </CustomButton>
      </div>
    </>
  );
}
