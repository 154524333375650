import { useEffect, useState, createContext, useContext } from "react";
import _ from 'lodash'
import { RefetchContext } from "./refetchProvider";
import hash from 'hash-it';

export const AtlasDataPipelineContext = createContext({});

const steps = ['Set Target Lists', 'Map Columns', 'Save'];


const normalizePath = (o: any, p: string) => _.toString(hash(_.get(o,p)))
const normalizeString = (o: string) => _.toString(hash(o))

const AtlasDataPipelineProvider = ({ children }: any) => {
  const [open, setOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});

  const [viewSelection, setViewSelection] = useState<any>({})

  const [inputConfig, setInputConfig] = useState({})
  const [config, setConfig] = useState({})

  const context = useContext(RefetchContext);

  const [existingViews, setExistingViews] = useState([])
  const [selectedViews, setSelectedViews] = useState<any>([])
  const [candidateViews, setCandidateViews] = useState<any>([])

  const [ summary, setSummary ] = useState<any[]>([])

  const allViews = context?.allViews;

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  useEffect(()=>{
    const updateExistingViews = () => {
      setExistingViews(
        //@ts-ignore
        _.map(allViews,v=>{
        const { name, columns } = v

        const fields = _.reduce(columns, (r,c)=>{
          if (_.chain(c).get('headerName').isNil().thru(o=>!o).value() && _.chain(c).get('field').startsWith('merc_').thru(o=>!o).value()) {
            //@ts-ignore
            r.push(_.chain(c).get('headerName').trim().value())
          }
          return r
        },[])

        return ({
          //@ts-ignore
          _id: _.chain(v).get('_id').toString().value(),
          exists: true,
          name,
          fields
        })
      }))
    }
    if (!_.isNil(allViews) && !_.isEmpty(allViews)) {
      updateExistingViews()
    }
  },[allViews])

  const handleNext = () => {
    // setErrorTick(errorTick+1)

    const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);
  };

  const handleBack = () => {

    let newCompleted = _.clone(completed);

    //@ts-ignore
    newCompleted[activeStep] = false

    setCompleted(newCompleted);

    setActiveStep((prevActiveStep: any) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();

  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };


  return (
    <AtlasDataPipelineContext.Provider value={{
      steps,
      normalizePath, normalizeString,
      existingViews,
      candidateViews, setCandidateViews,
      selectedViews, setSelectedViews,
      open, setOpen,
      activeStep, setActiveStep,
      completed, setCompleted,
      totalSteps,
      completedSteps,
      isLastStep,
      allStepsCompleted,
      handleNext,
      handleBack,
      handleStep,
      handleComplete,
      handleReset,
      viewSelection, setViewSelection,
      inputConfig, setInputConfig,
      config, setConfig,
      summary, setSummary
    }}>
      {children}
    </AtlasDataPipelineContext.Provider>
  );
};

export default AtlasDataPipelineProvider;
