import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  Chip,
  Divider,
  Drawer,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import {
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { RefetchContext } from "./refetchProvider";
import { fetchCustomLayer, setupParcels } from "./displayMap";
import { StyledTab, StyledTabs } from "./styledTabs";
import SearchIcon from "@mui/icons-material/Search";
import StorageIcon from "@mui/icons-material/Storage";
import PersonIcon from "@mui/icons-material/Person";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import PaidIcon from "@mui/icons-material/Paid";
import { CustomButton, CustomTextField } from "./PersonalDetails";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import {
  DataGridPremium,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid-premium";
import { faker } from "@faker-js/faker";
import PeopleIcon from "@mui/icons-material/People";
import { formatPhoneNumber } from "./DatagridFunctions";
import MapIcon from "@mui/icons-material/Map";
import { skipTraceServerUrl } from "./consts";

interface RowData {
  id: number;
  propertyAddress: string;
  assessorOwner: string;
  ownerName: string;
  ownerPhone: string;
  ownerEmail: string;
  ownerMailingAddress: string;
}

const getColumns = (openSidebar: any) => [
  //   {
  //     field: "propertyAddress",
  //     headerName: "Property Address",
  //     width: 200,
  //     renderCell: (params: GridRenderCellParams) => {
  //       return (
  //         <Link
  //           to={`${params.id}`}
  //           style={{ color: "#0000EE" }}
  //           state={{ row: params?.row }}
  //         >
  //           {params.value}
  //         </Link>
  //       );
  //     },
  //   },
  //   { field: "assessorOwner", headerName: "Assessor Owner'", width: 200 },
  {
    field: "ownerName",
    headerName: "Name",
    width: 200,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Link
          to={`${params.id}`}
          style={{ color: "#0000EE" }}
          state={{ row: params?.row }}
        >
          {params.value} <br />
          <span style={{ color: "#333" }}>CEO - Mercero</span>
        </Link>
      );
    },
  },
  {
    field: "ownerPhone",
    headerName: "Phone",
    width: 250,
    renderCell: (params: GridRenderCellParams) => {
      if (!!params.value) {
        return (
          <Select value={params.value} sx={{ width: "100%", fontSize: "14px" }}>
            <option value={params.value}>
              {formatPhoneNumber(params.value)}
            </option>
          </Select>
        );
      }

      return <></>;
    },
  },
  { field: "ownerEmail", headerName: "Email", width: 250 },
  {
    field: "ownerMailingAddress",
    headerName: "Mailing Address",
    width: 200,
  },
  {
    field: "tags",
    headerName: "Tags",
    width: 200,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Chip
          variant="outlined"
          size="small"
          label={params.value}
          sx={{ fontSize: "12px" }}
        />
      );
    },
  },
  {
    field: "properties",
    headerName: "Properties",
    width: 600,
    renderCell: (params: GridRenderCellParams) => {
      return params?.value?.map((prop: string) => {
        return (
          <Chip
            variant="outlined"
            onClick={() => openSidebar(true)}
            size="small"
            label={prop}
            sx={{ fontSize: "12px", marginRight: "8px" }}
          />
        );
      });
    },
  },
];

const potentialTags = [
  "Prospect",
  "Owner",
  "Investor",
  "Developer",
  "Builder",
  "Broker",
];

const drawerWidth = 350;

const rows: RowData[] = Array.from({ length: 100 }, (_, index) => ({
  id: index,
  propertyAddress: faker.location.streetAddress(),
  assessorOwner: faker.company.name(),
  ownerName: faker.person.fullName(),
  ownerPhone: faker.phone.number(),
  ownerEmail: faker.internet.email(),
  ownerMailingAddress: faker.location.streetAddress(),
  tags: potentialTags[Math.floor(Math.random() * potentialTags.length)],
  properties: [
    faker.location.streetAddress(),
    faker.location.streetAddress(),
    faker.location.streetAddress(),
    faker.location.streetAddress(),
    faker.location.streetAddress(),
  ].slice(0, Math.floor(Math.random() * 5)),
}));

export default function DatabaseStaging() {
  const { user, getAccessTokenSilently } = useAuth0();

  const [addressToSearch, setAddressToSearch] = useState<string>("");
  const [lat, setLat] = useState<number | undefined>(undefined);
  const [lng, setLng] = useState<number | undefined>(undefined);
  const [propData, setPropData] = useState<any>(null);
  const [propView, setPropView] = useState<string>("1");
  const [theMap, setMap] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [filteredRows, setFilteredRows] = useState(rows);
  const [open, setOpen] = useState(false);

  const context = useContext(RefetchContext);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value.toLowerCase();
    const newFilteredRows = rows.filter((row) => {
      return row.ownerName?.toLowerCase().includes(searchValue);
    });
    setFilteredRows(newFilteredRows);
  };

  useEffect(() => {
    const callAPI = async () => {
      setLoading(true);
      const accessToken = await getAccessTokenSilently();
      const replyRes = await fetch(
        `${skipTraceServerUrl}/api/1/property/trace/${addressToSearch}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          method: "GET",
        }
      );

      const reply = await replyRes.json();
      setPropData(reply);
      setLoading(false);
    };
    if (addressToSearch.length > 0) {
      callAPI();
    }
  }, [addressToSearch, getAccessTokenSilently]);

  return (
    <div style={{ backgroundColor: "rgb(247, 249, 250)", minHeight: "100vh" }}>
      <div
        style={{
          width: "100%",
          borderBottom: "1px solid #e0e0e0",
          paddingTop: "8px",
          backgroundColor: "white",
        }}
      >
        <StyledTabs
          value={2}
          onChange={function (
            event: SyntheticEvent<Element, Event>,
            newValue: number
          ): void {
            throw new Error("Function not implemented.");
          }}
          sx={{ width: "385px", margin: "auto" }}
        >
          <Link to="/properties">
            <StyledTab
              icon={<SearchIcon fontSize="small" />}
              iconPosition="start"
              label="Search"
            />
          </Link>
          <Link to="/properties/discover">
            <StyledTab
              icon={<MapIcon fontSize="small" />}
              iconPosition="start"
              label="Map"
            />
          </Link>

          <StyledTab
            icon={<StorageIcon fontSize="small" />}
            iconPosition="start"
            label="Database"
          />
        </StyledTabs>
      </div>
      <StyledTabs
        value={0}
        sx={{ maxWidth: "90%", margin: "auto", marginTop: "24px" }}
        onChange={() => {}}
      >
        <StyledTab
          icon={<PersonIcon />}
          label={"Valid Phone and Email"}
          iconPosition="start"
        />
        <StyledTab
          icon={<LocationCityIcon />}
          label={"Company Owners not found"}
          iconPosition="start"
        />
        <StyledTab
          icon={<PeopleIcon />}
          label={"Valid email not found"}
          iconPosition="start"
        />
      </StyledTabs>
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{
          width: "90%",
          //   maxWidth: "1048px",
          margin: "auto",
          marginTop: "12px",
          backgroundColor: "white",
          padding: "24px",
          borderRadius: "12px",
        }}
      >
        <Grid
          xs={6}
          sx={{ display: "flex", flexDirection: "row", gap: "12px" }}
        >
          <Paper
            // component="form"
            sx={{
              p: "0px",
              display: "flex",
              alignItems: "center",
              width: "250px !important",
              boxShadow: "none",
              border: "1px solid #d1d1d1",
              backgroundColor: "#f7f9fa",
            }}
          >
            <div style={{ padding: "4px 8px", display: "flex" }}>
              <SearchIcon sx={{ fontSize: "18px", color: "#2b70ef" }} />
            </div>
            <InputBase
              sx={{ ml: 1, flex: 1, fontSize: "14px" }}
              placeholder="Search Contacts"
              inputProps={{ "aria-label": "search contacts" }}
              onChange={handleSearch} // Listen for changes here
            />
            {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
          </Paper>
          <Tooltip title="Filter Contacts">
            <IconButton
              type="button"
              sx={{ p: "4px" }}
              aria-label="filter"
              onClick={() => {}}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid
          xs={6}
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "12px",
            justifyContent: "flex-end",
          }}
        >
          <CustomButton
            sx={{
              fontSize: "14px",
              padding: "4px 8px",
            }}
          >
            <AddIcon sx={{ fontSize: "20px" }} />
            Add Contacts to Main Database
          </CustomButton>
        </Grid>
        <Grid xs={12} sx={{ marginTop: "24px", height: "calc(100vh - 250px)" }}>
          <DataGridPremium
            autoPageSize
            pagination
            rows={filteredRows}
            columns={getColumns(setOpen)}
            initialState={
              {
                //   filter: {
                //     filterModel: {
                //       items: [],
                //       quickFilterValues: ["quick", "filter"],
                //     },
                //   },
              }
            }
            pageSizeOptions={[5]}
            checkboxSelection
            disableRowSelectionOnClick
          />
        </Grid>
      </Grid>
      <Drawer
        open={open}
        onClose={() => setOpen(false)}
        variant="persistent"
        anchor="right"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Typography>Property Details</Typography>
        <Button onClick={() => setOpen(false)}>Close</Button>
      </Drawer>
    </div>
  );
}
