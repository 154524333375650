import { useContext, useState } from "react";
import "./App.css";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {
  Button,
  Grid,
  ListItemIcon,
  Menu,
  Select,
  Stack,
  styled,
} from "@mui/material";

import {
  GetHeaderText,
  GetSubheaderText,
  ReturnVisibleColumns,
  formatPhoneNumber,
  stripPhoneNumber,
} from "./DatagridFunctions";
import React from "react";
import { MenuItem } from "@mui/material";
import { Form, Link, NavLink, useParams } from "react-router-dom";
import { StyledMenu } from "./topButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { EditField, ShadowStyle } from "./individualView";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { colorShades, getImageString } from "./highlightRow";
import PhoneIcon from "@mui/icons-material/Phone";
import { DisplayRelatedCell } from "./displayRelatedCell";
import { useQuery } from "@apollo/client";
import { GET_ONE_VIEW } from "./graphql-ops";
import { isArray } from "lodash";
import { NewIndivHeader } from "./rowHeader";
import { formatLink } from "./v2Utils";
import { TagDisplay, getRelatedRows, getTimeStampCell } from "./dataDisplay";
import { RefetchContext } from "./refetchProvider";
import { Email } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { splitArrayByViewId } from "./relatedProperties";
import { GetListIcon } from "./icons";

const EmailButton = styled(Button)({
  display: "flex",
  padding: "10px 14px",
  justifyContent: "center",
  alignItems: "center",
  gap: "4px",
  borderRadius: "8px",
  border: "1px solid var(--blue-700, #175CD3)",
  background: "var(--blue-600, #1570EF)",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  color: "white",
  textTransform: "none",
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "20px",

  // Add hover state
  "&:hover": {
    background: "#1e88e5", // Darker shade for hover (example)
    border: "1px solid #1e88e5",
    boxShadow: "0px 2px 4px 0px rgba(16, 24, 40, 0.1)", // Slightly larger shadow
  },
});

const GridDiv = styled("div")(({ theme }) => ({
  borderRadius: "12px",
  border: "1px solid #EFF8FF",
  background: "var(--blue-25, #F5FAFF)",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  padding: "24px !important",
  height: "100%",
}));

const StyledDiv = styled("div")(({ theme }) => ({
  display: "flex",
  width: "48px",
  height: "48px",
  padding: "12px",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "10px",
  border: `1px solid ${theme.palette.grey[200]}`, // Using theme for colors
  backgroundColor: theme.palette.background.paper, // Using theme for colors
  boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
  marginBottom: "20px",
}));

export function HeaderWithKeyFields(props: {
  showDetailView: any;
  columns: any;
  isCompact?: boolean;
}) {
  const { columns, showDetailView, isCompact } = props;

  const lastActivityField =
    showDetailView?.latestActivity?.timestamp || showDetailView?.latestActivity;
  const lastActivityDate = new Date(lastActivityField);
  const lastActivity =
    !!lastActivityField && !isNaN(lastActivityDate?.getTime())
      ? lastActivityDate.toLocaleDateString()
      : "";

  const keyFields = columns?.filter(
    (col: any) => col?.display === "keyfield" && !!showDetailView?.[col?.field]
  );

  const headerElements = isCompact ? (
    <Link
      to={`/list/${showDetailView?.merc_viewId}/row/${showDetailView?._id}`}
      onClick={() => {
        window.scrollTo(0, 0);
      }}
      state={{
        allRows: "[]",
        relatedRoot: "",
        passedAllRows: JSON.stringify([]),
        relatedTitle: "",
      }}
    >
      <NewIndivHeader row={showDetailView} columns={columns} />
    </Link>
  ) : (
    <NewIndivHeader row={showDetailView} columns={columns} />
  );

  return (
    <div style={{ textAlign: "left" }}>
      <Typography
        sx={
          isCompact
            ? {
                color: "#101828",
                fontSize: "14px",
                fontWeight: 600,
              }
            : {
                color: "#101828",
                fontSize: "24px",
                fontWeight: 600,
              }
        }
        variant="h6"
      >
        {headerElements}
      </Typography>
      <Typography
        sx={
          isCompact
            ? {
                fontSize: "12px !important",
                color: "rgb(40, 42, 48)",
              }
            : {
                fontSize: "16px !important",
                color: "rgb(40, 42, 48)",
                marginBottom: "12px",
              }
        }
        variant="h6"
      >
        {GetSubheaderText(showDetailView, columns)}
      </Typography>
      {keyFields?.map((field: any, index: number) => {
        return (
          <Grid container style={{ paddingTop: "4px", paddingBottom: "4px" }}>
            <Grid xs={isCompact ? 4 : 3}>
              <Typography
                style={{
                  color: "#101828",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
              >
                {field?.headerName}:
              </Typography>
            </Grid>
            <Grid
              xs={isCompact ? 8 : 9}
              sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
            >
              <FormattedField
                type={field?.type}
                value={showDetailView?.[field?.field]}
                field={field?.field}
                row={showDetailView}
              />
            </Grid>
          </Grid>
        );
      })}
    </div>
  );
}

export function PhonesSection(props: {
  showDetailView: any;
  columns: any;
  isCompact?: boolean;
}) {
  const { columns, showDetailView, isCompact } = props;

  const phoneFields = columns?.filter((col: any) => col.type === "phone");

  const hasPhone = phoneFields?.filter((field: any) => {
    return !!showDetailView?.[field?.field]?.trim();
  });

  if (hasPhone?.length === 0) {
    return <></>;
  }

  return (
    <div style={{ textAlign: "left" }}>
      <div>
        {hasPhone?.map((field: any, index: number) => {
          return (
            <Grid container style={{ paddingTop: "4px", paddingBottom: "4px" }}>
              <Grid xs={isCompact ? 4 : 3}>
                <Typography
                  style={{
                    color: "#101828",
                    fontSize: "16px",
                    fontWeight: 600,
                  }}
                >
                  {field?.headerName}:
                </Typography>
              </Grid>
              <Grid
                xs={isCompact ? 8 : 9}
                sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
              >
                <FormattedField
                  type={field?.type}
                  value={showDetailView?.[field?.field]}
                  field={field?.field}
                  row={showDetailView}
                />
              </Grid>
            </Grid>
          );
        })}
      </div>
    </div>
  );
}

export function EmailDisplaySection(props: {
  showDetailView: any;
  columns: any;
  isCompact?: boolean;
}) {
  const { columns, showDetailView, isCompact } = props;

  const emailFields = columns?.filter((col: any) => col.type === "email");

  const hasEmail = emailFields?.filter((field: any) => {
    return !!showDetailView?.[field?.field]?.trim();
  });

  if (hasEmail?.length === 0) {
    return <></>;
  }

  return (
    <div style={{ textAlign: "left" }}>
      <div>
        {hasEmail?.map((field: any, index: number) => {
          return (
            <Grid container style={{ paddingTop: "4px", paddingBottom: "4px" }}>
              <Grid xs={isCompact ? 4 : 3}>
                <Typography
                  style={{
                    color: "#101828",
                    fontSize: "16px",
                    fontWeight: 600,
                  }}
                >
                  {field?.headerName}:
                </Typography>
              </Grid>
              <Grid
                xs={isCompact ? 8 : 9}
                sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
              >
                <FormattedField
                  type={field?.type}
                  value={showDetailView?.[field?.field]}
                  field={field?.field}
                  row={showDetailView}
                />
              </Grid>
            </Grid>
          );
        })}
      </div>
    </div>
  );
}

export function RelatedCards(props: {
  showDetailView: any;
  columns: any;
  isMobile?: boolean;
  viewId: string;
  curRowId?: string;
  deleteRow: any;
  updateRow: any;
  navigate: any;
  mapDisplay?: boolean;
  initialState?: any;
  setRow?: any;
}) {
  const {
    columns,
    showDetailView,
    isMobile,
    viewId,
    navigate,
    updateRow,
    mapDisplay,
    initialState,
    setRow,
  } = props;

  const refetchContext = useContext(RefetchContext);

  const [showAllRelated, setShowAllRelated] = useState(false);

  const sliceNumber = showAllRelated ? showDetailView?.relatedRows?.length : 3;

  const splitArrays = splitArrayByViewId(showDetailView?.relatedRows);

  const hasRelated = showDetailView?.relatedRows?.length > 0;

  if (!hasRelated) {
    return <></>;
  }

  return (
    <Grid
      container
      sx={{
        ...ShadowStyle,
        padding: "20px",
        width: "100%",
        marginBottom: "20px",
      }}
    >
      {splitArrays?.map((splitArray: any, index: number) => {
        const view = refetchContext?.allViews?.find((v) => {
          return v?._id === splitArray?.[0]?.merc_viewId;
        });

        return (
          <Grid item xs>
            <Typography
              sx={{
                fontSize: "16px !important",
                display: "flex",
                alignItems: "center",
                gap: "8px",
                marginBottom: "8px",
              }}
            >
              {GetListIcon(view?.icon?.fileString, view?.icon?.color)}
              {view?.name}
            </Typography>
            <Stack
              direction={splitArrays?.length === 1 ? "row" : "column"}
              flexWrap={"wrap"}
              columnGap={"20px"}
              rowGap={"16px"}
              alignItems={"flex-start"}
              sx={{
                width: "100%",
              }}
            >
              {splitArray
                ?.slice(0, sliceNumber)
                ?.map((row: any, index: number) => {
                  const singleRow = splitArrays?.length === 1;
                  const isWrapped = singleRow && index % 3 === 0 && index !== 0;

                  return (
                    <div
                      style={
                        isWrapped
                          ? { width: "31%", marginLeft: "0px" }
                          : singleRow
                          ? { width: "31%" }
                          : { width: "100%" }
                      }
                    >
                      <HeaderWithKeyFields
                        showDetailView={row}
                        columns={view?.columns}
                        isCompact={true}
                      />
                      <PhonesSection
                        showDetailView={row}
                        columns={view?.columns}
                        isCompact={true}
                      />
                      <EmailDisplaySection
                        showDetailView={row}
                        columns={view?.columns}
                        isCompact={true}
                      />
                      <LatestActivitySection
                        latestActivity={row.latestActivity}
                      />
                    </div>
                  );
                })}
            </Stack>
          </Grid>
        );
      })}
      {showDetailView?.relatedRows?.length > 4 && (
        <Grid item xs={12}>
          <Button
            onClick={() => setShowAllRelated(!showAllRelated)}
            sx={{
              textTransform: "none",
              gap: "4px",
              alignItems: "center",
              marginTop: "16px",
            }}
          >
            {showAllRelated ? <RemoveIcon /> : <AddIcon />}
            {showAllRelated ? "Hide Related" : "Show All Related"}
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

const LatestActivitySection = ({ latestActivity }: any) => {
  if (!latestActivity) return null;

  const { commentId, timestamp } = latestActivity;

  if (!commentId) return null;

  const { comment, userId } = commentId;
  const formattedDate = new Date(timestamp).toLocaleDateString();

  return (
    <div
      className="latest-activity"
      style={{
        padding: "8px 12px",
        border: "1px solid rgb(226, 228, 233)",
        borderRadius: "4px",
        marginTop: "8px",
        width: "80%",
      }}
    >
      <p
        style={{
          margin: "0px",
          marginBottom: "4px",
        }}
      >
        <span style={{ fontWeight: 500 }}>
          {userId?.name || "Unknown User"}
        </span>{" "}
        {formattedDate}
      </p>
      {comment && (
        <div
          className="related-card-comment"
          dangerouslySetInnerHTML={{ __html: comment }}
        />
      )}
    </div>
  );
};

function FormattedField(props: {
  type: string;
  value: any;
  field: any;
  row: any;
}) {
  const { type, value, field, row } = props;
  switch (type) {
    case "type":
      return (
        <TagDisplay
          key={`${row?._id}-${field}-tags`}
          align="left"
          field={field}
          options={value}
        />
      );

    case "phone":
      try {
        let phoneObj = JSON.parse(value);
        if (Array.isArray(phoneObj)) {
          return phoneObj.map((phone: any, index: number) => {
            const formattedNumber = formatPhoneNumber(phone?.phoneNumber);
            const phoneToCall = stripPhoneNumber(phone?.phoneNumber);
            return (
              <a
                key={index}
                href={`tel:${phoneToCall}`}
                style={{
                  color: "#2e59a8",
                  fontWeight: 500,
                  display: "block",
                  marginBottom: "4px",
                }}
              >
                {formattedNumber}
              </a>
            );
          });
        } else if (typeof phoneObj === "object" && phoneObj?.phoneNumber) {
          const formattedNumber = formatPhoneNumber(phoneObj.phoneNumber);
          const phoneToCall = stripPhoneNumber(phoneObj.phoneNumber);
          return (
            <a
              href={`tel:${phoneToCall}`}
              style={{
                color: "#2e59a8",
                fontWeight: 500,
              }}
            >
              {formattedNumber}
            </a>
          );
        }
      } catch (e) {
        console.log("Not a phone obj", e);
      }
      const formattedNumber = formatPhoneNumber(value);
      const phoneToCall = stripPhoneNumber(value);
      return formattedNumber?.length === 14 ? (
        <a
          href={`tel:${phoneToCall}`}
          style={{
            color: "#2e59a8",
            fontWeight: 500,
          }}
        >
          {formattedNumber}
        </a>
      ) : (
        formattedNumber
      );

    case "email":
      try {
        let emailObj = JSON.parse(value);
        if (Array.isArray(emailObj)) {
          return emailObj.map((email: any, index: number) => (
            <a
              key={index}
              href={`mailto:${email?.emailAddress}`}
              style={{
                color: "#2e59a8",
                fontWeight: 500,
                display: "block",
                marginBottom: "4px",
              }}
            >
              {email?.emailAddress}
            </a>
          ));
        } else if (typeof emailObj === "object" && emailObj?.emailAddress) {
          return (
            <a
              href={`mailto:${emailObj.emailAddress}`}
              style={{
                color: "#2e59a8",
                fontWeight: 500,
              }}
            >
              {emailObj.emailAddress}
            </a>
          );
        }
      } catch (e) {
        console.log("Not an email obj", e);
      }
      return (
        <a
          href={`mailto:${value}`}
          style={{
            color: "#2e59a8",
            fontWeight: 500,
          }}
        >
          {value}
        </a>
      );

    case "link":
      const formattedLink = formatLink(value);
      return formattedLink ? (
        <a
          href={formattedLink}
          target="_blank"
          rel="noreferrer"
          style={{
            color: "#2e59a8",
            fontWeight: 500,
          }}
        >
          {value}
        </a>
      ) : (
        value
      );

    case "number":
      const numValue =
        typeof value === "string" ? parseFloat(value.replace(/,/g, "")) : value;
      return (
        <span>
          {Number.isFinite(numValue) ? numValue.toLocaleString() : ""}
        </span>
      );

    case "date":
      const date = new Date(value);
      return isNaN(date.getTime())
        ? ""
        : date.toLocaleDateString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "2-digit",
          });

    case "lastActivity":
      return getTimeStampCell(value);

    case "related":
      return getRelatedRows(value);

    default:
      return value;
  }
}
