import { useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Alert,
  AlertTitle,
  Button,
  Grid,
  Icon,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";


import { useContext } from "react";


import AtlasDataPipelineModal from './atlasDataPipelineModal'

import { AtlasDataPipelineContext } from "./atlasDataPipelineProvider";

export default function AtlasDataPipeline() {

  const { setOpen } = useContext(AtlasDataPipelineContext) as any;

  return (
    <>
      <AtlasDataPipelineModal />
      <div style={{ width: "85%", margin: "auto", marginTop: "40px" }}>
        <Alert
          severity="warning"
          sx={{ marginTop: "10px", marginBottom: "10px" }}
        >
          {" "}
          This feature is in beta, please reach out with any questions or issues.{" "}
        </Alert>

        <Grid
          container
          justifyContent="space-between"
          alignContent={"center"}
          sx={{ marginBottom: "20px", width: "100%" }}
        >
          <Grid item sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6">Atlas Data Pipeline</Typography>

          </Grid>

          <Grid item xs={12}>
            <Button
              onClick={() => {
                setOpen(true);
              }}
            >
              Open
            </Button>


          </Grid>
        </Grid>


      </div>
    </>
  );
}
