import { useEffect, useState, useCallback, useRef } from "react";
import "./App.css";
import Typography from "@mui/material/Typography";
import {
  Avatar,
  Box,
  Card,
  Divider,
  Grid,
  IconButton,
  Modal,
  Pagination,
  CircularProgress,
  ListItem,
  ListItemText,
  ListItemButton,
  TextField,
  Alert,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { apiURL } from "./consts";
import _ from "lodash";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import { stripHtml } from "./DatagridFunctions";
import { modalBoxStyle } from "./rowTodos";
import { CustomButton, OnboardingHeader } from "./PersonalDetails";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { handlePreviewIcon } from "./filesSection";
import { Editor } from "@tinymce/tinymce-react";
import { CustomChip } from "./individualView";
// import { ClearIcon } from "@mui/x-date-pickers";
import ClearIcon from "@mui/icons-material/Clear";
import LoadingScreen from "./loadingScreen";

interface Email {
  sender: {
    emailAddress: {
      name: string;
      address: string;
    };
  };
  body: {
    contentType: string;
    content: string;
  };
  sentDateTime: string;
}

const colorMap = new Map();

export function EmailSection(props: {
  showDetailView: any;
  email: string;
  columns: any[];
}) {
  const { showDetailView, columns } = props;
  const [emails, setEmails] = useState<any[]>([]);
  const { getAccessTokenSilently, user } = useAuth0();
  const [requestId, setRequestId] = useState<string | undefined>(undefined);

  const [count, setCount] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const [emailsByPage, setEmailsByPage] = useState<any>({});

  const [loading, setLoading] = useState<boolean>(false);
  const [moreData, setMoreData] = useState<boolean>(true);

  const [errorReadingData, setErrorReadingData] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<String | undefined>(
    undefined
  );

  const handleChange = useCallback(
    (e: any, p: number) => {
      setPage(p);
    },
    [page]
  );

  useEffect(() => {
    const getEmails = async () => {
      try {
        let email;
        if (!_.has(emailsByPage, `p${page}`)) {
          const accessToken = await getAccessTokenSilently();

          const emailColumn =
            !!columns && columns.find((c: any) => c?.type === "email");

          const contactEmail =
            showDetailView[emailColumn?.field] ||
            showDetailView["Email"] ||
            showDetailView["email"] ||
            showDetailView["E-mail"] ||
            showDetailView["e-mail"];

          const requestIdQueryBuff = _.isNil(requestId)
            ? ""
            : `&requestId=${requestId}`;

          const emailRes = await fetch(
            `${apiURL}email/read-conversation-ids?memberEmail=${
              props.email || contactEmail
            }${requestIdQueryBuff}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );

          email = await emailRes.json();

          if (
            _.chain(email)
              .get("error.message.client")
              .isNil()
              .thru((o) => !o)
              .value()
          ) {
            setErrorReadingData(true);
            setErrorMessage(
              _.get(
                email,
                "error.message.client",
                "Server error. Contact Mercero support."
              )
            );
          } else {
            setEmailsByPage(_.set(emailsByPage, `p${page}`, email));
          }
        } else {
          email = _.get(emailsByPage, `p${page}`);
        }
        setLoading(false);

        if (_.isNil(requestId)) {
          setRequestId(email.requestId);
        }

        if (email.moreData && page === count) {
          setCount(page + 1);
        }

        setMoreData(email.moreData);

        setEmails(
          email.conversations.map((email: any) => ({ ...email, type: "email" }))
        );
      } catch (error) {
        setLoading(false);
        setMoreData(false);
        console.log(error);
      }
    };
    if (!!showDetailView._id) {
      setLoading(true);
      getEmails();
    }
  }, [showDetailView, getAccessTokenSilently, page]);

  const activityFeed = emails.sort((a, b) => {
    let dateA = !!a.createdAt
      ? new Date(a.createdAt)
      : new Date(a.sentDateTime);
    let dateB = !!b.createdAt
      ? new Date(b.createdAt)
      : new Date(b.sentDateTime);

    return dateB.getTime() - dateA.getTime();
  });

  const noData =
    !loading && !moreData && (!!!activityFeed || activityFeed?.length === 0);

  const firstLoad = loading && moreData && activityFeed.length === 0;

  return (
    <>
      {errorReadingData ? (
        <Alert severity="error">{errorMessage}</Alert>
      ) : (
        <></>
      )}
      <Typography
        style={{
          marginTop: 25,
          marginLeft: 20,
          color: "rgb(40, 42, 48)",
          fontSize: "16px",
          fontWeight: 600,
        }}
      >
        Emails
      </Typography>
      {noData && (
        <>
          <UnsubscribeIcon
            fontSize="large"
            sx={{
              width: "100px",
              height: "100px",
              opacity: ".6",
              margin: "auto",
              display: "block",
              marginTop: "70px",
            }}
          />
          <Typography
            style={{
              marginBottom: 70,
              color: "rgb(40, 42, 48)",
              textAlign: "center",
            }}
          >
            No emails found
          </Typography>
        </>
      )}
      {firstLoad ? (
        <>
          <Typography>Loading emails ...</Typography>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "20vh" }}
          >
            <Grid item>
              <CircularProgress color="inherit" />
            </Grid>
          </Grid>
        </>
      ) : null}
      {activityFeed?.length > 0 && (
        <>
          <Pagination
            count={count}
            size="small"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
            style={{ marginTop: "10px" }}
          />
          {loading ? (
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{ minHeight: "70vh" }}
            >
              <Grid item>
                <CircularProgress color="inherit" />
              </Grid>
            </Grid>
          ) : (
            <Timeline
              sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }}
            >
              {activityFeed?.length > 0 &&
                activityFeed.map((activity, index) => {
                  return (
                    <DisplayEmail
                      key={"email" + index}
                      email={activity}
                      index={index}
                      emails={activityFeed}
                    />
                  );
                })}
            </Timeline>
          )}
          <Pagination
            count={count}
            size="small"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
            style={{ marginTop: "10px" }}
          />
        </>
      )}
    </>
  );
}

const DisplayEmail = (props: { email: any; emails: any; index: number }) => {
  const { email, emails, index } = props;
  const senderName = email.participants[0];
  const [expandEmails, setExpandEmails] = useState(false);
  const [threadEmails, setThreadEmails] = useState<any[]>([]);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const expandEmailCall = async () => {
      try {
        const accessToken = await getAccessTokenSilently();

        const emailRes = await fetch(
          `${apiURL}email/read-conversation-messages?conversationId=${email.conversationId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        const emails = await emailRes.json();
        setThreadEmails(emails.messages || emails || []);
      } catch (error) {
        console.log(error);
      }
    };

    if (expandEmails) {
      expandEmailCall();
    } else {
      setThreadEmails([]);
    }
  }, [expandEmails, email.conversationId, getAccessTokenSilently]);

  // const emailRecipients = _.compact(
  //   email?.mostRecentMessage?.toRecipients.concat(
  //     email?.mostRecentMessage?.ccRecipients
  //   )
  // );

  const emailRecipients = _.compact(email?.participants);

  emailRecipients.forEach((recipient: any) => {
    if (colorMap.has(recipient.address)) {
      return;
    }
    colorMap.set(recipient.address, getRandomColor());
  });

  return (
    <>
      <ShowEmailModal
        showEmail={expandEmails}
        handleCloseModal={() => setExpandEmails(false)}
        conversationId={email?.conversationId}
      />
      <TimelineItem key={index}>
        <TimelineSeparator>
          {/* <TimelineConnector style={{ backgroundColor: "rgba(0,0,0,.2)" }} /> */}
          <TimelineDot style={{ backgroundColor: "#f5f5f5", padding: 0 }}>
            <Avatar
              sx={{
                backgroundColor: colorMap.get(
                  email?.mostRecentMessage?.from?.emailAddress?.address
                ),
                fontSize: "18px",
                textTransform: "uppercase",
              }}
            >
              {GetAvatarChars(
                email?.mostRecentMessage?.from?.emailAddress?.name ||
                  email?.mostRecentMessage?.from?.emailAddress?.address
              )}
            </Avatar>
            {/* <EmailIcon
            sx={{ width: "15px", height: "15px", color: "rgba(0,0,0,.5)" }}
          /> */}
          </TimelineDot>
          {/* {index !== emails?.length - 1 && <TimelineConnector />} */}
        </TimelineSeparator>
        <TimelineContent sx={{ py: "12px", px: 2 }}>
          <div style={{ fontSize: "14px" }}>
            {/* {email?.mostRecentMessage?.sender?.emailAddress?.name ||
            email?.mostRecentMessage?.sender?.emailAddress?.email}{" "}
          sent an email */}

            <Grid
              container
              justifyContent={"space-between"}
              sx={{ marginBottom: "5px" }}
            >
              <Grid item>
                <span
                  onClick={() => setExpandEmails(!expandEmails)}
                  style={{
                    fontWeight: 600,
                    color: "#4872b5",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  {email?.mostRecentMessage?.subject || "No Subject"}
                </span>{" "}
              </Grid>
              <Grid item>
                {formatEmailDate(email.mostRecentMessage.sentDateTime)}
              </Grid>
            </Grid>
            <div style={{ opacity: ".7" }}>
              {emailRecipients
                ?.slice(0, 5)
                ?.map((recipient: any, index: number) => {
                  const displayName = recipient?.name || recipient?.address;

                  if (!!!displayName) return null;

                  if (
                    !!recipient?.name &&
                    index === emailRecipients?.length - 1
                  ) {
                    return displayName;
                  } else if (emailRecipients?.length === 2 || !!displayName) {
                    return `${displayName}, `;
                  }
                  return `${displayName}, `;
                })}
            </div>
            <div>
              {stripHtml(email?.mostRecentMessage?.body?.content)?.slice(
                0,
                100
              )}
            </div>
            {threadEmails?.length > 0 && (
              <Card
                style={{
                  padding: "16px 16px 8px 16px",
                  border: "1px solid rgb(236, 239, 242)",
                  boxShadow: "rgb(0 0 0 / 9%) 0px 3px 12px",
                  borderRadius: "4px",
                  marginTop: "10px",
                }}
              >
                {threadEmails
                  .slice(0)
                  .reverse()
                  .map((email) => {
                    const emailRecipients = email?.toRecipients.concat(
                      email?.ccRecipients
                    );

                    return (
                      <>
                        <Grid container justifyContent={"space-between"}>
                          <Grid item xs={6}>
                            <span
                              style={{
                                fontSize: "11px",
                                fontWeight: "600",
                                display: "inline-block",
                                marginBottom: "10px",
                                color: "#595d64",
                              }}
                            >
                              {email?.from?.emailAddress?.name} <br />
                              to{" "}
                              {emailRecipients?.map(
                                (recipient: any, index: number) => {
                                  const displayName =
                                    recipient?.emailAddress?.name ||
                                    recipient?.emailAddress?.address;

                                  if (index === emailRecipients?.length - 1) {
                                    return displayName;
                                  } else if (emailRecipients?.length === 2) {
                                    return `${displayName} and `;
                                  }
                                  return `${displayName}, `;
                                }
                              )}
                            </span>
                          </Grid>
                          <Grid>
                            <Typography sx={{ fontSize: "12px" }}>
                              {formatEmailDate(new Date(email?.sentDateTime))}
                            </Typography>
                            <Typography sx={{ fontSize: "12px" }}>
                              {email?.openedCount} opens
                            </Typography>
                          </Grid>
                        </Grid>
                        <Divider sx={{ marginBottom: "5px" }} />
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              email?.body?.content || email?.body?.textHtml,
                          }}
                        />
                        <Divider
                          style={{ marginTop: "20px", marginBottom: "20px" }}
                        />
                      </>
                    );
                  })}
              </Card>
            )}
          </div>
        </TimelineContent>
      </TimelineItem>
    </>
  );
};

function ShowEmailModal(props: {
  showEmail: any;
  handleCloseModal: any;
  conversationId: string;
}) {
  const { showEmail, handleCloseModal, conversationId } = props;

  const { getAccessTokenSilently, user } = useAuth0();

  const [threadEmails, setThreadEmails] = useState<any[]>([]);
  const [currentEmailIndex, setCurrentEmailIndex] = useState<number[]>([]);
  const [expandEmailItems, setExpandEmailItems] = useState<boolean>(false);
  const [expandHovering, setExpandHovering] = useState<boolean>(false);
  const [openReply, setOpenReply] = useState<boolean>(false);
  const editorRef = useRef(null);
  const [replyRecipients, setReplyRecipients] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const expandEmailCall = async () => {
    try {
      const accessToken = await getAccessTokenSilently();

      const emailRes = await fetch(
        `${apiURL}email/read-conversation-messages?conversationId=${conversationId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const emails = await emailRes.json();
      setThreadEmails(emails.messages || emails || []);
    } catch (error) {
      console.log(error);
    }
  };

  const handleNodeChange = (e: any) => {
    // @ts-ignore
    const selection = editorRef?.current?.selection?.getContent();
    const toolbar = document.querySelector(".tox-editor-header");

    if (toolbar) {
      if (selection && selection.length > 0) {
        // @ts-ignore
        toolbar.style.display = "flex";
        // @ts-ignore
        toolbar.style.width = "184px";
        // @ts-ignore
        toolbar.style.transform = "translateY(-4px)";
      } else {
        // @ts-ignore
        toolbar.style.display = "none";
      }
    }
  };

  useEffect(() => {
    if (showEmail) {
      expandEmailCall();
    } else {
      setThreadEmails([]);
    }
  }, [conversationId, showEmail, getAccessTokenSilently]);

  useEffect(() => {
    if (threadEmails?.length > 0) {
      setCurrentEmailIndex([threadEmails.length - 1]);
    }
  }, [threadEmails]);

  // const displayedEmail = threadEmails[currentEmailIndex];

  if (!conversationId) return <></>;

  return (
    <>
      {loading && <LoadingScreen />}

      <Modal
        open={showEmail}
        onClose={() => {
          setExpandEmailItems(false);
          setExpandHovering(false);
          setOpenReply(false);
          handleCloseModal();
        }}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        sx={{
          maxHeight: "80vh",
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(255, 250, 250, 0.60)",
          },
        }}
      >
        <Box
          {...modalBoxStyle}
          sx={{
            top: "65vh",
            width: "70%",
            maxHeight: "80vh",
            transform: "translate(-50%, -60vh)",
            overflow: "scroll",
            paddingTop: "0px",
            paddingBottom: "0px",
          }}
        >
          <OnboardingHeader
            sx={{
              fontSize: "22px",
              textAlign: "left",
              padding: "24px",
              position: "sticky",
              top: 0,
              backgroundColor: "white",
              zIndex: 1,
            }}
          >
            {threadEmails[0]?.subject}
          </OnboardingHeader>
          {threadEmails.length < 4 || expandEmailItems ? (
            threadEmails.map((email, index) => {
              return (
                <EmailListItem
                  email={email}
                  index={index}
                  currentEmailIndex={currentEmailIndex}
                  setCurrentEmailIndex={setCurrentEmailIndex}
                />
              );
            })
          ) : (
            <>
              <EmailListItem
                email={threadEmails[0]}
                index={0}
                currentEmailIndex={currentEmailIndex}
                setCurrentEmailIndex={setCurrentEmailIndex}
              />
              <div style={{ marginTop: "12px", height: "12px" }}>
                <div
                  style={{
                    width: "100%",
                    height: "8px",
                    borderTop: "1px solid rgba(0,0,0,.2)",
                    borderBottom: "1px solid rgba(0,0,0,.2)",
                  }}
                />
                <IconButton
                  sx={{
                    height: "36px",
                    width: "36px",
                    fontSize: "16px",
                    fontWeight: 500,
                    color: "#344054",
                    border: "1px solid rgba(0,0,0,.2)",
                    backgroundColor: "#fff",
                    marginTop: "-35px",
                    "&:hover": {
                      backgroundColor: "#fff",
                    },
                  }}
                  onClick={() => {
                    setExpandEmailItems(true);
                  }}
                  onMouseEnter={() => setExpandHovering(true)}
                  onMouseLeave={() => setExpandHovering(false)}
                >
                  {expandHovering ? (
                    <UnfoldMoreIcon sx={{ backgroundColor: "#FFF" }} />
                  ) : (
                    threadEmails?.length - 3
                  )}
                </IconButton>
              </div>
              <EmailListItem
                email={threadEmails[threadEmails.length - 2]}
                index={threadEmails.length - 2}
                currentEmailIndex={currentEmailIndex}
                setCurrentEmailIndex={setCurrentEmailIndex}
              />
              <EmailListItem
                email={threadEmails[threadEmails.length - 1]}
                index={threadEmails.length - 1}
                currentEmailIndex={currentEmailIndex}
                setCurrentEmailIndex={setCurrentEmailIndex}
              />
            </>
          )}
          {openReply ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                position: "sticky",
                bottom: 0,
                backgroundColor: "white",
                zIndex: 1,
                padding: "24px",
              }}
            >
              <div
                style={{
                  padding: "14px",
                  border: "1px solid #D0D5DD",
                  borderRadius: "8px",
                  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                }}
              >
                <div style={{ marginBottom: "12px" }}>
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: 500,
                      color: "#344054",
                    }}
                  >
                    {user?.name}
                  </span>{" "}
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: 400,
                      color: "#667085",
                    }}
                  >
                    (
                    {!!user &&
                      user["https://crm.mercero-api.com/primaryEmailAddress"]}
                    )
                  </span>
                </div>
                <div style={{ marginBottom: "16px" }}>
                  <span style={{ color: "#667085", fontSize: "12px" }}>
                    To:{" "}
                  </span>
                  {replyRecipients?.map((r: any) => {
                    return (
                      <CustomChip
                        label={r?.emailAddress?.address}
                        sx={{
                          marginRight: "10px",
                          "& .MuiSvgIcon-root": {
                            marginLeft: "8px",
                            fontSize: "16px",
                          },
                        }}
                        onDelete={() => {
                          const newRecipients = replyRecipients.filter(
                            (recipient) => {
                              return (
                                recipient?.emailAddress?.address !==
                                r?.emailAddress?.address
                              );
                            }
                          );
                          setReplyRecipients(newRecipients);
                        }}
                      />
                    );
                  })}
                  <TextField
                    placeholder="Add recipients"
                    variant="standard"
                    InputProps={{
                      startAdornment: (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M7.99967 3.33398V12.6673M3.33301 8.00065H12.6663"
                            stroke="#525866"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      ),
                      disableUnderline: true,
                    }}
                    sx={{
                      "& .MuiInputBase-input": {
                        fontSize: "14px",
                        padding: "6px 12px",
                        border: "none",
                      },
                    }}
                    onKeyUp={(event: any) => {
                      if (event.key === "Enter") {
                        const newRecipients = replyRecipients.concat([
                          { emailAddress: { address: event.target.value } },
                        ]);
                        setReplyRecipients(newRecipients);
                        event.target.value = "";
                      }
                    }}
                  />
                </div>
                <Editor
                  apiKey="sf3duu37n3516sap1ur5edu5dcvla052148mvggs0ue2zuit"
                  key={"body"}
                  // @ts-ignore
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  // initialValue="<p>Type your email here</p>"
                  init={{
                    placeholder: "Type your email here",
                    browser_spellcheck: true,
                    auto_focus: true,
                    height: 250,
                    width: "100%",
                    menubar: false,
                    inline: true,
                    plugins: [
                      "advlist",
                      "autolink",
                      "lists",
                      "link",
                      "image",
                      "charmap",
                      "preview",
                      "anchor",
                      "searchreplace",
                      "visualblocks",
                      "code",
                      "fullscreen",
                      "insertdatetime",
                      "media",
                      "table",
                      "code",
                    ],
                    toolbar: "bold italic underline bullist",
                    force_br_newlines: true,
                    forced_root_block: "div",
                    force_p_newlines: false,
                    content_style:
                      "body { font-size:14px; line-height: 1; }" +
                      ".tox-tinymce { border: none !important; }",
                    setup: (editor: any) => {
                      editor.on("NodeChange", handleNodeChange);
                    },
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    gap: "12px",
                    backgroundColor: "white",
                    paddingTop: "16px",
                    justifyContent: "flex-end",
                  }}
                >
                  <CustomButton
                    sx={{ fontSize: "14px" }}
                    onClick={() => {
                      setOpenReply(false);
                    }}
                  >
                    Cancel
                  </CustomButton>
                  <CustomButton
                    sx={{
                      fontSize: "14px",
                      "& .MuiButton-startIcon": {
                        marginRight: "2px",
                      },
                    }}
                    startIcon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M1.66699 5.83398L8.47109 10.5969C9.02207 10.9825 9.29756 11.1754 9.59721 11.2501C9.8619 11.3161 10.1387 11.3161 10.4034 11.2501C10.7031 11.1754 10.9786 10.9825 11.5296 10.5969L18.3337 5.83398M5.66699 16.6673H14.3337C15.7338 16.6673 16.4339 16.6673 16.9686 16.3948C17.439 16.1552 17.8215 15.7727 18.0612 15.3023C18.3337 14.7675 18.3337 14.0674 18.3337 12.6673V7.33398C18.3337 5.93385 18.3337 5.23379 18.0612 4.69901C17.8215 4.2286 17.439 3.84615 16.9686 3.60647C16.4339 3.33398 15.7338 3.33398 14.3337 3.33398H5.66699C4.26686 3.33398 3.5668 3.33398 3.03202 3.60647C2.56161 3.84615 2.17916 4.2286 1.93948 4.69901C1.66699 5.23379 1.66699 5.93385 1.66699 7.33398V12.6673C1.66699 14.0674 1.66699 14.7675 1.93948 15.3023C2.17916 15.7727 2.56161 16.1552 3.03202 16.3948C3.5668 16.6673 4.26686 16.6673 5.66699 16.6673Z"
                          stroke="white"
                          stroke-width="1.66667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    }
                    onClick={async () => {
                      console.log("Send email");
                      const accessToken = await getAccessTokenSilently();

                      const formData = new FormData();

                      formData.append(
                        "comment",
                        // @ts-ignore
                        editorRef?.current?.getContent()
                      );
                      formData.append(
                        "message",
                        JSON.stringify({ toRecipients: replyRecipients })
                      );
                      formData.append(
                        "msgId",
                        threadEmails[threadEmails?.length - 1]?.id
                      );

                      setLoading(true);

                      const replyRes = await fetch(`${apiURL}email/reply`, {
                        headers: {
                          Authorization: `Bearer ${accessToken}`,
                        },
                        method: "POST",
                        body: formData,
                      });

                      setLoading(false);
                      setOpenReply(false);
                      expandEmailCall();
                    }}
                  >
                    Send
                  </CustomButton>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                gap: "12px",
                position: "sticky",
                bottom: 0,
                backgroundColor: "white",
                zIndex: 1,
                padding: "24px",
                justifyContent: "flex-end",
              }}
            >
              <CustomButton
                sx={{ fontSize: "14px" }}
                onClick={() => {
                  const reply = threadEmails[
                    threadEmails?.length - 1
                  ]?.toRecipients
                    .concat([threadEmails[threadEmails?.length - 1]?.from])
                    ?.filter((r: any) => {
                      return (
                        r?.emailAddress?.address !==
                        user?.[
                          "https://crm.mercero-api.com/primaryEmailAddress"
                        ]
                      );
                    });

                  setReplyRecipients(reply);
                  setOpenReply(true);
                }}
              >
                Reply
              </CustomButton>
              <CustomButton
                sx={{ fontSize: "14px" }}
                onClick={() => {
                  const replyAll = threadEmails[
                    threadEmails?.length - 1
                  ]?.toRecipients
                    .concat(
                      threadEmails[threadEmails?.length - 1]?.ccRecipients
                    )
                    .concat([threadEmails[threadEmails?.length - 1]?.from])
                    ?.filter((r: any) => {
                      return (
                        r?.emailAddress?.address !==
                        user?.[
                          "https://crm.mercero-api.com/primaryEmailAddress"
                        ]
                      );
                    });

                  setReplyRecipients(replyAll);
                  setOpenReply(true);
                }}
              >
                Reply All
              </CustomButton>
            </div>
          )}
        </Box>
      </Modal>
    </>
  );
}

function EmailListItem(props: {
  email: any;
  index: number;
  currentEmailIndex: number[];
  setCurrentEmailIndex: any;
}) {
  const { email, index, currentEmailIndex, setCurrentEmailIndex } = props;

  const isOpen = currentEmailIndex.includes(index);

  const recipients = email?.toRecipients.concat(email?.ccRecipients);

  return (
    <ListItem>
      <ListItemButton
        onClick={() => {
          if (isOpen) {
            const newEmailIndex = currentEmailIndex.filter((n) => n !== index);
            setCurrentEmailIndex(newEmailIndex);
          } else {
            setCurrentEmailIndex([...currentEmailIndex, index]);
          }
        }}
      >
        <ListItemText
          primary={
            <Grid container justifyContent={"space-between"}>
              <Grid item xs={8}>
                <Typography
                  sx={{
                    color: "#344054",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "20px",
                  }}
                >
                  {email?.from?.emailAddress?.name ||
                    email?.from?.emailAddress?.address}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={{ fontSize: "12px", textAlign: "right" }}>
                  {formatEmailDate(new Date(email?.sentDateTime))}
                </Typography>
              </Grid>
            </Grid>
          }
          secondary={
            isOpen ? (
              <Grid container justifyContent={"space-between"}>
                <Grid item xs={8}>
                  <span
                    style={{
                      fontSize: "11px",
                      display: "inline-block",
                      marginBottom: "10px",
                      color: "#5e5e5e",
                    }}
                  >
                    to&nbsp;
                    {recipients?.map((recipient: any, index: number) => {
                      const displayName =
                        recipient?.emailAddress?.name ||
                        recipient?.emailAddress?.address;

                      if (!!!displayName) return null;

                      if (index === recipients?.length - 1) {
                        return displayName.trim();
                      }

                      return `${displayName}, `;
                    })}
                  </span>
                </Grid>
                <Grid item xs={4}>
                  <Typography sx={{ fontSize: "12px", textAlign: "right" }}>
                    {email?.openedCount} opens
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    backgroundColor: "#F9FAFB",
                    border: "1px solid #EAECF0",
                    padding: "8px 12px",
                    borderRadius: "4px",
                  }}
                >
                  {/* <iframe
                          title={"Email"}
                          srcDoc={email?.body?.content || email?.body?.textHtml}
                        /> */}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: email?.body?.content || email?.body?.textHtml,
                    }}
                  />
                </Grid>
              </Grid>
            ) : (
              <div
                style={{
                  paddingTop: "12px",
                  textWrap: "nowrap",
                  overflow: "hidden",
                  width: "100%",
                  textOverflow: "ellipsis",
                  backgroundColor: "#F9FAFB",
                  border: "1px solid #EAECF0",
                  padding: "8px 12px",
                  borderRadius: "4px",
                  marginTop: "4px",
                }}
              >
                {stripHtml(
                  email?.body?.content || email?.body?.textHtml
                )?.trim()}
                <div style={{ display: "flex", gap: "12px" }}>
                  {email?.body?.attachments?.map((attachment: any) => {
                    return (
                      <div
                        style={{
                          padding: "8px 12px",
                          border: "1px solid rgba(0,0,0,.4)",
                          width: "fit-content",
                          marginTop: "12px",
                          display: "flex",
                          alignItems: "center",
                          borderRadius: "4px",
                        }}
                      >
                        {handlePreviewIcon(
                          {
                            fileType: attachment?.headers["content-type"] || "",
                          },
                          {}
                        )}
                        {attachment.filename}
                      </div>
                    );
                  })}
                </div>
              </div>
            )
          }
        />
      </ListItemButton>
    </ListItem>
  );
}

function GetAvatarChars(name: string) {
  const nameSplit = name?.split(" ");
  if (!nameSplit) return null;
  const firstInitial = nameSplit[0]?.charAt(0);
  const secondInitial = nameSplit[1]?.charAt(0);

  if (!!secondInitial) return `${firstInitial}${secondInitial}`;

  return firstInitial;
}

function getRandomColor() {
  // Define an array of soft and appealing colors
  var colors = [
    "#0072CE",
    "#FF4081",
    "#00BFA5",
    "#FFC107",
    "#536DFE",
    "#FF5722",
    "#9C27B0",
    "#795548",
    "#FF9800",
    "#4CAF50",
  ];

  // Select a random color from the array
  var color = colors[Math.floor(Math.random() * colors?.length)];

  return color;
}

function formatEmailDate(date: Date) {
  const now = new Date();
  const givenDate = new Date(date);
  const dateFormatter = new Intl.DateTimeFormat("en", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  // Calculate the difference in days
  const yesterday = new Date(now);
  yesterday.setDate(yesterday.getDate() - 1);

  // Format the time part always
  const timePart = dateFormatter.format(givenDate);

  if (givenDate.toDateString() === now.toDateString()) {
    // If the given date is today
    return timePart;
  } else if (givenDate.toDateString() === yesterday.toDateString()) {
    // If the given date was yesterday
    return `Yesterday, ${timePart}`;
  } else if (now.getFullYear() === givenDate.getFullYear()) {
    // If the given date is in the current year
    return givenDate.toLocaleDateString("en", {
      month: "short",
      day: "numeric",
    });
  } else {
    // If the given date is in a different year
    return givenDate.toLocaleDateString("en", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  }
}
