import { createContext, useEffect, useState } from "react";
import "./App.css";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { useAuth0 } from "@auth0/auth0-react";
import { LicenseInfo } from "@mui/x-data-grid-premium";
import { Link, Outlet, useLocation } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  useQuery,
  NormalizedCacheObject,
} from "@apollo/client";
import * as Realm from "realm-web";
import Sidebar from "./navBar";
import RefetchProvider from "./refetchProvider";
import { apiURL } from "./consts";
import SystemMessage from "./systemMessage";
import { cache } from "./cache";
import { Typography, useMediaQuery } from "@mui/material";
import SocketIOProvider from "./socketIOProvider";
import { CachePersistor } from "apollo3-cache-persist";
import localForage from "localforage";
import _ from "lodash";
import RowProvider from "./rowProvider";
import GlobalProvider from "./globalContext";
import LoadingScreen from "./loadingScreen";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import SkipTraceSocketIOProvider from "./skipTraceSocketIOProvider";
import AtlasDataPipelineProvider from "./atlasDataPipelineProvider";

const drawerWidth = 220;

const interFont = {
  fontFamily: "Inter",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local('Inter'),
    url('/fonts/Inter-Regular.woff2') format('woff2')
  `,
};

const interFont500 = {
  fontFamily: "Inter",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 500,
  src: `
    local('Inter'),
    url('/fonts/Inter-Medium.woff2') format('woff2')
  `,
};

const theTheme = createTheme({
  typography: {
    fontFamily: "'Inter', sans-serif",
    body1: {
      fontSize: "11px !important",
    },
    // add more typography settings as needed
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@font-face": [interFont, interFont500],
      },
    },
  },
});

export function filterOutColumns(columns: any[]) {
  return columns?.filter(
    (column) =>
      !["date", "actions", "__check__", "__reorder__", "new-column"]?.includes(
        column?.field
      ) && !column?.field?.includes("merc_")
  );
}

export function filterOutColumnsForDisplay(columns: any[]) {
  return columns?.filter(
    (column) =>
      !["date", "actions", "__check__", "__reorder__", "new-column"]?.includes(
        column?.field
      ) &&
      column?.type !== "lastActivity" &&
      !column?.type?.includes("related")
  );
}

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

/**
 *
 * Handle Mongo Auth
 */

// Once your app is set up, replace the value of APP_ID with your App ID
export const PROD_APP_ID = "prod-crm-dlamt";
export const DEV_APP_ID = "devql-dfuhx";
export const STAGING_APP_ID = "thomasdev-vriyw";

export const APP_ID = PROD_APP_ID;

//
export const PROD_GQL_URL =
  "https://us-west-2.aws.realm.mongodb.com/api/client/v2.0/app/prod-crm-dlamt/graphql";
export const DEV_GQL_URL =
  "https://us-west-2.aws.realm.mongodb.com/api/client/v2.0/app/devql-dfuhx/graphql";
export const STAGING_GQL_URL =
  "https://us-west-2.aws.realm.mongodb.com/api/client/v2.0/app/thomasdev-vriyw/graphql";

export const GQL_URL = PROD_GQL_URL;

// Connect to your MongoDB Realm app
const app = new Realm.App(APP_ID);

async function loginCustomJwt(jwt: string) {
  // Create a Custom JWT credential
  const credentials = Realm.Credentials.jwt(jwt);
  // Authenticate the user
  const user = await app.logIn(credentials);
  // `App.currentUser` updates to match the logged in user
  console.assert(user.id === app?.currentUser?.id);
  return user;
}

export default function Layout() {
  //const theme = useTheme();
  LicenseInfo.setLicenseKey(
    "d061d62b6dcae43902c7f3788128f0d4Tz05NTc5MCxFPTE3NTQ3MDg2MDQwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI="
  );

  const {
    user,
    getAccessTokenSilently,
    isAuthenticated,
    loginWithRedirect,
    isLoading,
  } = useAuth0();

  const isMobile = useMediaQuery("(max-width:600px)");

  const location = useLocation();
  const hasWelcome = location.pathname.includes("welcome");
  const hasMap = location.pathname.includes("map");
  const isV2 = location.pathname.includes("properties");

  const [views, setViews] = useState<any[]>([]);
  const [mongoUser, setMongoUser] = useState<any>(null);

  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();
  const [persistor, setPersistor] =
    useState<CachePersistor<NormalizedCacheObject>>();

  useEffect(() => {
    async function init() {
      let newPersistor = new CachePersistor({
        cache,
        storage: localForage,
        debug: true,
        trigger: "write",
        maxSize: false,
      });
      await newPersistor.restore();
      setPersistor(newPersistor);
      setClient(
        new ApolloClient({
          connectToDevTools: true,
          link: new HttpLink({
            uri: GQL_URL,
            fetch: async (uri, options) => {
              const jwt = await getAccessTokenSilently();
              const user = await loginCustomJwt(jwt);
              // @ts-ignore
              // options.headers.jwtTokenString = `${user._accessToken}`;
              // @ts-ignore
              options.headers.Authorization = `Bearer ${user._accessToken}`;
              return fetch(uri, options);
            },
          }),
          // @ts-ignore
          cache,
        })
      );
    }

    init().catch(console.error);
  }, []);

  useEffect(() => {
    const getUserMetadata = async () => {
      try {
        const accessToken = await getAccessTokenSilently();

        const allViewsResponse = await fetch(`${apiURL}view/user`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const views = await allViewsResponse.json();

        const parsedViews = views.map((view: any) => {
          return {
            ...view,
            //columns: parseObjectWithFunctions(view.columnsJsonString),
            columns: filterOutColumns(view.columns),
            initialState: JSON.parse(view.initialState),
          };
        });

        setViews(parsedViews);
      } catch (error) {
        console.log(error);
      }
    };

    const getMongoUser = async () => {
      const jwt = await getAccessTokenSilently();
      const user = await loginCustomJwt(jwt);
      setMongoUser(user);
    };

    getUserMetadata();
    getMongoUser();
  }, [getAccessTokenSilently, user?.sub]);

  useEffect(() => {
    // Define the Intercom settings
    if (!user) return;
    // @ts-ignore
    window.intercomSettings = {
      api_base: "https://api-iam.intercom.io",
      app_id: "e3od59hf",
      // Assuming you have a user object from your auth
      name: user?.name, // Full name
      email: user?.email, // Email address
      created_at: user?.createdAt, // Signup date as a Unix timestamp
    };

    // Create a script element
    const script = document.createElement("script");
    script.src = "https://widget.intercom.io/widget/e3od59hf"; // Use the correct src from Intercom docs
    script.async = true;
    document.body.appendChild(script);
    // @ts-ignore
    if (window.Intercom) {
      // @ts-ignore
      window.Intercom("reattach_activator");
      // @ts-ignore
      window.Intercom("update", window.intercomSettings);
    }

    // Cleanup the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, [user]);

  if (isLoading) {
    return <LoadingScreen customMessage={"Welcome to Mercero"} />;
  }

  if (!isAuthenticated) {
    const searchParams = new URLSearchParams(location.search);
    const hasSignup = searchParams.has("signup");
    if (hasSignup) {
      loginWithRedirect({
        authorizationParams: {
          screen_hint: "signup",
        },
      });
    } else {
      loginWithRedirect();
    }
  }

  if (!client) {
    return <LoadingScreen customMessage={"Welcome to Mercero"} />;
  }

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theTheme}>
        <GlobalProvider>
          <SocketIOProvider>
            <RefetchProvider>
              <RowProvider>
                <SkipTraceSocketIOProvider>
                  <AtlasDataPipelineProvider>
                    {/* <Link to="/properties">
                  <div
                    style={{
                      marginLeft: "220px",
                      padding: "12px",
                      backgroundColor: "#DEE9EF",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    className="atlasAnnouncement"
                  >
                    <AutoAwesomeIcon sx={{ marginRight: "8px" }} />

                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "center",
                        // color: "white",
                        fontWeight: "500",
                        letterSpacing: "0.03rem",
                      }}
                    >
                      We're excited to announce{" "}
                      <span style={{ fontWeight: "600" }}>Atlas</span>,
                      Mercero's AI Property researcher. Click here to try it
                      out.
                    </Typography>
                  </div>
                </Link> */}
                    <Grid container>
                      <SystemMessage />

                      {isMobile && (
                        <Grid item xs={0} component={"div" as any}>
                          <Sidebar key="mobileSidebar" />
                        </Grid>
                      )}

                      {!isMobile && !hasWelcome && !hasMap && (
                        <Sidebar key="mainSidebar" />
                      )}

                      <Grid item xs={true} component={"div" as any}>
                        {/* <Main style={{ padding: 0 }}> */}
                        <Outlet />
                        {/* </Main> */}
                      </Grid>

                      {/* </Box> */}
                    </Grid>
                  </AtlasDataPipelineProvider>
                </SkipTraceSocketIOProvider>
              </RowProvider>
            </RefetchProvider>
          </SocketIOProvider>
        </GlobalProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}
