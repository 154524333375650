import { useAuth0 } from "@auth0/auth0-react";
import {
  Chip,
  Drawer,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { CustomButton } from "./PersonalDetails";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import {
  DataGridPremium,
  GridRenderCellParams,
} from "@mui/x-data-grid-premium";
import { useQuery } from "@apollo/client";
import { GET_COMPANIES } from "./v2-gql";
import CloseIcon from "@mui/icons-material/Close";
import { CustomChip } from "./individualView";
import { PropertySmallDisplay } from "./v2contactsSection";
import { SidebarContacts } from "./v2Utils";
import { WorkspaceContext } from "./v2workspaceProvider";

const getColumns = (openSidebar: any, setOpenContacts: any) => [
  {
    field: "entityName",
    headerName: "Entity Name",
    width: 275,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Link style={{ color: "#0000EE" }} to={`company/${params.row._id}`}>
          {params.value}
        </Link>
      );
    },
  },
  {
    field: "dba",
    headerName: "DBA",
    width: 200,
  },
  {
    field: "domain",
    headerName: "Domain",
    width: 200,
  },
  {
    field: "address",
    headerName: "Mailing Address",
    width: 200,
  },
  {
    field: "tags",
    headerName: "Tags",
    width: 200,
    renderCell: (params: GridRenderCellParams) => {
      return params?.value?.map((tag: any) => {
        return (
          <Chip
            variant="outlined"
            size="small"
            label={tag}
            sx={{ fontSize: "12px", marginRight: "8px" }}
          />
        );
      });
    },
  },
  {
    field: "contacts",
    headerName: "Contacts",
    width: 600,
    renderCell: (params: GridRenderCellParams) => {
      return params?.value?.map((contact: any, index: number) => {
        return (
          <CustomChip
            variant="outlined"
            onClick={() => {
              setOpenContacts({ contact: params?.value, index });
              openSidebar(undefined);
            }}
            size="small"
            label={contact?.name?.toLowerCase()}
            sx={{
              fontSize: "12px",
              marginRight: "8px",
              textTransform: "capitalize",
            }}
          />
        );
      });
    },
  },
  {
    field: "properties",
    headerName: "Properties",
    width: 600,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <div style={{ display: "flex", gap: "4px" }}>
          {params?.value?.map((prop: any, index: number) => {
            return (
              <CustomChip
                label={prop?.address}
                onClick={() => {
                  openSidebar({ properties: params?.value, index });
                  setOpenContacts(undefined);
                }}
              />
            );
          })}
        </div>
      );
    },
  },
];

const drawerWidth = 350;

export default function CompaniesSection() {
  const { user, getAccessTokenSilently } = useAuth0();

  const [filteredRows, setFilteredRows] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [openProperties, setOpenProperties] = useState<any>(undefined);
  const [openContacts, setOpenContacts] = useState<any>(undefined);

  const workspaceContext = useContext(WorkspaceContext);

  const {
    data,
    loading: loadingCompanies,
    error,
  } = useQuery(GET_COMPANIES, {
    variables: {
      query: {
        workspaceId: workspaceContext?.workspaces?.[0],
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value?.toLowerCase();
    const newFilteredRows = data?.getCompanies?.filter((row: any) => {
      return row.entityName?.toLowerCase()?.includes(searchValue);
    });
    setFilteredRows(newFilteredRows);
  };

  useEffect(() => {
    if (data) {
      setFilteredRows([...data?.getCompanies]?.reverse());
    }
  }, [data]);

  if (loadingCompanies) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{
          width: "90%",
          //   maxWidth: "1048px",
          margin: "auto",
          marginTop: "12px",
          backgroundColor: "white",
          padding: "24px",
          borderRadius: "12px",
        }}
      >
        <Grid
          xs={6}
          sx={{ display: "flex", flexDirection: "row", gap: "12px" }}
        >
          <Paper
            // component="form"
            sx={{
              p: "0px",
              display: "flex",
              alignItems: "center",
              width: "250px !important",
              boxShadow: "none",
              border: "1px solid #d1d1d1",
              backgroundColor: "#f7f9fa",
            }}
          >
            <div style={{ padding: "4px 8px", display: "flex" }}>
              <SearchIcon sx={{ fontSize: "18px", color: "#2b70ef" }} />
            </div>
            <InputBase
              sx={{ ml: 1, flex: 1, fontSize: "14px" }}
              placeholder="Search Contacts"
              inputProps={{ "aria-label": "search contacts" }}
              onChange={handleSearch} // Listen for changes here
            />
            {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
          </Paper>
          <Tooltip title="Filter Contacts">
            <IconButton
              type="button"
              sx={{ p: "4px" }}
              aria-label="filter"
              onClick={() => {}}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid
          xs={6}
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "12px",
            justifyContent: "flex-end",
          }}
        >
          <CustomButton
            sx={{
              fontSize: "14px",
              padding: "4px 8px",
            }}
          >
            <AddIcon sx={{ fontSize: "20px" }} />
            Add Contact
          </CustomButton>
        </Grid>
        <Grid xs={12} sx={{ marginTop: "24px", height: "calc(100vh - 250px)" }}>
          <DataGridPremium
            autoPageSize
            pagination
            rowHeight={50}
            getRowId={(row) => row._id}
            rows={filteredRows}
            loading={loadingCompanies}
            columns={getColumns(setOpenProperties, setOpenContacts)}
            initialState={{
              pinnedColumns: { left: ["__check__", "name"] },
            }}
            pageSizeOptions={[5]}
            checkboxSelection
            disableRowSelectionOnClick
          />
        </Grid>
      </Grid>
      <Drawer
        open={!!openProperties}
        onClose={() => setOpenProperties(undefined)}
        variant="persistent"
        anchor="right"
        sx={{
          width: drawerWidth,
          padding: "12px",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            padding: "12px",
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "12px",
          }}
        >
          <Typography variant="body1">Related Properties</Typography>
          <IconButton
            onClick={() => {
              setOpenProperties(undefined);
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <PropertySmallDisplay openProperties={openProperties} />
      </Drawer>
      <Drawer
        open={!!openContacts}
        onClose={() => setOpenContacts(undefined)}
        variant="persistent"
        anchor="right"
        sx={{
          width: drawerWidth,
          padding: "12px",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            padding: "12px",
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "12px",
          }}
        >
          <Typography variant="body1">Related Contacts</Typography>
          <IconButton
            onClick={() => {
              setOpenContacts(undefined);
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <SidebarContacts openContacts={openContacts} />
      </Drawer>
    </>
  );
}
