import { NavLink } from "react-router-dom";
import { useContext, useRef, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { StyledMenu } from "./topButton";
import { WorkspaceContext } from "./v2workspaceProvider";
import { useAuth0 } from "@auth0/auth0-react";
import { Divider, IconButton, Menu, MenuItem } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import PersonIcon from "@mui/icons-material/Person";
import WorkspacesIcon from "@mui/icons-material/Workspaces";

export default function WorkspaceDropdown() {
  // const context = useContext(RefetchContext);
  const workspaceContext = useContext(WorkspaceContext);
  const workspaces = workspaceContext?.workspaces;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  //   const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        sx={{ marginRight: "12px" }}
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          if (!!anchorEl) {
            handleClose();
          } else {
            handleClick(event);
          }
        }}
      >
        <WorkspacesIcon />
      </IconButton>
      <StyledMenu
        //onClick={handleClick}
        open={!!anchorEl}
        onClose={handleClose}
        anchorEl={anchorEl}
        style={{ fontSize: ".75em" }}
      >
        <MenuItem
          key={`${workspaceContext?.selectedWorkspace?._id}-selected`}
          sx={{ fontWeight: 600 }}
          disableRipple
        >
          <WorkspacesIcon />
          {workspaceContext?.selectedWorkspace?.name}
        </MenuItem>

        <Divider sx={{ my: 0.5 }} />

        {workspaces?.map((workspace: any) => (
          <MenuItem
            key={`${workspace?._id}-headerMenu`}
            onClick={() => {
              window.localStorage.setItem("selectedWorkspace", workspace._id);
              workspaceContext?.setSelectedWorkspace(workspace);
              handleClose();
            }}
            disableRipple
          >
            <WorkspacesIcon />
            {workspace?.name}
          </MenuItem>
        ))}
        <NavLink
          to="/workspace/create"
          className={({ isActive }) => (isActive ? "active" : "inactive")}
          onClick={handleClose}
        >
          <MenuItem key={"Create-Workspace-MenuItem"} disableRipple>
            <AddIcon />
            Create new workspace
          </MenuItem>
        </NavLink>
        <Divider sx={{ my: 0.5 }} />
        <NavLink
          to="/settings"
          className={({ isActive }) => (isActive ? "active" : "inactive")}
          onClick={handleClose}
        >
          <MenuItem key={"Account-Settings-MenuItem"} disableRipple>
            <PersonIcon />
            Account Settings
          </MenuItem>
        </NavLink>
        <NavLink
          to={`/${workspaceContext?.selectedWorkspace?._id}/settings`}
          className={({ isActive }) => (isActive ? "active" : "inactive")}
          onClick={handleClose}
        >
          <MenuItem key="Workspace-Settings-MenuItem" disableRipple>
            <SettingsIcon />
            Workspace Settings
          </MenuItem>
        </NavLink>
      </StyledMenu>
    </div>
  );
}
