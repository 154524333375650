import { isArray } from "lodash";
import { getRelatedRows } from "./dataDisplay";
import { useQuery } from "@apollo/client";
import { GET_ONE_VIEW } from "./graphql-ops";
import { useParams } from "react-router-dom";
import { useContext } from "react";
import { RefetchContext } from "./refetchProvider";
import {
  GetHeaderText,
  formatPhoneNumber,
  stripPhoneNumber,
} from "./DatagridFunctions";
import { RowContext } from "./rowProvider";
import { GlobalContext } from "./globalContext";

export function breakString(inputString: string) {
  // Split the inputString by '-'
  let parts = inputString.split("-");

  // Ensure we only take the last 4 parts
  if (parts.length > 4) {
    const firstOccurence = inputString.indexOf("-");
    const firstPart = inputString.slice(0, firstOccurence);
    const remainingParts = inputString.slice(firstOccurence + 1);
    const secondOccurence = remainingParts.indexOf("-");
    const secondPart = remainingParts.slice(0, secondOccurence);
    const thirdPart = remainingParts.slice(secondOccurence + 1);
    const lastOccurence = thirdPart.indexOf("-");
    const thirdPartFinal = thirdPart.slice(0, lastOccurence);
    const fourthPart = thirdPart.slice(lastOccurence + 1);
    return [firstPart, secondPart, thirdPartFinal, fourthPart];
  } else {
    return parts; // If fewer than 4 parts, return all parts
  }
}

export const DisplayRelatedField = (props: any) => {
  const { colDef, row, api } = props;

  const { rootViewId, listId } = useParams();

  const refetchContext = useContext(RefetchContext);
  const globalContext = useContext(GlobalContext);

  const parsedType = breakString(colDef.type); //.split("-");
  const viewId = parsedType?.[1];
  const column = parsedType?.[3];

  // const {
  //   loading,
  //   error,
  //   data,
  //   refetch: refetchView,
  // } = useQuery(GET_ONE_VIEW, {
  //   variables: { query: { _id: viewId[1], isDeleted: false } },
  // });

  const columnObj = refetchContext?.allViews
    ?.find((view) => view._id === viewId)
    ?.columns?.filter((col: any) => {
      return col?.field === column;
    })?.[0];

  if (viewId && column) {
    const filteredRelated = row?.relatedRows?.filter((r: any) => {
      return r.merc_viewId === viewId;
    });

    const value = filteredRelated?.[0]?.[column];

    if (columnObj?.type?.toLowerCase() === "email") {
      return (
        <a
          href={`mailto:${value}`}
          style={{
            color: "#2e59a8",
            fontWeight: 500,
          }}
        >
          {value}
        </a>
      );
    }

    if (columnObj?.type?.toLowerCase() === "phone") {
      const formattedNumber = formatPhoneNumber(value);
      const phoneToCall = stripPhoneNumber(value);

      if (formattedNumber?.length !== 14) {
        return formattedNumber;
      }

      return (
        <a
          href={`tel:${phoneToCall}`}
          style={{
            color: "#2e59a8",
            fontWeight: 500,
          }}
        >
          {formattedNumber}
        </a>
      );
    }

    if (columnObj?.type === "date") {
      const date = new Date(value);
      if (isNaN(date.getTime())) {
        // Check if the date is invalid
        return "";
      } else {
        return date.toLocaleDateString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "2-digit",
        });
      }
    }

    if (columnObj?.type === "number") {
      const num = Number(value);
      if (isNaN(num)) {
        return "";
      } else {
        return num.toLocaleString("en-US");
      }
    }

    if (columnObj?.type?.includes("related")) {
      const parsedRelated = columnObj?.type.split("-");
      const relatedViewId = parsedRelated?.[1];
      const relatedViewColumns = refetchContext?.allViews?.find(
        (view) => view._id === relatedViewId
      )?.columns;

      const rowIds = filteredRelated?.map((r: any) => {
        return r?._id;
      });

      // @ts-ignore
      const correctRow = globalContext?.rows?.[viewId]
        ?.get(rowIds?.[0])
        ?.relatedRows?.filter((relRow: any) => {
          return relRow.merc_viewId === relatedViewId;
        });
      const getFieldValue = (type: string) =>
        correctRow?.[0]?.[
          relatedViewColumns?.find((col: any) => col?.type === type)?.field
        ];

      const header = GetHeaderText(correctRow?.[0], relatedViewColumns) || "";
      const companyName = getFieldValue("companyName");
      const emailValue = getFieldValue("email");
      const phoneValue = getFieldValue("phone");

      return (
        <>
          {header}
          {companyName && (
            <>
              {" - "}
              <span>({companyName})</span>
            </>
          )}
          {emailValue && (
            <>
              {" - "}
              <a
                href={`mailto:${emailValue}`}
                style={{ color: "#2e59a8", fontWeight: 500 }}
              >
                {emailValue}
              </a>
            </>
          )}
          {phoneValue && (
            <>
              {" - "}
              <a
                href={`tel:${phoneValue}`}
                style={{ color: "#2e59a8", fontWeight: 500 }}
              >
                {phoneValue}
              </a>
            </>
          )}
        </>
      );
    }

    return value;
  }
};
